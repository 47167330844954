import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bondSale = {
  _tag: `BondSale`,
  id: 1,
  name: `Bond Sale`
} as const;

export type BondSaleTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondSale`>
}>;
export const bondSaleTaggedC: BondSaleTaggedC = t.type({
  _tag: t.literal(`BondSale`)
});
export type BondSaleTagged = t.TypeOf<BondSaleTaggedC>;
export type BondSale = BondSaleTagged & typeof bondSale;
export type BondSaleC = t.Type<BondSale, BondSaleTagged>;
export const bondSaleC: BondSaleC = pipe(bondSaleTaggedC, c => new t.Type<BondSale, BondSaleTagged>(
  `BondSale`,
  (u: unknown): u is BondSale => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondSale> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondSale }))),
  (x: BondSale): BondSaleTagged => ({ ...x, _tag: `BondSale`}),
)) satisfies t.Type<BondSale, unknown>;


export const privateLoan = {
  _tag: `PrivateLoan`,
  id: 2,
  name: `Private Loan`
} as const;

export type PrivateLoanTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PrivateLoan`>
}>;
export const privateLoanTaggedC: PrivateLoanTaggedC = t.type({
  _tag: t.literal(`PrivateLoan`)
});
export type PrivateLoanTagged = t.TypeOf<PrivateLoanTaggedC>;
export type PrivateLoan = PrivateLoanTagged & typeof privateLoan;
export type PrivateLoanC = t.Type<PrivateLoan, PrivateLoanTagged>;
export const privateLoanC: PrivateLoanC = pipe(privateLoanTaggedC, c => new t.Type<PrivateLoan, PrivateLoanTagged>(
  `PrivateLoan`,
  (u: unknown): u is PrivateLoan => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateLoan> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateLoan }))),
  (x: PrivateLoan): PrivateLoanTagged => ({ ...x, _tag: `PrivateLoan`}),
)) satisfies t.Type<PrivateLoan, unknown>;


export const note = {
  _tag: `Note`,
  id: 3,
  name: `Note`
} as const;

export type NoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Note`>
}>;
export const noteTaggedC: NoteTaggedC = t.type({
  _tag: t.literal(`Note`)
});
export type NoteTagged = t.TypeOf<NoteTaggedC>;
export type Note = NoteTagged & typeof note;
export type NoteC = t.Type<Note, NoteTagged>;
export const noteC: NoteC = pipe(noteTaggedC, c => new t.Type<Note, NoteTagged>(
  `Note`,
  (u: unknown): u is Note => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Note> => pipe(c.decode(u), E.map(x => ({ ...x, ...note }))),
  (x: Note): NoteTagged => ({ ...x, _tag: `Note`}),
)) satisfies t.Type<Note, unknown>;


export const other = {
  _tag: `Other`,
  id: 4,
  name: `Other`
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
)) satisfies t.Type<Other, unknown>;


export const allDebtTypeC = [bondSaleC, privateLoanC, noteC, otherC] as const;
export const allDebtTypeNames = [`BondSale`, `PrivateLoan`, `Note`, `Other`] as const;
export type DebtTypeName = (typeof allDebtTypeNames)[number];

export type DebtTypeCU = t.UnionC<[BondSaleC, PrivateLoanC, NoteC, OtherC]>;
export type DebtTypeU = BondSale | PrivateLoan | Note | Other;
export const DebtTypeCU: DebtTypeCU = t.union([bondSaleC, privateLoanC, noteC, otherC]) satisfies t.Type<DebtTypeU, unknown>;

export const debtTypeOrd: Ord.Ord<DebtTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDebtType = [bondSale, privateLoan, note, other] as const;
export type DebtTypeMap<A> = { [K in DebtTypeName]: A };


