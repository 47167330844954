import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const completeYourInvestorProfile = {
  _tag: `CompleteYourInvestorProfile`,
  id: 1,
  name: `Complete your investor profile`,
  step: 2
} as const;

export type CompleteYourInvestorProfileTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CompleteYourInvestorProfile`>
}>;
export const completeYourInvestorProfileTaggedC: CompleteYourInvestorProfileTaggedC = t.type({
  _tag: t.literal(`CompleteYourInvestorProfile`)
});
export type CompleteYourInvestorProfileTagged = t.TypeOf<CompleteYourInvestorProfileTaggedC>;
export type CompleteYourInvestorProfile = CompleteYourInvestorProfileTagged & typeof completeYourInvestorProfile;
export type CompleteYourInvestorProfileC = t.Type<CompleteYourInvestorProfile, CompleteYourInvestorProfileTagged>;
export const completeYourInvestorProfileC: CompleteYourInvestorProfileC = pipe(completeYourInvestorProfileTaggedC, c => new t.Type<CompleteYourInvestorProfile, CompleteYourInvestorProfileTagged>(
  `CompleteYourInvestorProfile`,
  (u: unknown): u is CompleteYourInvestorProfile => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CompleteYourInvestorProfile> => pipe(c.decode(u), E.map(x => ({ ...x, ...completeYourInvestorProfile }))),
  (x: CompleteYourInvestorProfile): CompleteYourInvestorProfileTagged => ({ ...x, _tag: `CompleteYourInvestorProfile`}),
)) satisfies t.Type<CompleteYourInvestorProfile, unknown>;


export const buildYourWatchlist = {
  _tag: `BuildYourWatchlist`,
  id: 2,
  name: `Build your watchlist`,
  step: 3
} as const;

export type BuildYourWatchlistTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BuildYourWatchlist`>
}>;
export const buildYourWatchlistTaggedC: BuildYourWatchlistTaggedC = t.type({
  _tag: t.literal(`BuildYourWatchlist`)
});
export type BuildYourWatchlistTagged = t.TypeOf<BuildYourWatchlistTaggedC>;
export type BuildYourWatchlist = BuildYourWatchlistTagged & typeof buildYourWatchlist;
export type BuildYourWatchlistC = t.Type<BuildYourWatchlist, BuildYourWatchlistTagged>;
export const buildYourWatchlistC: BuildYourWatchlistC = pipe(buildYourWatchlistTaggedC, c => new t.Type<BuildYourWatchlist, BuildYourWatchlistTagged>(
  `BuildYourWatchlist`,
  (u: unknown): u is BuildYourWatchlist => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildYourWatchlist> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildYourWatchlist }))),
  (x: BuildYourWatchlist): BuildYourWatchlistTagged => ({ ...x, _tag: `BuildYourWatchlist`}),
)) satisfies t.Type<BuildYourWatchlist, unknown>;


export const allOnboardingStepC = [completeYourInvestorProfileC, buildYourWatchlistC] as const;
export const allOnboardingStepNames = [`CompleteYourInvestorProfile`, `BuildYourWatchlist`] as const;
export type OnboardingStepName = (typeof allOnboardingStepNames)[number];

export type OnboardingStepCU = t.UnionC<[CompleteYourInvestorProfileC, BuildYourWatchlistC]>;
export type OnboardingStepU = CompleteYourInvestorProfile | BuildYourWatchlist;
export const OnboardingStepCU: OnboardingStepCU = t.union([completeYourInvestorProfileC, buildYourWatchlistC]) satisfies t.Type<OnboardingStepU, unknown>;

export const onboardingStepOrd: Ord.Ord<OnboardingStepU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allOnboardingStep = [completeYourInvestorProfile, buildYourWatchlist] as const;
export type OnboardingStepMap<A> = { [K in OnboardingStepName]: A };


