import * as t from "io-ts";
import { RatingAgencyCU as imported83_RatingAgencyCU, RatingAgencyCU as imported79_RatingAgencyCU, RatingAgencyU as imported41_RatingAgencyU, RatingAgencyCU as imported82_RatingAgencyCU, RatingAgencyU as imported40_RatingAgencyU, RatingAgencyCU as imported78_RatingAgencyCU, RatingAgencyCU as imported81_RatingAgencyCU, RatingAgencyCU as imported80_RatingAgencyCU, RatingAgencyU as imported39_RatingAgencyU } from "../domaintables/ratingAgencies";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { RatingOutlookU as imported7_RatingOutlookU, RatingOutlookU as imported5_RatingOutlookU, RatingOutlookCU as imported15_RatingOutlookCU, RatingOutlookCU as imported12_RatingOutlookCU, RatingOutlookCU as imported13_RatingOutlookCU, RatingOutlookCU as imported14_RatingOutlookCU, RatingOutlookU as imported6_RatingOutlookU, RatingOutlookCU as imported10_RatingOutlookCU, RatingOutlookCU as imported11_RatingOutlookCU } from "../domaintables/ratingOutlook";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type IssuerRatingC = t.TypeC<{
  _tag: t.LiteralC<`IssuerRating`>,
  ratingAgency: imported79_RatingAgencyCU,
  ratingOutlook: imported11_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.StringC,
  ratingNotes: OptionFromNullableC<MarkdownC>
}>;
export type IssuerRating = {
  _tag: `IssuerRating`,
  ratingAgency: imported39_RatingAgencyU,
  ratingOutlook: imported5_RatingOutlookU,
  ratingDate: LocalDate,
  rating: string,
  ratingNotes: O.Option<Markdown>
};
export const issuerRatingC: IssuerRatingC = t.type({
  _tag: t.literal(`IssuerRating`),
  ratingAgency: imported78_RatingAgencyCU,
  ratingOutlook: imported10_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.string,
  ratingNotes: optionFromNullable(markdownC)
}) satisfies t.Type<IssuerRating, unknown>;


export type ProgramRatingC = t.TypeC<{
  _tag: t.LiteralC<`ProgramRating`>,
  bondProgramId: t.NumberC,
  bondProgramName: t.StringC,
  ratingAgency: imported81_RatingAgencyCU,
  ratingOutlook: imported13_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.StringC,
  ratingNotes: OptionFromNullableC<MarkdownC>
}>;
export type ProgramRating = {
  _tag: `ProgramRating`,
  bondProgramId: number,
  bondProgramName: string,
  ratingAgency: imported40_RatingAgencyU,
  ratingOutlook: imported6_RatingOutlookU,
  ratingDate: LocalDate,
  rating: string,
  ratingNotes: O.Option<Markdown>
};
export const programRatingC: ProgramRatingC = t.type({
  _tag: t.literal(`ProgramRating`),
  bondProgramId: t.number,
  bondProgramName: t.string,
  ratingAgency: imported80_RatingAgencyCU,
  ratingOutlook: imported12_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.string,
  ratingNotes: optionFromNullable(markdownC)
}) satisfies t.Type<ProgramRating, unknown>;


export type BondRatingC = t.TypeC<{
  _tag: t.LiteralC<`BondRating`>,
  ratingAgency: imported83_RatingAgencyCU,
  ratingOutlook: imported15_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.StringC,
  ratingNotes: OptionFromNullableC<MarkdownC>
}>;
export type BondRating = {
  _tag: `BondRating`,
  ratingAgency: imported41_RatingAgencyU,
  ratingOutlook: imported7_RatingOutlookU,
  ratingDate: LocalDate,
  rating: string,
  ratingNotes: O.Option<Markdown>
};
export const bondRatingC: BondRatingC = t.type({
  _tag: t.literal(`BondRating`),
  ratingAgency: imported82_RatingAgencyCU,
  ratingOutlook: imported14_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.string,
  ratingNotes: optionFromNullable(markdownC)
}) satisfies t.Type<BondRating, unknown>;


export const allRatingC = [issuerRatingC, programRatingC, bondRatingC] as const;
export const allRatingNames = [`IssuerRating`, `ProgramRating`, `BondRating`] as const;
export type RatingName = (typeof allRatingNames)[number];

export type RatingCU = t.UnionC<[IssuerRatingC, ProgramRatingC, BondRatingC]>;
export type RatingU = IssuerRating | ProgramRating | BondRating;
export const RatingCU: RatingCU = t.union([issuerRatingC, programRatingC, bondRatingC]) satisfies t.Type<RatingU, unknown>;

export type RatingMap<A> = { [K in RatingName]: A };


export const allIssuerOrProgramRatingC = [issuerRatingC, programRatingC] as const;
export const allIssuerOrProgramRatingNames = [`IssuerRating`, `ProgramRating`] as const;
export type IssuerOrProgramRatingName = (typeof allIssuerOrProgramRatingNames)[number];

export type IssuerOrProgramRatingCU = t.UnionC<[IssuerRatingC, ProgramRatingC]>;
export type IssuerOrProgramRatingU = IssuerRating | ProgramRating;
export const IssuerOrProgramRatingCU: IssuerOrProgramRatingCU = t.union([issuerRatingC, programRatingC]) satisfies t.Type<IssuerOrProgramRatingU, unknown>;

export type IssuerOrProgramRatingMap<A> = { [K in IssuerOrProgramRatingName]: A };


