import { WithId as imported298_WithId, WithId as imported303_WithId, WithIdC as imported296_WithIdC, WithId as imported296_WithId, WithId as imported295_WithId, withIdC as imported295_withIdC, withIdC as imported300_withIdC, withIdC as imported298_withIdC, withIdC as imported301_withIdC, WithId as imported297_WithId, WithIdC as imported300_WithIdC, withIdC as imported296_withIdC, withIdC as imported299_withIdC, WithId as imported302_WithId, withIdC as imported302_withIdC, withIdC as imported303_withIdC, WithIdC as imported301_WithIdC, WithIdC as imported297_WithIdC, WithId as imported301_WithId, WithId as imported299_WithId, withIdC as imported297_withIdC, WithIdC as imported298_WithIdC, WithId as imported300_WithId, WithIdC as imported299_WithIdC, WithIdC as imported303_WithIdC, WithIdC as imported302_WithIdC, WithIdC as imported295_WithIdC } from "./threadThrough";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { DebtTypeU as imported3_DebtTypeU, DebtTypeCU as imported7_DebtTypeCU, DebtTypeCU as imported4_DebtTypeCU, DebtTypeU as imported2_DebtTypeU, DebtTypeCU as imported6_DebtTypeCU, DebtTypeCU as imported5_DebtTypeCU } from "../domaintables/debtTypes";
import { LocalDateTimeFromIsoStringC, LocalDateC } from "../../codecs/localDate";
import { DebtComparisonLineC as imported4_DebtComparisonLineC, DebtComparisonLine as imported5_DebtComparisonLine, DebtComparisonLineC as imported5_DebtComparisonLineC, debtComparisonLineC as imported4_debtComparisonLineC, debtComparisonLineC as imported5_debtComparisonLineC, DebtComparisonLine as imported4_DebtComparisonLine, DebtComparisonLine as imported3_DebtComparisonLine, DebtComparisonLineC as imported3_DebtComparisonLineC, debtComparisonLineC as imported3_debtComparisonLineC } from "./debtComparisonLine";
import * as O from "fp-ts/lib/Option";
import { BigNumberC, ReadonlyBig, bigNumberC } from "../../Big/bigNumberC";
import { LocalDateTime, LocalDate } from "@js-joda/core";
import { CouponTypeCU as imported3_CouponTypeCU, CouponTypeU as imported1_CouponTypeU, CouponTypeCU as imported2_CouponTypeCU } from "../domaintables/couponTypes";
import { EitherC, either } from "io-ts-types/lib/either";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MuniTaxCreditCategoryCU as imported4_MuniTaxCreditCategoryCU, MuniTaxCreditCategoryCU as imported5_MuniTaxCreditCategoryCU, MuniTaxCreditCategoryCU as imported6_MuniTaxCreditCategoryCU, MuniTaxCreditCategoryU as imported3_MuniTaxCreditCategoryU, MuniTaxCreditCategoryCU as imported7_MuniTaxCreditCategoryCU, MuniTaxCreditCategoryU as imported2_MuniTaxCreditCategoryU } from "../domaintables/muniTaxCreditCategories";
import { MuniIssueTypeU as imported2_MuniIssueTypeU, MuniIssueTypeU as imported3_MuniIssueTypeU, MuniIssueTypeCU as imported4_MuniIssueTypeCU, MuniIssueTypeCU as imported5_MuniIssueTypeCU, MuniIssueTypeCU as imported6_MuniIssueTypeCU, MuniIssueTypeCU as imported7_MuniIssueTypeCU } from "../domaintables/muniIssueTypes";
import { MuniPurposeClassTypeCU as imported5_MuniPurposeClassTypeCU, MuniPurposeClassTypeU as imported2_MuniPurposeClassTypeU, MuniPurposeClassTypeCU as imported6_MuniPurposeClassTypeCU, MuniPurposeClassTypeCU as imported4_MuniPurposeClassTypeCU, MuniPurposeClassTypeCU as imported7_MuniPurposeClassTypeCU, MuniPurposeClassTypeU as imported3_MuniPurposeClassTypeU } from "../domaintables/muniPurposeClassTypes";
import { CallStatusCU as imported3_CallStatusCU, CallStatusU as imported1_CallStatusU, CallStatusCU as imported2_CallStatusCU } from "../domaintables/callStatuses";
import * as t from "io-ts";
import { MuniEnhancementTypeCU as imported3_MuniEnhancementTypeCU, MuniEnhancementTypeU as imported1_MuniEnhancementTypeU, MuniEnhancementTypeCU as imported2_MuniEnhancementTypeCU } from "../domaintables/muniEnhancementTypes";
import * as E from "fp-ts/lib/Either";
import { RepaymentSourceCU as imported3_RepaymentSourceCU, RepaymentSourceU as imported1_RepaymentSourceU, RepaymentSourceCU as imported2_RepaymentSourceCU } from "../domaintables/repaymentSources";
import { MuniRedemptionTypeCU as imported3_MuniRedemptionTypeCU, MuniRedemptionTypeU as imported1_MuniRedemptionTypeU, MuniRedemptionTypeCU as imported2_MuniRedemptionTypeCU } from "../domaintables/muniRedemptionTypes";
import { MethodOfSaleCU as imported7_MethodOfSaleCU, MethodOfSaleU as imported3_MethodOfSaleU, MethodOfSaleCU as imported6_MethodOfSaleCU } from "../domaintables/methodsOfSale";
import { IssuerSeriesTagCategoryCU as imported3_IssuerSeriesTagCategoryCU, IssuerSeriesTagCategoryU as imported1_IssuerSeriesTagCategoryU, IssuerSeriesTagCategoryCU as imported2_IssuerSeriesTagCategoryCU } from "../domaintables/issuerSeriesTagCategories";
import { TaxStatusCU as imported3_TaxStatusCU, TaxStatusU as imported1_TaxStatusU, TaxStatusCU as imported2_TaxStatusCU } from "../domaintables/taxStatuses";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { InterestPaymentFrequencyCU as imported3_InterestPaymentFrequencyCU, InterestPaymentFrequencyU as imported1_InterestPaymentFrequencyU, InterestPaymentFrequencyCU as imported2_InterestPaymentFrequencyCU } from "../domaintables/interestPaymentFrequencies";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type IceSeriesIssuerDataC = t.TypeC<{
  seriesId: t.NumberC,
  showInCharts: t.BooleanC,
  customName: OptionFromNullableC<t.StringC>,
  notes: OptionFromNullableC<t.StringC>,
  tagIds: t.ReadonlyArrayC<t.NumberC>
}>;
export type IceSeriesIssuerData = {
  seriesId: number,
  showInCharts: boolean,
  customName: O.Option<string>,
  notes: O.Option<string>,
  tagIds: ReadonlyArray<number>
};
export const iceSeriesIssuerDataC: IceSeriesIssuerDataC = t.type({
  seriesId: t.number,
  showInCharts: t.boolean,
  customName: optionFromNullable(t.string),
  notes: optionFromNullable(t.string),
  tagIds: t.readonlyArray(t.number)
}) satisfies t.Type<IceSeriesIssuerData, unknown>;


export type IceSeriesIssuerDataPostC = t.TypeC<{
  seriesId: t.NumberC,
  showInCharts: t.BooleanC,
  customName: OptionFromNullableC<t.StringC>,
  notes: OptionFromNullableC<t.StringC>,
  tagIds: t.ReadonlyArrayC<t.NumberC>
}>;
export type IceSeriesIssuerDataPost = {
  seriesId: number,
  showInCharts: boolean,
  customName: O.Option<string>,
  notes: O.Option<string>,
  tagIds: ReadonlyArray<number>
};
export const iceSeriesIssuerDataPostC: IceSeriesIssuerDataPostC = t.type({
  seriesId: t.number,
  showInCharts: t.boolean,
  customName: optionFromNullable(t.string),
  notes: optionFromNullable(t.string),
  tagIds: t.readonlyArray(t.number)
}) satisfies t.Type<IceSeriesIssuerDataPost, unknown>;


export type IceCusip6IssuerDataC = t.TypeC<{
  cusip6: t.StringC,
  name: OptionFromNullableC<t.StringC>,
  esgRelated: t.BooleanC,
  notes: OptionFromNullableC<t.StringC>
}>;
export type IceCusip6IssuerData = {
  cusip6: string,
  name: O.Option<string>,
  esgRelated: boolean,
  notes: O.Option<string>
};
export const iceCusip6IssuerDataC: IceCusip6IssuerDataC = t.type({
  cusip6: t.string,
  name: optionFromNullable(t.string),
  esgRelated: t.boolean,
  notes: optionFromNullable(t.string)
}) satisfies t.Type<IceCusip6IssuerData, unknown>;


export type IceCusip6IssuerDataPostC = t.TypeC<{
  cusip: t.StringC,
  name: OptionFromNullableC<t.StringC>,
  esgRelated: t.BooleanC,
  notes: OptionFromNullableC<t.StringC>
}>;
export type IceCusip6IssuerDataPost = {
  cusip: string,
  name: O.Option<string>,
  esgRelated: boolean,
  notes: O.Option<string>
};
export const iceCusip6IssuerDataPostC: IceCusip6IssuerDataPostC = t.type({
  cusip: t.string,
  name: optionFromNullable(t.string),
  esgRelated: t.boolean,
  notes: optionFromNullable(t.string)
}) satisfies t.Type<IceCusip6IssuerDataPost, unknown>;


export type IssuerSeriesTagC = t.TypeC<{
  name: t.StringC,
  category: imported3_IssuerSeriesTagCategoryCU,
  notes: OptionFromNullableC<t.StringC>,
  created: LocalDateTimeFromIsoStringC
}>;
export type IssuerSeriesTag = {
  name: string,
  category: imported1_IssuerSeriesTagCategoryU,
  notes: O.Option<string>,
  created: LocalDateTime
};
export const issuerSeriesTagC: IssuerSeriesTagC = t.type({
  name: t.string,
  category: imported2_IssuerSeriesTagCategoryCU,
  notes: optionFromNullable(t.string),
  created: LocalDateTimeFromIsoStringC
}) satisfies t.Type<IssuerSeriesTag, unknown>;


export type IceSeriesIdC = t.TypeC<{
  iceSeriesId: t.NumberC
}>;
export type IceSeriesId = {
  iceSeriesId: number
};
export const iceSeriesIdC: IceSeriesIdC = t.type({
  iceSeriesId: t.number
}) satisfies t.Type<IceSeriesId, unknown>;


export type NonIceSeriesIdC = t.TypeC<{
  nonIceDebtInstrumentId: t.NumberC
}>;
export type NonIceSeriesId = {
  nonIceDebtInstrumentId: number
};
export const nonIceSeriesIdC: NonIceSeriesIdC = t.type({
  nonIceDebtInstrumentId: t.number
}) satisfies t.Type<NonIceSeriesId, unknown>;


export type EitherSeriesIdC = t.TypeC<{
  iceSeriesId: OptionFromNullableC<t.NumberC>,
  nonIceDebtInstrumentId: OptionFromNullableC<t.NumberC>
}>;
export type EitherSeriesId = {
  iceSeriesId: O.Option<number>,
  nonIceDebtInstrumentId: O.Option<number>
};
export const eitherSeriesIdC: EitherSeriesIdC = t.type({
  iceSeriesId: optionFromNullable(t.number),
  nonIceDebtInstrumentId: optionFromNullable(t.number)
}) satisfies t.Type<EitherSeriesId, unknown>;


export type IssuerSeriesTagPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  categoryId: t.NumberC,
  notes: OptionFromNullableC<t.StringC>,
  seriesIds: t.ReadonlyArrayC<EitherSeriesIdC>
}>;
export type IssuerSeriesTagPost = {
  id: O.Option<number>,
  name: string,
  categoryId: number,
  notes: O.Option<string>,
  seriesIds: ReadonlyArray<EitherSeriesId>
};
export const issuerSeriesTagPostC: IssuerSeriesTagPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  categoryId: t.number,
  notes: optionFromNullable(t.string),
  seriesIds: t.readonlyArray(eitherSeriesIdC)
}) satisfies t.Type<IssuerSeriesTagPost, unknown>;


export type PendingCusip9PostC = t.TypeC<{
  seriesData: IceSeriesIssuerDataPostC,
  acceptedCusip9s: t.ReadonlyArrayC<t.StringC>,
  rejectedCusip9s: t.ReadonlyArrayC<t.StringC>,
  rejectionNotes: OptionFromNullableC<t.StringC>
}>;
export type PendingCusip9Post = {
  seriesData: IceSeriesIssuerDataPost,
  acceptedCusip9s: ReadonlyArray<string>,
  rejectedCusip9s: ReadonlyArray<string>,
  rejectionNotes: O.Option<string>
};
export const pendingCusip9PostC: PendingCusip9PostC = t.type({
  seriesData: iceSeriesIssuerDataPostC,
  acceptedCusip9s: t.readonlyArray(t.string),
  rejectedCusip9s: t.readonlyArray(t.string),
  rejectionNotes: optionFromNullable(t.string)
}) satisfies t.Type<PendingCusip9Post, unknown>;


export type PaymentC = t.TypeC<{
  date: LocalDateC,
  principal: BigNumberC,
  interest: BigNumberC
}>;
export type Payment = {
  date: LocalDate,
  principal: ReadonlyBig,
  interest: ReadonlyBig
};
export const paymentC: PaymentC = t.type({
  date: LocalDateC,
  principal: bigNumberC,
  interest: bigNumberC
}) satisfies t.Type<Payment, unknown>;


export type CusipC = t.TypeC<{
  cusip: t.StringC,
  originalPrincipal: OptionFromNullableC<BigNumberC>,
  couponRate: OptionFromNullableC<BigNumberC>,
  variableCouponRate: OptionFromNullableC<BigNumberC>,
  issueDate: LocalDateC,
  maturityDate: LocalDateC,
  callStatus: imported3_CallStatusCU,
  firstCallDate: OptionFromNullableC<LocalDateC>,
  couponType: OptionFromNullableC<imported3_CouponTypeCU>,
  interestPaymentFrequency: OptionFromNullableC<imported3_InterestPaymentFrequencyCU>,
  repaymentSource: imported3_RepaymentSourceCU,
  saleType: OptionFromNullableC<imported7_MethodOfSaleCU>,
  debtType: imported5_DebtTypeCU,
  taxType: OptionFromNullableC<imported3_TaxStatusCU>,
  issueType: OptionFromNullableC<imported5_MuniIssueTypeCU>,
  taxCreditCategory: OptionFromNullableC<imported5_MuniTaxCreditCategoryCU>,
  purposeClassType: OptionFromNullableC<imported5_MuniPurposeClassTypeCU>,
  redemptionType: OptionFromNullableC<imported3_MuniRedemptionTypeCU>,
  enhancementType: OptionFromNullableC<imported3_MuniEnhancementTypeCU>,
  payments: t.ReadonlyArrayC<PaymentC>,
  escrowAgent: OptionFromNullableC<t.StringC>,
  defeased: t.BooleanC,
  refundDate: OptionFromNullableC<LocalDateC>,
  children: t.ReadonlyArrayC<t.NumberC>
}>;
export type Cusip = {
  cusip: string,
  originalPrincipal: O.Option<ReadonlyBig>,
  couponRate: O.Option<ReadonlyBig>,
  variableCouponRate: O.Option<ReadonlyBig>,
  issueDate: LocalDate,
  maturityDate: LocalDate,
  callStatus: imported1_CallStatusU,
  firstCallDate: O.Option<LocalDate>,
  couponType: O.Option<imported1_CouponTypeU>,
  interestPaymentFrequency: O.Option<imported1_InterestPaymentFrequencyU>,
  repaymentSource: imported1_RepaymentSourceU,
  saleType: O.Option<imported3_MethodOfSaleU>,
  debtType: imported2_DebtTypeU,
  taxType: O.Option<imported1_TaxStatusU>,
  issueType: O.Option<imported2_MuniIssueTypeU>,
  taxCreditCategory: O.Option<imported2_MuniTaxCreditCategoryU>,
  purposeClassType: O.Option<imported2_MuniPurposeClassTypeU>,
  redemptionType: O.Option<imported1_MuniRedemptionTypeU>,
  enhancementType: O.Option<imported1_MuniEnhancementTypeU>,
  payments: ReadonlyArray<Payment>,
  escrowAgent: O.Option<string>,
  defeased: boolean,
  refundDate: O.Option<LocalDate>,
  children: ReadonlyArray<number>
};
export const cusipC: CusipC = t.type({
  cusip: t.string,
  originalPrincipal: optionFromNullable(bigNumberC),
  couponRate: optionFromNullable(bigNumberC),
  variableCouponRate: optionFromNullable(bigNumberC),
  issueDate: LocalDateC,
  maturityDate: LocalDateC,
  callStatus: imported2_CallStatusCU,
  firstCallDate: optionFromNullable(LocalDateC),
  couponType: optionFromNullable(imported2_CouponTypeCU),
  interestPaymentFrequency: optionFromNullable(imported2_InterestPaymentFrequencyCU),
  repaymentSource: imported2_RepaymentSourceCU,
  saleType: optionFromNullable(imported6_MethodOfSaleCU),
  debtType: imported4_DebtTypeCU,
  taxType: optionFromNullable(imported2_TaxStatusCU),
  issueType: optionFromNullable(imported4_MuniIssueTypeCU),
  taxCreditCategory: optionFromNullable(imported4_MuniTaxCreditCategoryCU),
  purposeClassType: optionFromNullable(imported4_MuniPurposeClassTypeCU),
  redemptionType: optionFromNullable(imported2_MuniRedemptionTypeCU),
  enhancementType: optionFromNullable(imported2_MuniEnhancementTypeCU),
  payments: t.readonlyArray(paymentC),
  escrowAgent: optionFromNullable(t.string),
  defeased: t.boolean,
  refundDate: optionFromNullable(LocalDateC),
  children: t.readonlyArray(t.number)
}) satisfies t.Type<Cusip, unknown>;


export type PendingCusipC = t.TypeC<{
  cusip: CusipC,
  created: LocalDateTimeFromIsoStringC
}>;
export type PendingCusip = {
  cusip: Cusip,
  created: LocalDateTime
};
export const pendingCusipC: PendingCusipC = t.type({
  cusip: cusipC,
  created: LocalDateTimeFromIsoStringC
}) satisfies t.Type<PendingCusip, unknown>;


export type AcceptedCusipC = t.TypeC<{
  cusip: CusipC,
  accepted: LocalDateTimeFromIsoStringC,
  userIdModified: t.NumberC
}>;
export type AcceptedCusip = {
  cusip: Cusip,
  accepted: LocalDateTime,
  userIdModified: number
};
export const acceptedCusipC: AcceptedCusipC = t.type({
  cusip: cusipC,
  accepted: LocalDateTimeFromIsoStringC,
  userIdModified: t.number
}) satisfies t.Type<AcceptedCusip, unknown>;


export type RejectedCusipC = t.TypeC<{
  cusip: CusipC,
  rejected: LocalDateTimeFromIsoStringC,
  userIdModified: t.NumberC,
  notes: OptionFromNullableC<t.StringC>
}>;
export type RejectedCusip = {
  cusip: Cusip,
  rejected: LocalDateTime,
  userIdModified: number,
  notes: O.Option<string>
};
export const rejectedCusipC: RejectedCusipC = t.type({
  cusip: cusipC,
  rejected: LocalDateTimeFromIsoStringC,
  userIdModified: t.number,
  notes: optionFromNullable(t.string)
}) satisfies t.Type<RejectedCusip, unknown>;


export type SeriesC<A1 extends t.Mixed> = t.TypeC<{
  name: t.StringC,
  issueKey: t.NumberC,
  originalPrincipal: BigNumberC,
  maturityDate: LocalDateC,
  cusips: t.ReadonlyArrayC<imported295_WithIdC<A1>>,
  showInCharts: t.BooleanC,
  customName: OptionFromNullableC<t.StringC>,
  notes: OptionFromNullableC<t.StringC>,
  tags: t.ReadonlyArrayC<imported296_WithIdC<IssuerSeriesTagC>>
}>;
export type Series<A1> = {
  name: string,
  issueKey: number,
  originalPrincipal: ReadonlyBig,
  maturityDate: LocalDate,
  cusips: ReadonlyArray<imported295_WithId<A1>>,
  showInCharts: boolean,
  customName: O.Option<string>,
  notes: O.Option<string>,
  tags: ReadonlyArray<imported296_WithId<IssuerSeriesTag>>
};
export const seriesC = <A1 extends t.Mixed>(A1: A1): SeriesC<A1> => t.type({
  name: t.string,
  issueKey: t.number,
  originalPrincipal: bigNumberC,
  maturityDate: LocalDateC,
  cusips: t.readonlyArray(imported295_withIdC(A1)),
  showInCharts: t.boolean,
  customName: optionFromNullable(t.string),
  notes: optionFromNullable(t.string),
  tags: t.readonlyArray(imported296_withIdC(issuerSeriesTagC))
}) satisfies t.Type<Series<t.TypeOf<A1>>, unknown>;


export type RegularPaymentsScheduleC = t.TypeC<{
  principal: BigNumberC,
  interest: BigNumberC,
  startingDate: LocalDateC,
  monthsPerInterval: t.NumberC,
  numberOfPayments: t.NumberC,
  finalPaymentAltPrincipal: OptionFromNullableC<BigNumberC>,
  finalPaymentAltInterest: OptionFromNullableC<BigNumberC>
}>;
export type RegularPaymentsSchedule = {
  principal: ReadonlyBig,
  interest: ReadonlyBig,
  startingDate: LocalDate,
  monthsPerInterval: number,
  numberOfPayments: number,
  finalPaymentAltPrincipal: O.Option<ReadonlyBig>,
  finalPaymentAltInterest: O.Option<ReadonlyBig>
};
export const regularPaymentsScheduleC: RegularPaymentsScheduleC = t.type({
  principal: bigNumberC,
  interest: bigNumberC,
  startingDate: LocalDateC,
  monthsPerInterval: t.number,
  numberOfPayments: t.number,
  finalPaymentAltPrincipal: optionFromNullable(bigNumberC),
  finalPaymentAltInterest: optionFromNullable(bigNumberC)
}) satisfies t.Type<RegularPaymentsSchedule, unknown>;


export type ManualDatesPaymentC = t.TypeC<{
  principal: BigNumberC,
  interest: BigNumberC,
  date: LocalDateC
}>;
export type ManualDatesPayment = {
  principal: ReadonlyBig,
  interest: ReadonlyBig,
  date: LocalDate
};
export const manualDatesPaymentC: ManualDatesPaymentC = t.type({
  principal: bigNumberC,
  interest: bigNumberC,
  date: LocalDateC
}) satisfies t.Type<ManualDatesPayment, unknown>;


export type InstrumentC = t.TypeC<{
  name: t.StringC,
  debtTypeId: imported7_DebtTypeCU,
  issueType: OptionFromNullableC<imported7_MuniIssueTypeCU>,
  taxCreditCategory: OptionFromNullableC<imported7_MuniTaxCreditCategoryCU>,
  purposeClassType: OptionFromNullableC<imported7_MuniPurposeClassTypeCU>,
  showInCharts: t.BooleanC,
  originalPrincipal: BigNumberC,
  issueDate: OptionFromNullableC<LocalDateC>,
  additionalInfo: OptionFromNullableC<t.StringC>,
  paySchedule: EitherC<RegularPaymentsScheduleC, ReadonlyNonEmptyArrayType<ManualDatesPaymentC>>,
  tags: t.ReadonlyArrayC<imported297_WithIdC<IssuerSeriesTagC>>,
  created: LocalDateTimeFromIsoStringC,
  modified: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  userIdModified: t.NumberC
}>;
export type Instrument = {
  name: string,
  debtTypeId: imported3_DebtTypeU,
  issueType: O.Option<imported3_MuniIssueTypeU>,
  taxCreditCategory: O.Option<imported3_MuniTaxCreditCategoryU>,
  purposeClassType: O.Option<imported3_MuniPurposeClassTypeU>,
  showInCharts: boolean,
  originalPrincipal: ReadonlyBig,
  issueDate: O.Option<LocalDate>,
  additionalInfo: O.Option<string>,
  paySchedule: E.Either<RegularPaymentsSchedule, RNEA.ReadonlyNonEmptyArray<ManualDatesPayment>>,
  tags: ReadonlyArray<imported297_WithId<IssuerSeriesTag>>,
  created: LocalDateTime,
  modified: O.Option<LocalDateTime>,
  userIdModified: number
};
export const instrumentC: InstrumentC = t.type({
  name: t.string,
  debtTypeId: imported6_DebtTypeCU,
  issueType: optionFromNullable(imported6_MuniIssueTypeCU),
  taxCreditCategory: optionFromNullable(imported6_MuniTaxCreditCategoryCU),
  purposeClassType: optionFromNullable(imported6_MuniPurposeClassTypeCU),
  showInCharts: t.boolean,
  originalPrincipal: bigNumberC,
  issueDate: optionFromNullable(LocalDateC),
  additionalInfo: optionFromNullable(t.string),
  paySchedule: either(regularPaymentsScheduleC, readonlyNonEmptyArrayC(manualDatesPaymentC)),
  tags: t.readonlyArray(imported297_withIdC(issuerSeriesTagC)),
  created: LocalDateTimeFromIsoStringC,
  modified: optionFromNullable(LocalDateTimeFromIsoStringC),
  userIdModified: t.number
}) satisfies t.Type<Instrument, unknown>;


export type InstrumentPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  debtTypeId: t.NumberC,
  issueTypeId: OptionFromNullableC<t.NumberC>,
  taxCreditCategoryId: OptionFromNullableC<t.NumberC>,
  purposeClassTypeId: OptionFromNullableC<t.NumberC>,
  showInCharts: t.BooleanC,
  originalPrincipal: BigNumberC,
  issueDate: OptionFromNullableC<LocalDateC>,
  additionalInfo: OptionFromNullableC<t.StringC>,
  regularPaymentsSchedule: OptionFromNullableC<RegularPaymentsScheduleC>,
  manualDatesPayments: OptionFromNullableC<ReadonlyNonEmptyArrayType<ManualDatesPaymentC>>,
  tagIds: t.ReadonlyArrayC<t.NumberC>
}>;
export type InstrumentPostBody = {
  id: O.Option<number>,
  name: string,
  debtTypeId: number,
  issueTypeId: O.Option<number>,
  taxCreditCategoryId: O.Option<number>,
  purposeClassTypeId: O.Option<number>,
  showInCharts: boolean,
  originalPrincipal: ReadonlyBig,
  issueDate: O.Option<LocalDate>,
  additionalInfo: O.Option<string>,
  regularPaymentsSchedule: O.Option<RegularPaymentsSchedule>,
  manualDatesPayments: O.Option<RNEA.ReadonlyNonEmptyArray<ManualDatesPayment>>,
  tagIds: ReadonlyArray<number>
};
export const instrumentPostBodyC: InstrumentPostBodyC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  debtTypeId: t.number,
  issueTypeId: optionFromNullable(t.number),
  taxCreditCategoryId: optionFromNullable(t.number),
  purposeClassTypeId: optionFromNullable(t.number),
  showInCharts: t.boolean,
  originalPrincipal: bigNumberC,
  issueDate: optionFromNullable(LocalDateC),
  additionalInfo: optionFromNullable(t.string),
  regularPaymentsSchedule: optionFromNullable(regularPaymentsScheduleC),
  manualDatesPayments: optionFromNullable(readonlyNonEmptyArrayC(manualDatesPaymentC)),
  tagIds: t.readonlyArray(t.number)
}) satisfies t.Type<InstrumentPostBody, unknown>;


export type CustomFiscalYearC = t.TypeC<{
  month: t.NumberC
}>;
export type CustomFiscalYear = {
  month: number
};
export const customFiscalYearC: CustomFiscalYearC = t.type({
  month: t.number
}) satisfies t.Type<CustomFiscalYear, unknown>;


export type AllDebtListC = t.TypeC<{
  instruments: t.ReadonlyArrayC<EitherC<imported298_WithIdC<SeriesC<AcceptedCusipC>>, imported299_WithIdC<InstrumentC>>>,
  comparisonLine: OptionFromNullableC<imported3_DebtComparisonLineC>,
  customFiscalYear: CustomFiscalYearC,
  lastUpdated: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  cusip6IssuerData: t.RecordC<t.StringC, IceCusip6IssuerDataC>,
  tags: ReadonlyMapFromEntriesC<t.NumberC, IssuerSeriesTagC>,
  userNames: ReadonlyMapFromEntriesC<t.NumberC, t.StringC>
}>;
export type AllDebtList = {
  instruments: ReadonlyArray<E.Either<imported298_WithId<Series<AcceptedCusip>>, imported299_WithId<Instrument>>>,
  comparisonLine: O.Option<imported3_DebtComparisonLine>,
  customFiscalYear: CustomFiscalYear,
  lastUpdated: O.Option<LocalDateTime>,
  cusip6IssuerData: Record<string, IceCusip6IssuerData>,
  tags: ReadonlyMap<number, IssuerSeriesTag>,
  userNames: ReadonlyMap<number, string>
};
export const allDebtListC: AllDebtListC = t.type({
  instruments: t.readonlyArray(either(imported298_withIdC(seriesC(acceptedCusipC)), imported299_withIdC(instrumentC))),
  comparisonLine: optionFromNullable(imported3_debtComparisonLineC),
  customFiscalYear: customFiscalYearC,
  lastUpdated: optionFromNullable(LocalDateTimeFromIsoStringC),
  cusip6IssuerData: t.record(t.string, iceCusip6IssuerDataC),
  tags: readonlyMapFromEntries(t.number, numberOrd, issuerSeriesTagC),
  userNames: readonlyMapFromEntries(t.number, numberOrd, t.string)
}) satisfies t.Type<AllDebtList, unknown>;


export type IceDebtListC = t.TypeC<{
  series: t.ReadonlyArrayC<imported300_WithIdC<SeriesC<AcceptedCusipC>>>,
  comparisonLine: OptionFromNullableC<imported4_DebtComparisonLineC>,
  customFiscalYear: CustomFiscalYearC,
  lastUpdated: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  cusip6IssuerData: t.RecordC<t.StringC, IceCusip6IssuerDataC>
}>;
export type IceDebtList = {
  series: ReadonlyArray<imported300_WithId<Series<AcceptedCusip>>>,
  comparisonLine: O.Option<imported4_DebtComparisonLine>,
  customFiscalYear: CustomFiscalYear,
  lastUpdated: O.Option<LocalDateTime>,
  cusip6IssuerData: Record<string, IceCusip6IssuerData>
};
export const iceDebtListC: IceDebtListC = t.type({
  series: t.readonlyArray(imported300_withIdC(seriesC(acceptedCusipC))),
  comparisonLine: optionFromNullable(imported4_debtComparisonLineC),
  customFiscalYear: customFiscalYearC,
  lastUpdated: optionFromNullable(LocalDateTimeFromIsoStringC),
  cusip6IssuerData: t.record(t.string, iceCusip6IssuerDataC)
}) satisfies t.Type<IceDebtList, unknown>;


export type NonIceDebtListC = t.TypeC<{
  instruments: t.ReadonlyArrayC<imported301_WithIdC<InstrumentC>>,
  comparisonLine: OptionFromNullableC<imported5_DebtComparisonLineC>,
  customFiscalYear: CustomFiscalYearC
}>;
export type NonIceDebtList = {
  instruments: ReadonlyArray<imported301_WithId<Instrument>>,
  comparisonLine: O.Option<imported5_DebtComparisonLine>,
  customFiscalYear: CustomFiscalYear
};
export const nonIceDebtListC: NonIceDebtListC = t.type({
  instruments: t.readonlyArray(imported301_withIdC(instrumentC)),
  comparisonLine: optionFromNullable(imported5_debtComparisonLineC),
  customFiscalYear: customFiscalYearC
}) satisfies t.Type<NonIceDebtList, unknown>;


export type RejectedCusip9sC = t.TypeC<{
  data: t.ReadonlyArrayC<imported302_WithIdC<SeriesC<RejectedCusipC>>>,
  userNames: ReadonlyMapFromEntriesC<t.NumberC, t.StringC>
}>;
export type RejectedCusip9s = {
  data: ReadonlyArray<imported302_WithId<Series<RejectedCusip>>>,
  userNames: ReadonlyMap<number, string>
};
export const rejectedCusip9sC: RejectedCusip9sC = t.type({
  data: t.readonlyArray(imported302_withIdC(seriesC(rejectedCusipC))),
  userNames: readonlyMapFromEntries(t.number, numberOrd, t.string)
}) satisfies t.Type<RejectedCusip9s, unknown>;


export type AllStatusesDebtDataC = t.TypeC<{
  accepted: AllDebtListC,
  pending: t.ReadonlyArrayC<imported303_WithIdC<SeriesC<PendingCusipC>>>,
  rejected: RejectedCusip9sC
}>;
export type AllStatusesDebtData = {
  accepted: AllDebtList,
  pending: ReadonlyArray<imported303_WithId<Series<PendingCusip>>>,
  rejected: RejectedCusip9s
};
export const allStatusesDebtDataC: AllStatusesDebtDataC = t.type({
  accepted: allDebtListC,
  pending: t.readonlyArray(imported303_withIdC(seriesC(pendingCusipC))),
  rejected: rejectedCusip9sC
}) satisfies t.Type<AllStatusesDebtData, unknown>;


export type SeriesNamePostC = t.TypeC<{
  seriesId: t.NumberC,
  name: OptionFromNullableC<t.StringC>
}>;
export type SeriesNamePost = {
  seriesId: number,
  name: O.Option<string>
};
export const seriesNamePostC: SeriesNamePostC = t.type({
  seriesId: t.number,
  name: optionFromNullable(t.string)
}) satisfies t.Type<SeriesNamePost, unknown>;


export type ToggleDebtVisibilityPostBodyC = t.TypeC<{
  id: t.NumberC,
  showInCharts: t.BooleanC
}>;
export type ToggleDebtVisibilityPostBody = {
  id: number,
  showInCharts: boolean
};
export const toggleDebtVisibilityPostBodyC: ToggleDebtVisibilityPostBodyC = t.type({
  id: t.number,
  showInCharts: t.boolean
}) satisfies t.Type<ToggleDebtVisibilityPostBody, unknown>;


