import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const refunding = {
  _tag: `Refunding`,
  id: 1,
  name: `Refunding`
} as const;

export type RefundingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Refunding`>
}>;
export const refundingTaggedC: RefundingTaggedC = t.type({
  _tag: t.literal(`Refunding`)
});
export type RefundingTagged = t.TypeOf<RefundingTaggedC>;
export type Refunding = RefundingTagged & typeof refunding;
export type RefundingC = t.Type<Refunding, RefundingTagged>;
export const refundingC: RefundingC = pipe(refundingTaggedC, c => new t.Type<Refunding, RefundingTagged>(
  `Refunding`,
  (u: unknown): u is Refunding => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Refunding> => pipe(c.decode(u), E.map(x => ({ ...x, ...refunding }))),
  (x: Refunding): RefundingTagged => ({ ...x, _tag: `Refunding`}),
)) satisfies t.Type<Refunding, unknown>;


export const advancedRefunding = {
  _tag: `AdvancedRefunding`,
  id: 2,
  name: `Advanced refunding`
} as const;

export type AdvancedRefundingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AdvancedRefunding`>
}>;
export const advancedRefundingTaggedC: AdvancedRefundingTaggedC = t.type({
  _tag: t.literal(`AdvancedRefunding`)
});
export type AdvancedRefundingTagged = t.TypeOf<AdvancedRefundingTaggedC>;
export type AdvancedRefunding = AdvancedRefundingTagged & typeof advancedRefunding;
export type AdvancedRefundingC = t.Type<AdvancedRefunding, AdvancedRefundingTagged>;
export const advancedRefundingC: AdvancedRefundingC = pipe(advancedRefundingTaggedC, c => new t.Type<AdvancedRefunding, AdvancedRefundingTagged>(
  `AdvancedRefunding`,
  (u: unknown): u is AdvancedRefunding => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AdvancedRefunding> => pipe(c.decode(u), E.map(x => ({ ...x, ...advancedRefunding }))),
  (x: AdvancedRefunding): AdvancedRefundingTagged => ({ ...x, _tag: `AdvancedRefunding`}),
)) satisfies t.Type<AdvancedRefunding, unknown>;


export const advancedRefundingSlashNewFinancing = {
  _tag: `AdvancedRefundingSlashNewFinancing`,
  id: 3,
  name: `Advanced refunding/New financing`
} as const;

export type AdvancedRefundingSlashNewFinancingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AdvancedRefundingSlashNewFinancing`>
}>;
export const advancedRefundingSlashNewFinancingTaggedC: AdvancedRefundingSlashNewFinancingTaggedC = t.type({
  _tag: t.literal(`AdvancedRefundingSlashNewFinancing`)
});
export type AdvancedRefundingSlashNewFinancingTagged = t.TypeOf<AdvancedRefundingSlashNewFinancingTaggedC>;
export type AdvancedRefundingSlashNewFinancing = AdvancedRefundingSlashNewFinancingTagged & typeof advancedRefundingSlashNewFinancing;
export type AdvancedRefundingSlashNewFinancingC = t.Type<AdvancedRefundingSlashNewFinancing, AdvancedRefundingSlashNewFinancingTagged>;
export const advancedRefundingSlashNewFinancingC: AdvancedRefundingSlashNewFinancingC = pipe(advancedRefundingSlashNewFinancingTaggedC, c => new t.Type<AdvancedRefundingSlashNewFinancing, AdvancedRefundingSlashNewFinancingTagged>(
  `AdvancedRefundingSlashNewFinancing`,
  (u: unknown): u is AdvancedRefundingSlashNewFinancing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AdvancedRefundingSlashNewFinancing> => pipe(c.decode(u), E.map(x => ({ ...x, ...advancedRefundingSlashNewFinancing }))),
  (x: AdvancedRefundingSlashNewFinancing): AdvancedRefundingSlashNewFinancingTagged => ({ ...x, _tag: `AdvancedRefundingSlashNewFinancing`}),
)) satisfies t.Type<AdvancedRefundingSlashNewFinancing, unknown>;


export const currentRefunding = {
  _tag: `CurrentRefunding`,
  id: 4,
  name: `Current refunding`
} as const;

export type CurrentRefundingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CurrentRefunding`>
}>;
export const currentRefundingTaggedC: CurrentRefundingTaggedC = t.type({
  _tag: t.literal(`CurrentRefunding`)
});
export type CurrentRefundingTagged = t.TypeOf<CurrentRefundingTaggedC>;
export type CurrentRefunding = CurrentRefundingTagged & typeof currentRefunding;
export type CurrentRefundingC = t.Type<CurrentRefunding, CurrentRefundingTagged>;
export const currentRefundingC: CurrentRefundingC = pipe(currentRefundingTaggedC, c => new t.Type<CurrentRefunding, CurrentRefundingTagged>(
  `CurrentRefunding`,
  (u: unknown): u is CurrentRefunding => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CurrentRefunding> => pipe(c.decode(u), E.map(x => ({ ...x, ...currentRefunding }))),
  (x: CurrentRefunding): CurrentRefundingTagged => ({ ...x, _tag: `CurrentRefunding`}),
)) satisfies t.Type<CurrentRefunding, unknown>;


export const currentRefundingSlashNewFinancing = {
  _tag: `CurrentRefundingSlashNewFinancing`,
  id: 5,
  name: `Current refunding/New financing`
} as const;

export type CurrentRefundingSlashNewFinancingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CurrentRefundingSlashNewFinancing`>
}>;
export const currentRefundingSlashNewFinancingTaggedC: CurrentRefundingSlashNewFinancingTaggedC = t.type({
  _tag: t.literal(`CurrentRefundingSlashNewFinancing`)
});
export type CurrentRefundingSlashNewFinancingTagged = t.TypeOf<CurrentRefundingSlashNewFinancingTaggedC>;
export type CurrentRefundingSlashNewFinancing = CurrentRefundingSlashNewFinancingTagged & typeof currentRefundingSlashNewFinancing;
export type CurrentRefundingSlashNewFinancingC = t.Type<CurrentRefundingSlashNewFinancing, CurrentRefundingSlashNewFinancingTagged>;
export const currentRefundingSlashNewFinancingC: CurrentRefundingSlashNewFinancingC = pipe(currentRefundingSlashNewFinancingTaggedC, c => new t.Type<CurrentRefundingSlashNewFinancing, CurrentRefundingSlashNewFinancingTagged>(
  `CurrentRefundingSlashNewFinancing`,
  (u: unknown): u is CurrentRefundingSlashNewFinancing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CurrentRefundingSlashNewFinancing> => pipe(c.decode(u), E.map(x => ({ ...x, ...currentRefundingSlashNewFinancing }))),
  (x: CurrentRefundingSlashNewFinancing): CurrentRefundingSlashNewFinancingTagged => ({ ...x, _tag: `CurrentRefundingSlashNewFinancing`}),
)) satisfies t.Type<CurrentRefundingSlashNewFinancing, unknown>;


export const newFinancing = {
  _tag: `NewFinancing`,
  id: 6,
  name: `New financing`
} as const;

export type NewFinancingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NewFinancing`>
}>;
export const newFinancingTaggedC: NewFinancingTaggedC = t.type({
  _tag: t.literal(`NewFinancing`)
});
export type NewFinancingTagged = t.TypeOf<NewFinancingTaggedC>;
export type NewFinancing = NewFinancingTagged & typeof newFinancing;
export type NewFinancingC = t.Type<NewFinancing, NewFinancingTagged>;
export const newFinancingC: NewFinancingC = pipe(newFinancingTaggedC, c => new t.Type<NewFinancing, NewFinancingTagged>(
  `NewFinancing`,
  (u: unknown): u is NewFinancing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewFinancing> => pipe(c.decode(u), E.map(x => ({ ...x, ...newFinancing }))),
  (x: NewFinancing): NewFinancingTagged => ({ ...x, _tag: `NewFinancing`}),
)) satisfies t.Type<NewFinancing, unknown>;


export const remarketingSlashConversion = {
  _tag: `RemarketingSlashConversion`,
  id: 7,
  name: `Remarketing/Conversion`
} as const;

export type RemarketingSlashConversionTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RemarketingSlashConversion`>
}>;
export const remarketingSlashConversionTaggedC: RemarketingSlashConversionTaggedC = t.type({
  _tag: t.literal(`RemarketingSlashConversion`)
});
export type RemarketingSlashConversionTagged = t.TypeOf<RemarketingSlashConversionTaggedC>;
export type RemarketingSlashConversion = RemarketingSlashConversionTagged & typeof remarketingSlashConversion;
export type RemarketingSlashConversionC = t.Type<RemarketingSlashConversion, RemarketingSlashConversionTagged>;
export const remarketingSlashConversionC: RemarketingSlashConversionC = pipe(remarketingSlashConversionTaggedC, c => new t.Type<RemarketingSlashConversion, RemarketingSlashConversionTagged>(
  `RemarketingSlashConversion`,
  (u: unknown): u is RemarketingSlashConversion => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RemarketingSlashConversion> => pipe(c.decode(u), E.map(x => ({ ...x, ...remarketingSlashConversion }))),
  (x: RemarketingSlashConversion): RemarketingSlashConversionTagged => ({ ...x, _tag: `RemarketingSlashConversion`}),
)) satisfies t.Type<RemarketingSlashConversion, unknown>;


export const allMuniIssueTypeC = [refundingC, advancedRefundingC, advancedRefundingSlashNewFinancingC, currentRefundingC, currentRefundingSlashNewFinancingC, newFinancingC, remarketingSlashConversionC] as const;
export const allMuniIssueTypeNames = [`Refunding`, `AdvancedRefunding`, `AdvancedRefundingSlashNewFinancing`, `CurrentRefunding`, `CurrentRefundingSlashNewFinancing`, `NewFinancing`, `RemarketingSlashConversion`] as const;
export type MuniIssueTypeName = (typeof allMuniIssueTypeNames)[number];

export type MuniIssueTypeCU = t.UnionC<[RefundingC, AdvancedRefundingC, AdvancedRefundingSlashNewFinancingC, CurrentRefundingC, CurrentRefundingSlashNewFinancingC, NewFinancingC, RemarketingSlashConversionC]>;
export type MuniIssueTypeU = Refunding | AdvancedRefunding | AdvancedRefundingSlashNewFinancing | CurrentRefunding | CurrentRefundingSlashNewFinancing | NewFinancing | RemarketingSlashConversion;
export const MuniIssueTypeCU: MuniIssueTypeCU = t.union([refundingC, advancedRefundingC, advancedRefundingSlashNewFinancingC, currentRefundingC, currentRefundingSlashNewFinancingC, newFinancingC, remarketingSlashConversionC]) satisfies t.Type<MuniIssueTypeU, unknown>;

export const muniIssueTypeOrd: Ord.Ord<MuniIssueTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniIssueType = [refunding, advancedRefunding, advancedRefundingSlashNewFinancing, currentRefunding, currentRefundingSlashNewFinancing, newFinancing, remarketingSlashConversion] as const;
export type MuniIssueTypeMap<A> = { [K in MuniIssueTypeName]: A };


