import { IssuerC as imported73_IssuerC, Issuer as imported73_Issuer, issuerC as imported71_issuerC } from "./issuer";
import * as t from "io-ts";
import { ContactFormSubmissionC as imported25_ContactFormSubmissionC, ContactFormSubmission as imported25_ContactFormSubmission, contactFormSubmissionC as imported25_contactFormSubmissionC } from "./contact";
import * as O from "fp-ts/lib/Option";
import { BankC as imported25_BankC, Bank as imported25_Bank, bankC as imported25_bankC } from "./bank";
import { RelativeUrlC, RelativeUrl, relativeUrlC } from "../../codecs/relativeUrl";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { LoginReasonCU as imported3_LoginReasonCU, LoginReasonU as imported1_LoginReasonU, LoginReasonCU as imported2_LoginReasonCU } from "../domaintables/loginReasons";

export type LoginPageDataC = t.TypeC<{
  bank: OptionFromNullableC<imported25_BankC>,
  issuer: OptionFromNullableC<imported73_IssuerC>,
  contactSubmission: OptionFromNullableC<imported25_ContactFormSubmissionC>,
  reason: OptionFromNullableC<imported3_LoginReasonCU>,
  redirect: OptionFromNullableC<RelativeUrlC>,
  commonCompanies: t.ReadonlyArrayC<t.StringC>
}>;
export type LoginPageData = {
  bank: O.Option<imported25_Bank>,
  issuer: O.Option<imported73_Issuer>,
  contactSubmission: O.Option<imported25_ContactFormSubmission>,
  reason: O.Option<imported1_LoginReasonU>,
  redirect: O.Option<RelativeUrl>,
  commonCompanies: ReadonlyArray<string>
};
export const loginPageDataC: LoginPageDataC = t.type({
  bank: optionFromNullable(imported25_bankC),
  issuer: optionFromNullable(imported71_issuerC),
  contactSubmission: optionFromNullable(imported25_contactFormSubmissionC),
  reason: optionFromNullable(imported2_LoginReasonCU),
  redirect: optionFromNullable(relativeUrlC),
  commonCompanies: t.readonlyArray(t.string)
}) satisfies t.Type<LoginPageData, unknown>;


export type LoginPostC = t.TypeC<{
  email: t.StringC,
  password: t.StringC,
  redirect: OptionFromNullableC<RelativeUrlC>
}>;
export type LoginPost = {
  email: string,
  password: string,
  redirect: O.Option<RelativeUrl>
};
export const loginPostC: LoginPostC = t.type({
  email: t.string,
  password: t.string,
  redirect: optionFromNullable(relativeUrlC)
}) satisfies t.Type<LoginPost, unknown>;


export type LoginAsPostC = t.TypeC<{
  password: t.StringC
}>;
export type LoginAsPost = {
  password: string
};
export const loginAsPostC: LoginAsPostC = t.type({
  password: t.string
}) satisfies t.Type<LoginAsPost, unknown>;


export type ForgotPasswordPostC = t.TypeC<{
  email: t.StringC
}>;
export type ForgotPasswordPost = {
  email: string
};
export const forgotPasswordPostC: ForgotPasswordPostC = t.type({
  email: t.string
}) satisfies t.Type<ForgotPasswordPost, unknown>;


export type RefreshSessionC = t.TypeC<{
  jws: OptionFromNullableC<t.StringC>
}>;
export type RefreshSession = {
  jws: O.Option<string>
};
export const refreshSessionC: RefreshSessionC = t.type({
  jws: optionFromNullable(t.string)
}) satisfies t.Type<RefreshSession, unknown>;


export type ResetPasswordC = t.TypeC<{
  email: t.StringC,
  hash: t.StringC
}>;
export type ResetPassword = {
  email: string,
  hash: string
};
export const resetPasswordC: ResetPasswordC = t.type({
  email: t.string,
  hash: t.string
}) satisfies t.Type<ResetPassword, unknown>;


export type ExpiredPasswordC = t.TypeC<{
  expirationDuration: t.StringC
}>;
export type ExpiredPassword = {
  expirationDuration: string
};
export const expiredPasswordC: ExpiredPasswordC = t.type({
  expirationDuration: t.string
}) satisfies t.Type<ExpiredPassword, unknown>;


export type ResetPasswordDataC = t.TypeC<{
  reset: ResetPasswordC,
  expired: OptionFromNullableC<ExpiredPasswordC>
}>;
export type ResetPasswordData = {
  reset: ResetPassword,
  expired: O.Option<ExpiredPassword>
};
export const resetPasswordDataC: ResetPasswordDataC = t.type({
  reset: resetPasswordC,
  expired: optionFromNullable(expiredPasswordC)
}) satisfies t.Type<ResetPasswordData, unknown>;


export type ResetPasswordMfaDataC = t.TypeC<{
  reset: ResetPasswordDataC,
  phoneLineNumber: t.StringC
}>;
export type ResetPasswordMfaData = {
  reset: ResetPasswordData,
  phoneLineNumber: string
};
export const resetPasswordMfaDataC: ResetPasswordMfaDataC = t.type({
  reset: resetPasswordDataC,
  phoneLineNumber: t.string
}) satisfies t.Type<ResetPasswordMfaData, unknown>;


export type ResetForgottenPasswordPostC = t.TypeC<{
  password: t.StringC,
  confirmPassword: t.StringC,
  email: t.StringC,
  hash: t.StringC,
  verificationId: OptionFromNullableC<t.StringC>
}>;
export type ResetForgottenPasswordPost = {
  password: string,
  confirmPassword: string,
  email: string,
  hash: string,
  verificationId: O.Option<string>
};
export const resetForgottenPasswordPostC: ResetForgottenPasswordPostC = t.type({
  password: t.string,
  confirmPassword: t.string,
  email: t.string,
  hash: t.string,
  verificationId: optionFromNullable(t.string)
}) satisfies t.Type<ResetForgottenPasswordPost, unknown>;


