import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fixedRate = {
  _tag: `FixedRate`,
  id: 1,
  name: `Fixed`
} as const;

export type FixedRateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FixedRate`>
}>;
export const fixedRateTaggedC: FixedRateTaggedC = t.type({
  _tag: t.literal(`FixedRate`)
});
export type FixedRateTagged = t.TypeOf<FixedRateTaggedC>;
export type FixedRate = FixedRateTagged & typeof fixedRate;
export type FixedRateC = t.Type<FixedRate, FixedRateTagged>;
export const fixedRateC: FixedRateC = pipe(fixedRateTaggedC, c => new t.Type<FixedRate, FixedRateTagged>(
  `FixedRate`,
  (u: unknown): u is FixedRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FixedRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...fixedRate }))),
  (x: FixedRate): FixedRateTagged => ({ ...x, _tag: `FixedRate`}),
)) satisfies t.Type<FixedRate, unknown>;


export const variableRate = {
  _tag: `VariableRate`,
  id: 2,
  name: `Variable`
} as const;

export type VariableRateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`VariableRate`>
}>;
export const variableRateTaggedC: VariableRateTaggedC = t.type({
  _tag: t.literal(`VariableRate`)
});
export type VariableRateTagged = t.TypeOf<VariableRateTaggedC>;
export type VariableRate = VariableRateTagged & typeof variableRate;
export type VariableRateC = t.Type<VariableRate, VariableRateTagged>;
export const variableRateC: VariableRateC = pipe(variableRateTaggedC, c => new t.Type<VariableRate, VariableRateTagged>(
  `VariableRate`,
  (u: unknown): u is VariableRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, VariableRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...variableRate }))),
  (x: VariableRate): VariableRateTagged => ({ ...x, _tag: `VariableRate`}),
)) satisfies t.Type<VariableRate, unknown>;


export const zeroCoupon = {
  _tag: `ZeroCoupon`,
  id: 3,
  name: `Zero Coupon`
} as const;

export type ZeroCouponTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ZeroCoupon`>
}>;
export const zeroCouponTaggedC: ZeroCouponTaggedC = t.type({
  _tag: t.literal(`ZeroCoupon`)
});
export type ZeroCouponTagged = t.TypeOf<ZeroCouponTaggedC>;
export type ZeroCoupon = ZeroCouponTagged & typeof zeroCoupon;
export type ZeroCouponC = t.Type<ZeroCoupon, ZeroCouponTagged>;
export const zeroCouponC: ZeroCouponC = pipe(zeroCouponTaggedC, c => new t.Type<ZeroCoupon, ZeroCouponTagged>(
  `ZeroCoupon`,
  (u: unknown): u is ZeroCoupon => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ZeroCoupon> => pipe(c.decode(u), E.map(x => ({ ...x, ...zeroCoupon }))),
  (x: ZeroCoupon): ZeroCouponTagged => ({ ...x, _tag: `ZeroCoupon`}),
)) satisfies t.Type<ZeroCoupon, unknown>;


export const allCouponTypeC = [fixedRateC, variableRateC, zeroCouponC] as const;
export const allCouponTypeNames = [`FixedRate`, `VariableRate`, `ZeroCoupon`] as const;
export type CouponTypeName = (typeof allCouponTypeNames)[number];

export type CouponTypeCU = t.UnionC<[FixedRateC, VariableRateC, ZeroCouponC]>;
export type CouponTypeU = FixedRate | VariableRate | ZeroCoupon;
export const CouponTypeCU: CouponTypeCU = t.union([fixedRateC, variableRateC, zeroCouponC]) satisfies t.Type<CouponTypeU, unknown>;

export const couponTypeOrd: Ord.Ord<CouponTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCouponType = [fixedRate, variableRate, zeroCoupon] as const;
export type CouponTypeMap<A> = { [K in CouponTypeName]: A };


