import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bestPractices = {
  _tag: `BestPractices`,
  id: 1,
  name: `Best Practices`
} as const;

export type BestPracticesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BestPractices`>
}>;
export const bestPracticesTaggedC: BestPracticesTaggedC = t.type({
  _tag: t.literal(`BestPractices`)
});
export type BestPracticesTagged = t.TypeOf<BestPracticesTaggedC>;
export type BestPractices = BestPracticesTagged & typeof bestPractices;
export type BestPracticesC = t.Type<BestPractices, BestPracticesTagged>;
export const bestPracticesC: BestPracticesC = pipe(bestPracticesTaggedC, c => new t.Type<BestPractices, BestPracticesTagged>(
  `BestPractices`,
  (u: unknown): u is BestPractices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BestPractices> => pipe(c.decode(u), E.map(x => ({ ...x, ...bestPractices }))),
  (x: BestPractices): BestPracticesTagged => ({ ...x, _tag: `BestPractices`}),
)) satisfies t.Type<BestPractices, unknown>;


export const aroundTheNetwork = {
  _tag: `AroundTheNetwork`,
  id: 3,
  name: `Around The Network`
} as const;

export type AroundTheNetworkTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AroundTheNetwork`>
}>;
export const aroundTheNetworkTaggedC: AroundTheNetworkTaggedC = t.type({
  _tag: t.literal(`AroundTheNetwork`)
});
export type AroundTheNetworkTagged = t.TypeOf<AroundTheNetworkTaggedC>;
export type AroundTheNetwork = AroundTheNetworkTagged & typeof aroundTheNetwork;
export type AroundTheNetworkC = t.Type<AroundTheNetwork, AroundTheNetworkTagged>;
export const aroundTheNetworkC: AroundTheNetworkC = pipe(aroundTheNetworkTaggedC, c => new t.Type<AroundTheNetwork, AroundTheNetworkTagged>(
  `AroundTheNetwork`,
  (u: unknown): u is AroundTheNetwork => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AroundTheNetwork> => pipe(c.decode(u), E.map(x => ({ ...x, ...aroundTheNetwork }))),
  (x: AroundTheNetwork): AroundTheNetworkTagged => ({ ...x, _tag: `AroundTheNetwork`}),
)) satisfies t.Type<AroundTheNetwork, unknown>;


export const caseStudy = {
  _tag: `CaseStudy`,
  id: 4,
  name: `Case Study`
} as const;

export type CaseStudyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CaseStudy`>
}>;
export const caseStudyTaggedC: CaseStudyTaggedC = t.type({
  _tag: t.literal(`CaseStudy`)
});
export type CaseStudyTagged = t.TypeOf<CaseStudyTaggedC>;
export type CaseStudy = CaseStudyTagged & typeof caseStudy;
export type CaseStudyC = t.Type<CaseStudy, CaseStudyTagged>;
export const caseStudyC: CaseStudyC = pipe(caseStudyTaggedC, c => new t.Type<CaseStudy, CaseStudyTagged>(
  `CaseStudy`,
  (u: unknown): u is CaseStudy => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CaseStudy> => pipe(c.decode(u), E.map(x => ({ ...x, ...caseStudy }))),
  (x: CaseStudy): CaseStudyTagged => ({ ...x, _tag: `CaseStudy`}),
)) satisfies t.Type<CaseStudy, unknown>;


export const researchSlashWhitePapers = {
  _tag: `ResearchSlashWhitePapers`,
  id: 5,
  name: `Research / White Papers`
} as const;

export type ResearchSlashWhitePapersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ResearchSlashWhitePapers`>
}>;
export const researchSlashWhitePapersTaggedC: ResearchSlashWhitePapersTaggedC = t.type({
  _tag: t.literal(`ResearchSlashWhitePapers`)
});
export type ResearchSlashWhitePapersTagged = t.TypeOf<ResearchSlashWhitePapersTaggedC>;
export type ResearchSlashWhitePapers = ResearchSlashWhitePapersTagged & typeof researchSlashWhitePapers;
export type ResearchSlashWhitePapersC = t.Type<ResearchSlashWhitePapers, ResearchSlashWhitePapersTagged>;
export const researchSlashWhitePapersC: ResearchSlashWhitePapersC = pipe(researchSlashWhitePapersTaggedC, c => new t.Type<ResearchSlashWhitePapers, ResearchSlashWhitePapersTagged>(
  `ResearchSlashWhitePapers`,
  (u: unknown): u is ResearchSlashWhitePapers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ResearchSlashWhitePapers> => pipe(c.decode(u), E.map(x => ({ ...x, ...researchSlashWhitePapers }))),
  (x: ResearchSlashWhitePapers): ResearchSlashWhitePapersTagged => ({ ...x, _tag: `ResearchSlashWhitePapers`}),
)) satisfies t.Type<ResearchSlashWhitePapers, unknown>;


export const userGuides = {
  _tag: `UserGuides`,
  id: 2,
  name: `User Guides`
} as const;

export type UserGuidesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserGuides`>
}>;
export const userGuidesTaggedC: UserGuidesTaggedC = t.type({
  _tag: t.literal(`UserGuides`)
});
export type UserGuidesTagged = t.TypeOf<UserGuidesTaggedC>;
export type UserGuides = UserGuidesTagged & typeof userGuides;
export type UserGuidesC = t.Type<UserGuides, UserGuidesTagged>;
export const userGuidesC: UserGuidesC = pipe(userGuidesTaggedC, c => new t.Type<UserGuides, UserGuidesTagged>(
  `UserGuides`,
  (u: unknown): u is UserGuides => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserGuides> => pipe(c.decode(u), E.map(x => ({ ...x, ...userGuides }))),
  (x: UserGuides): UserGuidesTagged => ({ ...x, _tag: `UserGuides`}),
)) satisfies t.Type<UserGuides, unknown>;


export const dataSheet = {
  _tag: `DataSheet`,
  id: 6,
  name: `Data Sheet`
} as const;

export type DataSheetTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DataSheet`>
}>;
export const dataSheetTaggedC: DataSheetTaggedC = t.type({
  _tag: t.literal(`DataSheet`)
});
export type DataSheetTagged = t.TypeOf<DataSheetTaggedC>;
export type DataSheet = DataSheetTagged & typeof dataSheet;
export type DataSheetC = t.Type<DataSheet, DataSheetTagged>;
export const dataSheetC: DataSheetC = pipe(dataSheetTaggedC, c => new t.Type<DataSheet, DataSheetTagged>(
  `DataSheet`,
  (u: unknown): u is DataSheet => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DataSheet> => pipe(c.decode(u), E.map(x => ({ ...x, ...dataSheet }))),
  (x: DataSheet): DataSheetTagged => ({ ...x, _tag: `DataSheet`}),
)) satisfies t.Type<DataSheet, unknown>;


export const other = {
  _tag: `Other`,
  id: 7,
  name: `Other`
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
)) satisfies t.Type<Other, unknown>;


export const allBondLinkResourceTypeC = [bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC, userGuidesC, dataSheetC, otherC] as const;
export const allBondLinkResourceTypeNames = [`BestPractices`, `AroundTheNetwork`, `CaseStudy`, `ResearchSlashWhitePapers`, `UserGuides`, `DataSheet`, `Other`] as const;
export type BondLinkResourceTypeName = (typeof allBondLinkResourceTypeNames)[number];

export type BondLinkResourceTypeCU = t.UnionC<[BestPracticesC, AroundTheNetworkC, CaseStudyC, ResearchSlashWhitePapersC, UserGuidesC, DataSheetC, OtherC]>;
export type BondLinkResourceTypeU = BestPractices | AroundTheNetwork | CaseStudy | ResearchSlashWhitePapers | UserGuides | DataSheet | Other;
export const BondLinkResourceTypeCU: BondLinkResourceTypeCU = t.union([bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC, userGuidesC, dataSheetC, otherC]) satisfies t.Type<BondLinkResourceTypeU, unknown>;

export const bondLinkResourceTypeOrd: Ord.Ord<BondLinkResourceTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkResourceType = [bestPractices, aroundTheNetwork, caseStudy, researchSlashWhitePapers, userGuides, dataSheet, other] as const;
export type BondLinkResourceTypeMap<A> = { [K in BondLinkResourceTypeName]: A };


export const allBondLinkResourceBestPracticesTypeC = [bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC] as const;
export const allBondLinkResourceBestPracticesTypeNames = [`BestPractices`, `AroundTheNetwork`, `CaseStudy`, `ResearchSlashWhitePapers`] as const;
export type BondLinkResourceBestPracticesTypeName = (typeof allBondLinkResourceBestPracticesTypeNames)[number];

export type BondLinkResourceBestPracticesTypeCU = t.UnionC<[BestPracticesC, AroundTheNetworkC, CaseStudyC, ResearchSlashWhitePapersC]>;
export type BondLinkResourceBestPracticesTypeU = BestPractices | AroundTheNetwork | CaseStudy | ResearchSlashWhitePapers;
export const BondLinkResourceBestPracticesTypeCU: BondLinkResourceBestPracticesTypeCU = t.union([bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC]) satisfies t.Type<BondLinkResourceBestPracticesTypeU, unknown>;

export const bondLinkResourceBestPracticesTypeOrd: Ord.Ord<BondLinkResourceBestPracticesTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkResourceBestPracticesType = [bestPractices, aroundTheNetwork, caseStudy, researchSlashWhitePapers] as const;
export type BondLinkResourceBestPracticesTypeMap<A> = { [K in BondLinkResourceBestPracticesTypeName]: A };


export const allBondLinkResourceUserGuidesTypeC = [userGuidesC, dataSheetC, otherC] as const;
export const allBondLinkResourceUserGuidesTypeNames = [`UserGuides`, `DataSheet`, `Other`] as const;
export type BondLinkResourceUserGuidesTypeName = (typeof allBondLinkResourceUserGuidesTypeNames)[number];

export type BondLinkResourceUserGuidesTypeCU = t.UnionC<[UserGuidesC, DataSheetC, OtherC]>;
export type BondLinkResourceUserGuidesTypeU = UserGuides | DataSheet | Other;
export const BondLinkResourceUserGuidesTypeCU: BondLinkResourceUserGuidesTypeCU = t.union([userGuidesC, dataSheetC, otherC]) satisfies t.Type<BondLinkResourceUserGuidesTypeU, unknown>;

export const bondLinkResourceUserGuidesTypeOrd: Ord.Ord<BondLinkResourceUserGuidesTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkResourceUserGuidesType = [userGuides, dataSheet, other] as const;
export type BondLinkResourceUserGuidesTypeMap<A> = { [K in BondLinkResourceUserGuidesTypeName]: A };


