import * as t from "io-ts";

export interface MarkdownTag { readonly MarkdownTag: unique symbol }
const markdownCUnderlying = t.string;

export type MarkdownC = t.Type<MarkdownTag, string, unknown>;

export const markdownC: MarkdownC = new t.Type<MarkdownTag, string, unknown>(
  "MarkdownTypeC",
  (u: unknown): u is MarkdownTag => markdownCUnderlying.is(u),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (i: unknown, c: t.Context) => markdownCUnderlying.validate(i, c) as t.Validation<MarkdownTag>,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (m): string => m as unknown as t.TypeOf<typeof markdownCUnderlying>,
);

export type Markdown = t.TypeOf<MarkdownC>;
