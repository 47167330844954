import * as t from "io-ts";

export type CsvC = t.TypeC<{
  data: t.ReadonlyArrayC<t.ReadonlyArrayC<t.StringC>>
}>;
export type Csv = {
  data: ReadonlyArray<ReadonlyArray<string>>
};
export const csvC: CsvC = t.type({
  data: t.readonlyArray(t.readonlyArray(t.string))
}) satisfies t.Type<Csv, unknown>;


