import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const authority = {
  _tag: `Authority`,
  id: 1,
  name: `Authority`
} as const;

export type AuthorityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Authority`>
}>;
export const authorityTaggedC: AuthorityTaggedC = t.type({
  _tag: t.literal(`Authority`)
});
export type AuthorityTagged = t.TypeOf<AuthorityTaggedC>;
export type Authority = AuthorityTagged & typeof authority;
export type AuthorityC = t.Type<Authority, AuthorityTagged>;
export const authorityC: AuthorityC = pipe(authorityTaggedC, c => new t.Type<Authority, AuthorityTagged>(
  `Authority`,
  (u: unknown): u is Authority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Authority> => pipe(c.decode(u), E.map(x => ({ ...x, ...authority }))),
  (x: Authority): AuthorityTagged => ({ ...x, _tag: `Authority`}),
)) satisfies t.Type<Authority, unknown>;


export const bondAnticipationNote = {
  _tag: `BondAnticipationNote`,
  id: 2,
  name: `Bond Anticipation Note`
} as const;

export type BondAnticipationNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondAnticipationNote`>
}>;
export const bondAnticipationNoteTaggedC: BondAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`BondAnticipationNote`)
});
export type BondAnticipationNoteTagged = t.TypeOf<BondAnticipationNoteTaggedC>;
export type BondAnticipationNote = BondAnticipationNoteTagged & typeof bondAnticipationNote;
export type BondAnticipationNoteC = t.Type<BondAnticipationNote, BondAnticipationNoteTagged>;
export const bondAnticipationNoteC: BondAnticipationNoteC = pipe(bondAnticipationNoteTaggedC, c => new t.Type<BondAnticipationNote, BondAnticipationNoteTagged>(
  `BondAnticipationNote`,
  (u: unknown): u is BondAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondAnticipationNote }))),
  (x: BondAnticipationNote): BondAnticipationNoteTagged => ({ ...x, _tag: `BondAnticipationNote`}),
)) satisfies t.Type<BondAnticipationNote, unknown>;


export const building = {
  _tag: `Building`,
  id: 3,
  name: `Building`
} as const;

export type BuildingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Building`>
}>;
export const buildingTaggedC: BuildingTaggedC = t.type({
  _tag: t.literal(`Building`)
});
export type BuildingTagged = t.TypeOf<BuildingTaggedC>;
export type Building = BuildingTagged & typeof building;
export type BuildingC = t.Type<Building, BuildingTagged>;
export const buildingC: BuildingC = pipe(buildingTaggedC, c => new t.Type<Building, BuildingTagged>(
  `Building`,
  (u: unknown): u is Building => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Building> => pipe(c.decode(u), E.map(x => ({ ...x, ...building }))),
  (x: Building): BuildingTagged => ({ ...x, _tag: `Building`}),
)) satisfies t.Type<Building, unknown>;


export const capitalLoanNotes = {
  _tag: `CapitalLoanNotes`,
  id: 4,
  name: `Capital Loan Notes`
} as const;

export type CapitalLoanNotesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CapitalLoanNotes`>
}>;
export const capitalLoanNotesTaggedC: CapitalLoanNotesTaggedC = t.type({
  _tag: t.literal(`CapitalLoanNotes`)
});
export type CapitalLoanNotesTagged = t.TypeOf<CapitalLoanNotesTaggedC>;
export type CapitalLoanNotes = CapitalLoanNotesTagged & typeof capitalLoanNotes;
export type CapitalLoanNotesC = t.Type<CapitalLoanNotes, CapitalLoanNotesTagged>;
export const capitalLoanNotesC: CapitalLoanNotesC = pipe(capitalLoanNotesTaggedC, c => new t.Type<CapitalLoanNotes, CapitalLoanNotesTagged>(
  `CapitalLoanNotes`,
  (u: unknown): u is CapitalLoanNotes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CapitalLoanNotes> => pipe(c.decode(u), E.map(x => ({ ...x, ...capitalLoanNotes }))),
  (x: CapitalLoanNotes): CapitalLoanNotesTagged => ({ ...x, _tag: `CapitalLoanNotes`}),
)) satisfies t.Type<CapitalLoanNotes, unknown>;


export const certificatesOfParticipation = {
  _tag: `CertificatesOfParticipation`,
  id: 5,
  name: `Certificates Of Participation`
} as const;

export type CertificatesOfParticipationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CertificatesOfParticipation`>
}>;
export const certificatesOfParticipationTaggedC: CertificatesOfParticipationTaggedC = t.type({
  _tag: t.literal(`CertificatesOfParticipation`)
});
export type CertificatesOfParticipationTagged = t.TypeOf<CertificatesOfParticipationTaggedC>;
export type CertificatesOfParticipation = CertificatesOfParticipationTagged & typeof certificatesOfParticipation;
export type CertificatesOfParticipationC = t.Type<CertificatesOfParticipation, CertificatesOfParticipationTagged>;
export const certificatesOfParticipationC: CertificatesOfParticipationC = pipe(certificatesOfParticipationTaggedC, c => new t.Type<CertificatesOfParticipation, CertificatesOfParticipationTagged>(
  `CertificatesOfParticipation`,
  (u: unknown): u is CertificatesOfParticipation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CertificatesOfParticipation> => pipe(c.decode(u), E.map(x => ({ ...x, ...certificatesOfParticipation }))),
  (x: CertificatesOfParticipation): CertificatesOfParticipationTagged => ({ ...x, _tag: `CertificatesOfParticipation`}),
)) satisfies t.Type<CertificatesOfParticipation, unknown>;


export const corporatePurpose = {
  _tag: `CorporatePurpose`,
  id: 6,
  name: `Corporate Purpose`
} as const;

export type CorporatePurposeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CorporatePurpose`>
}>;
export const corporatePurposeTaggedC: CorporatePurposeTaggedC = t.type({
  _tag: t.literal(`CorporatePurpose`)
});
export type CorporatePurposeTagged = t.TypeOf<CorporatePurposeTaggedC>;
export type CorporatePurpose = CorporatePurposeTagged & typeof corporatePurpose;
export type CorporatePurposeC = t.Type<CorporatePurpose, CorporatePurposeTagged>;
export const corporatePurposeC: CorporatePurposeC = pipe(corporatePurposeTaggedC, c => new t.Type<CorporatePurpose, CorporatePurposeTagged>(
  `CorporatePurpose`,
  (u: unknown): u is CorporatePurpose => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CorporatePurpose> => pipe(c.decode(u), E.map(x => ({ ...x, ...corporatePurpose }))),
  (x: CorporatePurpose): CorporatePurposeTagged => ({ ...x, _tag: `CorporatePurpose`}),
)) satisfies t.Type<CorporatePurpose, unknown>;


export const drainageDistrict = {
  _tag: `DrainageDistrict`,
  id: 7,
  name: `Drainage District`
} as const;

export type DrainageDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DrainageDistrict`>
}>;
export const drainageDistrictTaggedC: DrainageDistrictTaggedC = t.type({
  _tag: t.literal(`DrainageDistrict`)
});
export type DrainageDistrictTagged = t.TypeOf<DrainageDistrictTaggedC>;
export type DrainageDistrict = DrainageDistrictTagged & typeof drainageDistrict;
export type DrainageDistrictC = t.Type<DrainageDistrict, DrainageDistrictTagged>;
export const drainageDistrictC: DrainageDistrictC = pipe(drainageDistrictTaggedC, c => new t.Type<DrainageDistrict, DrainageDistrictTagged>(
  `DrainageDistrict`,
  (u: unknown): u is DrainageDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DrainageDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...drainageDistrict }))),
  (x: DrainageDistrict): DrainageDistrictTagged => ({ ...x, _tag: `DrainageDistrict`}),
)) satisfies t.Type<DrainageDistrict, unknown>;


export const economicDevelopmentRevenue = {
  _tag: `EconomicDevelopmentRevenue`,
  id: 8,
  name: `Economic Development Revenue`
} as const;

export type EconomicDevelopmentRevenueTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EconomicDevelopmentRevenue`>
}>;
export const economicDevelopmentRevenueTaggedC: EconomicDevelopmentRevenueTaggedC = t.type({
  _tag: t.literal(`EconomicDevelopmentRevenue`)
});
export type EconomicDevelopmentRevenueTagged = t.TypeOf<EconomicDevelopmentRevenueTaggedC>;
export type EconomicDevelopmentRevenue = EconomicDevelopmentRevenueTagged & typeof economicDevelopmentRevenue;
export type EconomicDevelopmentRevenueC = t.Type<EconomicDevelopmentRevenue, EconomicDevelopmentRevenueTagged>;
export const economicDevelopmentRevenueC: EconomicDevelopmentRevenueC = pipe(economicDevelopmentRevenueTaggedC, c => new t.Type<EconomicDevelopmentRevenue, EconomicDevelopmentRevenueTagged>(
  `EconomicDevelopmentRevenue`,
  (u: unknown): u is EconomicDevelopmentRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EconomicDevelopmentRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...economicDevelopmentRevenue }))),
  (x: EconomicDevelopmentRevenue): EconomicDevelopmentRevenueTagged => ({ ...x, _tag: `EconomicDevelopmentRevenue`}),
)) satisfies t.Type<EconomicDevelopmentRevenue, unknown>;


export const education = {
  _tag: `Education`,
  id: 9,
  name: `Education`
} as const;

export type EducationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Education`>
}>;
export const educationTaggedC: EducationTaggedC = t.type({
  _tag: t.literal(`Education`)
});
export type EducationTagged = t.TypeOf<EducationTaggedC>;
export type Education = EducationTagged & typeof education;
export type EducationC = t.Type<Education, EducationTagged>;
export const educationC: EducationC = pipe(educationTaggedC, c => new t.Type<Education, EducationTagged>(
  `Education`,
  (u: unknown): u is Education => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Education> => pipe(c.decode(u), E.map(x => ({ ...x, ...education }))),
  (x: Education): EducationTagged => ({ ...x, _tag: `Education`}),
)) satisfies t.Type<Education, unknown>;


export const environment = {
  _tag: `Environment`,
  id: 10,
  name: `Environment`
} as const;

export type EnvironmentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Environment`>
}>;
export const environmentTaggedC: EnvironmentTaggedC = t.type({
  _tag: t.literal(`Environment`)
});
export type EnvironmentTagged = t.TypeOf<EnvironmentTaggedC>;
export type Environment = EnvironmentTagged & typeof environment;
export type EnvironmentC = t.Type<Environment, EnvironmentTagged>;
export const environmentC: EnvironmentC = pipe(environmentTaggedC, c => new t.Type<Environment, EnvironmentTagged>(
  `Environment`,
  (u: unknown): u is Environment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Environment> => pipe(c.decode(u), E.map(x => ({ ...x, ...environment }))),
  (x: Environment): EnvironmentTagged => ({ ...x, _tag: `Environment`}),
)) satisfies t.Type<Environment, unknown>;


export const equipment = {
  _tag: `Equipment`,
  id: 11,
  name: `Equipment`
} as const;

export type EquipmentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Equipment`>
}>;
export const equipmentTaggedC: EquipmentTaggedC = t.type({
  _tag: t.literal(`Equipment`)
});
export type EquipmentTagged = t.TypeOf<EquipmentTaggedC>;
export type Equipment = EquipmentTagged & typeof equipment;
export type EquipmentC = t.Type<Equipment, EquipmentTagged>;
export const equipmentC: EquipmentC = pipe(equipmentTaggedC, c => new t.Type<Equipment, EquipmentTagged>(
  `Equipment`,
  (u: unknown): u is Equipment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Equipment> => pipe(c.decode(u), E.map(x => ({ ...x, ...equipment }))),
  (x: Equipment): EquipmentTagged => ({ ...x, _tag: `Equipment`}),
)) satisfies t.Type<Equipment, unknown>;


export const federalAidNote = {
  _tag: `FederalAidNote`,
  id: 12,
  name: `Federal Aid Note`
} as const;

export type FederalAidNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FederalAidNote`>
}>;
export const federalAidNoteTaggedC: FederalAidNoteTaggedC = t.type({
  _tag: t.literal(`FederalAidNote`)
});
export type FederalAidNoteTagged = t.TypeOf<FederalAidNoteTaggedC>;
export type FederalAidNote = FederalAidNoteTagged & typeof federalAidNote;
export type FederalAidNoteC = t.Type<FederalAidNote, FederalAidNoteTagged>;
export const federalAidNoteC: FederalAidNoteC = pipe(federalAidNoteTaggedC, c => new t.Type<FederalAidNote, FederalAidNoteTagged>(
  `FederalAidNote`,
  (u: unknown): u is FederalAidNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FederalAidNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...federalAidNote }))),
  (x: FederalAidNote): FederalAidNoteTagged => ({ ...x, _tag: `FederalAidNote`}),
)) satisfies t.Type<FederalAidNote, unknown>;


export const fireDistrict = {
  _tag: `FireDistrict`,
  id: 13,
  name: `Fire District`
} as const;

export type FireDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FireDistrict`>
}>;
export const fireDistrictTaggedC: FireDistrictTaggedC = t.type({
  _tag: t.literal(`FireDistrict`)
});
export type FireDistrictTagged = t.TypeOf<FireDistrictTaggedC>;
export type FireDistrict = FireDistrictTagged & typeof fireDistrict;
export type FireDistrictC = t.Type<FireDistrict, FireDistrictTagged>;
export const fireDistrictC: FireDistrictC = pipe(fireDistrictTaggedC, c => new t.Type<FireDistrict, FireDistrictTagged>(
  `FireDistrict`,
  (u: unknown): u is FireDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FireDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...fireDistrict }))),
  (x: FireDistrict): FireDistrictTagged => ({ ...x, _tag: `FireDistrict`}),
)) satisfies t.Type<FireDistrict, unknown>;


export const floodWaterSewerDisposal = {
  _tag: `FloodWaterSewerDisposal`,
  id: 14,
  name: `Flood Water Sewer Disposal`
} as const;

export type FloodWaterSewerDisposalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FloodWaterSewerDisposal`>
}>;
export const floodWaterSewerDisposalTaggedC: FloodWaterSewerDisposalTaggedC = t.type({
  _tag: t.literal(`FloodWaterSewerDisposal`)
});
export type FloodWaterSewerDisposalTagged = t.TypeOf<FloodWaterSewerDisposalTaggedC>;
export type FloodWaterSewerDisposal = FloodWaterSewerDisposalTagged & typeof floodWaterSewerDisposal;
export type FloodWaterSewerDisposalC = t.Type<FloodWaterSewerDisposal, FloodWaterSewerDisposalTagged>;
export const floodWaterSewerDisposalC: FloodWaterSewerDisposalC = pipe(floodWaterSewerDisposalTaggedC, c => new t.Type<FloodWaterSewerDisposal, FloodWaterSewerDisposalTagged>(
  `FloodWaterSewerDisposal`,
  (u: unknown): u is FloodWaterSewerDisposal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FloodWaterSewerDisposal> => pipe(c.decode(u), E.map(x => ({ ...x, ...floodWaterSewerDisposal }))),
  (x: FloodWaterSewerDisposal): FloodWaterSewerDisposalTagged => ({ ...x, _tag: `FloodWaterSewerDisposal`}),
)) satisfies t.Type<FloodWaterSewerDisposal, unknown>;


export const generalPurpose = {
  _tag: `GeneralPurpose`,
  id: 15,
  name: `General Purpose`
} as const;

export type GeneralPurposeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`GeneralPurpose`>
}>;
export const generalPurposeTaggedC: GeneralPurposeTaggedC = t.type({
  _tag: t.literal(`GeneralPurpose`)
});
export type GeneralPurposeTagged = t.TypeOf<GeneralPurposeTaggedC>;
export type GeneralPurpose = GeneralPurposeTagged & typeof generalPurpose;
export type GeneralPurposeC = t.Type<GeneralPurpose, GeneralPurposeTagged>;
export const generalPurposeC: GeneralPurposeC = pipe(generalPurposeTaggedC, c => new t.Type<GeneralPurpose, GeneralPurposeTagged>(
  `GeneralPurpose`,
  (u: unknown): u is GeneralPurpose => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GeneralPurpose> => pipe(c.decode(u), E.map(x => ({ ...x, ...generalPurpose }))),
  (x: GeneralPurpose): GeneralPurposeTagged => ({ ...x, _tag: `GeneralPurpose`}),
)) satisfies t.Type<GeneralPurpose, unknown>;


export const grantAnticipationNote = {
  _tag: `GrantAnticipationNote`,
  id: 16,
  name: `Grant Anticipation Note`
} as const;

export type GrantAnticipationNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`GrantAnticipationNote`>
}>;
export const grantAnticipationNoteTaggedC: GrantAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`GrantAnticipationNote`)
});
export type GrantAnticipationNoteTagged = t.TypeOf<GrantAnticipationNoteTaggedC>;
export type GrantAnticipationNote = GrantAnticipationNoteTagged & typeof grantAnticipationNote;
export type GrantAnticipationNoteC = t.Type<GrantAnticipationNote, GrantAnticipationNoteTagged>;
export const grantAnticipationNoteC: GrantAnticipationNoteC = pipe(grantAnticipationNoteTaggedC, c => new t.Type<GrantAnticipationNote, GrantAnticipationNoteTagged>(
  `GrantAnticipationNote`,
  (u: unknown): u is GrantAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GrantAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...grantAnticipationNote }))),
  (x: GrantAnticipationNote): GrantAnticipationNoteTagged => ({ ...x, _tag: `GrantAnticipationNote`}),
)) satisfies t.Type<GrantAnticipationNote, unknown>;


export const health = {
  _tag: `Health`,
  id: 17,
  name: `Health`
} as const;

export type HealthTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Health`>
}>;
export const healthTaggedC: HealthTaggedC = t.type({
  _tag: t.literal(`Health`)
});
export type HealthTagged = t.TypeOf<HealthTaggedC>;
export type Health = HealthTagged & typeof health;
export type HealthC = t.Type<Health, HealthTagged>;
export const healthC: HealthC = pipe(healthTaggedC, c => new t.Type<Health, HealthTagged>(
  `Health`,
  (u: unknown): u is Health => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Health> => pipe(c.decode(u), E.map(x => ({ ...x, ...health }))),
  (x: Health): HealthTagged => ({ ...x, _tag: `Health`}),
)) satisfies t.Type<Health, unknown>;


export const housing = {
  _tag: `Housing`,
  id: 18,
  name: `Housing`
} as const;

export type HousingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Housing`>
}>;
export const housingTaggedC: HousingTaggedC = t.type({
  _tag: t.literal(`Housing`)
});
export type HousingTagged = t.TypeOf<HousingTaggedC>;
export type Housing = HousingTagged & typeof housing;
export type HousingC = t.Type<Housing, HousingTagged>;
export const housingC: HousingC = pipe(housingTaggedC, c => new t.Type<Housing, HousingTagged>(
  `Housing`,
  (u: unknown): u is Housing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Housing> => pipe(c.decode(u), E.map(x => ({ ...x, ...housing }))),
  (x: Housing): HousingTagged => ({ ...x, _tag: `Housing`}),
)) satisfies t.Type<Housing, unknown>;


export const improvement = {
  _tag: `Improvement`,
  id: 19,
  name: `Improvement`
} as const;

export type ImprovementTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Improvement`>
}>;
export const improvementTaggedC: ImprovementTaggedC = t.type({
  _tag: t.literal(`Improvement`)
});
export type ImprovementTagged = t.TypeOf<ImprovementTaggedC>;
export type Improvement = ImprovementTagged & typeof improvement;
export type ImprovementC = t.Type<Improvement, ImprovementTagged>;
export const improvementC: ImprovementC = pipe(improvementTaggedC, c => new t.Type<Improvement, ImprovementTagged>(
  `Improvement`,
  (u: unknown): u is Improvement => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Improvement> => pipe(c.decode(u), E.map(x => ({ ...x, ...improvement }))),
  (x: Improvement): ImprovementTagged => ({ ...x, _tag: `Improvement`}),
)) satisfies t.Type<Improvement, unknown>;


export const industrialDevelopmentRevenue = {
  _tag: `IndustrialDevelopmentRevenue`,
  id: 20,
  name: `Industrial Development Revenue`
} as const;

export type IndustrialDevelopmentRevenueTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IndustrialDevelopmentRevenue`>
}>;
export const industrialDevelopmentRevenueTaggedC: IndustrialDevelopmentRevenueTaggedC = t.type({
  _tag: t.literal(`IndustrialDevelopmentRevenue`)
});
export type IndustrialDevelopmentRevenueTagged = t.TypeOf<IndustrialDevelopmentRevenueTaggedC>;
export type IndustrialDevelopmentRevenue = IndustrialDevelopmentRevenueTagged & typeof industrialDevelopmentRevenue;
export type IndustrialDevelopmentRevenueC = t.Type<IndustrialDevelopmentRevenue, IndustrialDevelopmentRevenueTagged>;
export const industrialDevelopmentRevenueC: IndustrialDevelopmentRevenueC = pipe(industrialDevelopmentRevenueTaggedC, c => new t.Type<IndustrialDevelopmentRevenue, IndustrialDevelopmentRevenueTagged>(
  `IndustrialDevelopmentRevenue`,
  (u: unknown): u is IndustrialDevelopmentRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IndustrialDevelopmentRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...industrialDevelopmentRevenue }))),
  (x: IndustrialDevelopmentRevenue): IndustrialDevelopmentRevenueTagged => ({ ...x, _tag: `IndustrialDevelopmentRevenue`}),
)) satisfies t.Type<IndustrialDevelopmentRevenue, unknown>;


export const libraryDistrict = {
  _tag: `LibraryDistrict`,
  id: 21,
  name: `Library District`
} as const;

export type LibraryDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LibraryDistrict`>
}>;
export const libraryDistrictTaggedC: LibraryDistrictTaggedC = t.type({
  _tag: t.literal(`LibraryDistrict`)
});
export type LibraryDistrictTagged = t.TypeOf<LibraryDistrictTaggedC>;
export type LibraryDistrict = LibraryDistrictTagged & typeof libraryDistrict;
export type LibraryDistrictC = t.Type<LibraryDistrict, LibraryDistrictTagged>;
export const libraryDistrictC: LibraryDistrictC = pipe(libraryDistrictTaggedC, c => new t.Type<LibraryDistrict, LibraryDistrictTagged>(
  `LibraryDistrict`,
  (u: unknown): u is LibraryDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LibraryDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...libraryDistrict }))),
  (x: LibraryDistrict): LibraryDistrictTagged => ({ ...x, _tag: `LibraryDistrict`}),
)) satisfies t.Type<LibraryDistrict, unknown>;


export const metropolitanDistrict = {
  _tag: `MetropolitanDistrict`,
  id: 22,
  name: `Metropolitan District`
} as const;

export type MetropolitanDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MetropolitanDistrict`>
}>;
export const metropolitanDistrictTaggedC: MetropolitanDistrictTaggedC = t.type({
  _tag: t.literal(`MetropolitanDistrict`)
});
export type MetropolitanDistrictTagged = t.TypeOf<MetropolitanDistrictTaggedC>;
export type MetropolitanDistrict = MetropolitanDistrictTagged & typeof metropolitanDistrict;
export type MetropolitanDistrictC = t.Type<MetropolitanDistrict, MetropolitanDistrictTagged>;
export const metropolitanDistrictC: MetropolitanDistrictC = pipe(metropolitanDistrictTaggedC, c => new t.Type<MetropolitanDistrict, MetropolitanDistrictTagged>(
  `MetropolitanDistrict`,
  (u: unknown): u is MetropolitanDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MetropolitanDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...metropolitanDistrict }))),
  (x: MetropolitanDistrict): MetropolitanDistrictTagged => ({ ...x, _tag: `MetropolitanDistrict`}),
)) satisfies t.Type<MetropolitanDistrict, unknown>;


export const miscellaneous = {
  _tag: `Miscellaneous`,
  id: 23,
  name: `Miscellaneous`
} as const;

export type MiscellaneousTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Miscellaneous`>
}>;
export const miscellaneousTaggedC: MiscellaneousTaggedC = t.type({
  _tag: t.literal(`Miscellaneous`)
});
export type MiscellaneousTagged = t.TypeOf<MiscellaneousTaggedC>;
export type Miscellaneous = MiscellaneousTagged & typeof miscellaneous;
export type MiscellaneousC = t.Type<Miscellaneous, MiscellaneousTagged>;
export const miscellaneousC: MiscellaneousC = pipe(miscellaneousTaggedC, c => new t.Type<Miscellaneous, MiscellaneousTagged>(
  `Miscellaneous`,
  (u: unknown): u is Miscellaneous => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Miscellaneous> => pipe(c.decode(u), E.map(x => ({ ...x, ...miscellaneous }))),
  (x: Miscellaneous): MiscellaneousTagged => ({ ...x, _tag: `Miscellaneous`}),
)) satisfies t.Type<Miscellaneous, unknown>;


export const municipalBondBank = {
  _tag: `MunicipalBondBank`,
  id: 24,
  name: `Municipal Bond Bank`
} as const;

export type MunicipalBondBankTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MunicipalBondBank`>
}>;
export const municipalBondBankTaggedC: MunicipalBondBankTaggedC = t.type({
  _tag: t.literal(`MunicipalBondBank`)
});
export type MunicipalBondBankTagged = t.TypeOf<MunicipalBondBankTaggedC>;
export type MunicipalBondBank = MunicipalBondBankTagged & typeof municipalBondBank;
export type MunicipalBondBankC = t.Type<MunicipalBondBank, MunicipalBondBankTagged>;
export const municipalBondBankC: MunicipalBondBankC = pipe(municipalBondBankTaggedC, c => new t.Type<MunicipalBondBank, MunicipalBondBankTagged>(
  `MunicipalBondBank`,
  (u: unknown): u is MunicipalBondBank => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalBondBank> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalBondBank }))),
  (x: MunicipalBondBank): MunicipalBondBankTagged => ({ ...x, _tag: `MunicipalBondBank`}),
)) satisfies t.Type<MunicipalBondBank, unknown>;


export const municipalUtilitiesDistrict = {
  _tag: `MunicipalUtilitiesDistrict`,
  id: 25,
  name: `Municipal Utilities District`
} as const;

export type MunicipalUtilitiesDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MunicipalUtilitiesDistrict`>
}>;
export const municipalUtilitiesDistrictTaggedC: MunicipalUtilitiesDistrictTaggedC = t.type({
  _tag: t.literal(`MunicipalUtilitiesDistrict`)
});
export type MunicipalUtilitiesDistrictTagged = t.TypeOf<MunicipalUtilitiesDistrictTaggedC>;
export type MunicipalUtilitiesDistrict = MunicipalUtilitiesDistrictTagged & typeof municipalUtilitiesDistrict;
export type MunicipalUtilitiesDistrictC = t.Type<MunicipalUtilitiesDistrict, MunicipalUtilitiesDistrictTagged>;
export const municipalUtilitiesDistrictC: MunicipalUtilitiesDistrictC = pipe(municipalUtilitiesDistrictTaggedC, c => new t.Type<MunicipalUtilitiesDistrict, MunicipalUtilitiesDistrictTagged>(
  `MunicipalUtilitiesDistrict`,
  (u: unknown): u is MunicipalUtilitiesDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalUtilitiesDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalUtilitiesDistrict }))),
  (x: MunicipalUtilitiesDistrict): MunicipalUtilitiesDistrictTagged => ({ ...x, _tag: `MunicipalUtilitiesDistrict`}),
)) satisfies t.Type<MunicipalUtilitiesDistrict, unknown>;


export const parkDistrict = {
  _tag: `ParkDistrict`,
  id: 26,
  name: `Park District`
} as const;

export type ParkDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ParkDistrict`>
}>;
export const parkDistrictTaggedC: ParkDistrictTaggedC = t.type({
  _tag: t.literal(`ParkDistrict`)
});
export type ParkDistrictTagged = t.TypeOf<ParkDistrictTaggedC>;
export type ParkDistrict = ParkDistrictTagged & typeof parkDistrict;
export type ParkDistrictC = t.Type<ParkDistrict, ParkDistrictTagged>;
export const parkDistrictC: ParkDistrictC = pipe(parkDistrictTaggedC, c => new t.Type<ParkDistrict, ParkDistrictTagged>(
  `ParkDistrict`,
  (u: unknown): u is ParkDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ParkDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...parkDistrict }))),
  (x: ParkDistrict): ParkDistrictTagged => ({ ...x, _tag: `ParkDistrict`}),
)) satisfies t.Type<ParkDistrict, unknown>;


export const parkingAuthority = {
  _tag: `ParkingAuthority`,
  id: 27,
  name: `Parking Authority`
} as const;

export type ParkingAuthorityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ParkingAuthority`>
}>;
export const parkingAuthorityTaggedC: ParkingAuthorityTaggedC = t.type({
  _tag: t.literal(`ParkingAuthority`)
});
export type ParkingAuthorityTagged = t.TypeOf<ParkingAuthorityTaggedC>;
export type ParkingAuthority = ParkingAuthorityTagged & typeof parkingAuthority;
export type ParkingAuthorityC = t.Type<ParkingAuthority, ParkingAuthorityTagged>;
export const parkingAuthorityC: ParkingAuthorityC = pipe(parkingAuthorityTaggedC, c => new t.Type<ParkingAuthority, ParkingAuthorityTagged>(
  `ParkingAuthority`,
  (u: unknown): u is ParkingAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ParkingAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...parkingAuthority }))),
  (x: ParkingAuthority): ParkingAuthorityTagged => ({ ...x, _tag: `ParkingAuthority`}),
)) satisfies t.Type<ParkingAuthority, unknown>;


export const pollutionControlRevenue = {
  _tag: `PollutionControlRevenue`,
  id: 28,
  name: `Pollution Control Revenue`
} as const;

export type PollutionControlRevenueTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PollutionControlRevenue`>
}>;
export const pollutionControlRevenueTaggedC: PollutionControlRevenueTaggedC = t.type({
  _tag: t.literal(`PollutionControlRevenue`)
});
export type PollutionControlRevenueTagged = t.TypeOf<PollutionControlRevenueTaggedC>;
export type PollutionControlRevenue = PollutionControlRevenueTagged & typeof pollutionControlRevenue;
export type PollutionControlRevenueC = t.Type<PollutionControlRevenue, PollutionControlRevenueTagged>;
export const pollutionControlRevenueC: PollutionControlRevenueC = pipe(pollutionControlRevenueTaggedC, c => new t.Type<PollutionControlRevenue, PollutionControlRevenueTagged>(
  `PollutionControlRevenue`,
  (u: unknown): u is PollutionControlRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PollutionControlRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...pollutionControlRevenue }))),
  (x: PollutionControlRevenue): PollutionControlRevenueTagged => ({ ...x, _tag: `PollutionControlRevenue`}),
)) satisfies t.Type<PollutionControlRevenue, unknown>;


export const poolFinancingAuthority = {
  _tag: `PoolFinancingAuthority`,
  id: 29,
  name: `Pool Financing Authority`
} as const;

export type PoolFinancingAuthorityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PoolFinancingAuthority`>
}>;
export const poolFinancingAuthorityTaggedC: PoolFinancingAuthorityTaggedC = t.type({
  _tag: t.literal(`PoolFinancingAuthority`)
});
export type PoolFinancingAuthorityTagged = t.TypeOf<PoolFinancingAuthorityTaggedC>;
export type PoolFinancingAuthority = PoolFinancingAuthorityTagged & typeof poolFinancingAuthority;
export type PoolFinancingAuthorityC = t.Type<PoolFinancingAuthority, PoolFinancingAuthorityTagged>;
export const poolFinancingAuthorityC: PoolFinancingAuthorityC = pipe(poolFinancingAuthorityTaggedC, c => new t.Type<PoolFinancingAuthority, PoolFinancingAuthorityTagged>(
  `PoolFinancingAuthority`,
  (u: unknown): u is PoolFinancingAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PoolFinancingAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...poolFinancingAuthority }))),
  (x: PoolFinancingAuthority): PoolFinancingAuthorityTagged => ({ ...x, _tag: `PoolFinancingAuthority`}),
)) satisfies t.Type<PoolFinancingAuthority, unknown>;


export const promissoryNote = {
  _tag: `PromissoryNote`,
  id: 30,
  name: `Promissory Note`
} as const;

export type PromissoryNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PromissoryNote`>
}>;
export const promissoryNoteTaggedC: PromissoryNoteTaggedC = t.type({
  _tag: t.literal(`PromissoryNote`)
});
export type PromissoryNoteTagged = t.TypeOf<PromissoryNoteTaggedC>;
export type PromissoryNote = PromissoryNoteTagged & typeof promissoryNote;
export type PromissoryNoteC = t.Type<PromissoryNote, PromissoryNoteTagged>;
export const promissoryNoteC: PromissoryNoteC = pipe(promissoryNoteTaggedC, c => new t.Type<PromissoryNote, PromissoryNoteTagged>(
  `PromissoryNote`,
  (u: unknown): u is PromissoryNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PromissoryNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...promissoryNote }))),
  (x: PromissoryNote): PromissoryNoteTagged => ({ ...x, _tag: `PromissoryNote`}),
)) satisfies t.Type<PromissoryNote, unknown>;


export const publicHousingAuthority = {
  _tag: `PublicHousingAuthority`,
  id: 31,
  name: `Public Housing Authority`
} as const;

export type PublicHousingAuthorityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PublicHousingAuthority`>
}>;
export const publicHousingAuthorityTaggedC: PublicHousingAuthorityTaggedC = t.type({
  _tag: t.literal(`PublicHousingAuthority`)
});
export type PublicHousingAuthorityTagged = t.TypeOf<PublicHousingAuthorityTaggedC>;
export type PublicHousingAuthority = PublicHousingAuthorityTagged & typeof publicHousingAuthority;
export type PublicHousingAuthorityC = t.Type<PublicHousingAuthority, PublicHousingAuthorityTagged>;
export const publicHousingAuthorityC: PublicHousingAuthorityC = pipe(publicHousingAuthorityTaggedC, c => new t.Type<PublicHousingAuthority, PublicHousingAuthorityTagged>(
  `PublicHousingAuthority`,
  (u: unknown): u is PublicHousingAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublicHousingAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...publicHousingAuthority }))),
  (x: PublicHousingAuthority): PublicHousingAuthorityTagged => ({ ...x, _tag: `PublicHousingAuthority`}),
)) satisfies t.Type<PublicHousingAuthority, unknown>;


export const utility = {
  _tag: `Utility`,
  id: 50,
  name: `Utility`
} as const;

export type UtilityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Utility`>
}>;
export const utilityTaggedC: UtilityTaggedC = t.type({
  _tag: t.literal(`Utility`)
});
export type UtilityTagged = t.TypeOf<UtilityTaggedC>;
export type Utility = UtilityTagged & typeof utility;
export type UtilityC = t.Type<Utility, UtilityTagged>;
export const utilityC: UtilityC = pipe(utilityTaggedC, c => new t.Type<Utility, UtilityTagged>(
  `Utility`,
  (u: unknown): u is Utility => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Utility> => pipe(c.decode(u), E.map(x => ({ ...x, ...utility }))),
  (x: Utility): UtilityTagged => ({ ...x, _tag: `Utility`}),
)) satisfies t.Type<Utility, unknown>;


export const publicUtilityDistrict = {
  _tag: `PublicUtilityDistrict`,
  id: 32,
  name: `Public Utility District`
} as const;

export type PublicUtilityDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PublicUtilityDistrict`>
}>;
export const publicUtilityDistrictTaggedC: PublicUtilityDistrictTaggedC = t.type({
  _tag: t.literal(`PublicUtilityDistrict`)
});
export type PublicUtilityDistrictTagged = t.TypeOf<PublicUtilityDistrictTaggedC>;
export type PublicUtilityDistrict = PublicUtilityDistrictTagged & typeof publicUtilityDistrict;
export type PublicUtilityDistrictC = t.Type<PublicUtilityDistrict, PublicUtilityDistrictTagged>;
export const publicUtilityDistrictC: PublicUtilityDistrictC = pipe(publicUtilityDistrictTaggedC, c => new t.Type<PublicUtilityDistrict, PublicUtilityDistrictTagged>(
  `PublicUtilityDistrict`,
  (u: unknown): u is PublicUtilityDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublicUtilityDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...publicUtilityDistrict }))),
  (x: PublicUtilityDistrict): PublicUtilityDistrictTagged => ({ ...x, _tag: `PublicUtilityDistrict`}),
)) satisfies t.Type<PublicUtilityDistrict, unknown>;


export const racetrackSlashCasino = {
  _tag: `RacetrackSlashCasino`,
  id: 33,
  name: `Racetrack/Casino`
} as const;

export type RacetrackSlashCasinoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RacetrackSlashCasino`>
}>;
export const racetrackSlashCasinoTaggedC: RacetrackSlashCasinoTaggedC = t.type({
  _tag: t.literal(`RacetrackSlashCasino`)
});
export type RacetrackSlashCasinoTagged = t.TypeOf<RacetrackSlashCasinoTaggedC>;
export type RacetrackSlashCasino = RacetrackSlashCasinoTagged & typeof racetrackSlashCasino;
export type RacetrackSlashCasinoC = t.Type<RacetrackSlashCasino, RacetrackSlashCasinoTagged>;
export const racetrackSlashCasinoC: RacetrackSlashCasinoC = pipe(racetrackSlashCasinoTaggedC, c => new t.Type<RacetrackSlashCasino, RacetrackSlashCasinoTagged>(
  `RacetrackSlashCasino`,
  (u: unknown): u is RacetrackSlashCasino => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RacetrackSlashCasino> => pipe(c.decode(u), E.map(x => ({ ...x, ...racetrackSlashCasino }))),
  (x: RacetrackSlashCasino): RacetrackSlashCasinoTagged => ({ ...x, _tag: `RacetrackSlashCasino`}),
)) satisfies t.Type<RacetrackSlashCasino, unknown>;


export const recreationAuthority = {
  _tag: `RecreationAuthority`,
  id: 34,
  name: `Recreation Authority`
} as const;

export type RecreationAuthorityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RecreationAuthority`>
}>;
export const recreationAuthorityTaggedC: RecreationAuthorityTaggedC = t.type({
  _tag: t.literal(`RecreationAuthority`)
});
export type RecreationAuthorityTagged = t.TypeOf<RecreationAuthorityTaggedC>;
export type RecreationAuthority = RecreationAuthorityTagged & typeof recreationAuthority;
export type RecreationAuthorityC = t.Type<RecreationAuthority, RecreationAuthorityTagged>;
export const recreationAuthorityC: RecreationAuthorityC = pipe(recreationAuthorityTaggedC, c => new t.Type<RecreationAuthority, RecreationAuthorityTagged>(
  `RecreationAuthority`,
  (u: unknown): u is RecreationAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RecreationAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...recreationAuthority }))),
  (x: RecreationAuthority): RecreationAuthorityTagged => ({ ...x, _tag: `RecreationAuthority`}),
)) satisfies t.Type<RecreationAuthority, unknown>;


export const revenueAnticipationNote = {
  _tag: `RevenueAnticipationNote`,
  id: 35,
  name: `Revenue Anticipation Note`
} as const;

export type RevenueAnticipationNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RevenueAnticipationNote`>
}>;
export const revenueAnticipationNoteTaggedC: RevenueAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`RevenueAnticipationNote`)
});
export type RevenueAnticipationNoteTagged = t.TypeOf<RevenueAnticipationNoteTaggedC>;
export type RevenueAnticipationNote = RevenueAnticipationNoteTagged & typeof revenueAnticipationNote;
export type RevenueAnticipationNoteC = t.Type<RevenueAnticipationNote, RevenueAnticipationNoteTagged>;
export const revenueAnticipationNoteC: RevenueAnticipationNoteC = pipe(revenueAnticipationNoteTaggedC, c => new t.Type<RevenueAnticipationNote, RevenueAnticipationNoteTagged>(
  `RevenueAnticipationNote`,
  (u: unknown): u is RevenueAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RevenueAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...revenueAnticipationNote }))),
  (x: RevenueAnticipationNote): RevenueAnticipationNoteTagged => ({ ...x, _tag: `RevenueAnticipationNote`}),
)) satisfies t.Type<RevenueAnticipationNote, unknown>;


export const sanitationDistrict = {
  _tag: `SanitationDistrict`,
  id: 36,
  name: `Sanitation District`
} as const;

export type SanitationDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SanitationDistrict`>
}>;
export const sanitationDistrictTaggedC: SanitationDistrictTaggedC = t.type({
  _tag: t.literal(`SanitationDistrict`)
});
export type SanitationDistrictTagged = t.TypeOf<SanitationDistrictTaggedC>;
export type SanitationDistrict = SanitationDistrictTagged & typeof sanitationDistrict;
export type SanitationDistrictC = t.Type<SanitationDistrict, SanitationDistrictTagged>;
export const sanitationDistrictC: SanitationDistrictC = pipe(sanitationDistrictTaggedC, c => new t.Type<SanitationDistrict, SanitationDistrictTagged>(
  `SanitationDistrict`,
  (u: unknown): u is SanitationDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SanitationDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...sanitationDistrict }))),
  (x: SanitationDistrict): SanitationDistrictTagged => ({ ...x, _tag: `SanitationDistrict`}),
)) satisfies t.Type<SanitationDistrict, unknown>;


export const schoolDistrict = {
  _tag: `SchoolDistrict`,
  id: 37,
  name: `School District`
} as const;

export type SchoolDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SchoolDistrict`>
}>;
export const schoolDistrictTaggedC: SchoolDistrictTaggedC = t.type({
  _tag: t.literal(`SchoolDistrict`)
});
export type SchoolDistrictTagged = t.TypeOf<SchoolDistrictTaggedC>;
export type SchoolDistrict = SchoolDistrictTagged & typeof schoolDistrict;
export type SchoolDistrictC = t.Type<SchoolDistrict, SchoolDistrictTagged>;
export const schoolDistrictC: SchoolDistrictC = pipe(schoolDistrictTaggedC, c => new t.Type<SchoolDistrict, SchoolDistrictTagged>(
  `SchoolDistrict`,
  (u: unknown): u is SchoolDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SchoolDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...schoolDistrict }))),
  (x: SchoolDistrict): SchoolDistrictTagged => ({ ...x, _tag: `SchoolDistrict`}),
)) satisfies t.Type<SchoolDistrict, unknown>;


export const specialAssessment = {
  _tag: `SpecialAssessment`,
  id: 38,
  name: `Special Assessment`
} as const;

export type SpecialAssessmentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SpecialAssessment`>
}>;
export const specialAssessmentTaggedC: SpecialAssessmentTaggedC = t.type({
  _tag: t.literal(`SpecialAssessment`)
});
export type SpecialAssessmentTagged = t.TypeOf<SpecialAssessmentTaggedC>;
export type SpecialAssessment = SpecialAssessmentTagged & typeof specialAssessment;
export type SpecialAssessmentC = t.Type<SpecialAssessment, SpecialAssessmentTagged>;
export const specialAssessmentC: SpecialAssessmentC = pipe(specialAssessmentTaggedC, c => new t.Type<SpecialAssessment, SpecialAssessmentTagged>(
  `SpecialAssessment`,
  (u: unknown): u is SpecialAssessment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SpecialAssessment> => pipe(c.decode(u), E.map(x => ({ ...x, ...specialAssessment }))),
  (x: SpecialAssessment): SpecialAssessmentTagged => ({ ...x, _tag: `SpecialAssessment`}),
)) satisfies t.Type<SpecialAssessment, unknown>;


export const specialObligation = {
  _tag: `SpecialObligation`,
  id: 39,
  name: `Special Obligation`
} as const;

export type SpecialObligationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SpecialObligation`>
}>;
export const specialObligationTaggedC: SpecialObligationTaggedC = t.type({
  _tag: t.literal(`SpecialObligation`)
});
export type SpecialObligationTagged = t.TypeOf<SpecialObligationTaggedC>;
export type SpecialObligation = SpecialObligationTagged & typeof specialObligation;
export type SpecialObligationC = t.Type<SpecialObligation, SpecialObligationTagged>;
export const specialObligationC: SpecialObligationC = pipe(specialObligationTaggedC, c => new t.Type<SpecialObligation, SpecialObligationTagged>(
  `SpecialObligation`,
  (u: unknown): u is SpecialObligation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SpecialObligation> => pipe(c.decode(u), E.map(x => ({ ...x, ...specialObligation }))),
  (x: SpecialObligation): SpecialObligationTagged => ({ ...x, _tag: `SpecialObligation`}),
)) satisfies t.Type<SpecialObligation, unknown>;


export const stateAndFederalAnticipationNote = {
  _tag: `StateAndFederalAnticipationNote`,
  id: 40,
  name: `State and Federal Anticipation Note`
} as const;

export type StateAndFederalAnticipationNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StateAndFederalAnticipationNote`>
}>;
export const stateAndFederalAnticipationNoteTaggedC: StateAndFederalAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`StateAndFederalAnticipationNote`)
});
export type StateAndFederalAnticipationNoteTagged = t.TypeOf<StateAndFederalAnticipationNoteTaggedC>;
export type StateAndFederalAnticipationNote = StateAndFederalAnticipationNoteTagged & typeof stateAndFederalAnticipationNote;
export type StateAndFederalAnticipationNoteC = t.Type<StateAndFederalAnticipationNote, StateAndFederalAnticipationNoteTagged>;
export const stateAndFederalAnticipationNoteC: StateAndFederalAnticipationNoteC = pipe(stateAndFederalAnticipationNoteTaggedC, c => new t.Type<StateAndFederalAnticipationNote, StateAndFederalAnticipationNoteTagged>(
  `StateAndFederalAnticipationNote`,
  (u: unknown): u is StateAndFederalAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateAndFederalAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateAndFederalAnticipationNote }))),
  (x: StateAndFederalAnticipationNote): StateAndFederalAnticipationNoteTagged => ({ ...x, _tag: `StateAndFederalAnticipationNote`}),
)) satisfies t.Type<StateAndFederalAnticipationNote, unknown>;


export const stateAndGrantAnticipationNote = {
  _tag: `StateAndGrantAnticipationNote`,
  id: 41,
  name: `State and Grant Anticipation Note`
} as const;

export type StateAndGrantAnticipationNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StateAndGrantAnticipationNote`>
}>;
export const stateAndGrantAnticipationNoteTaggedC: StateAndGrantAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`StateAndGrantAnticipationNote`)
});
export type StateAndGrantAnticipationNoteTagged = t.TypeOf<StateAndGrantAnticipationNoteTaggedC>;
export type StateAndGrantAnticipationNote = StateAndGrantAnticipationNoteTagged & typeof stateAndGrantAnticipationNote;
export type StateAndGrantAnticipationNoteC = t.Type<StateAndGrantAnticipationNote, StateAndGrantAnticipationNoteTagged>;
export const stateAndGrantAnticipationNoteC: StateAndGrantAnticipationNoteC = pipe(stateAndGrantAnticipationNoteTaggedC, c => new t.Type<StateAndGrantAnticipationNote, StateAndGrantAnticipationNoteTagged>(
  `StateAndGrantAnticipationNote`,
  (u: unknown): u is StateAndGrantAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateAndGrantAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateAndGrantAnticipationNote }))),
  (x: StateAndGrantAnticipationNote): StateAndGrantAnticipationNoteTagged => ({ ...x, _tag: `StateAndGrantAnticipationNote`}),
)) satisfies t.Type<StateAndGrantAnticipationNote, unknown>;


export const stateAnticipationNotes = {
  _tag: `StateAnticipationNotes`,
  id: 42,
  name: `State Anticipation Notes`
} as const;

export type StateAnticipationNotesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StateAnticipationNotes`>
}>;
export const stateAnticipationNotesTaggedC: StateAnticipationNotesTaggedC = t.type({
  _tag: t.literal(`StateAnticipationNotes`)
});
export type StateAnticipationNotesTagged = t.TypeOf<StateAnticipationNotesTaggedC>;
export type StateAnticipationNotes = StateAnticipationNotesTagged & typeof stateAnticipationNotes;
export type StateAnticipationNotesC = t.Type<StateAnticipationNotes, StateAnticipationNotesTagged>;
export const stateAnticipationNotesC: StateAnticipationNotesC = pipe(stateAnticipationNotesTaggedC, c => new t.Type<StateAnticipationNotes, StateAnticipationNotesTagged>(
  `StateAnticipationNotes`,
  (u: unknown): u is StateAnticipationNotes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateAnticipationNotes> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateAnticipationNotes }))),
  (x: StateAnticipationNotes): StateAnticipationNotesTagged => ({ ...x, _tag: `StateAnticipationNotes`}),
)) satisfies t.Type<StateAnticipationNotes, unknown>;


export const stateStore = {
  _tag: `StateStore`,
  id: 43,
  name: `State Store`
} as const;

export type StateStoreTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StateStore`>
}>;
export const stateStoreTaggedC: StateStoreTaggedC = t.type({
  _tag: t.literal(`StateStore`)
});
export type StateStoreTagged = t.TypeOf<StateStoreTaggedC>;
export type StateStore = StateStoreTagged & typeof stateStore;
export type StateStoreC = t.Type<StateStore, StateStoreTagged>;
export const stateStoreC: StateStoreC = pipe(stateStoreTaggedC, c => new t.Type<StateStore, StateStoreTagged>(
  `StateStore`,
  (u: unknown): u is StateStore => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateStore> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateStore }))),
  (x: StateStore): StateStoreTagged => ({ ...x, _tag: `StateStore`}),
)) satisfies t.Type<StateStore, unknown>;


export const taxAndRevenueAnticipation = {
  _tag: `TaxAndRevenueAnticipation`,
  id: 44,
  name: `Tax and Revenue Anticipation`
} as const;

export type TaxAndRevenueAnticipationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TaxAndRevenueAnticipation`>
}>;
export const taxAndRevenueAnticipationTaggedC: TaxAndRevenueAnticipationTaggedC = t.type({
  _tag: t.literal(`TaxAndRevenueAnticipation`)
});
export type TaxAndRevenueAnticipationTagged = t.TypeOf<TaxAndRevenueAnticipationTaggedC>;
export type TaxAndRevenueAnticipation = TaxAndRevenueAnticipationTagged & typeof taxAndRevenueAnticipation;
export type TaxAndRevenueAnticipationC = t.Type<TaxAndRevenueAnticipation, TaxAndRevenueAnticipationTagged>;
export const taxAndRevenueAnticipationC: TaxAndRevenueAnticipationC = pipe(taxAndRevenueAnticipationTaggedC, c => new t.Type<TaxAndRevenueAnticipation, TaxAndRevenueAnticipationTagged>(
  `TaxAndRevenueAnticipation`,
  (u: unknown): u is TaxAndRevenueAnticipation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxAndRevenueAnticipation> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxAndRevenueAnticipation }))),
  (x: TaxAndRevenueAnticipation): TaxAndRevenueAnticipationTagged => ({ ...x, _tag: `TaxAndRevenueAnticipation`}),
)) satisfies t.Type<TaxAndRevenueAnticipation, unknown>;


export const taxAnticipationNote = {
  _tag: `TaxAnticipationNote`,
  id: 45,
  name: `Tax Anticipation Note`
} as const;

export type TaxAnticipationNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TaxAnticipationNote`>
}>;
export const taxAnticipationNoteTaggedC: TaxAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`TaxAnticipationNote`)
});
export type TaxAnticipationNoteTagged = t.TypeOf<TaxAnticipationNoteTaggedC>;
export type TaxAnticipationNote = TaxAnticipationNoteTagged & typeof taxAnticipationNote;
export type TaxAnticipationNoteC = t.Type<TaxAnticipationNote, TaxAnticipationNoteTagged>;
export const taxAnticipationNoteC: TaxAnticipationNoteC = pipe(taxAnticipationNoteTaggedC, c => new t.Type<TaxAnticipationNote, TaxAnticipationNoteTagged>(
  `TaxAnticipationNote`,
  (u: unknown): u is TaxAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxAnticipationNote }))),
  (x: TaxAnticipationNote): TaxAnticipationNoteTagged => ({ ...x, _tag: `TaxAnticipationNote`}),
)) satisfies t.Type<TaxAnticipationNote, unknown>;


export const taxRevenue = {
  _tag: `TaxRevenue`,
  id: 46,
  name: `Tax Revenue`
} as const;

export type TaxRevenueTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TaxRevenue`>
}>;
export const taxRevenueTaggedC: TaxRevenueTaggedC = t.type({
  _tag: t.literal(`TaxRevenue`)
});
export type TaxRevenueTagged = t.TypeOf<TaxRevenueTaggedC>;
export type TaxRevenue = TaxRevenueTagged & typeof taxRevenue;
export type TaxRevenueC = t.Type<TaxRevenue, TaxRevenueTagged>;
export const taxRevenueC: TaxRevenueC = pipe(taxRevenueTaggedC, c => new t.Type<TaxRevenue, TaxRevenueTagged>(
  `TaxRevenue`,
  (u: unknown): u is TaxRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxRevenue }))),
  (x: TaxRevenue): TaxRevenueTagged => ({ ...x, _tag: `TaxRevenue`}),
)) satisfies t.Type<TaxRevenue, unknown>;


export const temporaryNotes = {
  _tag: `TemporaryNotes`,
  id: 47,
  name: `Temporary Notes`
} as const;

export type TemporaryNotesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TemporaryNotes`>
}>;
export const temporaryNotesTaggedC: TemporaryNotesTaggedC = t.type({
  _tag: t.literal(`TemporaryNotes`)
});
export type TemporaryNotesTagged = t.TypeOf<TemporaryNotesTaggedC>;
export type TemporaryNotes = TemporaryNotesTagged & typeof temporaryNotes;
export type TemporaryNotesC = t.Type<TemporaryNotes, TemporaryNotesTagged>;
export const temporaryNotesC: TemporaryNotesC = pipe(temporaryNotesTaggedC, c => new t.Type<TemporaryNotes, TemporaryNotesTagged>(
  `TemporaryNotes`,
  (u: unknown): u is TemporaryNotes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TemporaryNotes> => pipe(c.decode(u), E.map(x => ({ ...x, ...temporaryNotes }))),
  (x: TemporaryNotes): TemporaryNotesTagged => ({ ...x, _tag: `TemporaryNotes`}),
)) satisfies t.Type<TemporaryNotes, unknown>;


export const transportation = {
  _tag: `Transportation`,
  id: 48,
  name: `Transportation`
} as const;

export type TransportationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Transportation`>
}>;
export const transportationTaggedC: TransportationTaggedC = t.type({
  _tag: t.literal(`Transportation`)
});
export type TransportationTagged = t.TypeOf<TransportationTaggedC>;
export type Transportation = TransportationTagged & typeof transportation;
export type TransportationC = t.Type<Transportation, TransportationTagged>;
export const transportationC: TransportationC = pipe(transportationTaggedC, c => new t.Type<Transportation, TransportationTagged>(
  `Transportation`,
  (u: unknown): u is Transportation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Transportation> => pipe(c.decode(u), E.map(x => ({ ...x, ...transportation }))),
  (x: Transportation): TransportationTagged => ({ ...x, _tag: `Transportation`}),
)) satisfies t.Type<Transportation, unknown>;


export const tribalBonds = {
  _tag: `TribalBonds`,
  id: 49,
  name: `Tribal Bonds`
} as const;

export type TribalBondsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TribalBonds`>
}>;
export const tribalBondsTaggedC: TribalBondsTaggedC = t.type({
  _tag: t.literal(`TribalBonds`)
});
export type TribalBondsTagged = t.TypeOf<TribalBondsTaggedC>;
export type TribalBonds = TribalBondsTagged & typeof tribalBonds;
export type TribalBondsC = t.Type<TribalBonds, TribalBondsTagged>;
export const tribalBondsC: TribalBondsC = pipe(tribalBondsTaggedC, c => new t.Type<TribalBonds, TribalBondsTagged>(
  `TribalBonds`,
  (u: unknown): u is TribalBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TribalBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...tribalBonds }))),
  (x: TribalBonds): TribalBondsTagged => ({ ...x, _tag: `TribalBonds`}),
)) satisfies t.Type<TribalBonds, unknown>;


export const variousPurpose = {
  _tag: `VariousPurpose`,
  id: 51,
  name: `Various Purpose`
} as const;

export type VariousPurposeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`VariousPurpose`>
}>;
export const variousPurposeTaggedC: VariousPurposeTaggedC = t.type({
  _tag: t.literal(`VariousPurpose`)
});
export type VariousPurposeTagged = t.TypeOf<VariousPurposeTaggedC>;
export type VariousPurpose = VariousPurposeTagged & typeof variousPurpose;
export type VariousPurposeC = t.Type<VariousPurpose, VariousPurposeTagged>;
export const variousPurposeC: VariousPurposeC = pipe(variousPurposeTaggedC, c => new t.Type<VariousPurpose, VariousPurposeTagged>(
  `VariousPurpose`,
  (u: unknown): u is VariousPurpose => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, VariousPurpose> => pipe(c.decode(u), E.map(x => ({ ...x, ...variousPurpose }))),
  (x: VariousPurpose): VariousPurposeTagged => ({ ...x, _tag: `VariousPurpose`}),
)) satisfies t.Type<VariousPurpose, unknown>;


export const veterans = {
  _tag: `Veterans`,
  id: 52,
  name: `Veterans`
} as const;

export type VeteransTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Veterans`>
}>;
export const veteransTaggedC: VeteransTaggedC = t.type({
  _tag: t.literal(`Veterans`)
});
export type VeteransTagged = t.TypeOf<VeteransTaggedC>;
export type Veterans = VeteransTagged & typeof veterans;
export type VeteransC = t.Type<Veterans, VeteransTagged>;
export const veteransC: VeteransC = pipe(veteransTaggedC, c => new t.Type<Veterans, VeteransTagged>(
  `Veterans`,
  (u: unknown): u is Veterans => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Veterans> => pipe(c.decode(u), E.map(x => ({ ...x, ...veterans }))),
  (x: Veterans): VeteransTagged => ({ ...x, _tag: `Veterans`}),
)) satisfies t.Type<Veterans, unknown>;


export const warrants = {
  _tag: `Warrants`,
  id: 53,
  name: `Warrants`
} as const;

export type WarrantsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Warrants`>
}>;
export const warrantsTaggedC: WarrantsTaggedC = t.type({
  _tag: t.literal(`Warrants`)
});
export type WarrantsTagged = t.TypeOf<WarrantsTaggedC>;
export type Warrants = WarrantsTagged & typeof warrants;
export type WarrantsC = t.Type<Warrants, WarrantsTagged>;
export const warrantsC: WarrantsC = pipe(warrantsTaggedC, c => new t.Type<Warrants, WarrantsTagged>(
  `Warrants`,
  (u: unknown): u is Warrants => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Warrants> => pipe(c.decode(u), E.map(x => ({ ...x, ...warrants }))),
  (x: Warrants): WarrantsTagged => ({ ...x, _tag: `Warrants`}),
)) satisfies t.Type<Warrants, unknown>;


export const allMuniPurposeClassTypeC = [authorityC, bondAnticipationNoteC, buildingC, capitalLoanNotesC, certificatesOfParticipationC, corporatePurposeC, drainageDistrictC, economicDevelopmentRevenueC, educationC, environmentC, equipmentC, federalAidNoteC, fireDistrictC, floodWaterSewerDisposalC, generalPurposeC, grantAnticipationNoteC, healthC, housingC, improvementC, industrialDevelopmentRevenueC, libraryDistrictC, metropolitanDistrictC, miscellaneousC, municipalBondBankC, municipalUtilitiesDistrictC, parkDistrictC, parkingAuthorityC, pollutionControlRevenueC, poolFinancingAuthorityC, promissoryNoteC, publicHousingAuthorityC, publicUtilityDistrictC, racetrackSlashCasinoC, recreationAuthorityC, revenueAnticipationNoteC, sanitationDistrictC, schoolDistrictC, specialAssessmentC, specialObligationC, stateAndFederalAnticipationNoteC, stateAndGrantAnticipationNoteC, stateAnticipationNotesC, stateStoreC, taxAndRevenueAnticipationC, taxAnticipationNoteC, taxRevenueC, temporaryNotesC, transportationC, tribalBondsC, utilityC, variousPurposeC, veteransC, warrantsC] as const;
export const allMuniPurposeClassTypeNames = [`Authority`, `BondAnticipationNote`, `Building`, `CapitalLoanNotes`, `CertificatesOfParticipation`, `CorporatePurpose`, `DrainageDistrict`, `EconomicDevelopmentRevenue`, `Education`, `Environment`, `Equipment`, `FederalAidNote`, `FireDistrict`, `FloodWaterSewerDisposal`, `GeneralPurpose`, `GrantAnticipationNote`, `Health`, `Housing`, `Improvement`, `IndustrialDevelopmentRevenue`, `LibraryDistrict`, `MetropolitanDistrict`, `Miscellaneous`, `MunicipalBondBank`, `MunicipalUtilitiesDistrict`, `ParkDistrict`, `ParkingAuthority`, `PollutionControlRevenue`, `PoolFinancingAuthority`, `PromissoryNote`, `PublicHousingAuthority`, `PublicUtilityDistrict`, `RacetrackSlashCasino`, `RecreationAuthority`, `RevenueAnticipationNote`, `SanitationDistrict`, `SchoolDistrict`, `SpecialAssessment`, `SpecialObligation`, `StateAndFederalAnticipationNote`, `StateAndGrantAnticipationNote`, `StateAnticipationNotes`, `StateStore`, `TaxAndRevenueAnticipation`, `TaxAnticipationNote`, `TaxRevenue`, `TemporaryNotes`, `Transportation`, `TribalBonds`, `Utility`, `VariousPurpose`, `Veterans`, `Warrants`] as const;
export type MuniPurposeClassTypeName = (typeof allMuniPurposeClassTypeNames)[number];

export type MuniPurposeClassTypeCU = t.UnionC<[AuthorityC, BondAnticipationNoteC, BuildingC, CapitalLoanNotesC, CertificatesOfParticipationC, CorporatePurposeC, DrainageDistrictC, EconomicDevelopmentRevenueC, EducationC, EnvironmentC, EquipmentC, FederalAidNoteC, FireDistrictC, FloodWaterSewerDisposalC, GeneralPurposeC, GrantAnticipationNoteC, HealthC, HousingC, ImprovementC, IndustrialDevelopmentRevenueC, LibraryDistrictC, MetropolitanDistrictC, MiscellaneousC, MunicipalBondBankC, MunicipalUtilitiesDistrictC, ParkDistrictC, ParkingAuthorityC, PollutionControlRevenueC, PoolFinancingAuthorityC, PromissoryNoteC, PublicHousingAuthorityC, PublicUtilityDistrictC, RacetrackSlashCasinoC, RecreationAuthorityC, RevenueAnticipationNoteC, SanitationDistrictC, SchoolDistrictC, SpecialAssessmentC, SpecialObligationC, StateAndFederalAnticipationNoteC, StateAndGrantAnticipationNoteC, StateAnticipationNotesC, StateStoreC, TaxAndRevenueAnticipationC, TaxAnticipationNoteC, TaxRevenueC, TemporaryNotesC, TransportationC, TribalBondsC, UtilityC, VariousPurposeC, VeteransC, WarrantsC]>;
export type MuniPurposeClassTypeU = Authority | BondAnticipationNote | Building | CapitalLoanNotes | CertificatesOfParticipation | CorporatePurpose | DrainageDistrict | EconomicDevelopmentRevenue | Education | Environment | Equipment | FederalAidNote | FireDistrict | FloodWaterSewerDisposal | GeneralPurpose | GrantAnticipationNote | Health | Housing | Improvement | IndustrialDevelopmentRevenue | LibraryDistrict | MetropolitanDistrict | Miscellaneous | MunicipalBondBank | MunicipalUtilitiesDistrict | ParkDistrict | ParkingAuthority | PollutionControlRevenue | PoolFinancingAuthority | PromissoryNote | PublicHousingAuthority | PublicUtilityDistrict | RacetrackSlashCasino | RecreationAuthority | RevenueAnticipationNote | SanitationDistrict | SchoolDistrict | SpecialAssessment | SpecialObligation | StateAndFederalAnticipationNote | StateAndGrantAnticipationNote | StateAnticipationNotes | StateStore | TaxAndRevenueAnticipation | TaxAnticipationNote | TaxRevenue | TemporaryNotes | Transportation | TribalBonds | Utility | VariousPurpose | Veterans | Warrants;
export const MuniPurposeClassTypeCU: MuniPurposeClassTypeCU = t.union([authorityC, bondAnticipationNoteC, buildingC, capitalLoanNotesC, certificatesOfParticipationC, corporatePurposeC, drainageDistrictC, economicDevelopmentRevenueC, educationC, environmentC, equipmentC, federalAidNoteC, fireDistrictC, floodWaterSewerDisposalC, generalPurposeC, grantAnticipationNoteC, healthC, housingC, improvementC, industrialDevelopmentRevenueC, libraryDistrictC, metropolitanDistrictC, miscellaneousC, municipalBondBankC, municipalUtilitiesDistrictC, parkDistrictC, parkingAuthorityC, pollutionControlRevenueC, poolFinancingAuthorityC, promissoryNoteC, publicHousingAuthorityC, publicUtilityDistrictC, racetrackSlashCasinoC, recreationAuthorityC, revenueAnticipationNoteC, sanitationDistrictC, schoolDistrictC, specialAssessmentC, specialObligationC, stateAndFederalAnticipationNoteC, stateAndGrantAnticipationNoteC, stateAnticipationNotesC, stateStoreC, taxAndRevenueAnticipationC, taxAnticipationNoteC, taxRevenueC, temporaryNotesC, transportationC, tribalBondsC, utilityC, variousPurposeC, veteransC, warrantsC]) satisfies t.Type<MuniPurposeClassTypeU, unknown>;

export const muniPurposeClassTypeOrd: Ord.Ord<MuniPurposeClassTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniPurposeClassType = [authority, bondAnticipationNote, building, capitalLoanNotes, certificatesOfParticipation, corporatePurpose, drainageDistrict, economicDevelopmentRevenue, education, environment, equipment, federalAidNote, fireDistrict, floodWaterSewerDisposal, generalPurpose, grantAnticipationNote, health, housing, improvement, industrialDevelopmentRevenue, libraryDistrict, metropolitanDistrict, miscellaneous, municipalBondBank, municipalUtilitiesDistrict, parkDistrict, parkingAuthority, pollutionControlRevenue, poolFinancingAuthority, promissoryNote, publicHousingAuthority, publicUtilityDistrict, racetrackSlashCasino, recreationAuthority, revenueAnticipationNote, sanitationDistrict, schoolDistrict, specialAssessment, specialObligation, stateAndFederalAnticipationNote, stateAndGrantAnticipationNote, stateAnticipationNotes, stateStore, taxAndRevenueAnticipation, taxAnticipationNote, taxRevenue, temporaryNotes, transportation, tribalBonds, utility, variousPurpose, veterans, warrants] as const;
export type MuniPurposeClassTypeMap<A> = { [K in MuniPurposeClassTypeName]: A };


