import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { RelativeUrlC, RelativeUrl, relativeUrlC } from "../../codecs/relativeUrl";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";

export type RedirectC = t.TypeC<{
  _tag: t.LiteralC<`Redirect`>,
  redirect: t.StringC
}>;
export type Redirect = {
  _tag: `Redirect`,
  redirect: string
};
export const redirectC: RedirectC = t.type({
  _tag: t.literal(`Redirect`),
  redirect: t.string
}) satisfies t.Type<Redirect, unknown>;


export type MfaInitiatedC = t.TypeC<{
  _tag: t.LiteralC<`MfaInitiated`>,
  phoneLineNumber: t.StringC,
  redirect: OptionFromNullableC<RelativeUrlC>,
  userId: t.NumberC,
  verificationId: t.StringC
}>;
export type MfaInitiated = {
  _tag: `MfaInitiated`,
  phoneLineNumber: string,
  redirect: O.Option<RelativeUrl>,
  userId: number,
  verificationId: string
};
export const mfaInitiatedC: MfaInitiatedC = t.type({
  _tag: t.literal(`MfaInitiated`),
  phoneLineNumber: t.string,
  redirect: optionFromNullable(relativeUrlC),
  userId: t.number,
  verificationId: t.string
}) satisfies t.Type<MfaInitiated, unknown>;


export const allLoginStatusC = [redirectC, mfaInitiatedC] as const;
export const allLoginStatusNames = [`Redirect`, `MfaInitiated`] as const;
export type LoginStatusName = (typeof allLoginStatusNames)[number];

export type LoginStatusCU = t.UnionC<[RedirectC, MfaInitiatedC]>;
export type LoginStatusU = Redirect | MfaInitiated;
export const LoginStatusCU: LoginStatusCU = t.union([redirectC, mfaInitiatedC]) satisfies t.Type<LoginStatusU, unknown>;

export type LoginStatusMap<A> = { [K in LoginStatusName]: A };


