import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { DisclaimerTypeCU as imported7_DisclaimerTypeCU, DisclaimerTypeU as imported3_DisclaimerTypeU, DisclaimerTypeCU as imported6_DisclaimerTypeCU } from "../domaintables/disclaimerTypes";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type DisclaimerC = t.TypeC<{
  disclaimerType: imported7_DisclaimerTypeCU,
  data: MarkdownC,
  sessionLength: OptionFromNullableC<t.NumberC>
}>;
export type Disclaimer = {
  disclaimerType: imported3_DisclaimerTypeU,
  data: Markdown,
  sessionLength: O.Option<number>
};
export const disclaimerC: DisclaimerC = t.type({
  disclaimerType: imported6_DisclaimerTypeCU,
  data: markdownC,
  sessionLength: optionFromNullable(t.number)
}) satisfies t.Type<Disclaimer, unknown>;


export type DisclaimerPostC = t.TypeC<{
  disclaimerTypeId: t.NumberC,
  data: MarkdownC,
  sessionLength: OptionFromNullableC<t.NumberC>
}>;
export type DisclaimerPost = {
  disclaimerTypeId: number,
  data: Markdown,
  sessionLength: O.Option<number>
};
export const disclaimerPostC: DisclaimerPostC = t.type({
  disclaimerTypeId: t.number,
  data: markdownC,
  sessionLength: optionFromNullable(t.number)
}) satisfies t.Type<DisclaimerPost, unknown>;


export type AcceptDisclaimerPostC = t.TypeC<{
  disclaimerCookieNames: t.ReadonlyArrayC<t.StringC>
}>;
export type AcceptDisclaimerPost = {
  disclaimerCookieNames: ReadonlyArray<string>
};
export const acceptDisclaimerPostC: AcceptDisclaimerPostC = t.type({
  disclaimerCookieNames: t.readonlyArray(t.string)
}) satisfies t.Type<AcceptDisclaimerPost, unknown>;


