import * as t from "io-ts";
import { Ord as numberOrd } from "fp-ts/lib/number";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";

export type ClientFeatureFlagC = t.TypeC<{
  featureFlagId: t.NumberC,
  enabled: t.BooleanC
}>;
export type ClientFeatureFlag = {
  featureFlagId: number,
  enabled: boolean
};
export const clientFeatureFlagC: ClientFeatureFlagC = t.type({
  featureFlagId: t.number,
  enabled: t.boolean
}) satisfies t.Type<ClientFeatureFlag, unknown>;


export type ClientFeatureFlagsC = t.TypeC<{
  ffs: ReadonlyMapFromEntriesC<t.NumberC, t.BooleanC>
}>;
export type ClientFeatureFlags = {
  ffs: ReadonlyMap<number, boolean>
};
export const clientFeatureFlagsC: ClientFeatureFlagsC = t.type({
  ffs: readonlyMapFromEntries(t.number, numberOrd, t.boolean)
}) satisfies t.Type<ClientFeatureFlags, unknown>;


