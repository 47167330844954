import * as t from "io-ts";
import { HeaderPhotoC as imported10_HeaderPhotoC, HeaderPhoto as imported10_HeaderPhoto, headerPhotoC as imported7_headerPhotoC } from "./headerPhoto";
import * as O from "fp-ts/lib/Option";
import { NonEmailSendActivityCU as imported3_NonEmailSendActivityCU, NonEmailSendActivityU as imported1_NonEmailSendActivityU, NonEmailSendActivityCU as imported2_NonEmailSendActivityCU } from "./dealPortalActivity";
import { activityFC as imported2_activityFC, activityFC as imported3_activityFC, ActivityF as imported2_ActivityF, ActivityFC as imported2_ActivityFC, ActivityFC as imported3_ActivityFC, ActivityF as imported3_ActivityF } from "./dealPortalAnalytics";
import { BondOfferingC as imported23_BondOfferingC, BondOffering as imported23_BondOffering, bondOfferingC as imported23_bondOfferingC } from "./bondOfferingBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { PhotoEditableCU as imported17_PhotoEditableCU, PhotoEditableU as imported8_PhotoEditableU, PhotoEditableCU as imported16_PhotoEditableCU } from "../domaintables/pages";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { emailContactIdC as imported4_emailContactIdC, emailContactIdC as imported3_emailContactIdC, MinimalActorC as imported4_MinimalActorC, EmailContactIdC as imported5_EmailContactIdC, MinimalActor as imported3_MinimalActor, EmailContactId as imported5_EmailContactId, MinimalActorC as imported3_MinimalActorC, minimalActorC as imported4_minimalActorC, minimalActorC as imported5_minimalActorC, MinimalActor as imported5_MinimalActor, MinimalActor as imported4_MinimalActor, EmailContactId as imported6_EmailContactId, MinimalActorC as imported5_MinimalActorC, minimalActorC as imported3_minimalActorC, EmailContactIdC as imported6_EmailContactIdC } from "./actor";
import { withModInfoC as imported159_withModInfoC, WithModInfo as imported159_WithModInfo, WithStatusU as imported238_WithStatusU, WithStatusCU as imported479_WithStatusCU, WithStatusCU as imported477_WithStatusCU, WithModInfoC as imported159_WithModInfoC, WithStatusU as imported239_WithStatusU, WithStatusCU as imported478_WithStatusCU, WithStatusCU as imported476_WithStatusCU } from "./threadThrough";
import { WithIssuerC as imported7_WithIssuerC, WithIssuer as imported7_WithIssuer, withIssuerC as imported7_withIssuerC } from "./issuer";
import { ipreoHoldingsC as imported8_ipreoHoldingsC, IpreoHoldingsC as imported9_IpreoHoldingsC, IpreoHoldings as imported8_IpreoHoldings, IpreoHoldings as imported9_IpreoHoldings, IpreoHoldingsC as imported8_IpreoHoldingsC, ipreoHoldingsC as imported9_ipreoHoldingsC } from "./ipreoHoldings";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";

export type ActiveDealViewDataC = t.TypeC<{
  last14DaysAnalytics: imported2_ActivityFC<imported5_EmailContactIdC, imported3_MinimalActorC>,
  lifetimeAnalytics: imported3_ActivityFC<imported6_EmailContactIdC, imported4_MinimalActorC>,
  recentActivity: t.ReadonlyArrayC<t.TupleC<[imported5_MinimalActorC, imported3_NonEmailSendActivityCU]>>,
  topHoldersByCusip6: t.ReadonlyArrayC<imported8_IpreoHoldingsC>,
  topHoldersBySector: t.ReadonlyArrayC<imported9_IpreoHoldingsC>,
  ipreoToBLCompanies: t.RecordC<t.StringC, ReadonlyNonEmptyArrayType<t.StringC>>
}>;
export type ActiveDealViewData = {
  last14DaysAnalytics: imported2_ActivityF<imported5_EmailContactId, imported3_MinimalActor>,
  lifetimeAnalytics: imported3_ActivityF<imported6_EmailContactId, imported4_MinimalActor>,
  recentActivity: ReadonlyArray<[imported5_MinimalActor, imported1_NonEmailSendActivityU]>,
  topHoldersByCusip6: ReadonlyArray<imported8_IpreoHoldings>,
  topHoldersBySector: ReadonlyArray<imported9_IpreoHoldings>,
  ipreoToBLCompanies: Record<string, RNEA.ReadonlyNonEmptyArray<string>>
};
export const activeDealViewDataC: ActiveDealViewDataC = t.type({
  last14DaysAnalytics: imported2_activityFC(imported3_emailContactIdC, imported3_minimalActorC),
  lifetimeAnalytics: imported3_activityFC(imported4_emailContactIdC, imported4_minimalActorC),
  recentActivity: t.readonlyArray(t.tuple([imported5_minimalActorC, imported2_NonEmailSendActivityCU])),
  topHoldersByCusip6: t.readonlyArray(imported8_ipreoHoldingsC),
  topHoldersBySector: t.readonlyArray(imported9_ipreoHoldingsC),
  ipreoToBLCompanies: t.record(t.string, readonlyNonEmptyArrayC(t.string))
}) satisfies t.Type<ActiveDealViewData, unknown>;


export type DealViewDataC = t.TypeC<{
  offering: imported7_WithIssuerC<imported478_WithStatusCU<imported159_WithModInfoC<imported23_BondOfferingC>>>,
  issuerHeaderPhotos: t.ReadonlyArrayC<t.TupleC<[imported17_PhotoEditableCU, imported479_WithStatusCU<imported10_HeaderPhotoC>]>>,
  activeData: OptionFromNullableC<ActiveDealViewDataC>
}>;
export type DealViewData = {
  offering: imported7_WithIssuer<imported238_WithStatusU<imported159_WithModInfo<imported23_BondOffering>>>,
  issuerHeaderPhotos: ReadonlyArray<[imported8_PhotoEditableU, imported239_WithStatusU<imported10_HeaderPhoto>]>,
  activeData: O.Option<ActiveDealViewData>
};
export const dealViewDataC: DealViewDataC = t.type({
  offering: imported7_withIssuerC(imported476_WithStatusCU(imported159_withModInfoC(imported23_bondOfferingC))),
  issuerHeaderPhotos: t.readonlyArray(t.tuple([imported16_PhotoEditableCU, imported477_WithStatusCU(imported7_headerPhotoC)])),
  activeData: optionFromNullable(activeDealViewDataC)
}) satisfies t.Type<DealViewData, unknown>;


