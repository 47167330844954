import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const moodys = {
  _tag: `Moodys`,
  agencyName: `Moody's Investors Service`,
  id: 1,
  shortName: `Moody's`
} as const;

export type MoodysTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Moodys`>
}>;
export const moodysTaggedC: MoodysTaggedC = t.type({
  _tag: t.literal(`Moodys`)
});
export type MoodysTagged = t.TypeOf<MoodysTaggedC>;
export type Moodys = MoodysTagged & typeof moodys;
export type MoodysC = t.Type<Moodys, MoodysTagged>;
export const moodysC: MoodysC = pipe(moodysTaggedC, c => new t.Type<Moodys, MoodysTagged>(
  `Moodys`,
  (u: unknown): u is Moodys => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Moodys> => pipe(c.decode(u), E.map(x => ({ ...x, ...moodys }))),
  (x: Moodys): MoodysTagged => ({ ...x, _tag: `Moodys`}),
)) satisfies t.Type<Moodys, unknown>;


export const sandP = {
  _tag: `SandP`,
  agencyName: `S&P Global Ratings`,
  id: 2,
  shortName: `S&P`
} as const;

export type SandPTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SandP`>
}>;
export const sandPTaggedC: SandPTaggedC = t.type({
  _tag: t.literal(`SandP`)
});
export type SandPTagged = t.TypeOf<SandPTaggedC>;
export type SandP = SandPTagged & typeof sandP;
export type SandPC = t.Type<SandP, SandPTagged>;
export const sandPC: SandPC = pipe(sandPTaggedC, c => new t.Type<SandP, SandPTagged>(
  `SandP`,
  (u: unknown): u is SandP => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SandP> => pipe(c.decode(u), E.map(x => ({ ...x, ...sandP }))),
  (x: SandP): SandPTagged => ({ ...x, _tag: `SandP`}),
)) satisfies t.Type<SandP, unknown>;


export const fitch = {
  _tag: `Fitch`,
  agencyName: `Fitch Ratings`,
  id: 3,
  shortName: `Fitch`
} as const;

export type FitchTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Fitch`>
}>;
export const fitchTaggedC: FitchTaggedC = t.type({
  _tag: t.literal(`Fitch`)
});
export type FitchTagged = t.TypeOf<FitchTaggedC>;
export type Fitch = FitchTagged & typeof fitch;
export type FitchC = t.Type<Fitch, FitchTagged>;
export const fitchC: FitchC = pipe(fitchTaggedC, c => new t.Type<Fitch, FitchTagged>(
  `Fitch`,
  (u: unknown): u is Fitch => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Fitch> => pipe(c.decode(u), E.map(x => ({ ...x, ...fitch }))),
  (x: Fitch): FitchTagged => ({ ...x, _tag: `Fitch`}),
)) satisfies t.Type<Fitch, unknown>;


export const kroll = {
  _tag: `Kroll`,
  agencyName: `Kroll Bond Rating Agency`,
  id: 4,
  shortName: `Kroll`
} as const;

export type KrollTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Kroll`>
}>;
export const krollTaggedC: KrollTaggedC = t.type({
  _tag: t.literal(`Kroll`)
});
export type KrollTagged = t.TypeOf<KrollTaggedC>;
export type Kroll = KrollTagged & typeof kroll;
export type KrollC = t.Type<Kroll, KrollTagged>;
export const krollC: KrollC = pipe(krollTaggedC, c => new t.Type<Kroll, KrollTagged>(
  `Kroll`,
  (u: unknown): u is Kroll => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Kroll> => pipe(c.decode(u), E.map(x => ({ ...x, ...kroll }))),
  (x: Kroll): KrollTagged => ({ ...x, _tag: `Kroll`}),
)) satisfies t.Type<Kroll, unknown>;


export const assuredGuaranty = {
  _tag: `AssuredGuaranty`,
  agencyName: `Assured Guaranty`,
  id: 5,
  shortName: `Assured Guaranty`
} as const;

export type AssuredGuarantyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AssuredGuaranty`>
}>;
export const assuredGuarantyTaggedC: AssuredGuarantyTaggedC = t.type({
  _tag: t.literal(`AssuredGuaranty`)
});
export type AssuredGuarantyTagged = t.TypeOf<AssuredGuarantyTaggedC>;
export type AssuredGuaranty = AssuredGuarantyTagged & typeof assuredGuaranty;
export type AssuredGuarantyC = t.Type<AssuredGuaranty, AssuredGuarantyTagged>;
export const assuredGuarantyC: AssuredGuarantyC = pipe(assuredGuarantyTaggedC, c => new t.Type<AssuredGuaranty, AssuredGuarantyTagged>(
  `AssuredGuaranty`,
  (u: unknown): u is AssuredGuaranty => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AssuredGuaranty> => pipe(c.decode(u), E.map(x => ({ ...x, ...assuredGuaranty }))),
  (x: AssuredGuaranty): AssuredGuarantyTagged => ({ ...x, _tag: `AssuredGuaranty`}),
)) satisfies t.Type<AssuredGuaranty, unknown>;


export const buildAmericaMutual = {
  _tag: `BuildAmericaMutual`,
  agencyName: `Build America Mutual`,
  id: 6,
  shortName: `Build America Mutual`
} as const;

export type BuildAmericaMutualTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BuildAmericaMutual`>
}>;
export const buildAmericaMutualTaggedC: BuildAmericaMutualTaggedC = t.type({
  _tag: t.literal(`BuildAmericaMutual`)
});
export type BuildAmericaMutualTagged = t.TypeOf<BuildAmericaMutualTaggedC>;
export type BuildAmericaMutual = BuildAmericaMutualTagged & typeof buildAmericaMutual;
export type BuildAmericaMutualC = t.Type<BuildAmericaMutual, BuildAmericaMutualTagged>;
export const buildAmericaMutualC: BuildAmericaMutualC = pipe(buildAmericaMutualTaggedC, c => new t.Type<BuildAmericaMutual, BuildAmericaMutualTagged>(
  `BuildAmericaMutual`,
  (u: unknown): u is BuildAmericaMutual => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildAmericaMutual> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildAmericaMutual }))),
  (x: BuildAmericaMutual): BuildAmericaMutualTagged => ({ ...x, _tag: `BuildAmericaMutual`}),
)) satisfies t.Type<BuildAmericaMutual, unknown>;


export const municipalAssuranceCorp = {
  _tag: `MunicipalAssuranceCorp`,
  agencyName: `Municipal Assurance Corp`,
  id: 7,
  shortName: `Municipal Assurance Corp`
} as const;

export type MunicipalAssuranceCorpTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MunicipalAssuranceCorp`>
}>;
export const municipalAssuranceCorpTaggedC: MunicipalAssuranceCorpTaggedC = t.type({
  _tag: t.literal(`MunicipalAssuranceCorp`)
});
export type MunicipalAssuranceCorpTagged = t.TypeOf<MunicipalAssuranceCorpTaggedC>;
export type MunicipalAssuranceCorp = MunicipalAssuranceCorpTagged & typeof municipalAssuranceCorp;
export type MunicipalAssuranceCorpC = t.Type<MunicipalAssuranceCorp, MunicipalAssuranceCorpTagged>;
export const municipalAssuranceCorpC: MunicipalAssuranceCorpC = pipe(municipalAssuranceCorpTaggedC, c => new t.Type<MunicipalAssuranceCorp, MunicipalAssuranceCorpTagged>(
  `MunicipalAssuranceCorp`,
  (u: unknown): u is MunicipalAssuranceCorp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalAssuranceCorp> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalAssuranceCorp }))),
  (x: MunicipalAssuranceCorp): MunicipalAssuranceCorpTagged => ({ ...x, _tag: `MunicipalAssuranceCorp`}),
)) satisfies t.Type<MunicipalAssuranceCorp, unknown>;


export const nationalPublicFinanceGuarantee = {
  _tag: `NationalPublicFinanceGuarantee`,
  agencyName: `National Public Finance Guarantee`,
  id: 8,
  shortName: `National Public Finance Guarantee`
} as const;

export type NationalPublicFinanceGuaranteeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NationalPublicFinanceGuarantee`>
}>;
export const nationalPublicFinanceGuaranteeTaggedC: NationalPublicFinanceGuaranteeTaggedC = t.type({
  _tag: t.literal(`NationalPublicFinanceGuarantee`)
});
export type NationalPublicFinanceGuaranteeTagged = t.TypeOf<NationalPublicFinanceGuaranteeTaggedC>;
export type NationalPublicFinanceGuarantee = NationalPublicFinanceGuaranteeTagged & typeof nationalPublicFinanceGuarantee;
export type NationalPublicFinanceGuaranteeC = t.Type<NationalPublicFinanceGuarantee, NationalPublicFinanceGuaranteeTagged>;
export const nationalPublicFinanceGuaranteeC: NationalPublicFinanceGuaranteeC = pipe(nationalPublicFinanceGuaranteeTaggedC, c => new t.Type<NationalPublicFinanceGuarantee, NationalPublicFinanceGuaranteeTagged>(
  `NationalPublicFinanceGuarantee`,
  (u: unknown): u is NationalPublicFinanceGuarantee => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NationalPublicFinanceGuarantee> => pipe(c.decode(u), E.map(x => ({ ...x, ...nationalPublicFinanceGuarantee }))),
  (x: NationalPublicFinanceGuarantee): NationalPublicFinanceGuaranteeTagged => ({ ...x, _tag: `NationalPublicFinanceGuarantee`}),
)) satisfies t.Type<NationalPublicFinanceGuarantee, unknown>;


export const syncora = {
  _tag: `Syncora`,
  agencyName: `Syncora`,
  id: 9,
  shortName: `Syncora`
} as const;

export type SyncoraTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Syncora`>
}>;
export const syncoraTaggedC: SyncoraTaggedC = t.type({
  _tag: t.literal(`Syncora`)
});
export type SyncoraTagged = t.TypeOf<SyncoraTaggedC>;
export type Syncora = SyncoraTagged & typeof syncora;
export type SyncoraC = t.Type<Syncora, SyncoraTagged>;
export const syncoraC: SyncoraC = pipe(syncoraTaggedC, c => new t.Type<Syncora, SyncoraTagged>(
  `Syncora`,
  (u: unknown): u is Syncora => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Syncora> => pipe(c.decode(u), E.map(x => ({ ...x, ...syncora }))),
  (x: Syncora): SyncoraTagged => ({ ...x, _tag: `Syncora`}),
)) satisfies t.Type<Syncora, unknown>;


export const morningstarDBRS = {
  _tag: `MorningstarDBRS`,
  agencyName: `Morningstar DBRS`,
  id: 12,
  shortName: `Morningstar DBRS`
} as const;

export type MorningstarDBRSTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MorningstarDBRS`>
}>;
export const morningstarDBRSTaggedC: MorningstarDBRSTaggedC = t.type({
  _tag: t.literal(`MorningstarDBRS`)
});
export type MorningstarDBRSTagged = t.TypeOf<MorningstarDBRSTaggedC>;
export type MorningstarDBRS = MorningstarDBRSTagged & typeof morningstarDBRS;
export type MorningstarDBRSC = t.Type<MorningstarDBRS, MorningstarDBRSTagged>;
export const morningstarDBRSC: MorningstarDBRSC = pipe(morningstarDBRSTaggedC, c => new t.Type<MorningstarDBRS, MorningstarDBRSTagged>(
  `MorningstarDBRS`,
  (u: unknown): u is MorningstarDBRS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MorningstarDBRS> => pipe(c.decode(u), E.map(x => ({ ...x, ...morningstarDBRS }))),
  (x: MorningstarDBRS): MorningstarDBRSTagged => ({ ...x, _tag: `MorningstarDBRS`}),
)) satisfies t.Type<MorningstarDBRS, unknown>;


export const allRatingAgencyC = [moodysC, sandPC, fitchC, krollC, assuredGuarantyC, buildAmericaMutualC, municipalAssuranceCorpC, nationalPublicFinanceGuaranteeC, syncoraC, morningstarDBRSC] as const;
export const allRatingAgencyNames = [`Moodys`, `SandP`, `Fitch`, `Kroll`, `AssuredGuaranty`, `BuildAmericaMutual`, `MunicipalAssuranceCorp`, `NationalPublicFinanceGuarantee`, `Syncora`, `MorningstarDBRS`] as const;
export type RatingAgencyName = (typeof allRatingAgencyNames)[number];

export type RatingAgencyCU = t.UnionC<[MoodysC, SandPC, FitchC, KrollC, AssuredGuarantyC, BuildAmericaMutualC, MunicipalAssuranceCorpC, NationalPublicFinanceGuaranteeC, SyncoraC, MorningstarDBRSC]>;
export type RatingAgencyU = Moodys | SandP | Fitch | Kroll | AssuredGuaranty | BuildAmericaMutual | MunicipalAssuranceCorp | NationalPublicFinanceGuarantee | Syncora | MorningstarDBRS;
export const RatingAgencyCU: RatingAgencyCU = t.union([moodysC, sandPC, fitchC, krollC, assuredGuarantyC, buildAmericaMutualC, municipalAssuranceCorpC, nationalPublicFinanceGuaranteeC, syncoraC, morningstarDBRSC]) satisfies t.Type<RatingAgencyU, unknown>;

export const ratingAgencyOrd: Ord.Ord<RatingAgencyU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRatingAgency = [moodys, sandP, fitch, kroll, assuredGuaranty, buildAmericaMutual, municipalAssuranceCorp, nationalPublicFinanceGuarantee, syncora, morningstarDBRS] as const;
export type RatingAgencyMap<A> = { [K in RatingAgencyName]: A };


