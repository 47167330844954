import * as t from "io-ts";

export type SortOrderC = t.TypeC<{
  sortOrder: t.NumberC
}>;
export type SortOrder = {
  sortOrder: number
};
export const sortOrderC: SortOrderC = t.type({
  sortOrder: t.number
}) satisfies t.Type<SortOrder, unknown>;


