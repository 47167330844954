import type { ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { Component } from "react";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";

import { ErrorContent } from "./ErrorContent";
import { ServerError } from "./ServerError";

type ErrorBoundaryState = { error: O.Option<[Error, ErrorInfo]> };

type BaseErrorBoundaryProps = {
  config: BLConfigWithLog;
  children: (resetError: () => void) => (error: O.Option<[Error, ErrorInfo]>) => ReactNode;
};

type ErrorBoundaryProps = PropsWithChildren<{
  config: BLConfigWithLog;
}>;

export class BaseErrorBoundary extends Component<BaseErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: BaseErrorBoundaryProps) {
    super(props);
    this.state = { error: O.none };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error: O.some([error, errorInfo]) });
    this.props.config.log.fatal(`ChromeErrorBoundary: ${error.message}`, error, errorInfo);
  }

  render(): ReactNode {
    return this.props.children(() => this.setState({ error: O.none }))(this.state.error);
  }
}

export const ErrorBoundary = (props: ErrorBoundaryProps) =>
  <BaseErrorBoundary config={props.config}>
    {resetError => O.fold(
      () => props.children,
      ([error, errorInfo]) =>
        <ServerError>
          <ErrorContent resetErrorState={resetError}>
            <details style={{ whiteSpace: "pre-wrap" }}>
              <strong>{error.message}</strong>
              {errorInfo.componentStack}
            </details>
          </ErrorContent>
        </ServerError>
    )}
  </BaseErrorBoundary>;
