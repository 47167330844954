import { lit, Match, query, end } from "fp-ts-routing/lib";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as t from "io-ts";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";


export const dashboard = {
  _tag: `Dashboard`
} as const;

export type DashboardTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Dashboard`>
}>;
export const dashboardTaggedC: DashboardTaggedC = t.type({
  _tag: t.literal(`Dashboard`)
});
export type DashboardTagged = t.TypeOf<DashboardTaggedC>;
export type Dashboard = DashboardTagged & typeof dashboard;
export type DashboardC = t.Type<Dashboard, DashboardTagged>;
export const dashboardC: DashboardC = pipe(dashboardTaggedC, c => new t.Type<Dashboard, DashboardTagged>(
  `Dashboard`,
  (u: unknown): u is Dashboard => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Dashboard> => pipe(c.decode(u), E.map(x => ({ ...x, ...dashboard }))),
  (x: Dashboard): DashboardTagged => ({ ...x, _tag: `Dashboard`}),
)) satisfies t.Type<Dashboard, unknown>;


export const issuerFeed = {
  _tag: `IssuerFeed`
} as const;

export type IssuerFeedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerFeed`>
}>;
export const issuerFeedTaggedC: IssuerFeedTaggedC = t.type({
  _tag: t.literal(`IssuerFeed`)
});
export type IssuerFeedTagged = t.TypeOf<IssuerFeedTaggedC>;
export type IssuerFeed = IssuerFeedTagged & typeof issuerFeed;
export type IssuerFeedC = t.Type<IssuerFeed, IssuerFeedTagged>;
export const issuerFeedC: IssuerFeedC = pipe(issuerFeedTaggedC, c => new t.Type<IssuerFeed, IssuerFeedTagged>(
  `IssuerFeed`,
  (u: unknown): u is IssuerFeed => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerFeed> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerFeed }))),
  (x: IssuerFeed): IssuerFeedTagged => ({ ...x, _tag: `IssuerFeed`}),
)) satisfies t.Type<IssuerFeed, unknown>;


export const bondSaleCalendar = {
  _tag: `BondSaleCalendar`
} as const;

export type BondSaleCalendarTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondSaleCalendar`>
}>;
export const bondSaleCalendarTaggedC: BondSaleCalendarTaggedC = t.type({
  _tag: t.literal(`BondSaleCalendar`)
});
export type BondSaleCalendarTagged = t.TypeOf<BondSaleCalendarTaggedC>;
export type BondSaleCalendar = BondSaleCalendarTagged & typeof bondSaleCalendar;
export type BondSaleCalendarC = t.Type<BondSaleCalendar, BondSaleCalendarTagged>;
export const bondSaleCalendarC: BondSaleCalendarC = pipe(bondSaleCalendarTaggedC, c => new t.Type<BondSaleCalendar, BondSaleCalendarTagged>(
  `BondSaleCalendar`,
  (u: unknown): u is BondSaleCalendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondSaleCalendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondSaleCalendar }))),
  (x: BondSaleCalendar): BondSaleCalendarTagged => ({ ...x, _tag: `BondSaleCalendar`}),
)) satisfies t.Type<BondSaleCalendar, unknown>;


export const watchlistBonds = {
  _tag: `WatchlistBonds`
} as const;

export type WatchlistBondsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WatchlistBonds`>
}>;
export const watchlistBondsTaggedC: WatchlistBondsTaggedC = t.type({
  _tag: t.literal(`WatchlistBonds`)
});
export type WatchlistBondsTagged = t.TypeOf<WatchlistBondsTaggedC>;
export type WatchlistBonds = WatchlistBondsTagged & typeof watchlistBonds;
export type WatchlistBondsC = t.Type<WatchlistBonds, WatchlistBondsTagged>;
export const watchlistBondsC: WatchlistBondsC = pipe(watchlistBondsTaggedC, c => new t.Type<WatchlistBonds, WatchlistBondsTagged>(
  `WatchlistBonds`,
  (u: unknown): u is WatchlistBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistBonds }))),
  (x: WatchlistBonds): WatchlistBondsTagged => ({ ...x, _tag: `WatchlistBonds`}),
)) satisfies t.Type<WatchlistBonds, unknown>;


export const watchlistRfps = {
  _tag: `WatchlistRfps`
} as const;

export type WatchlistRfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WatchlistRfps`>
}>;
export const watchlistRfpsTaggedC: WatchlistRfpsTaggedC = t.type({
  _tag: t.literal(`WatchlistRfps`)
});
export type WatchlistRfpsTagged = t.TypeOf<WatchlistRfpsTaggedC>;
export type WatchlistRfps = WatchlistRfpsTagged & typeof watchlistRfps;
export type WatchlistRfpsC = t.Type<WatchlistRfps, WatchlistRfpsTagged>;
export const watchlistRfpsC: WatchlistRfpsC = pipe(watchlistRfpsTaggedC, c => new t.Type<WatchlistRfps, WatchlistRfpsTagged>(
  `WatchlistRfps`,
  (u: unknown): u is WatchlistRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistRfps }))),
  (x: WatchlistRfps): WatchlistRfpsTagged => ({ ...x, _tag: `WatchlistRfps`}),
)) satisfies t.Type<WatchlistRfps, unknown>;


export const watchlistIssuers = {
  _tag: `WatchlistIssuers`
} as const;

export type WatchlistIssuersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WatchlistIssuers`>
}>;
export const watchlistIssuersTaggedC: WatchlistIssuersTaggedC = t.type({
  _tag: t.literal(`WatchlistIssuers`)
});
export type WatchlistIssuersTagged = t.TypeOf<WatchlistIssuersTaggedC>;
export type WatchlistIssuers = WatchlistIssuersTagged & typeof watchlistIssuers;
export type WatchlistIssuersC = t.Type<WatchlistIssuers, WatchlistIssuersTagged>;
export const watchlistIssuersC: WatchlistIssuersC = pipe(watchlistIssuersTaggedC, c => new t.Type<WatchlistIssuers, WatchlistIssuersTagged>(
  `WatchlistIssuers`,
  (u: unknown): u is WatchlistIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistIssuers }))),
  (x: WatchlistIssuers): WatchlistIssuersTagged => ({ ...x, _tag: `WatchlistIssuers`}),
)) satisfies t.Type<WatchlistIssuers, unknown>;


export const watchlistSectors = {
  _tag: `WatchlistSectors`
} as const;

export type WatchlistSectorsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WatchlistSectors`>
}>;
export const watchlistSectorsTaggedC: WatchlistSectorsTaggedC = t.type({
  _tag: t.literal(`WatchlistSectors`)
});
export type WatchlistSectorsTagged = t.TypeOf<WatchlistSectorsTaggedC>;
export type WatchlistSectors = WatchlistSectorsTagged & typeof watchlistSectors;
export type WatchlistSectorsC = t.Type<WatchlistSectors, WatchlistSectorsTagged>;
export const watchlistSectorsC: WatchlistSectorsC = pipe(watchlistSectorsTaggedC, c => new t.Type<WatchlistSectors, WatchlistSectorsTagged>(
  `WatchlistSectors`,
  (u: unknown): u is WatchlistSectors => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistSectors> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistSectors }))),
  (x: WatchlistSectors): WatchlistSectorsTagged => ({ ...x, _tag: `WatchlistSectors`}),
)) satisfies t.Type<WatchlistSectors, unknown>;


export const watchlistStates = {
  _tag: `WatchlistStates`
} as const;

export type WatchlistStatesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WatchlistStates`>
}>;
export const watchlistStatesTaggedC: WatchlistStatesTaggedC = t.type({
  _tag: t.literal(`WatchlistStates`)
});
export type WatchlistStatesTagged = t.TypeOf<WatchlistStatesTaggedC>;
export type WatchlistStates = WatchlistStatesTagged & typeof watchlistStates;
export type WatchlistStatesC = t.Type<WatchlistStates, WatchlistStatesTagged>;
export const watchlistStatesC: WatchlistStatesC = pipe(watchlistStatesTaggedC, c => new t.Type<WatchlistStates, WatchlistStatesTagged>(
  `WatchlistStates`,
  (u: unknown): u is WatchlistStates => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistStates> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistStates }))),
  (x: WatchlistStates): WatchlistStatesTagged => ({ ...x, _tag: `WatchlistStates`}),
)) satisfies t.Type<WatchlistStates, unknown>;


export const allInvestorPortalPageC = [dashboardC, issuerFeedC, bondSaleCalendarC, watchlistBondsC, watchlistRfpsC, watchlistIssuersC, watchlistSectorsC, watchlistStatesC] as const;
export const allInvestorPortalPageNames = [`Dashboard`, `IssuerFeed`, `BondSaleCalendar`, `WatchlistBonds`, `WatchlistRfps`, `WatchlistIssuers`, `WatchlistSectors`, `WatchlistStates`] as const;
export type InvestorPortalPageName = (typeof allInvestorPortalPageNames)[number];

export type InvestorPortalPageCU = t.UnionC<[DashboardC, IssuerFeedC, BondSaleCalendarC, WatchlistBondsC, WatchlistRfpsC, WatchlistIssuersC, WatchlistSectorsC, WatchlistStatesC]>;
export type InvestorPortalPageU = Dashboard | IssuerFeed | BondSaleCalendar | WatchlistBonds | WatchlistRfps | WatchlistIssuers | WatchlistSectors | WatchlistStates;
export const InvestorPortalPageCU: InvestorPortalPageCU = t.union([dashboardC, issuerFeedC, bondSaleCalendarC, watchlistBondsC, watchlistRfpsC, watchlistIssuersC, watchlistSectorsC, watchlistStatesC]) satisfies t.Type<InvestorPortalPageU, unknown>;

export const investorPortalPageOrd: Ord.Ord<InvestorPortalPageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allInvestorPortalPage = [dashboard, issuerFeed, bondSaleCalendar, watchlistBonds, watchlistRfps, watchlistIssuers, watchlistSectors, watchlistStates] as const;
export type InvestorPortalPageMap<A> = { [K in InvestorPortalPageName]: A };





const bondSaleCalendarPath = lit("investor-portal").then(lit("bond-sale-calendar"));
const bondSaleCalendarPathParts = ["bond-sale-calendar"] as const;
export type BondSaleCalendarPathParts = typeof bondSaleCalendarPathParts;

const bondSaleCalendarQuery = t.strict({});
export type BondSaleCalendarParams = Describe<typeof bondSaleCalendarPath._A & typeof bondSaleCalendarQuery._A>;

export type BondSaleCalendarRoute<A = {}> = { match: Match<BondSaleCalendarParams & A>; pathParts: BondSaleCalendarPathParts };

export function bondSaleCalendarRoute(): BondSaleCalendarRoute;
export function bondSaleCalendarRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondSaleCalendarParams>): BondSaleCalendarRoute<t.TypeOf<A>>;
export function bondSaleCalendarRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondSaleCalendarParams>) {
  return {
    match: (q ? bondSaleCalendarPath.then(query(t.intersection([bondSaleCalendarQuery, q]))) : bondSaleCalendarPath.then(query(bondSaleCalendarQuery))).then(end),
    pathParts: bondSaleCalendarPathParts,
  };
}

const dashboardPath = lit("investor-portal").then(lit("dashboard"));
const dashboardPathParts = ["dashboard"] as const;
export type DashboardPathParts = typeof dashboardPathParts;

const dashboardQuery = t.strict({});
export type DashboardParams = Describe<typeof dashboardPath._A & typeof dashboardQuery._A>;

export type DashboardRoute<A = {}> = { match: Match<DashboardParams & A>; pathParts: DashboardPathParts };

export function dashboardRoute(): DashboardRoute;
export function dashboardRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DashboardParams>): DashboardRoute<t.TypeOf<A>>;
export function dashboardRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DashboardParams>) {
  return {
    match: (q ? dashboardPath.then(query(t.intersection([dashboardQuery, q]))) : dashboardPath.then(query(dashboardQuery))).then(end),
    pathParts: dashboardPathParts,
  };
}

const issuerFeedPath = lit("investor-portal").then(lit("issuer-feed"));
const issuerFeedPathParts = ["issuer-feed"] as const;
export type IssuerFeedPathParts = typeof issuerFeedPathParts;

const issuerFeedQuery = t.strict({});
export type IssuerFeedParams = Describe<typeof issuerFeedPath._A & typeof issuerFeedQuery._A>;

export type IssuerFeedRoute<A = {}> = { match: Match<IssuerFeedParams & A>; pathParts: IssuerFeedPathParts };

export function issuerFeedRoute(): IssuerFeedRoute;
export function issuerFeedRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuerFeedParams>): IssuerFeedRoute<t.TypeOf<A>>;
export function issuerFeedRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuerFeedParams>) {
  return {
    match: (q ? issuerFeedPath.then(query(t.intersection([issuerFeedQuery, q]))) : issuerFeedPath.then(query(issuerFeedQuery))).then(end),
    pathParts: issuerFeedPathParts,
  };
}

const watchlistBondsPath = lit("investor-portal").then(lit("watchlist")).then(lit("bonds"));
const watchlistBondsPathParts = ["watchlist", "bonds"] as const;
export type WatchlistBondsPathParts = typeof watchlistBondsPathParts;

const watchlistBondsQuery = t.strict({});
export type WatchlistBondsParams = Describe<typeof watchlistBondsPath._A & typeof watchlistBondsQuery._A>;

export type WatchlistBondsRoute<A = {}> = { match: Match<WatchlistBondsParams & A>; pathParts: WatchlistBondsPathParts };

export function watchlistBondsRoute(): WatchlistBondsRoute;
export function watchlistBondsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistBondsParams>): WatchlistBondsRoute<t.TypeOf<A>>;
export function watchlistBondsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistBondsParams>) {
  return {
    match: (q ? watchlistBondsPath.then(query(t.intersection([watchlistBondsQuery, q]))) : watchlistBondsPath.then(query(watchlistBondsQuery))).then(end),
    pathParts: watchlistBondsPathParts,
  };
}

const watchlistIssuersPath = lit("investor-portal").then(lit("watchlist")).then(lit("issuers"));
const watchlistIssuersPathParts = ["watchlist", "issuers"] as const;
export type WatchlistIssuersPathParts = typeof watchlistIssuersPathParts;

const watchlistIssuersQuery = t.strict({});
export type WatchlistIssuersParams = Describe<typeof watchlistIssuersPath._A & typeof watchlistIssuersQuery._A>;

export type WatchlistIssuersRoute<A = {}> = { match: Match<WatchlistIssuersParams & A>; pathParts: WatchlistIssuersPathParts };

export function watchlistIssuersRoute(): WatchlistIssuersRoute;
export function watchlistIssuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistIssuersParams>): WatchlistIssuersRoute<t.TypeOf<A>>;
export function watchlistIssuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistIssuersParams>) {
  return {
    match: (q ? watchlistIssuersPath.then(query(t.intersection([watchlistIssuersQuery, q]))) : watchlistIssuersPath.then(query(watchlistIssuersQuery))).then(end),
    pathParts: watchlistIssuersPathParts,
  };
}

const watchlistRfpsPath = lit("investor-portal").then(lit("watchlist")).then(lit("rfps"));
const watchlistRfpsPathParts = ["watchlist", "rfps"] as const;
export type WatchlistRfpsPathParts = typeof watchlistRfpsPathParts;

const watchlistRfpsQuery = t.strict({});
export type WatchlistRfpsParams = Describe<typeof watchlistRfpsPath._A & typeof watchlistRfpsQuery._A>;

export type WatchlistRfpsRoute<A = {}> = { match: Match<WatchlistRfpsParams & A>; pathParts: WatchlistRfpsPathParts };

export function watchlistRfpsRoute(): WatchlistRfpsRoute;
export function watchlistRfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistRfpsParams>): WatchlistRfpsRoute<t.TypeOf<A>>;
export function watchlistRfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistRfpsParams>) {
  return {
    match: (q ? watchlistRfpsPath.then(query(t.intersection([watchlistRfpsQuery, q]))) : watchlistRfpsPath.then(query(watchlistRfpsQuery))).then(end),
    pathParts: watchlistRfpsPathParts,
  };
}

const watchlistSectorsPath = lit("investor-portal").then(lit("watchlist")).then(lit("sectors"));
const watchlistSectorsPathParts = ["watchlist", "sectors"] as const;
export type WatchlistSectorsPathParts = typeof watchlistSectorsPathParts;

const watchlistSectorsQuery = t.strict({});
export type WatchlistSectorsParams = Describe<typeof watchlistSectorsPath._A & typeof watchlistSectorsQuery._A>;

export type WatchlistSectorsRoute<A = {}> = { match: Match<WatchlistSectorsParams & A>; pathParts: WatchlistSectorsPathParts };

export function watchlistSectorsRoute(): WatchlistSectorsRoute;
export function watchlistSectorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistSectorsParams>): WatchlistSectorsRoute<t.TypeOf<A>>;
export function watchlistSectorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistSectorsParams>) {
  return {
    match: (q ? watchlistSectorsPath.then(query(t.intersection([watchlistSectorsQuery, q]))) : watchlistSectorsPath.then(query(watchlistSectorsQuery))).then(end),
    pathParts: watchlistSectorsPathParts,
  };
}

const watchlistStatesPath = lit("investor-portal").then(lit("watchlist")).then(lit("states"));
const watchlistStatesPathParts = ["watchlist", "states"] as const;
export type WatchlistStatesPathParts = typeof watchlistStatesPathParts;

const watchlistStatesQuery = t.strict({});
export type WatchlistStatesParams = Describe<typeof watchlistStatesPath._A & typeof watchlistStatesQuery._A>;

export type WatchlistStatesRoute<A = {}> = { match: Match<WatchlistStatesParams & A>; pathParts: WatchlistStatesPathParts };

export function watchlistStatesRoute(): WatchlistStatesRoute;
export function watchlistStatesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistStatesParams>): WatchlistStatesRoute<t.TypeOf<A>>;
export function watchlistStatesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistStatesParams>) {
  return {
    match: (q ? watchlistStatesPath.then(query(t.intersection([watchlistStatesQuery, q]))) : watchlistStatesPath.then(query(watchlistStatesQuery))).then(end),
    pathParts: watchlistStatesPathParts,
  };
}

export const allRoutes = [bondSaleCalendarRoute, dashboardRoute, issuerFeedRoute, watchlistBondsRoute, watchlistIssuersRoute, watchlistRfpsRoute, watchlistSectorsRoute, watchlistStatesRoute] as const;