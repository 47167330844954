import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const about = {
  _tag: `About`,
  id: 1,
  name: `About`
} as const;

export type AboutTaggedC = t.TypeC<{
  _tag: t.LiteralC<`About`>
}>;
export const aboutTaggedC: AboutTaggedC = t.type({
  _tag: t.literal(`About`)
});
export type AboutTagged = t.TypeOf<AboutTaggedC>;
export type About = AboutTagged & typeof about;
export type AboutC = t.Type<About, AboutTagged>;
export const aboutC: AboutC = pipe(aboutTaggedC, c => new t.Type<About, AboutTagged>(
  `About`,
  (u: unknown): u is About => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, About> => pipe(c.decode(u), E.map(x => ({ ...x, ...about }))),
  (x: About): AboutTagged => ({ ...x, _tag: `About`}),
)) satisfies t.Type<About, unknown>;


export const project = {
  _tag: `Project`,
  id: 2,
  name: `Project`
} as const;

export type ProjectTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Project`>
}>;
export const projectTaggedC: ProjectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export type ProjectC = t.Type<Project, ProjectTagged>;
export const projectC: ProjectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
)) satisfies t.Type<Project, unknown>;


export const esg = {
  _tag: `Esg`,
  id: 3,
  name: `ESG`
} as const;

export type EsgTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Esg`>
}>;
export const esgTaggedC: EsgTaggedC = t.type({
  _tag: t.literal(`Esg`)
});
export type EsgTagged = t.TypeOf<EsgTaggedC>;
export type Esg = EsgTagged & typeof esg;
export type EsgC = t.Type<Esg, EsgTagged>;
export const esgC: EsgC = pipe(esgTaggedC, c => new t.Type<Esg, EsgTagged>(
  `Esg`,
  (u: unknown): u is Esg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Esg> => pipe(c.decode(u), E.map(x => ({ ...x, ...esg }))),
  (x: Esg): EsgTagged => ({ ...x, _tag: `Esg`}),
)) satisfies t.Type<Esg, unknown>;


export const bondProgram = {
  _tag: `BondProgram`,
  id: 4,
  name: `Bond Program`
} as const;

export type BondProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondProgram`>
}>;
export const bondProgramTaggedC: BondProgramTaggedC = t.type({
  _tag: t.literal(`BondProgram`)
});
export type BondProgramTagged = t.TypeOf<BondProgramTaggedC>;
export type BondProgram = BondProgramTagged & typeof bondProgram;
export type BondProgramC = t.Type<BondProgram, BondProgramTagged>;
export const bondProgramC: BondProgramC = pipe(bondProgramTaggedC, c => new t.Type<BondProgram, BondProgramTagged>(
  `BondProgram`,
  (u: unknown): u is BondProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgram }))),
  (x: BondProgram): BondProgramTagged => ({ ...x, _tag: `BondProgram`}),
)) satisfies t.Type<BondProgram, unknown>;


export const allCalloutLocationC = [aboutC, projectC, esgC, bondProgramC] as const;
export const allCalloutLocationNames = [`About`, `Project`, `Esg`, `BondProgram`] as const;
export type CalloutLocationName = (typeof allCalloutLocationNames)[number];

export type CalloutLocationCU = t.UnionC<[AboutC, ProjectC, EsgC, BondProgramC]>;
export type CalloutLocationU = About | Project | Esg | BondProgram;
export const CalloutLocationCU: CalloutLocationCU = t.union([aboutC, projectC, esgC, bondProgramC]) satisfies t.Type<CalloutLocationU, unknown>;

export const calloutLocationOrd: Ord.Ord<CalloutLocationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCalloutLocation = [about, project, esg, bondProgram] as const;
export type CalloutLocationMap<A> = { [K in CalloutLocationName]: A };


