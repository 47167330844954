import type { ReactNode } from "react";

import type { Html as HtmlType } from "@scripts/codecs/html";
import { type O, pipe } from "@scripts/fp-ts";
import { Html } from "@scripts/react/components/Html";
import { klass } from "@scripts/react/util/classnames";

import { mapOrEmpty } from "../Empty";

export const ErrorLayout = (props: {
  header: string;
  content: ReactNode;
  debugContent: O.Option<HtmlType>;
}) =>
  <div {...klass("d-flex", "justify-content-center", "mt-3")}>
    <div {...klass("not-found-content", "d-flex", "flex-col", "align-items-center", "text-center")}>
      <h2 {...klass("mt-05")}>{props.header}</h2>
      {props.content}
      {pipe(props.debugContent, mapOrEmpty(debugContent =>
        <pre {...klass("text-left white-space-pre-wrap")}><Html content={debugContent} /></pre>
      ))}
    </div>
  </div>;
