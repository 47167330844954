import * as t from "io-ts";
import { OfferingParticipantWithTypeC as imported7_OfferingParticipantWithTypeC, OfferingParticipantWithType as imported7_OfferingParticipantWithType, offeringParticipantWithTypeC as imported7_offeringParticipantWithTypeC } from "./offeringParticipant";
import { MediaC as imported91_MediaC, Media as imported91_Media, mediaC as imported87_mediaC } from "./media";
import { TaggedContentC as imported219_TaggedContentC, taggedContentC as imported219_taggedContentC, taggedContentC as imported218_taggedContentC, TaggedContent as imported218_TaggedContent, taggedContentC as imported217_taggedContentC, TaggedContent as imported217_TaggedContent, TaggedContentC as imported217_TaggedContentC, TaggedContent as imported219_TaggedContent, TaggedContentC as imported218_TaggedContentC } from "./taggedContent";
import * as O from "fp-ts/lib/Option";
import { BankC as imported27_BankC, Bank as imported27_Bank, bankC as imported27_bankC } from "./bank";
import { BondOfferingC as imported35_BondOfferingC, BondOffering as imported35_BondOffering, bondOfferingC as imported35_bondOfferingC } from "./bondOfferingBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { RoadShowDataC as imported33_RoadShowDataC, RoadShowData as imported33_RoadShowData, roadShowDataC as imported33_roadShowDataC } from "./roadshow";
import { RelatedOfferingParticipantLinkC as imported23_RelatedOfferingParticipantLinkC, RelatedOfferingParticipantLink as imported23_RelatedOfferingParticipantLink, relatedOfferingParticipantLinkC as imported23_relatedOfferingParticipantLinkC } from "./relatedContent";
import { withIdC as imported304_withIdC, WithId as imported304_WithId, HasManyLink as imported177_HasManyLink, withIdC as imported307_withIdC, withIdC as imported306_withIdC, WithId as imported305_WithId, WithIdC as imported304_WithIdC, WithIdC as imported306_WithIdC, WithIdC as imported305_WithIdC, WithId as imported306_WithId, WithId as imported307_WithId, withIdC as imported305_withIdC, hasManyLinkC as imported177_hasManyLinkC, HasManyLinkC as imported177_HasManyLinkC, WithIdC as imported307_WithIdC } from "./threadThrough";
import { IssuerWithAddressC as imported9_IssuerWithAddressC, IssuerWithAddress as imported9_IssuerWithAddress, issuerWithAddressC as imported9_issuerWithAddressC } from "./issuer";

export type InvestorPortalOfferingC = t.TypeC<{
  offering: imported304_WithIdC<imported217_TaggedContentC<imported35_BondOfferingC>>,
  issuer: imported9_IssuerWithAddressC,
  bank: OptionFromNullableC<imported27_BankC>,
  subscribed: t.BooleanC,
  documents: t.ReadonlyArrayC<imported305_WithIdC<imported218_TaggedContentC<imported91_MediaC>>>,
  roadshows: t.ReadonlyArrayC<imported306_WithIdC<imported219_TaggedContentC<imported33_RoadShowDataC>>>,
  participants: t.ReadonlyArrayC<imported177_HasManyLinkC<imported307_WithIdC<imported7_OfferingParticipantWithTypeC>, imported23_RelatedOfferingParticipantLinkC>>
}>;
export type InvestorPortalOffering = {
  offering: imported304_WithId<imported217_TaggedContent<imported35_BondOffering>>,
  issuer: imported9_IssuerWithAddress,
  bank: O.Option<imported27_Bank>,
  subscribed: boolean,
  documents: ReadonlyArray<imported305_WithId<imported218_TaggedContent<imported91_Media>>>,
  roadshows: ReadonlyArray<imported306_WithId<imported219_TaggedContent<imported33_RoadShowData>>>,
  participants: ReadonlyArray<imported177_HasManyLink<imported307_WithId<imported7_OfferingParticipantWithType>, imported23_RelatedOfferingParticipantLink>>
};
export const investorPortalOfferingC: InvestorPortalOfferingC = t.type({
  offering: imported304_withIdC(imported217_taggedContentC(imported35_bondOfferingC)),
  issuer: imported9_issuerWithAddressC,
  bank: optionFromNullable(imported27_bankC),
  subscribed: t.boolean,
  documents: t.readonlyArray(imported305_withIdC(imported218_taggedContentC(imported87_mediaC))),
  roadshows: t.readonlyArray(imported306_withIdC(imported219_taggedContentC(imported33_roadShowDataC))),
  participants: t.readonlyArray(imported177_hasManyLinkC(imported307_withIdC(imported7_offeringParticipantWithTypeC), imported23_relatedOfferingParticipantLinkC))
}) satisfies t.Type<InvestorPortalOffering, unknown>;


