import * as t from "io-ts";

export type ResendMfaSmsC = t.TypeC<{
  userId: t.NumberC,
  verificationId: t.StringC
}>;
export type ResendMfaSms = {
  userId: number,
  verificationId: string
};
export const resendMfaSmsC: ResendMfaSmsC = t.type({
  userId: t.number,
  verificationId: t.string
}) satisfies t.Type<ResendMfaSms, unknown>;


