import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { AddressC as imported10_AddressC, Address as imported10_Address, addressC as imported8_addressC } from "./address";
import { SectorCU as imported35_SectorCU, SectorU as imported17_SectorU, SectorCU as imported34_SectorCU } from "../domaintables/sectors";

export const suspended = {
  _tag: `Suspended`,
  text: `Suspended`,
  value: `Suspended`
} as const;

export type SuspendedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Suspended`>
}>;
export const suspendedTaggedC: SuspendedTaggedC = t.type({
  _tag: t.literal(`Suspended`)
});
export type SuspendedTagged = t.TypeOf<SuspendedTaggedC>;
export type Suspended = SuspendedTagged & typeof suspended;
export type SuspendedC = t.Type<Suspended, SuspendedTagged>;
export const suspendedC: SuspendedC = pipe(suspendedTaggedC, c => new t.Type<Suspended, SuspendedTagged>(
  `Suspended`,
  (u: unknown): u is Suspended => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Suspended> => pipe(c.decode(u), E.map(x => ({ ...x, ...suspended }))),
  (x: Suspended): SuspendedTagged => ({ ...x, _tag: `Suspended`}),
)) satisfies t.Type<Suspended, unknown>;


export type IssuerC = t.TypeC<{
  id: t.NumberC,
  clientId: t.NumberC,
  bankId: OptionFromNullableC<t.NumberC>,
  issuerType: imported35_SectorCU,
  addressId: t.NumberC,
  primaryOfficerId: OptionFromNullableC<t.NumberC>,
  welcomeSigneeId: OptionFromNullableC<t.NumberC>,
  slug: t.StringC,
  name: t.StringC,
  shortName: OptionFromNullableC<t.StringC>,
  subheader: OptionFromNullableC<t.StringC>,
  email: t.StringC,
  phoneNumber: t.StringC,
  phoneExtension: OptionFromNullableC<t.StringC>,
  logoUrl: OptionFromNullableC<t.StringC>,
  logoIsRectangle: t.BooleanC,
  website: OptionFromNullableC<t.StringC>,
  customDomain: OptionFromNullableC<t.StringC>,
  published: t.BooleanC,
  publishDate: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  hasSitePassword: t.BooleanC,
  domainOwner: t.BooleanC,
  suspended: t.BooleanC
}>;
export type Issuer = {
  id: number,
  clientId: number,
  bankId: O.Option<number>,
  issuerType: imported17_SectorU,
  addressId: number,
  primaryOfficerId: O.Option<number>,
  welcomeSigneeId: O.Option<number>,
  slug: string,
  name: string,
  shortName: O.Option<string>,
  subheader: O.Option<string>,
  email: string,
  phoneNumber: string,
  phoneExtension: O.Option<string>,
  logoUrl: O.Option<string>,
  logoIsRectangle: boolean,
  website: O.Option<string>,
  customDomain: O.Option<string>,
  published: boolean,
  publishDate: O.Option<LocalDateTime>,
  hasSitePassword: boolean,
  domainOwner: boolean,
  suspended: boolean
};
export const issuerC: IssuerC = t.type({
  id: t.number,
  clientId: t.number,
  bankId: optionFromNullable(t.number),
  issuerType: imported34_SectorCU,
  addressId: t.number,
  primaryOfficerId: optionFromNullable(t.number),
  welcomeSigneeId: optionFromNullable(t.number),
  slug: t.string,
  name: t.string,
  shortName: optionFromNullable(t.string),
  subheader: optionFromNullable(t.string),
  email: t.string,
  phoneNumber: t.string,
  phoneExtension: optionFromNullable(t.string),
  logoUrl: optionFromNullable(t.string),
  logoIsRectangle: t.boolean,
  website: optionFromNullable(t.string),
  customDomain: optionFromNullable(t.string),
  published: t.boolean,
  publishDate: optionFromNullable(LocalDateTimeFromIsoStringC),
  hasSitePassword: t.boolean,
  domainOwner: t.boolean,
  suspended: t.boolean
}) satisfies t.Type<Issuer, unknown>;


export type WithIssuerC<A1 extends t.Mixed> = t.TypeC<{
  issuer: IssuerC,
  unWithIssuer: A1
}>;
export type WithIssuer<A1> = {
  issuer: Issuer,
  unWithIssuer: A1
};
export const withIssuerC = <A1 extends t.Mixed>(A1: A1): WithIssuerC<A1> => t.type({
  issuer: issuerC,
  unWithIssuer: A1
}) satisfies t.Type<WithIssuer<t.TypeOf<A1>>, unknown>;


export type IssuerWithAddressC = t.TypeC<{
  issuer: IssuerC,
  address: imported10_AddressC
}>;
export type IssuerWithAddress = {
  issuer: Issuer,
  address: imported10_Address
};
export const issuerWithAddressC: IssuerWithAddressC = t.type({
  issuer: issuerC,
  address: imported8_addressC
}) satisfies t.Type<IssuerWithAddress, unknown>;


export type CreateIssuerPostC = t.TypeC<{
  name: t.StringC,
  email: t.StringC,
  phoneNumber: t.StringC,
  shortName: OptionFromNullableC<t.StringC>,
  subheader: OptionFromNullableC<t.StringC>,
  issuerType: t.NumberC,
  address: OptionFromNullableC<t.StringC>,
  city: OptionFromNullableC<t.StringC>,
  state: t.NumberC,
  postalCode: OptionFromNullableC<t.StringC>
}>;
export type CreateIssuerPost = {
  name: string,
  email: string,
  phoneNumber: string,
  shortName: O.Option<string>,
  subheader: O.Option<string>,
  issuerType: number,
  address: O.Option<string>,
  city: O.Option<string>,
  state: number,
  postalCode: O.Option<string>
};
export const createIssuerPostC: CreateIssuerPostC = t.type({
  name: t.string,
  email: t.string,
  phoneNumber: t.string,
  shortName: optionFromNullable(t.string),
  subheader: optionFromNullable(t.string),
  issuerType: t.number,
  address: optionFromNullable(t.string),
  city: optionFromNullable(t.string),
  state: t.number,
  postalCode: optionFromNullable(t.string)
}) satisfies t.Type<CreateIssuerPost, unknown>;


export const inactive = {
  _tag: `Inactive`,
  text: `Inactive`,
  value: `Inactive`
} as const;

export type InactiveTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Inactive`>
}>;
export const inactiveTaggedC: InactiveTaggedC = t.type({
  _tag: t.literal(`Inactive`)
});
export type InactiveTagged = t.TypeOf<InactiveTaggedC>;
export type Inactive = InactiveTagged & typeof inactive;
export type InactiveC = t.Type<Inactive, InactiveTagged>;
export const inactiveC: InactiveC = pipe(inactiveTaggedC, c => new t.Type<Inactive, InactiveTagged>(
  `Inactive`,
  (u: unknown): u is Inactive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Inactive> => pipe(c.decode(u), E.map(x => ({ ...x, ...inactive }))),
  (x: Inactive): InactiveTagged => ({ ...x, _tag: `Inactive`}),
)) satisfies t.Type<Inactive, unknown>;


export const activePrivate = {
  _tag: `ActivePrivate`,
  text: `Active: Private`,
  value: `ActivePrivate`
} as const;

export type ActivePrivateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ActivePrivate`>
}>;
export const activePrivateTaggedC: ActivePrivateTaggedC = t.type({
  _tag: t.literal(`ActivePrivate`)
});
export type ActivePrivateTagged = t.TypeOf<ActivePrivateTaggedC>;
export type ActivePrivate = ActivePrivateTagged & typeof activePrivate;
export type ActivePrivateC = t.Type<ActivePrivate, ActivePrivateTagged>;
export const activePrivateC: ActivePrivateC = pipe(activePrivateTaggedC, c => new t.Type<ActivePrivate, ActivePrivateTagged>(
  `ActivePrivate`,
  (u: unknown): u is ActivePrivate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ActivePrivate> => pipe(c.decode(u), E.map(x => ({ ...x, ...activePrivate }))),
  (x: ActivePrivate): ActivePrivateTagged => ({ ...x, _tag: `ActivePrivate`}),
)) satisfies t.Type<ActivePrivate, unknown>;


export const activePublic = {
  _tag: `ActivePublic`,
  text: `Active: Public`,
  value: `ActivePublic`
} as const;

export type ActivePublicTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ActivePublic`>
}>;
export const activePublicTaggedC: ActivePublicTaggedC = t.type({
  _tag: t.literal(`ActivePublic`)
});
export type ActivePublicTagged = t.TypeOf<ActivePublicTaggedC>;
export type ActivePublic = ActivePublicTagged & typeof activePublic;
export type ActivePublicC = t.Type<ActivePublic, ActivePublicTagged>;
export const activePublicC: ActivePublicC = pipe(activePublicTaggedC, c => new t.Type<ActivePublic, ActivePublicTagged>(
  `ActivePublic`,
  (u: unknown): u is ActivePublic => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ActivePublic> => pipe(c.decode(u), E.map(x => ({ ...x, ...activePublic }))),
  (x: ActivePublic): ActivePublicTagged => ({ ...x, _tag: `ActivePublic`}),
)) satisfies t.Type<ActivePublic, unknown>;


export const allIssuerStatusC = [inactiveC, activePrivateC, activePublicC, suspendedC] as const;
export const allIssuerStatusNames = [`Inactive`, `ActivePrivate`, `ActivePublic`, `Suspended`] as const;
export type IssuerStatusName = (typeof allIssuerStatusNames)[number];

export type IssuerStatusCU = t.UnionC<[InactiveC, ActivePrivateC, ActivePublicC, SuspendedC]>;
export type IssuerStatusU = Inactive | ActivePrivate | ActivePublic | Suspended;
export const IssuerStatusCU: IssuerStatusCU = t.union([inactiveC, activePrivateC, activePublicC, suspendedC]) satisfies t.Type<IssuerStatusU, unknown>;

export const issuerStatusOrd: Ord.Ord<IssuerStatusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerStatus = [inactive, activePrivate, activePublic, suspended] as const;
export type IssuerStatusMap<A> = { [K in IssuerStatusName]: A };


export type IssuerStatusPostC = t.TypeC<{
  status: IssuerStatusCU
}>;
export type IssuerStatusPost = {
  status: IssuerStatusU
};
export const issuerStatusPostC: IssuerStatusPostC = t.type({
  status: IssuerStatusCU
}) satisfies t.Type<IssuerStatusPost, unknown>;


