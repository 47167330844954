import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const stable = {
  _tag: `stable`,
  id: 1,
  ratingOutlook: `Stable`
} as const;

export type StableTaggedC = t.TypeC<{
  _tag: t.LiteralC<`stable`>
}>;
export const stableTaggedC: StableTaggedC = t.type({
  _tag: t.literal(`stable`)
});
export type StableTagged = t.TypeOf<StableTaggedC>;
export type Stable = StableTagged & typeof stable;
export type StableC = t.Type<Stable, StableTagged>;
export const stableC: StableC = pipe(stableTaggedC, c => new t.Type<Stable, StableTagged>(
  `stable`,
  (u: unknown): u is Stable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Stable> => pipe(c.decode(u), E.map(x => ({ ...x, ...stable }))),
  (x: Stable): StableTagged => ({ ...x, _tag: `stable`}),
)) satisfies t.Type<Stable, unknown>;


export const positive = {
  _tag: `positive`,
  id: 2,
  ratingOutlook: `Positive`
} as const;

export type PositiveTaggedC = t.TypeC<{
  _tag: t.LiteralC<`positive`>
}>;
export const positiveTaggedC: PositiveTaggedC = t.type({
  _tag: t.literal(`positive`)
});
export type PositiveTagged = t.TypeOf<PositiveTaggedC>;
export type Positive = PositiveTagged & typeof positive;
export type PositiveC = t.Type<Positive, PositiveTagged>;
export const positiveC: PositiveC = pipe(positiveTaggedC, c => new t.Type<Positive, PositiveTagged>(
  `positive`,
  (u: unknown): u is Positive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Positive> => pipe(c.decode(u), E.map(x => ({ ...x, ...positive }))),
  (x: Positive): PositiveTagged => ({ ...x, _tag: `positive`}),
)) satisfies t.Type<Positive, unknown>;


export const negative = {
  _tag: `negative`,
  id: 3,
  ratingOutlook: `Negative`
} as const;

export type NegativeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`negative`>
}>;
export const negativeTaggedC: NegativeTaggedC = t.type({
  _tag: t.literal(`negative`)
});
export type NegativeTagged = t.TypeOf<NegativeTaggedC>;
export type Negative = NegativeTagged & typeof negative;
export type NegativeC = t.Type<Negative, NegativeTagged>;
export const negativeC: NegativeC = pipe(negativeTaggedC, c => new t.Type<Negative, NegativeTagged>(
  `negative`,
  (u: unknown): u is Negative => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Negative> => pipe(c.decode(u), E.map(x => ({ ...x, ...negative }))),
  (x: Negative): NegativeTagged => ({ ...x, _tag: `negative`}),
)) satisfies t.Type<Negative, unknown>;


export const notAssigned = {
  _tag: `notAssigned`,
  id: 4,
  ratingOutlook: `Not Assigned`
} as const;

export type NotAssignedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`notAssigned`>
}>;
export const notAssignedTaggedC: NotAssignedTaggedC = t.type({
  _tag: t.literal(`notAssigned`)
});
export type NotAssignedTagged = t.TypeOf<NotAssignedTaggedC>;
export type NotAssigned = NotAssignedTagged & typeof notAssigned;
export type NotAssignedC = t.Type<NotAssigned, NotAssignedTagged>;
export const notAssignedC: NotAssignedC = pipe(notAssignedTaggedC, c => new t.Type<NotAssigned, NotAssignedTagged>(
  `notAssigned`,
  (u: unknown): u is NotAssigned => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NotAssigned> => pipe(c.decode(u), E.map(x => ({ ...x, ...notAssigned }))),
  (x: NotAssigned): NotAssignedTagged => ({ ...x, _tag: `notAssigned`}),
)) satisfies t.Type<NotAssigned, unknown>;


export const developing = {
  _tag: `developing`,
  id: 5,
  ratingOutlook: `Developing`
} as const;

export type DevelopingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`developing`>
}>;
export const developingTaggedC: DevelopingTaggedC = t.type({
  _tag: t.literal(`developing`)
});
export type DevelopingTagged = t.TypeOf<DevelopingTaggedC>;
export type Developing = DevelopingTagged & typeof developing;
export type DevelopingC = t.Type<Developing, DevelopingTagged>;
export const developingC: DevelopingC = pipe(developingTaggedC, c => new t.Type<Developing, DevelopingTagged>(
  `developing`,
  (u: unknown): u is Developing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Developing> => pipe(c.decode(u), E.map(x => ({ ...x, ...developing }))),
  (x: Developing): DevelopingTagged => ({ ...x, _tag: `developing`}),
)) satisfies t.Type<Developing, unknown>;


export const allRatingOutlookC = [stableC, positiveC, negativeC, notAssignedC, developingC] as const;
export const allRatingOutlookNames = [`stable`, `positive`, `negative`, `notAssigned`, `developing`] as const;
export type RatingOutlookName = (typeof allRatingOutlookNames)[number];

export type RatingOutlookCU = t.UnionC<[StableC, PositiveC, NegativeC, NotAssignedC, DevelopingC]>;
export type RatingOutlookU = Stable | Positive | Negative | NotAssigned | Developing;
export const RatingOutlookCU: RatingOutlookCU = t.union([stableC, positiveC, negativeC, notAssignedC, developingC]) satisfies t.Type<RatingOutlookU, unknown>;

export const ratingOutlookOrd: Ord.Ord<RatingOutlookU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRatingOutlook = [stable, positive, negative, notAssigned, developing] as const;
export type RatingOutlookMap<A> = { [K in RatingOutlookName]: A };


