import * as t from "io-ts";
import { UserPersonaCU as imported7_UserPersonaCU, UserPersonaU as imported3_UserPersonaU, UserPersonaCU as imported6_UserPersonaCU } from "../domaintables/userPersonas";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { InvestorTypeCU as imported11_InvestorTypeCU, InvestorTypeU as imported5_InvestorTypeU, InvestorTypeCU as imported10_InvestorTypeCU } from "../domaintables/investorTypes";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BondLinkRoleCU as imported3_BondLinkRoleCU, BondLinkRoleU as imported1_BondLinkRoleU, BondLinkRoleCU as imported2_BondLinkRoleCU } from "../domaintables/roles";

export type ClientRolesC = t.TypeC<{
  clientId: t.NumberC,
  clientTypeId: t.NumberC,
  roles: t.ReadonlyArrayC<t.NumberC>
}>;
export type ClientRoles = {
  clientId: number,
  clientTypeId: number,
  roles: ReadonlyArray<number>
};
export const clientRolesC: ClientRolesC = t.type({
  clientId: t.number,
  clientTypeId: t.number,
  roles: t.readonlyArray(t.number)
}) satisfies t.Type<ClientRoles, unknown>;


export type UserC = t.TypeC<{
  id: t.NumberC,
  email: t.StringC,
  firstName: t.StringC,
  lastName: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  phoneExtension: OptionFromNullableC<t.StringC>,
  company: OptionFromNullableC<t.StringC>,
  title: OptionFromNullableC<t.StringC>,
  primaryClientId: OptionFromNullableC<t.NumberC>,
  subscribedToHubspot: t.BooleanC,
  persona: OptionFromNullableC<imported7_UserPersonaCU>,
  investorType: OptionFromNullableC<imported11_InvestorTypeCU>,
  created: LocalDateTimeFromIsoStringC,
  termsAccepted: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  mfaPhone: OptionFromNullableC<t.StringC>,
  mfaVerified: t.BooleanC
}>;
export type User = {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: O.Option<string>,
  phoneExtension: O.Option<string>,
  company: O.Option<string>,
  title: O.Option<string>,
  primaryClientId: O.Option<number>,
  subscribedToHubspot: boolean,
  persona: O.Option<imported3_UserPersonaU>,
  investorType: O.Option<imported5_InvestorTypeU>,
  created: LocalDateTime,
  termsAccepted: O.Option<LocalDateTime>,
  mfaPhone: O.Option<string>,
  mfaVerified: boolean
};
export const userC: UserC = t.type({
  id: t.number,
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  primaryClientId: optionFromNullable(t.number),
  subscribedToHubspot: t.boolean,
  persona: optionFromNullable(imported6_UserPersonaCU),
  investorType: optionFromNullable(imported10_InvestorTypeCU),
  created: LocalDateTimeFromIsoStringC,
  termsAccepted: optionFromNullable(LocalDateTimeFromIsoStringC),
  mfaPhone: optionFromNullable(t.string),
  mfaVerified: t.boolean
}) satisfies t.Type<User, unknown>;


export type UserWithRolesC = t.TypeC<{
  user: UserC,
  clientType: t.StringC,
  roleIds: t.ReadonlyArrayC<ClientRolesC>,
  bondlinkAdmin: t.BooleanC,
  bondlinkAdminRole: OptionFromNullableC<imported3_BondLinkRoleCU>
}>;
export type UserWithRoles = {
  user: User,
  clientType: string,
  roleIds: ReadonlyArray<ClientRoles>,
  bondlinkAdmin: boolean,
  bondlinkAdminRole: O.Option<imported1_BondLinkRoleU>
};
export const userWithRolesC: UserWithRolesC = t.type({
  user: userC,
  clientType: t.string,
  roleIds: t.readonlyArray(clientRolesC),
  bondlinkAdmin: t.boolean,
  bondlinkAdminRole: optionFromNullable(imported2_BondLinkRoleCU)
}) satisfies t.Type<UserWithRoles, unknown>;


export type ConfirmAccountDataC = t.TypeC<{
  user: UserC,
  email: t.StringC,
  hash: t.StringC
}>;
export type ConfirmAccountData = {
  user: User,
  email: string,
  hash: string
};
export const confirmAccountDataC: ConfirmAccountDataC = t.type({
  user: userC,
  email: t.string,
  hash: t.string
}) satisfies t.Type<ConfirmAccountData, unknown>;


export type ConfirmAccountPostC = t.TypeC<{
  password: t.StringC,
  confirmPassword: t.StringC,
  email: t.StringC,
  hash: t.StringC,
  termsAccepted: t.BooleanC
}>;
export type ConfirmAccountPost = {
  password: string,
  confirmPassword: string,
  email: string,
  hash: string,
  termsAccepted: boolean
};
export const confirmAccountPostC: ConfirmAccountPostC = t.type({
  password: t.string,
  confirmPassword: t.string,
  email: t.string,
  hash: t.string,
  termsAccepted: t.boolean
}) satisfies t.Type<ConfirmAccountPost, unknown>;


export type UserClientRolePostC = t.TypeC<{
  id: t.NumberC,
  role: t.NumberC
}>;
export type UserClientRolePost = {
  id: number,
  role: number
};
export const userClientRolePostC: UserClientRolePostC = t.type({
  id: t.number,
  role: t.number
}) satisfies t.Type<UserClientRolePost, unknown>;


