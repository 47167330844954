import * as t from "io-ts";
import { LocalDateOrd } from "../../syntax/date/jodaSyntax";
import { LocalDateC } from "../../codecs/localDate";
import { LocalDate } from "@js-joda/core";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";

export type GraphDataC = t.TypeC<{
  minY: t.NumberC,
  maxY: t.NumberC,
  start: LocalDateC,
  end: LocalDateC,
  avg: t.NumberC,
  data: ReadonlyMapFromEntriesC<LocalDateC, t.NumberC>
}>;
export type GraphData = {
  minY: number,
  maxY: number,
  start: LocalDate,
  end: LocalDate,
  avg: number,
  data: ReadonlyMap<LocalDate, number>
};
export const graphDataC: GraphDataC = t.type({
  minY: t.number,
  maxY: t.number,
  start: LocalDateC,
  end: LocalDateC,
  avg: t.number,
  data: readonlyMapFromEntries(LocalDateC, LocalDateOrd, t.number)
}) satisfies t.Type<GraphData, unknown>;


