import { WithStatusCU as imported523_WithStatusCU, WithStatusCU as imported531_WithStatusCU, WithStatusU as imported263_WithStatusU, WithStatusU as imported264_WithStatusU, WithStatusCU as imported527_WithStatusCU, WithStatusCU as imported526_WithStatusCU, WithStatusCU as imported528_WithStatusCU, WithStatusCU as imported529_WithStatusCU, WithStatusCU as imported522_WithStatusCU, WithStatusU as imported261_WithStatusU, WithStatusCU as imported525_WithStatusCU, WithStatusCU as imported524_WithStatusCU, WithStatusU as imported262_WithStatusU, WithStatusU as imported265_WithStatusU, WithStatusCU as imported530_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { MediaC as imported56_MediaC, mediaC as imported52_mediaC, mediaC as imported53_mediaC, MediaC as imported55_MediaC, mediaC as imported54_mediaC, Media as imported55_Media, ImageUploadResponse as imported6_ImageUploadResponse, Media as imported56_Media, imageUploadResponseC as imported6_imageUploadResponseC, ImageUploadResponseC as imported6_ImageUploadResponseC, Media as imported57_Media, MediaC as imported57_MediaC } from "./media";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { SortOrderC as imported22_SortOrderC, SortOrder as imported22_SortOrder, sortOrderC as imported22_sortOrderC } from "./sortOrder";
import { CustomPageCU as imported11_CustomPageCU, CustomPageU as imported3_CustomPageU, BucketCU as imported16_BucketCU, CustomPageCU as imported9_CustomPageCU, BucketCU as imported14_BucketCU, BucketCU as imported17_BucketCU, BucketU as imported8_BucketU, CustomPageU as imported4_CustomPageU, BucketCU as imported13_BucketCU, BucketU as imported6_BucketU, CustomPageU as imported5_CustomPageU, CustomPageCU as imported10_CustomPageCU, BucketCU as imported15_BucketCU, CustomPageCU as imported7_CustomPageCU, BucketU as imported7_BucketU, BucketCU as imported12_BucketCU, CustomPageCU as imported8_CustomPageCU, CustomPageCU as imported6_CustomPageCU } from "../domaintables/pages";
import { DataAndMetaC as imported3_DataAndMetaC, DataAndMeta as imported3_DataAndMeta, dataAndMetaC as imported3_dataAndMetaC } from "./relatedContent";
import { ClientTextItem as imported23_ClientTextItem, clientTextItemC as imported22_clientTextItemC, ClientTextItemC as imported23_ClientTextItemC, clientTextItemC as imported23_clientTextItemC, ClientTextItem as imported21_ClientTextItem, clientTextItemC as imported21_clientTextItemC, ClientTextItemC as imported21_ClientTextItemC, ClientTextItemC as imported22_ClientTextItemC, ClientTextItem as imported22_ClientTextItem } from "./clientTextItem";

export type CustomPageC = t.TypeC<{
  index: t.NumberC,
  title: t.StringC,
  bucket: imported13_BucketCU
}>;
export type CustomPage = {
  index: number,
  title: string,
  bucket: imported6_BucketU
};
export const customPageC: CustomPageC = t.type({
  index: t.number,
  title: t.string,
  bucket: imported12_BucketCU
}) satisfies t.Type<CustomPage, unknown>;


export type CustomPagesC = t.TypeC<{
  customPages: t.ReadonlyArrayC<CustomPageC>
}>;
export type CustomPages = {
  customPages: ReadonlyArray<CustomPage>
};
export const customPagesC: CustomPagesC = t.type({
  customPages: t.readonlyArray(customPageC)
}) satisfies t.Type<CustomPages, unknown>;


export type CustomPageDataIdC = t.TypeC<{
  page: imported7_CustomPageCU,
  title: t.StringC,
  bucket: imported15_BucketCU,
  data: imported524_WithStatusCU<imported21_ClientTextItemC>,
  photos: t.ReadonlyArrayC<imported525_WithStatusCU<imported55_MediaC>>
}>;
export type CustomPageDataId = {
  page: imported3_CustomPageU,
  title: string,
  bucket: imported7_BucketU,
  data: imported261_WithStatusU<imported21_ClientTextItem>,
  photos: ReadonlyArray<imported262_WithStatusU<imported55_Media>>
};
export const customPageDataIdC: CustomPageDataIdC = t.type({
  page: imported6_CustomPageCU,
  title: t.string,
  bucket: imported14_BucketCU,
  data: imported522_WithStatusCU(imported21_clientTextItemC),
  photos: t.readonlyArray(imported523_WithStatusCU(imported52_mediaC))
}) satisfies t.Type<CustomPageDataId, unknown>;


export type CustomPageDataOC = t.TypeC<{
  page: imported9_CustomPageCU,
  title: t.StringC,
  bucket: imported17_BucketCU,
  data: OptionFromNullableC<imported528_WithStatusCU<imported22_ClientTextItemC>>,
  photos: t.ReadonlyArrayC<imported529_WithStatusCU<imported56_MediaC>>
}>;
export type CustomPageDataO = {
  page: imported4_CustomPageU,
  title: string,
  bucket: imported8_BucketU,
  data: O.Option<imported263_WithStatusU<imported22_ClientTextItem>>,
  photos: ReadonlyArray<imported264_WithStatusU<imported56_Media>>
};
export const customPageDataOC: CustomPageDataOC = t.type({
  page: imported8_CustomPageCU,
  title: t.string,
  bucket: imported16_BucketCU,
  data: optionFromNullable(imported526_WithStatusCU(imported22_clientTextItemC)),
  photos: t.readonlyArray(imported527_WithStatusCU(imported53_mediaC))
}) satisfies t.Type<CustomPageDataO, unknown>;


export type CustomPagePostBodyC = t.TypeC<{
  customPageIndex: t.NumberC,
  title: t.StringC,
  parentSectionId: t.NumberC,
  hidden: t.BooleanC,
  data: MarkdownC,
  photos: t.ReadonlyArrayC<imported3_DataAndMetaC<imported6_ImageUploadResponseC, imported22_SortOrderC>>
}>;
export type CustomPagePostBody = {
  customPageIndex: number,
  title: string,
  parentSectionId: number,
  hidden: boolean,
  data: Markdown,
  photos: ReadonlyArray<imported3_DataAndMeta<imported6_ImageUploadResponse, imported22_SortOrder>>
};
export const customPagePostBodyC: CustomPagePostBodyC = t.type({
  customPageIndex: t.number,
  title: t.string,
  parentSectionId: t.number,
  hidden: t.boolean,
  data: markdownC,
  photos: t.readonlyArray(imported3_dataAndMetaC(imported6_imageUploadResponseC, imported22_sortOrderC))
}) satisfies t.Type<CustomPagePostBody, unknown>;


export type SitesCustomPageDataC = t.TypeC<{
  page: imported11_CustomPageCU,
  text: OptionFromNullableC<imported23_ClientTextItemC>,
  photos: t.ReadonlyArrayC<imported531_WithStatusCU<imported57_MediaC>>
}>;
export type SitesCustomPageData = {
  page: imported5_CustomPageU,
  text: O.Option<imported23_ClientTextItem>,
  photos: ReadonlyArray<imported265_WithStatusU<imported57_Media>>
};
export const sitesCustomPageDataC: SitesCustomPageDataC = t.type({
  page: imported10_CustomPageCU,
  text: optionFromNullable(imported23_clientTextItemC),
  photos: t.readonlyArray(imported530_WithStatusCU(imported54_mediaC))
}) satisfies t.Type<SitesCustomPageData, unknown>;


