import * as t from "io-ts";
import { InvestorFocusCU as imported7_InvestorFocusCU, InvestorFocusU as imported3_InvestorFocusU, InvestorFocusCU as imported6_InvestorFocusCU, investorFocusOrd as imported2_investorFocusOrd } from "../domaintables/investorFocuses";
import * as O from "fp-ts/lib/Option";
import { InvestorTypeCU as imported7_InvestorTypeCU, InvestorTypeU as imported3_InvestorTypeU, InvestorTypeCU as imported6_InvestorTypeCU } from "../domaintables/investorTypes";
import { ReadonlySetFromArrayC, readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SectorCU as imported27_SectorCU, SectorU as imported13_SectorU, SectorCU as imported26_SectorCU, sectorOrd as imported2_sectorOrd } from "../domaintables/sectors";

export type UserIdC = t.TypeC<{
  _tag: t.LiteralC<`UserId`>,
  userId: t.NumberC
}>;
export type UserId = {
  _tag: `UserId`,
  userId: number
};
export const userIdC: UserIdC = t.type({
  _tag: t.literal(`UserId`),
  userId: t.number
}) satisfies t.Type<UserId, unknown>;


export type ActorC = t.TypeC<{
  id: UserIdC,
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  company: t.StringC,
  title: OptionFromNullableC<t.StringC>,
  fullName: t.StringC,
  isNewActor: t.BooleanC,
  personaId: OptionFromNullableC<t.NumberC>,
  investorType: OptionFromNullableC<imported7_InvestorTypeCU>,
  investorFocuses: ReadonlySetFromArrayC<imported7_InvestorFocusCU>,
  investorSectors: ReadonlySetFromArrayC<imported27_SectorCU>
}>;
export type Actor = {
  id: UserId,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: O.Option<string>,
  company: string,
  title: O.Option<string>,
  fullName: string,
  isNewActor: boolean,
  personaId: O.Option<number>,
  investorType: O.Option<imported3_InvestorTypeU>,
  investorFocuses: ReadonlySet<imported3_InvestorFocusU>,
  investorSectors: ReadonlySet<imported13_SectorU>
};
export const actorC: ActorC = t.type({
  id: userIdC,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  fullName: t.string,
  isNewActor: t.boolean,
  personaId: optionFromNullable(t.number),
  investorType: optionFromNullable(imported6_InvestorTypeCU),
  investorFocuses: readonlySetFromArray(imported6_InvestorFocusCU, imported2_investorFocusOrd),
  investorSectors: readonlySetFromArray(imported26_SectorCU, imported2_sectorOrd)
}) satisfies t.Type<Actor, unknown>;


export type MinimalActorC = t.TypeC<{
  id: UserIdC,
  firstName: t.StringC,
  lastName: t.StringC,
  personaId: OptionFromNullableC<t.NumberC>,
  company: t.StringC
}>;
export type MinimalActor = {
  id: UserId,
  firstName: string,
  lastName: string,
  personaId: O.Option<number>,
  company: string
};
export const minimalActorC: MinimalActorC = t.type({
  id: userIdC,
  firstName: t.string,
  lastName: t.string,
  personaId: optionFromNullable(t.number),
  company: t.string
}) satisfies t.Type<MinimalActor, unknown>;


export type EmailContactIdC = t.TypeC<{
  _tag: t.LiteralC<`EmailContactId`>,
  emailContactId: t.NumberC
}>;
export type EmailContactId = {
  _tag: `EmailContactId`,
  emailContactId: number
};
export const emailContactIdC: EmailContactIdC = t.type({
  _tag: t.literal(`EmailContactId`),
  emailContactId: t.number
}) satisfies t.Type<EmailContactId, unknown>;


export const allActorIdC = [emailContactIdC, userIdC] as const;
export const allActorIdNames = [`EmailContactId`, `UserId`] as const;
export type ActorIdName = (typeof allActorIdNames)[number];

export type ActorIdCU = t.UnionC<[EmailContactIdC, UserIdC]>;
export type ActorIdU = EmailContactId | UserId;
export const ActorIdCU: ActorIdCU = t.union([emailContactIdC, userIdC]) satisfies t.Type<ActorIdU, unknown>;

export type ActorIdMap<A> = { [K in ActorIdName]: A };


export type EmailContactActorC = t.TypeC<{
  id: t.NumberC,
  email: t.StringC
}>;
export type EmailContactActor = {
  id: number,
  email: string
};
export const emailContactActorC: EmailContactActorC = t.type({
  id: t.number,
  email: t.string
}) satisfies t.Type<EmailContactActor, unknown>;


