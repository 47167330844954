import * as V2Router from "@scripts/generated/routers/v2Router";

export const PortalConfig = {
  deal: {
    name: "Deal",
    path: "/deal-portal",
    trackPageview: V2Router.dealPortalBankerControllerTrackPageview,
  },
  investor: {
    name: "Investor",
    path: "/investor-portal",
    trackPageview: V2Router.investorPortalInvestorControllerTrackPageview,
  },
  issuer: {
    name: "Issuer",
    path: "/issuer-portal",
    trackPageview: V2Router.issuerPortalIssuerControllerTrackPageview,
  },
} as const;

export type PortalTypeU = typeof PortalConfig[keyof typeof PortalConfig]["name"];
