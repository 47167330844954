import { ReadonlySetFromArrayC, readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { ContentTagTypeCU as imported7_ContentTagTypeCU, ContentTagTypeU as imported3_ContentTagTypeU, ContentTagTypeCU as imported6_ContentTagTypeCU, contentTagTypeOrd as imported3_contentTagTypeOrd } from "../domaintables/contentTagTypes";
import * as t from "io-ts";

export type TaggedContentC<A1 extends t.Mixed> = t.TypeC<{
  data: A1,
  tags: ReadonlySetFromArrayC<imported7_ContentTagTypeCU>
}>;
export type TaggedContent<A1> = {
  data: A1,
  tags: ReadonlySet<imported3_ContentTagTypeU>
};
export const taggedContentC = <A1 extends t.Mixed>(A1: A1): TaggedContentC<A1> => t.type({
  data: A1,
  tags: readonlySetFromArray(imported6_ContentTagTypeCU, imported3_contentTagTypeOrd)
}) satisfies t.Type<TaggedContent<t.TypeOf<A1>>, unknown>;


