import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { AddressC as imported6_AddressC, Address as imported6_Address, addressC as imported5_addressC } from "./address";
import { SectorCU as imported25_SectorCU, SectorU as imported12_SectorU, SectorCU as imported24_SectorCU } from "../domaintables/sectors";

export type IssuerPreferencesC = t.TypeC<{
  invoiceNotifyEmail: OptionFromNullableC<t.StringC>,
  customHeaderText1: OptionFromNullableC<t.StringC>,
  linkedinUrl: OptionFromNullableC<t.StringC>,
  twitterUrl: OptionFromNullableC<t.StringC>,
  facebookUrl: OptionFromNullableC<t.StringC>,
  instagramUrl: OptionFromNullableC<t.StringC>,
  youtubeUrl: OptionFromNullableC<t.StringC>,
  customPages: t.NumberC,
  outstandingDebt: OptionFromNullableC<t.NumberC>,
  customFiscalYear: OptionFromNullableC<t.NumberC>
}>;
export type IssuerPreferences = {
  invoiceNotifyEmail: O.Option<string>,
  customHeaderText1: O.Option<string>,
  linkedinUrl: O.Option<string>,
  twitterUrl: O.Option<string>,
  facebookUrl: O.Option<string>,
  instagramUrl: O.Option<string>,
  youtubeUrl: O.Option<string>,
  customPages: number,
  outstandingDebt: O.Option<number>,
  customFiscalYear: O.Option<number>
};
export const issuerPreferencesC: IssuerPreferencesC = t.type({
  invoiceNotifyEmail: optionFromNullable(t.string),
  customHeaderText1: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  facebookUrl: optionFromNullable(t.string),
  instagramUrl: optionFromNullable(t.string),
  youtubeUrl: optionFromNullable(t.string),
  customPages: t.number,
  outstandingDebt: optionFromNullable(t.number),
  customFiscalYear: optionFromNullable(t.number)
}) satisfies t.Type<IssuerPreferences, unknown>;


export type IssuerSettingsC = t.TypeC<{
  name: t.StringC,
  shortName: OptionFromNullableC<t.StringC>,
  subheader: OptionFromNullableC<t.StringC>,
  issuerType: imported25_SectorCU,
  email: t.StringC,
  website: OptionFromNullableC<t.StringC>,
  phoneNumber: t.StringC,
  phoneExtension: OptionFromNullableC<t.StringC>
}>;
export type IssuerSettings = {
  name: string,
  shortName: O.Option<string>,
  subheader: O.Option<string>,
  issuerType: imported12_SectorU,
  email: string,
  website: O.Option<string>,
  phoneNumber: string,
  phoneExtension: O.Option<string>
};
export const issuerSettingsC: IssuerSettingsC = t.type({
  name: t.string,
  shortName: optionFromNullable(t.string),
  subheader: optionFromNullable(t.string),
  issuerType: imported24_SectorCU,
  email: t.string,
  website: optionFromNullable(t.string),
  phoneNumber: t.string,
  phoneExtension: optionFromNullable(t.string)
}) satisfies t.Type<IssuerSettings, unknown>;


export type IssuerSocialC = t.TypeC<{
  linkedinUrl: OptionFromNullableC<t.StringC>,
  twitterUrl: OptionFromNullableC<t.StringC>,
  facebookUrl: OptionFromNullableC<t.StringC>,
  instagramUrl: OptionFromNullableC<t.StringC>,
  youtubeUrl: OptionFromNullableC<t.StringC>
}>;
export type IssuerSocial = {
  linkedinUrl: O.Option<string>,
  twitterUrl: O.Option<string>,
  facebookUrl: O.Option<string>,
  instagramUrl: O.Option<string>,
  youtubeUrl: O.Option<string>
};
export const issuerSocialC: IssuerSocialC = t.type({
  linkedinUrl: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  facebookUrl: optionFromNullable(t.string),
  instagramUrl: optionFromNullable(t.string),
  youtubeUrl: optionFromNullable(t.string)
}) satisfies t.Type<IssuerSocial, unknown>;


export type IssuerPreferenceDataC = t.TypeC<{
  issuer: IssuerSettingsC,
  address: imported6_AddressC,
  social: IssuerSocialC
}>;
export type IssuerPreferenceData = {
  issuer: IssuerSettings,
  address: imported6_Address,
  social: IssuerSocial
};
export const issuerPreferenceDataC: IssuerPreferenceDataC = t.type({
  issuer: issuerSettingsC,
  address: imported5_addressC,
  social: issuerSocialC
}) satisfies t.Type<IssuerPreferenceData, unknown>;


export type IssuerPreferencePostDataC = t.TypeC<{
  issuer: IssuerSettingsC,
  social: IssuerSocialC,
  address: OptionFromNullableC<t.StringC>,
  city: OptionFromNullableC<t.StringC>,
  state: t.NumberC,
  postalCode: OptionFromNullableC<t.StringC>
}>;
export type IssuerPreferencePostData = {
  issuer: IssuerSettings,
  social: IssuerSocial,
  address: O.Option<string>,
  city: O.Option<string>,
  state: number,
  postalCode: O.Option<string>
};
export const issuerPreferencePostDataC: IssuerPreferencePostDataC = t.type({
  issuer: issuerSettingsC,
  social: issuerSocialC,
  address: optionFromNullable(t.string),
  city: optionFromNullable(t.string),
  state: t.number,
  postalCode: optionFromNullable(t.string)
}) satisfies t.Type<IssuerPreferencePostData, unknown>;


export type PublishPreferencesC = t.TypeC<{
  password: OptionFromNullableC<t.StringC>,
  confirmPassword: OptionFromNullableC<t.StringC>,
  publish: t.BooleanC
}>;
export type PublishPreferences = {
  password: O.Option<string>,
  confirmPassword: O.Option<string>,
  publish: boolean
};
export const publishPreferencesC: PublishPreferencesC = t.type({
  password: optionFromNullable(t.string),
  confirmPassword: optionFromNullable(t.string),
  publish: t.boolean
}) satisfies t.Type<PublishPreferences, unknown>;


