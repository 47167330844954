import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";

export type BankC = t.TypeC<{
  id: t.NumberC,
  clientId: t.NumberC,
  slug: t.StringC,
  name: t.StringC,
  email: t.StringC,
  logoUrl: OptionFromNullableC<t.StringC>
}>;
export type Bank = {
  id: number,
  clientId: number,
  slug: string,
  name: string,
  email: string,
  logoUrl: O.Option<string>
};
export const bankC: BankC = t.type({
  id: t.number,
  clientId: t.number,
  slug: t.string,
  name: t.string,
  email: t.string,
  logoUrl: optionFromNullable(t.string)
}) satisfies t.Type<Bank, unknown>;


export type BankPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  email: t.StringC
}>;
export type BankPost = {
  id: O.Option<number>,
  name: string,
  email: string
};
export const bankPostC: BankPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  email: t.string
}) satisfies t.Type<BankPost, unknown>;


