import * as t from "io-ts";

export interface Html { readonly HtmlTag: unique symbol }
const htmlCUnderlying = t.string;

export type HtmlC = t.Type<Html, string, unknown>;

export const htmlC: HtmlC = new t.Type<Html, string, unknown>(
  "HtmlTypeC",
  (u: unknown): u is Html => htmlCUnderlying.is(u),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (i: unknown, c: t.Context) => htmlCUnderlying.validate(i, c) as t.Validation<Html>,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (m): string => m as unknown as t.TypeOf<typeof htmlCUnderlying>,
);
