import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BankerRoleCU as imported3_BankerRoleCU, BankerRoleU as imported1_BankerRoleU, BankerRoleCU as imported2_BankerRoleCU } from "../domaintables/roles";

export type DealPortalUserC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  lastActive: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  lastIssuer: OptionFromNullableC<t.StringC>,
  role: imported3_BankerRoleCU
}>;
export type DealPortalUser = {
  firstName: string,
  lastName: string,
  email: string,
  lastActive: O.Option<LocalDateTime>,
  lastIssuer: O.Option<string>,
  role: imported1_BankerRoleU
};
export const dealPortalUserC: DealPortalUserC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  lastActive: optionFromNullable(LocalDateTimeFromIsoStringC),
  lastIssuer: optionFromNullable(t.string),
  role: imported2_BankerRoleCU
}) satisfies t.Type<DealPortalUser, unknown>;


export type DealPortalUserCreateC = t.TypeC<{
  email: t.StringC,
  firstName: t.StringC,
  lastName: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  phoneExtension: OptionFromNullableC<t.StringC>,
  company: t.StringC,
  title: OptionFromNullableC<t.StringC>,
  roleId: t.NumberC,
  personaId: t.NumberC,
  investorTypeId: OptionFromNullableC<t.NumberC>
}>;
export type DealPortalUserCreate = {
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: O.Option<string>,
  phoneExtension: O.Option<string>,
  company: string,
  title: O.Option<string>,
  roleId: number,
  personaId: number,
  investorTypeId: O.Option<number>
};
export const dealPortalUserCreateC: DealPortalUserCreateC = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  roleId: t.number,
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number)
}) satisfies t.Type<DealPortalUserCreate, unknown>;


export type BankerExistingUserUpdateC = t.TypeC<{
  userId: t.NumberC,
  roleId: t.NumberC
}>;
export type BankerExistingUserUpdate = {
  userId: number,
  roleId: number
};
export const bankerExistingUserUpdateC: BankerExistingUserUpdateC = t.type({
  userId: t.number,
  roleId: t.number
}) satisfies t.Type<BankerExistingUserUpdate, unknown>;


