import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const postSaleDebrief = {
  _tag: `PostSaleDebrief`,
  id: 1,
  name: `Post-Sale Debrief`
} as const;

export type PostSaleDebriefTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PostSaleDebrief`>
}>;
export const postSaleDebriefTaggedC: PostSaleDebriefTaggedC = t.type({
  _tag: t.literal(`PostSaleDebrief`)
});
export type PostSaleDebriefTagged = t.TypeOf<PostSaleDebriefTaggedC>;
export type PostSaleDebrief = PostSaleDebriefTagged & typeof postSaleDebrief;
export type PostSaleDebriefC = t.Type<PostSaleDebrief, PostSaleDebriefTagged>;
export const postSaleDebriefC: PostSaleDebriefC = pipe(postSaleDebriefTaggedC, c => new t.Type<PostSaleDebrief, PostSaleDebriefTagged>(
  `PostSaleDebrief`,
  (u: unknown): u is PostSaleDebrief => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PostSaleDebrief> => pipe(c.decode(u), E.map(x => ({ ...x, ...postSaleDebrief }))),
  (x: PostSaleDebrief): PostSaleDebriefTagged => ({ ...x, _tag: `PostSaleDebrief`}),
)) satisfies t.Type<PostSaleDebrief, unknown>;


export const investorHoldingsReport = {
  _tag: `InvestorHoldingsReport`,
  id: 2,
  name: `Investor Holding Report`
} as const;

export type InvestorHoldingsReportTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorHoldingsReport`>
}>;
export const investorHoldingsReportTaggedC: InvestorHoldingsReportTaggedC = t.type({
  _tag: t.literal(`InvestorHoldingsReport`)
});
export type InvestorHoldingsReportTagged = t.TypeOf<InvestorHoldingsReportTaggedC>;
export type InvestorHoldingsReport = InvestorHoldingsReportTagged & typeof investorHoldingsReport;
export type InvestorHoldingsReportC = t.Type<InvestorHoldingsReport, InvestorHoldingsReportTagged>;
export const investorHoldingsReportC: InvestorHoldingsReportC = pipe(investorHoldingsReportTaggedC, c => new t.Type<InvestorHoldingsReport, InvestorHoldingsReportTagged>(
  `InvestorHoldingsReport`,
  (u: unknown): u is InvestorHoldingsReport => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorHoldingsReport> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorHoldingsReport }))),
  (x: InvestorHoldingsReport): InvestorHoldingsReportTagged => ({ ...x, _tag: `InvestorHoldingsReport`}),
)) satisfies t.Type<InvestorHoldingsReport, unknown>;


export const buildoutMaterials = {
  _tag: `BuildoutMaterials`,
  id: 3,
  name: `Buildout Materials`
} as const;

export type BuildoutMaterialsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BuildoutMaterials`>
}>;
export const buildoutMaterialsTaggedC: BuildoutMaterialsTaggedC = t.type({
  _tag: t.literal(`BuildoutMaterials`)
});
export type BuildoutMaterialsTagged = t.TypeOf<BuildoutMaterialsTaggedC>;
export type BuildoutMaterials = BuildoutMaterialsTagged & typeof buildoutMaterials;
export type BuildoutMaterialsC = t.Type<BuildoutMaterials, BuildoutMaterialsTagged>;
export const buildoutMaterialsC: BuildoutMaterialsC = pipe(buildoutMaterialsTaggedC, c => new t.Type<BuildoutMaterials, BuildoutMaterialsTagged>(
  `BuildoutMaterials`,
  (u: unknown): u is BuildoutMaterials => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildoutMaterials> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildoutMaterials }))),
  (x: BuildoutMaterials): BuildoutMaterialsTagged => ({ ...x, _tag: `BuildoutMaterials`}),
)) satisfies t.Type<BuildoutMaterials, unknown>;


export const other = {
  _tag: `Other`,
  id: 4,
  name: `Other`
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
)) satisfies t.Type<Other, unknown>;


export const allCustomReportTypeC = [postSaleDebriefC, investorHoldingsReportC, buildoutMaterialsC, otherC] as const;
export const allCustomReportTypeNames = [`PostSaleDebrief`, `InvestorHoldingsReport`, `BuildoutMaterials`, `Other`] as const;
export type CustomReportTypeName = (typeof allCustomReportTypeNames)[number];

export type CustomReportTypeCU = t.UnionC<[PostSaleDebriefC, InvestorHoldingsReportC, BuildoutMaterialsC, OtherC]>;
export type CustomReportTypeU = PostSaleDebrief | InvestorHoldingsReport | BuildoutMaterials | Other;
export const CustomReportTypeCU: CustomReportTypeCU = t.union([postSaleDebriefC, investorHoldingsReportC, buildoutMaterialsC, otherC]) satisfies t.Type<CustomReportTypeU, unknown>;

export const customReportTypeOrd: Ord.Ord<CustomReportTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCustomReportType = [postSaleDebrief, investorHoldingsReport, buildoutMaterials, other] as const;
export type CustomReportTypeMap<A> = { [K in CustomReportTypeName]: A };


