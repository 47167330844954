import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { BigNumberC, ReadonlyBig, bigNumberC } from "../../Big/bigNumberC";
import { EitherC, either } from "io-ts-types/lib/either";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const highestDebtLevel = {
  _tag: `HighestDebtLevel`
} as const;

export type HighestDebtLevelC = t.TypeC<{
  _tag: t.LiteralC<`HighestDebtLevel`>
}>;
export type HighestDebtLevel = {
  _tag: `HighestDebtLevel`
};
export const highestDebtLevelC: HighestDebtLevelC = t.type({
  _tag: t.literal(`HighestDebtLevel`)
}) satisfies t.Type<HighestDebtLevel, unknown>;


export type CustomDebtLineC = t.TypeC<{
  label: t.StringC,
  value: BigNumberC
}>;
export type CustomDebtLine = {
  label: string,
  value: ReadonlyBig
};
export const customDebtLineC: CustomDebtLineC = t.type({
  label: t.string,
  value: bigNumberC
}) satisfies t.Type<CustomDebtLine, unknown>;


export type DebtComparisonLineC = t.TypeC<{
  line: EitherC<HighestDebtLevelC, CustomDebtLineC>
}>;
export type DebtComparisonLine = {
  line: E.Either<HighestDebtLevel, CustomDebtLine>
};
export const debtComparisonLineC: DebtComparisonLineC = t.type({
  line: either(highestDebtLevelC, customDebtLineC)
}) satisfies t.Type<DebtComparisonLine, unknown>;


export type DebtComparisonLinePostC = t.TypeC<{
  label: OptionFromNullableC<t.StringC>,
  value: OptionFromNullableC<BigNumberC>,
  highestDebtLevel: t.BooleanC,
  hideComparisonLine: t.BooleanC
}>;
export type DebtComparisonLinePost = {
  label: O.Option<string>,
  value: O.Option<ReadonlyBig>,
  highestDebtLevel: boolean,
  hideComparisonLine: boolean
};
export const debtComparisonLinePostC: DebtComparisonLinePostC = t.type({
  label: optionFromNullable(t.string),
  value: optionFromNullable(bigNumberC),
  highestDebtLevel: t.boolean,
  hideComparisonLine: t.boolean
}) satisfies t.Type<DebtComparisonLinePost, unknown>;


