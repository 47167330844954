import * as t from "io-ts";

export type ExternalLinkC = t.TypeC<{
  text: t.StringC,
  noTrackingUrl: t.StringC
}>;
export type ExternalLink = {
  text: string,
  noTrackingUrl: string
};
export const externalLinkC: ExternalLinkC = t.type({
  text: t.string,
  noTrackingUrl: t.string
}) satisfies t.Type<ExternalLink, unknown>;


