import { WithIdC as imported201_WithIdC, WithId as imported201_WithId, withIdC as imported201_withIdC } from "./threadThrough";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { ReadonlySetFromArrayC, readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type ParticipantTypeC = t.TypeC<{
  name: t.StringC,
  sortOrder: t.NumberC
}>;
export type ParticipantType = {
  name: string,
  sortOrder: number
};
export const participantTypeC: ParticipantTypeC = t.type({
  name: t.string,
  sortOrder: t.number
}) satisfies t.Type<ParticipantType, unknown>;


export type ParticipantTypesC = t.TypeC<{
  types: t.ReadonlyArrayC<imported201_WithIdC<ParticipantTypeC>>,
  usedTypes: ReadonlySetFromArrayC<t.NumberC>
}>;
export type ParticipantTypes = {
  types: ReadonlyArray<imported201_WithId<ParticipantType>>,
  usedTypes: ReadonlySet<number>
};
export const participantTypesC: ParticipantTypesC = t.type({
  types: t.readonlyArray(imported201_withIdC(participantTypeC)),
  usedTypes: readonlySetFromArray(t.number, numberOrd)
}) satisfies t.Type<ParticipantTypes, unknown>;


export type ParticipantTypePostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  sortOrder: t.NumberC
}>;
export type ParticipantTypePost = {
  id: O.Option<number>,
  name: string,
  sortOrder: number
};
export const participantTypePostC: ParticipantTypePostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  sortOrder: t.number
}) satisfies t.Type<ParticipantTypePost, unknown>;


