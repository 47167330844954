import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export type RateLimitedC<A1 extends t.Mixed> = t.TypeC<{
  remainingRequests: t.NumberC,
  data: A1
}>;
export type RateLimited<A1> = {
  remainingRequests: number,
  data: A1
};
export const rateLimitedC = <A1 extends t.Mixed>(A1: A1): RateLimitedC<A1> => t.type({
  remainingRequests: t.number,
  data: A1
}) satisfies t.Type<RateLimited<t.TypeOf<A1>>, unknown>;


export type WithIdC<A1 extends t.Mixed> = t.TypeC<{
  record: A1,
  id: t.NumberC
}>;
export type WithId<A1> = {
  record: A1,
  id: number
};
export const withIdC = <A1 extends t.Mixed>(A1: A1): WithIdC<A1> => t.type({
  record: A1,
  id: t.number
}) satisfies t.Type<WithId<t.TypeOf<A1>>, unknown>;


export type HasManyLinkC<A1 extends t.Mixed, A2 extends t.Mixed> = t.TypeC<{
  data: A1,
  link: WithIdC<A2>
}>;
export type HasManyLink<A1, A2> = {
  data: A1,
  link: WithId<A2>
};
export const hasManyLinkC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2): HasManyLinkC<A1, A2> => t.type({
  data: A1,
  link: withIdC(A2)
}) satisfies t.Type<HasManyLink<t.TypeOf<A1>, t.TypeOf<A2>>, unknown>;


export type WithModInfoC<A1 extends t.Mixed> = t.TypeC<{
  issuerId: t.NumberC,
  userIdModified: t.NumberC,
  created: LocalDateTimeFromIsoStringC,
  modified: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  published: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  data: A1
}>;
export type WithModInfo<A1> = {
  issuerId: number,
  userIdModified: number,
  created: LocalDateTime,
  modified: O.Option<LocalDateTime>,
  published: O.Option<LocalDateTime>,
  data: A1
};
export const withModInfoC = <A1 extends t.Mixed>(A1: A1): WithModInfoC<A1> => t.type({
  issuerId: t.number,
  userIdModified: t.number,
  created: LocalDateTimeFromIsoStringC,
  modified: optionFromNullable(LocalDateTimeFromIsoStringC),
  published: optionFromNullable(LocalDateTimeFromIsoStringC),
  data: A1
}) satisfies t.Type<WithModInfo<t.TypeOf<A1>>, unknown>;


export type WithModUserNameC<A1 extends t.Mixed> = t.TypeC<{
  modifyingUserName: t.StringC,
  data: A1
}>;
export type WithModUserName<A1> = {
  modifyingUserName: string,
  data: A1
};
export const withModUserNameC = <A1 extends t.Mixed>(A1: A1): WithModUserNameC<A1> => t.type({
  modifyingUserName: t.string,
  data: A1
}) satisfies t.Type<WithModUserName<t.TypeOf<A1>>, unknown>;


export type PublishedC<A1 extends t.Mixed> = t.TypeC<{
  _tag: t.LiteralC<`Published`>,
  data: WithIdC<A1>
}>;
export type Published<A1> = {
  _tag: `Published`,
  data: WithId<A1>
};
export const publishedC = <A1 extends t.Mixed>(A1: A1): PublishedC<A1> => t.type({
  _tag: t.literal(`Published`),
  data: withIdC(A1)
}) satisfies t.Type<Published<t.TypeOf<A1>>, unknown>;


export type DraftedC<A1 extends t.Mixed> = t.TypeC<{
  _tag: t.LiteralC<`Drafted`>,
  data: WithIdC<A1>,
  parentId: OptionFromNullableC<t.NumberC>
}>;
export type Drafted<A1> = {
  _tag: `Drafted`,
  data: WithId<A1>,
  parentId: O.Option<number>
};
export const draftedC = <A1 extends t.Mixed>(A1: A1): DraftedC<A1> => t.type({
  _tag: t.literal(`Drafted`),
  data: withIdC(A1),
  parentId: optionFromNullable(t.number)
}) satisfies t.Type<Drafted<t.TypeOf<A1>>, unknown>;


export const allWithStatusC = <A1 extends t.Mixed>(A1: A1) => [publishedC(A1), draftedC(A1)] as const;
export const allWithStatusNames = [`Published`, `Drafted`] as const;
export type WithStatusName = (typeof allWithStatusNames)[number];

export type WithStatusCU<A1 extends t.Mixed> = t.UnionC<[PublishedC<A1>, DraftedC<A1>]>;
export type WithStatusU<A1> = Published<A1> | Drafted<A1>;
export const WithStatusCU = <A1 extends t.Mixed>(A1: A1): WithStatusCU<A1> => t.union([publishedC(A1), draftedC(A1)]) satisfies t.Type<WithStatusU<t.TypeOf<A1>>, unknown>;



export type DeletedC<A1 extends t.Mixed> = t.TypeC<{
  _tag: t.LiteralC<`Deleted`>,
  data: WithIdC<A1>,
  parentId: OptionFromNullableC<t.NumberC>,
  deleted: LocalDateTimeFromIsoStringC
}>;
export type Deleted<A1> = {
  _tag: `Deleted`,
  data: WithId<A1>,
  parentId: O.Option<number>,
  deleted: LocalDateTime
};
export const deletedC = <A1 extends t.Mixed>(A1: A1): DeletedC<A1> => t.type({
  _tag: t.literal(`Deleted`),
  data: withIdC(A1),
  parentId: optionFromNullable(t.number),
  deleted: LocalDateTimeFromIsoStringC
}) satisfies t.Type<Deleted<t.TypeOf<A1>>, unknown>;


export const allWithStatusIncludingDeletedC = <A1 extends t.Mixed>(A1: A1) => [publishedC(A1), draftedC(A1), deletedC(A1)] as const;
export const allWithStatusIncludingDeletedNames = [`Published`, `Drafted`, `Deleted`] as const;
export type WithStatusIncludingDeletedName = (typeof allWithStatusIncludingDeletedNames)[number];

export type WithStatusIncludingDeletedCU<A1 extends t.Mixed> = t.UnionC<[PublishedC<A1>, DraftedC<A1>, DeletedC<A1>]>;
export type WithStatusIncludingDeletedU<A1> = Published<A1> | Drafted<A1> | Deleted<A1>;
export const WithStatusIncludingDeletedCU = <A1 extends t.Mixed>(A1: A1): WithStatusIncludingDeletedCU<A1> => t.union([publishedC(A1), draftedC(A1), deletedC(A1)]) satisfies t.Type<WithStatusIncludingDeletedU<t.TypeOf<A1>>, unknown>;



export type ToManyC<A1 extends t.Mixed, A2 extends t.Mixed> = t.TypeC<{
  one: A1,
  many: t.ReadonlyArrayC<A2>
}>;
export type ToMany<A1, A2> = {
  one: A1,
  many: ReadonlyArray<A2>
};
export const toManyC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2): ToManyC<A1, A2> => t.type({
  one: A1,
  many: t.readonlyArray(A2)
}) satisfies t.Type<ToMany<t.TypeOf<A1>, t.TypeOf<A2>>, unknown>;


