import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const semiannually = {
  _tag: `Semiannually`,
  id: 1,
  name: `Semiannually`
} as const;

export type SemiannuallyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Semiannually`>
}>;
export const semiannuallyTaggedC: SemiannuallyTaggedC = t.type({
  _tag: t.literal(`Semiannually`)
});
export type SemiannuallyTagged = t.TypeOf<SemiannuallyTaggedC>;
export type Semiannually = SemiannuallyTagged & typeof semiannually;
export type SemiannuallyC = t.Type<Semiannually, SemiannuallyTagged>;
export const semiannuallyC: SemiannuallyC = pipe(semiannuallyTaggedC, c => new t.Type<Semiannually, SemiannuallyTagged>(
  `Semiannually`,
  (u: unknown): u is Semiannually => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Semiannually> => pipe(c.decode(u), E.map(x => ({ ...x, ...semiannually }))),
  (x: Semiannually): SemiannuallyTagged => ({ ...x, _tag: `Semiannually`}),
)) satisfies t.Type<Semiannually, unknown>;


export const monthly = {
  _tag: `Monthly`,
  id: 2,
  name: `Monthly`
} as const;

export type MonthlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Monthly`>
}>;
export const monthlyTaggedC: MonthlyTaggedC = t.type({
  _tag: t.literal(`Monthly`)
});
export type MonthlyTagged = t.TypeOf<MonthlyTaggedC>;
export type Monthly = MonthlyTagged & typeof monthly;
export type MonthlyC = t.Type<Monthly, MonthlyTagged>;
export const monthlyC: MonthlyC = pipe(monthlyTaggedC, c => new t.Type<Monthly, MonthlyTagged>(
  `Monthly`,
  (u: unknown): u is Monthly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Monthly> => pipe(c.decode(u), E.map(x => ({ ...x, ...monthly }))),
  (x: Monthly): MonthlyTagged => ({ ...x, _tag: `Monthly`}),
)) satisfies t.Type<Monthly, unknown>;


export const annually = {
  _tag: `Annually`,
  id: 3,
  name: `Annually`
} as const;

export type AnnuallyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Annually`>
}>;
export const annuallyTaggedC: AnnuallyTaggedC = t.type({
  _tag: t.literal(`Annually`)
});
export type AnnuallyTagged = t.TypeOf<AnnuallyTaggedC>;
export type Annually = AnnuallyTagged & typeof annually;
export type AnnuallyC = t.Type<Annually, AnnuallyTagged>;
export const annuallyC: AnnuallyC = pipe(annuallyTaggedC, c => new t.Type<Annually, AnnuallyTagged>(
  `Annually`,
  (u: unknown): u is Annually => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Annually> => pipe(c.decode(u), E.map(x => ({ ...x, ...annually }))),
  (x: Annually): AnnuallyTagged => ({ ...x, _tag: `Annually`}),
)) satisfies t.Type<Annually, unknown>;


export const weekly = {
  _tag: `Weekly`,
  id: 4,
  name: `Weekly`
} as const;

export type WeeklyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Weekly`>
}>;
export const weeklyTaggedC: WeeklyTaggedC = t.type({
  _tag: t.literal(`Weekly`)
});
export type WeeklyTagged = t.TypeOf<WeeklyTaggedC>;
export type Weekly = WeeklyTagged & typeof weekly;
export type WeeklyC = t.Type<Weekly, WeeklyTagged>;
export const weeklyC: WeeklyC = pipe(weeklyTaggedC, c => new t.Type<Weekly, WeeklyTagged>(
  `Weekly`,
  (u: unknown): u is Weekly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Weekly> => pipe(c.decode(u), E.map(x => ({ ...x, ...weekly }))),
  (x: Weekly): WeeklyTagged => ({ ...x, _tag: `Weekly`}),
)) satisfies t.Type<Weekly, unknown>;


export const quarterly = {
  _tag: `Quarterly`,
  id: 5,
  name: `Quarterly`
} as const;

export type QuarterlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Quarterly`>
}>;
export const quarterlyTaggedC: QuarterlyTaggedC = t.type({
  _tag: t.literal(`Quarterly`)
});
export type QuarterlyTagged = t.TypeOf<QuarterlyTaggedC>;
export type Quarterly = QuarterlyTagged & typeof quarterly;
export type QuarterlyC = t.Type<Quarterly, QuarterlyTagged>;
export const quarterlyC: QuarterlyC = pipe(quarterlyTaggedC, c => new t.Type<Quarterly, QuarterlyTagged>(
  `Quarterly`,
  (u: unknown): u is Quarterly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Quarterly> => pipe(c.decode(u), E.map(x => ({ ...x, ...quarterly }))),
  (x: Quarterly): QuarterlyTagged => ({ ...x, _tag: `Quarterly`}),
)) satisfies t.Type<Quarterly, unknown>;


export const every2Years = {
  _tag: `Every2Years`,
  id: 6,
  name: `Every 2 years`
} as const;

export type Every2YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every2Years`>
}>;
export const every2YearsTaggedC: Every2YearsTaggedC = t.type({
  _tag: t.literal(`Every2Years`)
});
export type Every2YearsTagged = t.TypeOf<Every2YearsTaggedC>;
export type Every2Years = Every2YearsTagged & typeof every2Years;
export type Every2YearsC = t.Type<Every2Years, Every2YearsTagged>;
export const every2YearsC: Every2YearsC = pipe(every2YearsTaggedC, c => new t.Type<Every2Years, Every2YearsTagged>(
  `Every2Years`,
  (u: unknown): u is Every2Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every2Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every2Years }))),
  (x: Every2Years): Every2YearsTagged => ({ ...x, _tag: `Every2Years`}),
)) satisfies t.Type<Every2Years, unknown>;


export const every3Years = {
  _tag: `Every3Years`,
  id: 7,
  name: `Every 3 years`
} as const;

export type Every3YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every3Years`>
}>;
export const every3YearsTaggedC: Every3YearsTaggedC = t.type({
  _tag: t.literal(`Every3Years`)
});
export type Every3YearsTagged = t.TypeOf<Every3YearsTaggedC>;
export type Every3Years = Every3YearsTagged & typeof every3Years;
export type Every3YearsC = t.Type<Every3Years, Every3YearsTagged>;
export const every3YearsC: Every3YearsC = pipe(every3YearsTaggedC, c => new t.Type<Every3Years, Every3YearsTagged>(
  `Every3Years`,
  (u: unknown): u is Every3Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every3Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every3Years }))),
  (x: Every3Years): Every3YearsTagged => ({ ...x, _tag: `Every3Years`}),
)) satisfies t.Type<Every3Years, unknown>;


export const every4Years = {
  _tag: `Every4Years`,
  id: 8,
  name: `Every 4 years`
} as const;

export type Every4YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every4Years`>
}>;
export const every4YearsTaggedC: Every4YearsTaggedC = t.type({
  _tag: t.literal(`Every4Years`)
});
export type Every4YearsTagged = t.TypeOf<Every4YearsTaggedC>;
export type Every4Years = Every4YearsTagged & typeof every4Years;
export type Every4YearsC = t.Type<Every4Years, Every4YearsTagged>;
export const every4YearsC: Every4YearsC = pipe(every4YearsTaggedC, c => new t.Type<Every4Years, Every4YearsTagged>(
  `Every4Years`,
  (u: unknown): u is Every4Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every4Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every4Years }))),
  (x: Every4Years): Every4YearsTagged => ({ ...x, _tag: `Every4Years`}),
)) satisfies t.Type<Every4Years, unknown>;


export const every5Years = {
  _tag: `Every5Years`,
  id: 9,
  name: `Every 5 years`
} as const;

export type Every5YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every5Years`>
}>;
export const every5YearsTaggedC: Every5YearsTaggedC = t.type({
  _tag: t.literal(`Every5Years`)
});
export type Every5YearsTagged = t.TypeOf<Every5YearsTaggedC>;
export type Every5Years = Every5YearsTagged & typeof every5Years;
export type Every5YearsC = t.Type<Every5Years, Every5YearsTagged>;
export const every5YearsC: Every5YearsC = pipe(every5YearsTaggedC, c => new t.Type<Every5Years, Every5YearsTagged>(
  `Every5Years`,
  (u: unknown): u is Every5Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every5Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every5Years }))),
  (x: Every5Years): Every5YearsTagged => ({ ...x, _tag: `Every5Years`}),
)) satisfies t.Type<Every5Years, unknown>;


export const every7Years = {
  _tag: `Every7Years`,
  id: 10,
  name: `Every 7 years`
} as const;

export type Every7YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every7Years`>
}>;
export const every7YearsTaggedC: Every7YearsTaggedC = t.type({
  _tag: t.literal(`Every7Years`)
});
export type Every7YearsTagged = t.TypeOf<Every7YearsTaggedC>;
export type Every7Years = Every7YearsTagged & typeof every7Years;
export type Every7YearsC = t.Type<Every7Years, Every7YearsTagged>;
export const every7YearsC: Every7YearsC = pipe(every7YearsTaggedC, c => new t.Type<Every7Years, Every7YearsTagged>(
  `Every7Years`,
  (u: unknown): u is Every7Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every7Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every7Years }))),
  (x: Every7Years): Every7YearsTagged => ({ ...x, _tag: `Every7Years`}),
)) satisfies t.Type<Every7Years, unknown>;


export const every8Years = {
  _tag: `Every8Years`,
  id: 11,
  name: `Every 8 years`
} as const;

export type Every8YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every8Years`>
}>;
export const every8YearsTaggedC: Every8YearsTaggedC = t.type({
  _tag: t.literal(`Every8Years`)
});
export type Every8YearsTagged = t.TypeOf<Every8YearsTaggedC>;
export type Every8Years = Every8YearsTagged & typeof every8Years;
export type Every8YearsC = t.Type<Every8Years, Every8YearsTagged>;
export const every8YearsC: Every8YearsC = pipe(every8YearsTaggedC, c => new t.Type<Every8Years, Every8YearsTagged>(
  `Every8Years`,
  (u: unknown): u is Every8Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every8Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every8Years }))),
  (x: Every8Years): Every8YearsTagged => ({ ...x, _tag: `Every8Years`}),
)) satisfies t.Type<Every8Years, unknown>;


export const biweekly = {
  _tag: `Biweekly`,
  id: 12,
  name: `Biweekly`
} as const;

export type BiweeklyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Biweekly`>
}>;
export const biweeklyTaggedC: BiweeklyTaggedC = t.type({
  _tag: t.literal(`Biweekly`)
});
export type BiweeklyTagged = t.TypeOf<BiweeklyTaggedC>;
export type Biweekly = BiweeklyTagged & typeof biweekly;
export type BiweeklyC = t.Type<Biweekly, BiweeklyTagged>;
export const biweeklyC: BiweeklyC = pipe(biweeklyTaggedC, c => new t.Type<Biweekly, BiweeklyTagged>(
  `Biweekly`,
  (u: unknown): u is Biweekly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Biweekly> => pipe(c.decode(u), E.map(x => ({ ...x, ...biweekly }))),
  (x: Biweekly): BiweeklyTagged => ({ ...x, _tag: `Biweekly`}),
)) satisfies t.Type<Biweekly, unknown>;


export const changeable = {
  _tag: `Changeable`,
  id: 13,
  name: `Changeable`
} as const;

export type ChangeableTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Changeable`>
}>;
export const changeableTaggedC: ChangeableTaggedC = t.type({
  _tag: t.literal(`Changeable`)
});
export type ChangeableTagged = t.TypeOf<ChangeableTaggedC>;
export type Changeable = ChangeableTagged & typeof changeable;
export type ChangeableC = t.Type<Changeable, ChangeableTagged>;
export const changeableC: ChangeableC = pipe(changeableTaggedC, c => new t.Type<Changeable, ChangeableTagged>(
  `Changeable`,
  (u: unknown): u is Changeable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Changeable> => pipe(c.decode(u), E.map(x => ({ ...x, ...changeable }))),
  (x: Changeable): ChangeableTagged => ({ ...x, _tag: `Changeable`}),
)) satisfies t.Type<Changeable, unknown>;


export const daily = {
  _tag: `Daily`,
  id: 14,
  name: `Daily`
} as const;

export type DailyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Daily`>
}>;
export const dailyTaggedC: DailyTaggedC = t.type({
  _tag: t.literal(`Daily`)
});
export type DailyTagged = t.TypeOf<DailyTaggedC>;
export type Daily = DailyTagged & typeof daily;
export type DailyC = t.Type<Daily, DailyTagged>;
export const dailyC: DailyC = pipe(dailyTaggedC, c => new t.Type<Daily, DailyTagged>(
  `Daily`,
  (u: unknown): u is Daily => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Daily> => pipe(c.decode(u), E.map(x => ({ ...x, ...daily }))),
  (x: Daily): DailyTagged => ({ ...x, _tag: `Daily`}),
)) satisfies t.Type<Daily, unknown>;


export const termMode = {
  _tag: `TermMode`,
  id: 15,
  name: `Term mode`
} as const;

export type TermModeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TermMode`>
}>;
export const termModeTaggedC: TermModeTaggedC = t.type({
  _tag: t.literal(`TermMode`)
});
export type TermModeTagged = t.TypeOf<TermModeTaggedC>;
export type TermMode = TermModeTagged & typeof termMode;
export type TermModeC = t.Type<TermMode, TermModeTagged>;
export const termModeC: TermModeC = pipe(termModeTaggedC, c => new t.Type<TermMode, TermModeTagged>(
  `TermMode`,
  (u: unknown): u is TermMode => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TermMode> => pipe(c.decode(u), E.map(x => ({ ...x, ...termMode }))),
  (x: TermMode): TermModeTagged => ({ ...x, _tag: `TermMode`}),
)) satisfies t.Type<TermMode, unknown>;


export const interestAtMaturity = {
  _tag: `InterestAtMaturity`,
  id: 16,
  name: `Interest at maturity`
} as const;

export type InterestAtMaturityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InterestAtMaturity`>
}>;
export const interestAtMaturityTaggedC: InterestAtMaturityTaggedC = t.type({
  _tag: t.literal(`InterestAtMaturity`)
});
export type InterestAtMaturityTagged = t.TypeOf<InterestAtMaturityTaggedC>;
export type InterestAtMaturity = InterestAtMaturityTagged & typeof interestAtMaturity;
export type InterestAtMaturityC = t.Type<InterestAtMaturity, InterestAtMaturityTagged>;
export const interestAtMaturityC: InterestAtMaturityC = pipe(interestAtMaturityTaggedC, c => new t.Type<InterestAtMaturity, InterestAtMaturityTagged>(
  `InterestAtMaturity`,
  (u: unknown): u is InterestAtMaturity => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InterestAtMaturity> => pipe(c.decode(u), E.map(x => ({ ...x, ...interestAtMaturity }))),
  (x: InterestAtMaturity): InterestAtMaturityTagged => ({ ...x, _tag: `InterestAtMaturity`}),
)) satisfies t.Type<InterestAtMaturity, unknown>;


export const bimonthly = {
  _tag: `Bimonthly`,
  id: 17,
  name: `Bimonthly`
} as const;

export type BimonthlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Bimonthly`>
}>;
export const bimonthlyTaggedC: BimonthlyTaggedC = t.type({
  _tag: t.literal(`Bimonthly`)
});
export type BimonthlyTagged = t.TypeOf<BimonthlyTaggedC>;
export type Bimonthly = BimonthlyTagged & typeof bimonthly;
export type BimonthlyC = t.Type<Bimonthly, BimonthlyTagged>;
export const bimonthlyC: BimonthlyC = pipe(bimonthlyTaggedC, c => new t.Type<Bimonthly, BimonthlyTagged>(
  `Bimonthly`,
  (u: unknown): u is Bimonthly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Bimonthly> => pipe(c.decode(u), E.map(x => ({ ...x, ...bimonthly }))),
  (x: Bimonthly): BimonthlyTagged => ({ ...x, _tag: `Bimonthly`}),
)) satisfies t.Type<Bimonthly, unknown>;


export const every13Weeks = {
  _tag: `Every13Weeks`,
  id: 18,
  name: `Every 13 weeks`
} as const;

export type Every13WeeksTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every13Weeks`>
}>;
export const every13WeeksTaggedC: Every13WeeksTaggedC = t.type({
  _tag: t.literal(`Every13Weeks`)
});
export type Every13WeeksTagged = t.TypeOf<Every13WeeksTaggedC>;
export type Every13Weeks = Every13WeeksTagged & typeof every13Weeks;
export type Every13WeeksC = t.Type<Every13Weeks, Every13WeeksTagged>;
export const every13WeeksC: Every13WeeksC = pipe(every13WeeksTaggedC, c => new t.Type<Every13Weeks, Every13WeeksTagged>(
  `Every13Weeks`,
  (u: unknown): u is Every13Weeks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every13Weeks> => pipe(c.decode(u), E.map(x => ({ ...x, ...every13Weeks }))),
  (x: Every13Weeks): Every13WeeksTagged => ({ ...x, _tag: `Every13Weeks`}),
)) satisfies t.Type<Every13Weeks, unknown>;


export const irregular = {
  _tag: `Irregular`,
  id: 19,
  name: `Irregular`
} as const;

export type IrregularTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Irregular`>
}>;
export const irregularTaggedC: IrregularTaggedC = t.type({
  _tag: t.literal(`Irregular`)
});
export type IrregularTagged = t.TypeOf<IrregularTaggedC>;
export type Irregular = IrregularTagged & typeof irregular;
export type IrregularC = t.Type<Irregular, IrregularTagged>;
export const irregularC: IrregularC = pipe(irregularTaggedC, c => new t.Type<Irregular, IrregularTagged>(
  `Irregular`,
  (u: unknown): u is Irregular => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Irregular> => pipe(c.decode(u), E.map(x => ({ ...x, ...irregular }))),
  (x: Irregular): IrregularTagged => ({ ...x, _tag: `Irregular`}),
)) satisfies t.Type<Irregular, unknown>;


export const every28Days = {
  _tag: `Every28Days`,
  id: 20,
  name: `Every 28 days`
} as const;

export type Every28DaysTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every28Days`>
}>;
export const every28DaysTaggedC: Every28DaysTaggedC = t.type({
  _tag: t.literal(`Every28Days`)
});
export type Every28DaysTagged = t.TypeOf<Every28DaysTaggedC>;
export type Every28Days = Every28DaysTagged & typeof every28Days;
export type Every28DaysC = t.Type<Every28Days, Every28DaysTagged>;
export const every28DaysC: Every28DaysC = pipe(every28DaysTaggedC, c => new t.Type<Every28Days, Every28DaysTagged>(
  `Every28Days`,
  (u: unknown): u is Every28Days => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every28Days> => pipe(c.decode(u), E.map(x => ({ ...x, ...every28Days }))),
  (x: Every28Days): Every28DaysTagged => ({ ...x, _tag: `Every28Days`}),
)) satisfies t.Type<Every28Days, unknown>;


export const every35Days = {
  _tag: `Every35Days`,
  id: 21,
  name: `Every 35 days`
} as const;

export type Every35DaysTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every35Days`>
}>;
export const every35DaysTaggedC: Every35DaysTaggedC = t.type({
  _tag: t.literal(`Every35Days`)
});
export type Every35DaysTagged = t.TypeOf<Every35DaysTaggedC>;
export type Every35Days = Every35DaysTagged & typeof every35Days;
export type Every35DaysC = t.Type<Every35Days, Every35DaysTagged>;
export const every35DaysC: Every35DaysC = pipe(every35DaysTaggedC, c => new t.Type<Every35Days, Every35DaysTagged>(
  `Every35Days`,
  (u: unknown): u is Every35Days => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every35Days> => pipe(c.decode(u), E.map(x => ({ ...x, ...every35Days }))),
  (x: Every35Days): Every35DaysTagged => ({ ...x, _tag: `Every35Days`}),
)) satisfies t.Type<Every35Days, unknown>;


export const every26Weeks = {
  _tag: `Every26Weeks`,
  id: 22,
  name: `Every 26 weeks`
} as const;

export type Every26WeeksTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every26Weeks`>
}>;
export const every26WeeksTaggedC: Every26WeeksTaggedC = t.type({
  _tag: t.literal(`Every26Weeks`)
});
export type Every26WeeksTagged = t.TypeOf<Every26WeeksTaggedC>;
export type Every26Weeks = Every26WeeksTagged & typeof every26Weeks;
export type Every26WeeksC = t.Type<Every26Weeks, Every26WeeksTagged>;
export const every26WeeksC: Every26WeeksC = pipe(every26WeeksTaggedC, c => new t.Type<Every26Weeks, Every26WeeksTagged>(
  `Every26Weeks`,
  (u: unknown): u is Every26Weeks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every26Weeks> => pipe(c.decode(u), E.map(x => ({ ...x, ...every26Weeks }))),
  (x: Every26Weeks): Every26WeeksTagged => ({ ...x, _tag: `Every26Weeks`}),
)) satisfies t.Type<Every26Weeks, unknown>;


export const tiedToPrime = {
  _tag: `TiedToPrime`,
  id: 23,
  name: `Tied to prime`
} as const;

export type TiedToPrimeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TiedToPrime`>
}>;
export const tiedToPrimeTaggedC: TiedToPrimeTaggedC = t.type({
  _tag: t.literal(`TiedToPrime`)
});
export type TiedToPrimeTagged = t.TypeOf<TiedToPrimeTaggedC>;
export type TiedToPrime = TiedToPrimeTagged & typeof tiedToPrime;
export type TiedToPrimeC = t.Type<TiedToPrime, TiedToPrimeTagged>;
export const tiedToPrimeC: TiedToPrimeC = pipe(tiedToPrimeTaggedC, c => new t.Type<TiedToPrime, TiedToPrimeTagged>(
  `TiedToPrime`,
  (u: unknown): u is TiedToPrime => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TiedToPrime> => pipe(c.decode(u), E.map(x => ({ ...x, ...tiedToPrime }))),
  (x: TiedToPrime): TiedToPrimeTagged => ({ ...x, _tag: `TiedToPrime`}),
)) satisfies t.Type<TiedToPrime, unknown>;


export const oneTime = {
  _tag: `OneTime`,
  id: 24,
  name: `One time`
} as const;

export type OneTimeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OneTime`>
}>;
export const oneTimeTaggedC: OneTimeTaggedC = t.type({
  _tag: t.literal(`OneTime`)
});
export type OneTimeTagged = t.TypeOf<OneTimeTaggedC>;
export type OneTime = OneTimeTagged & typeof oneTime;
export type OneTimeC = t.Type<OneTime, OneTimeTagged>;
export const oneTimeC: OneTimeC = pipe(oneTimeTaggedC, c => new t.Type<OneTime, OneTimeTagged>(
  `OneTime`,
  (u: unknown): u is OneTime => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OneTime> => pipe(c.decode(u), E.map(x => ({ ...x, ...oneTime }))),
  (x: OneTime): OneTimeTagged => ({ ...x, _tag: `OneTime`}),
)) satisfies t.Type<OneTime, unknown>;


export const every10Years = {
  _tag: `Every10Years`,
  id: 25,
  name: `Every 10 years`
} as const;

export type Every10YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every10Years`>
}>;
export const every10YearsTaggedC: Every10YearsTaggedC = t.type({
  _tag: t.literal(`Every10Years`)
});
export type Every10YearsTagged = t.TypeOf<Every10YearsTaggedC>;
export type Every10Years = Every10YearsTagged & typeof every10Years;
export type Every10YearsC = t.Type<Every10Years, Every10YearsTagged>;
export const every10YearsC: Every10YearsC = pipe(every10YearsTaggedC, c => new t.Type<Every10Years, Every10YearsTagged>(
  `Every10Years`,
  (u: unknown): u is Every10Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every10Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every10Years }))),
  (x: Every10Years): Every10YearsTagged => ({ ...x, _tag: `Every10Years`}),
)) satisfies t.Type<Every10Years, unknown>;


export const mandatoryPut = {
  _tag: `MandatoryPut`,
  id: 26,
  name: `Mandatory put`
} as const;

export type MandatoryPutTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MandatoryPut`>
}>;
export const mandatoryPutTaggedC: MandatoryPutTaggedC = t.type({
  _tag: t.literal(`MandatoryPut`)
});
export type MandatoryPutTagged = t.TypeOf<MandatoryPutTaggedC>;
export type MandatoryPut = MandatoryPutTagged & typeof mandatoryPut;
export type MandatoryPutC = t.Type<MandatoryPut, MandatoryPutTagged>;
export const mandatoryPutC: MandatoryPutC = pipe(mandatoryPutTaggedC, c => new t.Type<MandatoryPut, MandatoryPutTagged>(
  `MandatoryPut`,
  (u: unknown): u is MandatoryPut => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MandatoryPut> => pipe(c.decode(u), E.map(x => ({ ...x, ...mandatoryPut }))),
  (x: MandatoryPut): MandatoryPutTagged => ({ ...x, _tag: `MandatoryPut`}),
)) satisfies t.Type<MandatoryPut, unknown>;


export const every52Weeks = {
  _tag: `Every52Weeks`,
  id: 27,
  name: `Every 52 weeks`
} as const;

export type Every52WeeksTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every52Weeks`>
}>;
export const every52WeeksTaggedC: Every52WeeksTaggedC = t.type({
  _tag: t.literal(`Every52Weeks`)
});
export type Every52WeeksTagged = t.TypeOf<Every52WeeksTaggedC>;
export type Every52Weeks = Every52WeeksTagged & typeof every52Weeks;
export type Every52WeeksC = t.Type<Every52Weeks, Every52WeeksTagged>;
export const every52WeeksC: Every52WeeksC = pipe(every52WeeksTaggedC, c => new t.Type<Every52Weeks, Every52WeeksTagged>(
  `Every52Weeks`,
  (u: unknown): u is Every52Weeks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every52Weeks> => pipe(c.decode(u), E.map(x => ({ ...x, ...every52Weeks }))),
  (x: Every52Weeks): Every52WeeksTagged => ({ ...x, _tag: `Every52Weeks`}),
)) satisfies t.Type<Every52Weeks, unknown>;


export const whenInterestAdjustsCommercialPaper = {
  _tag: `WhenInterestAdjustsCommercialPaper`,
  id: 28,
  name: `When interest adjusts-commercial paper`
} as const;

export type WhenInterestAdjustsCommercialPaperTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WhenInterestAdjustsCommercialPaper`>
}>;
export const whenInterestAdjustsCommercialPaperTaggedC: WhenInterestAdjustsCommercialPaperTaggedC = t.type({
  _tag: t.literal(`WhenInterestAdjustsCommercialPaper`)
});
export type WhenInterestAdjustsCommercialPaperTagged = t.TypeOf<WhenInterestAdjustsCommercialPaperTaggedC>;
export type WhenInterestAdjustsCommercialPaper = WhenInterestAdjustsCommercialPaperTagged & typeof whenInterestAdjustsCommercialPaper;
export type WhenInterestAdjustsCommercialPaperC = t.Type<WhenInterestAdjustsCommercialPaper, WhenInterestAdjustsCommercialPaperTagged>;
export const whenInterestAdjustsCommercialPaperC: WhenInterestAdjustsCommercialPaperC = pipe(whenInterestAdjustsCommercialPaperTaggedC, c => new t.Type<WhenInterestAdjustsCommercialPaper, WhenInterestAdjustsCommercialPaperTagged>(
  `WhenInterestAdjustsCommercialPaper`,
  (u: unknown): u is WhenInterestAdjustsCommercialPaper => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WhenInterestAdjustsCommercialPaper> => pipe(c.decode(u), E.map(x => ({ ...x, ...whenInterestAdjustsCommercialPaper }))),
  (x: WhenInterestAdjustsCommercialPaper): WhenInterestAdjustsCommercialPaperTagged => ({ ...x, _tag: `WhenInterestAdjustsCommercialPaper`}),
)) satisfies t.Type<WhenInterestAdjustsCommercialPaper, unknown>;


export const certainYearsOnly = {
  _tag: `CertainYearsOnly`,
  id: 29,
  name: `Certain years only`
} as const;

export type CertainYearsOnlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CertainYearsOnly`>
}>;
export const certainYearsOnlyTaggedC: CertainYearsOnlyTaggedC = t.type({
  _tag: t.literal(`CertainYearsOnly`)
});
export type CertainYearsOnlyTagged = t.TypeOf<CertainYearsOnlyTaggedC>;
export type CertainYearsOnly = CertainYearsOnlyTagged & typeof certainYearsOnly;
export type CertainYearsOnlyC = t.Type<CertainYearsOnly, CertainYearsOnlyTagged>;
export const certainYearsOnlyC: CertainYearsOnlyC = pipe(certainYearsOnlyTaggedC, c => new t.Type<CertainYearsOnly, CertainYearsOnlyTagged>(
  `CertainYearsOnly`,
  (u: unknown): u is CertainYearsOnly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CertainYearsOnly> => pipe(c.decode(u), E.map(x => ({ ...x, ...certainYearsOnly }))),
  (x: CertainYearsOnly): CertainYearsOnlyTagged => ({ ...x, _tag: `CertainYearsOnly`}),
)) satisfies t.Type<CertainYearsOnly, unknown>;


export const underCertainCircumstances = {
  _tag: `UnderCertainCircumstances`,
  id: 30,
  name: `Under certain circumstances`
} as const;

export type UnderCertainCircumstancesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UnderCertainCircumstances`>
}>;
export const underCertainCircumstancesTaggedC: UnderCertainCircumstancesTaggedC = t.type({
  _tag: t.literal(`UnderCertainCircumstances`)
});
export type UnderCertainCircumstancesTagged = t.TypeOf<UnderCertainCircumstancesTaggedC>;
export type UnderCertainCircumstances = UnderCertainCircumstancesTagged & typeof underCertainCircumstances;
export type UnderCertainCircumstancesC = t.Type<UnderCertainCircumstances, UnderCertainCircumstancesTagged>;
export const underCertainCircumstancesC: UnderCertainCircumstancesC = pipe(underCertainCircumstancesTaggedC, c => new t.Type<UnderCertainCircumstances, UnderCertainCircumstancesTagged>(
  `UnderCertainCircumstances`,
  (u: unknown): u is UnderCertainCircumstances => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnderCertainCircumstances> => pipe(c.decode(u), E.map(x => ({ ...x, ...underCertainCircumstances }))),
  (x: UnderCertainCircumstances): UnderCertainCircumstancesTagged => ({ ...x, _tag: `UnderCertainCircumstances`}),
)) satisfies t.Type<UnderCertainCircumstances, unknown>;


export const every15Years = {
  _tag: `Every15Years`,
  id: 31,
  name: `Every 15 years`
} as const;

export type Every15YearsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Every15Years`>
}>;
export const every15YearsTaggedC: Every15YearsTaggedC = t.type({
  _tag: t.literal(`Every15Years`)
});
export type Every15YearsTagged = t.TypeOf<Every15YearsTaggedC>;
export type Every15Years = Every15YearsTagged & typeof every15Years;
export type Every15YearsC = t.Type<Every15Years, Every15YearsTagged>;
export const every15YearsC: Every15YearsC = pipe(every15YearsTaggedC, c => new t.Type<Every15Years, Every15YearsTagged>(
  `Every15Years`,
  (u: unknown): u is Every15Years => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Every15Years> => pipe(c.decode(u), E.map(x => ({ ...x, ...every15Years }))),
  (x: Every15Years): Every15YearsTagged => ({ ...x, _tag: `Every15Years`}),
)) satisfies t.Type<Every15Years, unknown>;


export const singleInterestPayment = {
  _tag: `SingleInterestPayment`,
  id: 32,
  name: `Single Interest Payment`
} as const;

export type SingleInterestPaymentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SingleInterestPayment`>
}>;
export const singleInterestPaymentTaggedC: SingleInterestPaymentTaggedC = t.type({
  _tag: t.literal(`SingleInterestPayment`)
});
export type SingleInterestPaymentTagged = t.TypeOf<SingleInterestPaymentTaggedC>;
export type SingleInterestPayment = SingleInterestPaymentTagged & typeof singleInterestPayment;
export type SingleInterestPaymentC = t.Type<SingleInterestPayment, SingleInterestPaymentTagged>;
export const singleInterestPaymentC: SingleInterestPaymentC = pipe(singleInterestPaymentTaggedC, c => new t.Type<SingleInterestPayment, SingleInterestPaymentTagged>(
  `SingleInterestPayment`,
  (u: unknown): u is SingleInterestPayment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SingleInterestPayment> => pipe(c.decode(u), E.map(x => ({ ...x, ...singleInterestPayment }))),
  (x: SingleInterestPayment): SingleInterestPaymentTagged => ({ ...x, _tag: `SingleInterestPayment`}),
)) satisfies t.Type<SingleInterestPayment, unknown>;


export const allInterestPaymentFrequencyC = [semiannuallyC, monthlyC, annuallyC, weeklyC, quarterlyC, every2YearsC, every3YearsC, every4YearsC, every5YearsC, every7YearsC, every8YearsC, biweeklyC, changeableC, dailyC, termModeC, interestAtMaturityC, bimonthlyC, every13WeeksC, irregularC, every28DaysC, every35DaysC, every26WeeksC, tiedToPrimeC, oneTimeC, every10YearsC, mandatoryPutC, every52WeeksC, whenInterestAdjustsCommercialPaperC, certainYearsOnlyC, underCertainCircumstancesC, every15YearsC, singleInterestPaymentC] as const;
export const allInterestPaymentFrequencyNames = [`Semiannually`, `Monthly`, `Annually`, `Weekly`, `Quarterly`, `Every2Years`, `Every3Years`, `Every4Years`, `Every5Years`, `Every7Years`, `Every8Years`, `Biweekly`, `Changeable`, `Daily`, `TermMode`, `InterestAtMaturity`, `Bimonthly`, `Every13Weeks`, `Irregular`, `Every28Days`, `Every35Days`, `Every26Weeks`, `TiedToPrime`, `OneTime`, `Every10Years`, `MandatoryPut`, `Every52Weeks`, `WhenInterestAdjustsCommercialPaper`, `CertainYearsOnly`, `UnderCertainCircumstances`, `Every15Years`, `SingleInterestPayment`] as const;
export type InterestPaymentFrequencyName = (typeof allInterestPaymentFrequencyNames)[number];

export type InterestPaymentFrequencyCU = t.UnionC<[SemiannuallyC, MonthlyC, AnnuallyC, WeeklyC, QuarterlyC, Every2YearsC, Every3YearsC, Every4YearsC, Every5YearsC, Every7YearsC, Every8YearsC, BiweeklyC, ChangeableC, DailyC, TermModeC, InterestAtMaturityC, BimonthlyC, Every13WeeksC, IrregularC, Every28DaysC, Every35DaysC, Every26WeeksC, TiedToPrimeC, OneTimeC, Every10YearsC, MandatoryPutC, Every52WeeksC, WhenInterestAdjustsCommercialPaperC, CertainYearsOnlyC, UnderCertainCircumstancesC, Every15YearsC, SingleInterestPaymentC]>;
export type InterestPaymentFrequencyU = Semiannually | Monthly | Annually | Weekly | Quarterly | Every2Years | Every3Years | Every4Years | Every5Years | Every7Years | Every8Years | Biweekly | Changeable | Daily | TermMode | InterestAtMaturity | Bimonthly | Every13Weeks | Irregular | Every28Days | Every35Days | Every26Weeks | TiedToPrime | OneTime | Every10Years | MandatoryPut | Every52Weeks | WhenInterestAdjustsCommercialPaper | CertainYearsOnly | UnderCertainCircumstances | Every15Years | SingleInterestPayment;
export const InterestPaymentFrequencyCU: InterestPaymentFrequencyCU = t.union([semiannuallyC, monthlyC, annuallyC, weeklyC, quarterlyC, every2YearsC, every3YearsC, every4YearsC, every5YearsC, every7YearsC, every8YearsC, biweeklyC, changeableC, dailyC, termModeC, interestAtMaturityC, bimonthlyC, every13WeeksC, irregularC, every28DaysC, every35DaysC, every26WeeksC, tiedToPrimeC, oneTimeC, every10YearsC, mandatoryPutC, every52WeeksC, whenInterestAdjustsCommercialPaperC, certainYearsOnlyC, underCertainCircumstancesC, every15YearsC, singleInterestPaymentC]) satisfies t.Type<InterestPaymentFrequencyU, unknown>;

export const interestPaymentFrequencyOrd: Ord.Ord<InterestPaymentFrequencyU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allInterestPaymentFrequency = [semiannually, monthly, annually, weekly, quarterly, every2Years, every3Years, every4Years, every5Years, every7Years, every8Years, biweekly, changeable, daily, termMode, interestAtMaturity, bimonthly, every13Weeks, irregular, every28Days, every35Days, every26Weeks, tiedToPrime, oneTime, every10Years, mandatoryPut, every52Weeks, whenInterestAdjustsCommercialPaper, certainYearsOnly, underCertainCircumstances, every15Years, singleInterestPayment] as const;
export type InterestPaymentFrequencyMap<A> = { [K in InterestPaymentFrequencyName]: A };


