import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { StateInfoCU as imported13_StateInfoCU, StateInfoU as imported6_StateInfoU, StateInfoCU as imported12_StateInfoCU } from "../domaintables/states";
import * as O from "fp-ts/lib/Option";

export type AddressC = t.TypeC<{
  address: OptionFromNullableC<t.StringC>,
  city: OptionFromNullableC<t.StringC>,
  state: imported13_StateInfoCU,
  postalCode: OptionFromNullableC<t.StringC>
}>;
export type Address = {
  address: O.Option<string>,
  city: O.Option<string>,
  state: imported6_StateInfoU,
  postalCode: O.Option<string>
};
export const addressC: AddressC = t.type({
  address: optionFromNullable(t.string),
  city: optionFromNullable(t.string),
  state: imported12_StateInfoCU,
  postalCode: optionFromNullable(t.string)
}) satisfies t.Type<Address, unknown>;


