import { flow, identity } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Refinement } from "fp-ts/lib/Refinement";
import * as t from "io-ts";

import type { Match } from "./types";

export type Whitespace = " " | "\t" | "\r" | "\n";

export type PrefixWith<
  A extends string,
  B extends string,
> = `${A}${B}`;

export type StripPrefix<Pre extends string, S extends string>
  = S extends `${Pre}${infer Tail}`
  ? Tail
  : S;

export const literal: <V extends Match.Primitive>(v: V) => V = identity;
export const prefix = <Pre extends string>(b: Pre) => <S extends string>(a: S): PrefixWith<Pre, S> => `${b}${a}`;
export const postfix = <Post extends string>(b: Post) => <S extends string>(a: S): PrefixWith<S, Post> => `${a}${b}`;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const toLower = <S extends string>(s: S): Lowercase<S> => s.toLowerCase() as Lowercase<S>;

export const stripPrefix =
  <Pre extends string>(prefix_: Pre) =>
    <S extends string>(str: S): StripPrefix<Pre, S> =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (str.startsWith(prefix_)
        ? str.replace(prefix_, "")
        : str) as StripPrefix<Pre, S>;

export const fromCodec = <C extends t.Any>(codec: C): Refinement<unknown, t.TypeOf<C>> => codec.is;

/** @internal */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const someTag = (O.some(null) as Match.AnySome)._tag;
/** @internal */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const noneTag = (O.none as Match.AnyNone)._tag;

export const optionCodec = t.union([
  t.readonly(t.type({ _tag: t.literal(someTag), value: t.any })),
  t.readonly(t.type({ _tag: t.literal(noneTag) })),
]);

export const refinementFor = {
  /* eslint-disable id-blacklist */
  undefined: fromCodec(t.undefined),
  string: fromCodec(t.string),
  /* eslint-enable id-blacklist */
  null: fromCodec(t.null),
  bigint: fromCodec(t.bigint),
  struct: fromCodec(t.readonly(t.UnknownRecord)),
  record: fromCodec(t.UnknownRecord),
  array: fromCodec(t.UnknownArray),
  void: fromCodec(t.void),
  int: fromCodec(t.Int),
  unknown: fromCodec(t.unknown),
  readonlyArray: fromCodec(t.readonlyArray(t.unknown)),
  literal: flow(t.literal, fromCodec),
} as const;
