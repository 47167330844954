import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { ActivityReportDataPointCU as imported3_ActivityReportDataPointCU, ActivityReportDataPointU as imported1_ActivityReportDataPointU, ActivityReportDataPointCU as imported2_ActivityReportDataPointCU } from "../domaintables/activityReportDataPoints";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

export type ActivityReportTemplateC = t.TypeC<{
  name: t.StringC,
  description: OptionFromNullableC<t.StringC>,
  dataPoints: ReadonlyNonEmptyArrayType<imported3_ActivityReportDataPointCU>
}>;
export type ActivityReportTemplate = {
  name: string,
  description: O.Option<string>,
  dataPoints: RNEA.ReadonlyNonEmptyArray<imported1_ActivityReportDataPointU>
};
export const activityReportTemplateC: ActivityReportTemplateC = t.type({
  name: t.string,
  description: optionFromNullable(t.string),
  dataPoints: readonlyNonEmptyArrayC(imported2_ActivityReportDataPointCU)
}) satisfies t.Type<ActivityReportTemplate, unknown>;


export type ActivityReportTemplatePostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  description: OptionFromNullableC<t.StringC>,
  dataPoints: ReadonlyNonEmptyArrayType<t.NumberC>
}>;
export type ActivityReportTemplatePost = {
  id: O.Option<number>,
  name: string,
  description: O.Option<string>,
  dataPoints: RNEA.ReadonlyNonEmptyArray<number>
};
export const activityReportTemplatePostC: ActivityReportTemplatePostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  description: optionFromNullable(t.string),
  dataPoints: readonlyNonEmptyArrayC(t.number)
}) satisfies t.Type<ActivityReportTemplatePost, unknown>;


