import * as t from "io-ts";

export type SiteTemplateC = t.TypeC<{
  primaryColor: t.StringC,
  secondaryColor: t.StringC,
  primaryForBg: t.BooleanC
}>;
export type SiteTemplate = {
  primaryColor: string,
  secondaryColor: string,
  primaryForBg: boolean
};
export const siteTemplateC: SiteTemplateC = t.type({
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean
}) satisfies t.Type<SiteTemplate, unknown>;


