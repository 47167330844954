import * as t from "io-ts";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type EventC = t.TypeC<{
  eventDate: OptionFromNullableC<LocalDateC>,
  endDate: OptionFromNullableC<LocalDateC>,
  eventTitle: t.StringC,
  eventBlurb: OptionFromNullableC<MarkdownC>
}>;
export type Event = {
  eventDate: O.Option<LocalDate>,
  endDate: O.Option<LocalDate>,
  eventTitle: string,
  eventBlurb: O.Option<Markdown>
};
export const eventC: EventC = t.type({
  eventDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  eventTitle: t.string,
  eventBlurb: optionFromNullable(markdownC)
}) satisfies t.Type<Event, unknown>;


