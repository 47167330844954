import type { Event, Scope, SeverityLevel } from "@sentry/browser";
import { browserTracingIntegration, captureException, getCurrentScope, init, withScope } from "@sentry/browser";
import { absurd, apply, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import semverSatisfies from "semver/functions/satisfies";

import type { BLConfigWithLog } from "./bondlink";
import { isDev } from "./bondlink";
import type { UserWithRoles } from "./generated/models/user";
import { Scrub } from "./scrub";
import * as Blang from "./util/blang";
import type { LogLevel } from "./util/log";

const hasExactErrorMessage = (message: string) => Blang.expr((event: Event): boolean =>
  event.message === message);

const hasPartialErrorMessage = (message: string) => Blang.expr((event: Event): boolean =>
  event.message?.includes(message) ?? false);

const hasBrowserName = (name: string) => Blang.expr((event: Event): boolean => {
  if (event.contexts?.["browser"]?.["name"]) {
    return event.contexts["browser"]["name"] === name;
  } else {
    return false;
  }
});

const hasBrowserVersionRange = (version: string) => Blang.expr((event: Event): boolean => {
  if (
    event.contexts?.["browser"]?.["version"]
    && typeof event.contexts["browser"]["version"] === "string"
  ) {
    return semverSatisfies(event.contexts["browser"]["version"], version);
  } else {
    return false;
  }
});

/* eslint-disable curly-quotes/no-straight-quotes */
const errorsToIgnore = Blang.or([
  hasPartialErrorMessage(
    "Non-Error promise rejection captured with value: Object Not Found Matching Id", // MS Scraper error
  ),
  hasExactErrorMessage(
    "Blocked 'script' from 'eval:'", // No information reported to sentry so I'm not sure where this comes from but it happens a lot - JDL
  ),
  Blang.and([
    hasBrowserName("Safari"),
    hasBrowserVersionRange("< 10.1"),
    hasExactErrorMessage(
      "Unexpected token '*'", // This is likely caused by Safari 10.0's lack of support for the ES2016 exponentiation operator (**). It's supported in Safari >= 10.1.
    ),
  ]),
]);
/* eslint-enable curly-quotes/no-straight-quotes */

const shouldIgnoreError = (error: Event) => pipe(errorsToIgnore, Blang.interpretWith(apply(error)));

type Property = "Corporate" | "Portals" | "Sites" | "Ssr";

const dsnLookup: { [key in Property]: string } = {
  Corporate: "https://5ef1c4e60fac40ccb7cba43ab9dac689@o60855.ingest.sentry.io/4505320042987520",
  Portals: "https://c64d3695a971481c82a2ba5956cc2b5d@o60855.ingest.sentry.io/130541",
  Sites: "https://d3c5b6701866cd94f3c5129f9608e2e6@o60855.ingest.sentry.io/4505846078046208",
  Ssr: "https://f48bb5b1a816419cb604ffade757e8b0@o60855.ingest.sentry.io/4505318579699712",
};

export const bootstrapSentry = (config: BLConfigWithLog, property: Property) => {
  if (!isDev(config)) {
    init({
      dsn: dsnLookup[property],
      release: config.commit,
      environment: config.environment._tag,
      beforeSend: (e) => {
        if (shouldIgnoreError(e)) {
          return null;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return Scrub.scrubData(e);
        }
      },
      integrations: [
        browserTracingIntegration({}),
      ],
    });

    O.map((u: UserWithRoles) => {
      getCurrentScope().setUser({ id: u.user.id.toString(), email: u.user.email });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
    })(O.fromNullable((globalThis as any).BLSession?.user));
  }
};

type BLErrorType = "BLError" | "BConsoleError";

export class BLError extends Error {
  message: string;
  arguments: unknown[];
  name: BLErrorType;

  constructor(message: string, args: unknown[], name: BLErrorType) {
    super(message);
    this.message = message;
    this.arguments = args;
    this.name = name;
  }
}

export const sentry = {
  capture: (error: BLError, level: LogLevel): void => {
    withScope((s: Scope) => {
      s.setLevel(levelToSeverity(level));
      captureException(error);
    });
  },
  captureMessage: (message: string, args: unknown[] = [], name: BLErrorType = "BLError", level: LogLevel = "error") =>
    sentry.capture(new BLError(message, args, name), level),
};

function levelToSeverity(level: LogLevel): SeverityLevel {
  switch (level) {
    case "fatal": return "fatal";
    case "error": return "error";
    case "warn": return "warning";
    case "info": return "info";
    case "debug": return "debug";
  }

  return absurd(level);
}
