import * as t from "io-ts";
import { RfpC as imported77_RfpC, Rfp as imported77_Rfp, rfpC as imported74_rfpC } from "./rfpBase";
import { SectorCU as imported43_SectorCU, SectorU as imported21_SectorU, SectorCU as imported42_SectorCU } from "../domaintables/sectors";
import { BondOfferingDetailsC as imported46_BondOfferingDetailsC, BondOfferingDetails as imported46_BondOfferingDetails, bondOfferingDetailsC as imported46_bondOfferingDetailsC } from "./bondOffering";
import { WithIdC as imported290_WithIdC, withIdC as imported291_withIdC, WithIdC as imported291_WithIdC, WithId as imported290_WithId, withIdC as imported290_withIdC, WithId as imported291_WithId } from "./threadThrough";
import { WithIssuer as imported13_WithIssuer, withIssuerC as imported13_withIssuerC, WithIssuer as imported12_WithIssuer, issuerC as imported70_issuerC, WithIssuerC as imported12_WithIssuerC, IssuerC as imported72_IssuerC, WithIssuerC as imported13_WithIssuerC, withIssuerC as imported12_withIssuerC, Issuer as imported72_Issuer } from "./issuer";

export type SectorDataC = t.TypeC<{
  sector: imported43_SectorCU,
  issuers: t.ReadonlyArrayC<imported72_IssuerC>,
  offerings: t.ReadonlyArrayC<imported12_WithIssuerC<imported290_WithIdC<imported46_BondOfferingDetailsC>>>,
  rfps: t.ReadonlyArrayC<imported13_WithIssuerC<imported291_WithIdC<imported77_RfpC>>>
}>;
export type SectorData = {
  sector: imported21_SectorU,
  issuers: ReadonlyArray<imported72_Issuer>,
  offerings: ReadonlyArray<imported12_WithIssuer<imported290_WithId<imported46_BondOfferingDetails>>>,
  rfps: ReadonlyArray<imported13_WithIssuer<imported291_WithId<imported77_Rfp>>>
};
export const sectorDataC: SectorDataC = t.type({
  sector: imported42_SectorCU,
  issuers: t.readonlyArray(imported70_issuerC),
  offerings: t.readonlyArray(imported12_withIssuerC(imported290_withIdC(imported46_bondOfferingDetailsC))),
  rfps: t.readonlyArray(imported13_withIssuerC(imported291_withIdC(imported74_rfpC)))
}) satisfies t.Type<SectorData, unknown>;


