import type { ReactNode } from "react";

import type { Html } from "@scripts/codecs/html";
import { type O } from "@scripts/fp-ts";
import { AnchorButtonWithChildren, ContactBondLinkAnchor } from "@scripts/react/components/Anchor";
import { klass } from "@scripts/react/util/classnames";
import type { PageRouting } from "@scripts/routes/routing/base";

import { ErrorLayout } from "./ErrorLayout";

export const NotFound = (props: { extraContent?: ReactNode, debugContent: O.Option<Html> }) =>
  <ErrorLayout
    header="Sorry, we couldn’t find the page you’re looking for."
    content={<>
      <p {...klass("large", "mb-0")}>
        We got a 404 error, which means this page has been moved or deleted, or
        the URL was mistyped. Double-check to make sure you have the correct URL,
        or <ContactBondLinkAnchor title="email us" /> for help if the problem persists.
      </p>
      {props.extraContent}
    </>}
    debugContent={props.debugContent}
  />;

export const NotFoundGoToRoute = (props: { route: PageRouting }) =>
  <AnchorButtonWithChildren
    target={"_self"}
    route={props.route}
    variant="primary"
    externalLinkLocation="none"
  >
    Go to {props.route.title()}
  </AnchorButtonWithChildren>;
