import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const callable = {
  _tag: `Callable`,
  id: 1,
  name: `Callable`
} as const;

export type CallableTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Callable`>
}>;
export const callableTaggedC: CallableTaggedC = t.type({
  _tag: t.literal(`Callable`)
});
export type CallableTagged = t.TypeOf<CallableTaggedC>;
export type Callable = CallableTagged & typeof callable;
export type CallableC = t.Type<Callable, CallableTagged>;
export const callableC: CallableC = pipe(callableTaggedC, c => new t.Type<Callable, CallableTagged>(
  `Callable`,
  (u: unknown): u is Callable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Callable> => pipe(c.decode(u), E.map(x => ({ ...x, ...callable }))),
  (x: Callable): CallableTagged => ({ ...x, _tag: `Callable`}),
)) satisfies t.Type<Callable, unknown>;


export const nonCallable = {
  _tag: `NonCallable`,
  id: 2,
  name: `Non-Callable`
} as const;

export type NonCallableTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NonCallable`>
}>;
export const nonCallableTaggedC: NonCallableTaggedC = t.type({
  _tag: t.literal(`NonCallable`)
});
export type NonCallableTagged = t.TypeOf<NonCallableTaggedC>;
export type NonCallable = NonCallableTagged & typeof nonCallable;
export type NonCallableC = t.Type<NonCallable, NonCallableTagged>;
export const nonCallableC: NonCallableC = pipe(nonCallableTaggedC, c => new t.Type<NonCallable, NonCallableTagged>(
  `NonCallable`,
  (u: unknown): u is NonCallable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NonCallable> => pipe(c.decode(u), E.map(x => ({ ...x, ...nonCallable }))),
  (x: NonCallable): NonCallableTagged => ({ ...x, _tag: `NonCallable`}),
)) satisfies t.Type<NonCallable, unknown>;


export const allCallStatusC = [callableC, nonCallableC] as const;
export const allCallStatusNames = [`Callable`, `NonCallable`] as const;
export type CallStatusName = (typeof allCallStatusNames)[number];

export type CallStatusCU = t.UnionC<[CallableC, NonCallableC]>;
export type CallStatusU = Callable | NonCallable;
export const CallStatusCU: CallStatusCU = t.union([callableC, nonCallableC]) satisfies t.Type<CallStatusU, unknown>;

export const callStatusOrd: Ord.Ord<CallStatusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCallStatus = [callable, nonCallable] as const;
export type CallStatusMap<A> = { [K in CallStatusName]: A };


