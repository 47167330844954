import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { BigNumberC, ReadonlyBig, bigNumberC } from "../../Big/bigNumberC";

export type IpreoHoldingsC = t.TypeC<{
  ownerName: t.StringC,
  parHeld: BigNumberC
}>;
export type IpreoHoldings = {
  ownerName: string,
  parHeld: ReadonlyBig
};
export const ipreoHoldingsC: IpreoHoldingsC = t.type({
  ownerName: t.string,
  parHeld: bigNumberC
}) satisfies t.Type<IpreoHoldings, unknown>;


export type HoldingsWithCompanyDataC = t.TypeC<{
  holdings: t.ReadonlyArrayC<IpreoHoldingsC>,
  ipreoToBLCompanies: t.RecordC<t.StringC, ReadonlyNonEmptyArrayType<t.StringC>>
}>;
export type HoldingsWithCompanyData = {
  holdings: ReadonlyArray<IpreoHoldings>,
  ipreoToBLCompanies: Record<string, RNEA.ReadonlyNonEmptyArray<string>>
};
export const holdingsWithCompanyDataC: HoldingsWithCompanyDataC = t.type({
  holdings: t.readonlyArray(ipreoHoldingsC),
  ipreoToBLCompanies: t.record(t.string, readonlyNonEmptyArrayC(t.string))
}) satisfies t.Type<HoldingsWithCompanyData, unknown>;


