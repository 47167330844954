import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const daily = {
  _tag: `daily`
} as const;

export type DailyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`daily`>
}>;
export const dailyTaggedC: DailyTaggedC = t.type({
  _tag: t.literal(`daily`)
});
export type DailyTagged = t.TypeOf<DailyTaggedC>;
export type Daily = DailyTagged & typeof daily;
export type DailyC = t.Type<Daily, DailyTagged>;
export const dailyC: DailyC = pipe(dailyTaggedC, c => new t.Type<Daily, DailyTagged>(
  `daily`,
  (u: unknown): u is Daily => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Daily> => pipe(c.decode(u), E.map(x => ({ ...x, ...daily }))),
  (x: Daily): DailyTagged => ({ ...x, _tag: `daily`}),
)) satisfies t.Type<Daily, unknown>;


export const weekly = {
  _tag: `weekly`
} as const;

export type WeeklyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`weekly`>
}>;
export const weeklyTaggedC: WeeklyTaggedC = t.type({
  _tag: t.literal(`weekly`)
});
export type WeeklyTagged = t.TypeOf<WeeklyTaggedC>;
export type Weekly = WeeklyTagged & typeof weekly;
export type WeeklyC = t.Type<Weekly, WeeklyTagged>;
export const weeklyC: WeeklyC = pipe(weeklyTaggedC, c => new t.Type<Weekly, WeeklyTagged>(
  `weekly`,
  (u: unknown): u is Weekly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Weekly> => pipe(c.decode(u), E.map(x => ({ ...x, ...weekly }))),
  (x: Weekly): WeeklyTagged => ({ ...x, _tag: `weekly`}),
)) satisfies t.Type<Weekly, unknown>;


export const monthly = {
  _tag: `monthly`
} as const;

export type MonthlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`monthly`>
}>;
export const monthlyTaggedC: MonthlyTaggedC = t.type({
  _tag: t.literal(`monthly`)
});
export type MonthlyTagged = t.TypeOf<MonthlyTaggedC>;
export type Monthly = MonthlyTagged & typeof monthly;
export type MonthlyC = t.Type<Monthly, MonthlyTagged>;
export const monthlyC: MonthlyC = pipe(monthlyTaggedC, c => new t.Type<Monthly, MonthlyTagged>(
  `monthly`,
  (u: unknown): u is Monthly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Monthly> => pipe(c.decode(u), E.map(x => ({ ...x, ...monthly }))),
  (x: Monthly): MonthlyTagged => ({ ...x, _tag: `monthly`}),
)) satisfies t.Type<Monthly, unknown>;


export const allAnalyticsIntervalC = [dailyC, weeklyC, monthlyC] as const;
export const allAnalyticsIntervalNames = [`daily`, `weekly`, `monthly`] as const;
export type AnalyticsIntervalName = (typeof allAnalyticsIntervalNames)[number];

export type AnalyticsIntervalCU = t.UnionC<[DailyC, WeeklyC, MonthlyC]>;
export type AnalyticsIntervalU = Daily | Weekly | Monthly;
export const AnalyticsIntervalCU: AnalyticsIntervalCU = t.union([dailyC, weeklyC, monthlyC]) satisfies t.Type<AnalyticsIntervalU, unknown>;

export const analyticsIntervalOrd: Ord.Ord<AnalyticsIntervalU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allAnalyticsInterval = [daily, weekly, monthly] as const;
export type AnalyticsIntervalMap<A> = { [K in AnalyticsIntervalName]: A };


