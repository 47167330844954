import * as t from "io-ts";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

export type EmmaSubcategoryC = t.TypeC<{
  id: t.NumberC,
  name: t.StringC
}>;
export type EmmaSubcategory = {
  id: number,
  name: string
};
export const emmaSubcategoryC: EmmaSubcategoryC = t.type({
  id: t.number,
  name: t.string
}) satisfies t.Type<EmmaSubcategory, unknown>;


export type EmmaDateQualifierC = t.TypeC<{
  id: t.NumberC,
  name: t.StringC,
  requiresBegin: t.BooleanC,
  requiresEnd: t.BooleanC,
  requiresAsOf: t.BooleanC
}>;
export type EmmaDateQualifier = {
  id: number,
  name: string,
  requiresBegin: boolean,
  requiresEnd: boolean,
  requiresAsOf: boolean
};
export const emmaDateQualifierC: EmmaDateQualifierC = t.type({
  id: t.number,
  name: t.string,
  requiresBegin: t.boolean,
  requiresEnd: t.boolean,
  requiresAsOf: t.boolean
}) satisfies t.Type<EmmaDateQualifier, unknown>;


export type EmmaCategoryC = t.TypeC<{
  id: t.NumberC,
  name: t.StringC,
  requiresDescription: t.BooleanC,
  subcategories: t.ReadonlyArrayC<EmmaSubcategoryC>,
  dateQualifiers: ReadonlyNonEmptyArrayType<EmmaDateQualifierC>
}>;
export type EmmaCategory = {
  id: number,
  name: string,
  requiresDescription: boolean,
  subcategories: ReadonlyArray<EmmaSubcategory>,
  dateQualifiers: RNEA.ReadonlyNonEmptyArray<EmmaDateQualifier>
};
export const emmaCategoryC: EmmaCategoryC = t.type({
  id: t.number,
  name: t.string,
  requiresDescription: t.boolean,
  subcategories: t.readonlyArray(emmaSubcategoryC),
  dateQualifiers: readonlyNonEmptyArrayC(emmaDateQualifierC)
}) satisfies t.Type<EmmaCategory, unknown>;


export type EmmaCategoryGroupC = t.TypeC<{
  name: t.StringC,
  categories: ReadonlyNonEmptyArrayType<EmmaCategoryC>
}>;
export type EmmaCategoryGroup = {
  name: string,
  categories: RNEA.ReadonlyNonEmptyArray<EmmaCategory>
};
export const emmaCategoryGroupC: EmmaCategoryGroupC = t.type({
  name: t.string,
  categories: readonlyNonEmptyArrayC(emmaCategoryC)
}) satisfies t.Type<EmmaCategoryGroup, unknown>;


export type EmmaDisclosureTypeC = t.TypeC<{
  id: t.NumberC,
  name: t.StringC,
  groups: ReadonlyNonEmptyArrayType<EmmaCategoryGroupC>
}>;
export type EmmaDisclosureType = {
  id: number,
  name: string,
  groups: RNEA.ReadonlyNonEmptyArray<EmmaCategoryGroup>
};
export const emmaDisclosureTypeC: EmmaDisclosureTypeC = t.type({
  id: t.number,
  name: t.string,
  groups: readonlyNonEmptyArrayC(emmaCategoryGroupC)
}) satisfies t.Type<EmmaDisclosureType, unknown>;


