import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const generalObligation = {
  _tag: `GeneralObligation`,
  id: 1,
  name: `General Obligation`
} as const;

export type GeneralObligationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`GeneralObligation`>
}>;
export const generalObligationTaggedC: GeneralObligationTaggedC = t.type({
  _tag: t.literal(`GeneralObligation`)
});
export type GeneralObligationTagged = t.TypeOf<GeneralObligationTaggedC>;
export type GeneralObligation = GeneralObligationTagged & typeof generalObligation;
export type GeneralObligationC = t.Type<GeneralObligation, GeneralObligationTagged>;
export const generalObligationC: GeneralObligationC = pipe(generalObligationTaggedC, c => new t.Type<GeneralObligation, GeneralObligationTagged>(
  `GeneralObligation`,
  (u: unknown): u is GeneralObligation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GeneralObligation> => pipe(c.decode(u), E.map(x => ({ ...x, ...generalObligation }))),
  (x: GeneralObligation): GeneralObligationTagged => ({ ...x, _tag: `GeneralObligation`}),
)) satisfies t.Type<GeneralObligation, unknown>;


export const revenue = {
  _tag: `Revenue`,
  id: 2,
  name: `Revenue`
} as const;

export type RevenueTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Revenue`>
}>;
export const revenueTaggedC: RevenueTaggedC = t.type({
  _tag: t.literal(`Revenue`)
});
export type RevenueTagged = t.TypeOf<RevenueTaggedC>;
export type Revenue = RevenueTagged & typeof revenue;
export type RevenueC = t.Type<Revenue, RevenueTagged>;
export const revenueC: RevenueC = pipe(revenueTaggedC, c => new t.Type<Revenue, RevenueTagged>(
  `Revenue`,
  (u: unknown): u is Revenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Revenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...revenue }))),
  (x: Revenue): RevenueTagged => ({ ...x, _tag: `Revenue`}),
)) satisfies t.Type<Revenue, unknown>;


export const allRepaymentSourceC = [generalObligationC, revenueC] as const;
export const allRepaymentSourceNames = [`GeneralObligation`, `Revenue`] as const;
export type RepaymentSourceName = (typeof allRepaymentSourceNames)[number];

export type RepaymentSourceCU = t.UnionC<[GeneralObligationC, RevenueC]>;
export type RepaymentSourceU = GeneralObligation | Revenue;
export const RepaymentSourceCU: RepaymentSourceCU = t.union([generalObligationC, revenueC]) satisfies t.Type<RepaymentSourceU, unknown>;

export const repaymentSourceOrd: Ord.Ord<RepaymentSourceU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRepaymentSource = [generalObligation, revenue] as const;
export type RepaymentSourceMap<A> = { [K in RepaymentSourceName]: A };


