import { WithIdC as imported212_WithIdC, WithId as imported212_WithId, withIdC as imported212_withIdC } from "./threadThrough";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { relatedOfferingLinkC as imported9_relatedOfferingLinkC, RelatedRfpLinkC as imported9_RelatedRfpLinkC, PostRelatedC as imported38_PostRelatedC, relatedRfpLinkC as imported9_relatedRfpLinkC, PostRelated as imported38_PostRelated, RelatedRfpLink as imported9_RelatedRfpLink, PostRelatedC as imported39_PostRelatedC, postRelatedC as imported39_postRelatedC, RelatedOfferingLinkC as imported9_RelatedOfferingLinkC, postRelatedC as imported38_postRelatedC, RelatedOfferingLink as imported9_RelatedOfferingLink, PostRelated as imported39_PostRelated } from "./relatedContent";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { imageUploadResponseC as imported5_imageUploadResponseC, Media as imported53_Media, ImageUploadResponseC as imported5_ImageUploadResponseC, MediaC as imported54_MediaC, MediaC as imported53_MediaC, Media as imported54_Media, mediaC as imported50_mediaC, mediaC as imported51_mediaC, ImageUploadResponse as imported5_ImageUploadResponse } from "./media";
import { LocalDateC } from "../../codecs/localDate";

export type RoadShowC = t.TypeC<{
  mp3MediaId: t.NumberC,
  duration: t.NumberC,
  skippable: t.BooleanC,
  audio: t.BooleanC,
  title: t.StringC,
  disclaimer: MarkdownC,
  startAvailableDate: OptionFromNullableC<LocalDateC>,
  endAvailableDate: OptionFromNullableC<LocalDateC>,
  availableToIssuer: t.BooleanC,
  roadShowOrder: t.NumberC,
  hidden: t.BooleanC
}>;
export type RoadShow = {
  mp3MediaId: number,
  duration: number,
  skippable: boolean,
  audio: boolean,
  title: string,
  disclaimer: Markdown,
  startAvailableDate: O.Option<LocalDate>,
  endAvailableDate: O.Option<LocalDate>,
  availableToIssuer: boolean,
  roadShowOrder: number,
  hidden: boolean
};
export const roadShowC: RoadShowC = t.type({
  mp3MediaId: t.number,
  duration: t.number,
  skippable: t.boolean,
  audio: t.boolean,
  title: t.string,
  disclaimer: markdownC,
  startAvailableDate: optionFromNullable(LocalDateC),
  endAvailableDate: optionFromNullable(LocalDateC),
  availableToIssuer: t.boolean,
  roadShowOrder: t.number,
  hidden: t.boolean
}) satisfies t.Type<RoadShow, unknown>;


export type RoadShowSlideC = t.TypeC<{
  roadShowId: t.NumberC,
  mediaId: t.NumberC,
  startTime: t.NumberC,
  participants: OptionFromNullableC<t.StringC>,
  media: imported54_MediaC
}>;
export type RoadShowSlide = {
  roadShowId: number,
  mediaId: number,
  startTime: number,
  participants: O.Option<string>,
  media: imported54_Media
};
export const roadShowSlideC: RoadShowSlideC = t.type({
  roadShowId: t.number,
  mediaId: t.number,
  startTime: t.number,
  participants: optionFromNullable(t.string),
  media: imported51_mediaC
}) satisfies t.Type<RoadShowSlide, unknown>;


export type RoadShowDataC = t.TypeC<{
  show: RoadShowC,
  mp3: imported53_MediaC,
  slides: t.ReadonlyArrayC<imported212_WithIdC<RoadShowSlideC>>,
  offeringIds: t.ReadonlyArrayC<t.NumberC>,
  rfpIds: t.ReadonlyArrayC<t.NumberC>
}>;
export type RoadShowData = {
  show: RoadShow,
  mp3: imported53_Media,
  slides: ReadonlyArray<imported212_WithId<RoadShowSlide>>,
  offeringIds: ReadonlyArray<number>,
  rfpIds: ReadonlyArray<number>
};
export const roadShowDataC: RoadShowDataC = t.type({
  show: roadShowC,
  mp3: imported50_mediaC,
  slides: t.readonlyArray(imported212_withIdC(roadShowSlideC)),
  offeringIds: t.readonlyArray(t.number),
  rfpIds: t.readonlyArray(t.number)
}) satisfies t.Type<RoadShowData, unknown>;


export type RoadShowPostRelatedContentC = t.TypeC<{
  offerings: imported38_PostRelatedC<imported9_RelatedOfferingLinkC>,
  rfps: imported39_PostRelatedC<imported9_RelatedRfpLinkC>
}>;
export type RoadShowPostRelatedContent = {
  offerings: imported38_PostRelated<imported9_RelatedOfferingLink>,
  rfps: imported39_PostRelated<imported9_RelatedRfpLink>
};
export const roadShowPostRelatedContentC: RoadShowPostRelatedContentC = t.type({
  offerings: imported38_postRelatedC(imported9_relatedOfferingLinkC),
  rfps: imported39_postRelatedC(imported9_relatedRfpLinkC)
}) satisfies t.Type<RoadShowPostRelatedContent, unknown>;


export type RoadShowPostBodyC = t.TypeC<{
  id: t.NumberC,
  title: t.StringC,
  hidden: t.BooleanC,
  startAvailableDate: OptionFromNullableC<LocalDateC>,
  endAvailableDate: OptionFromNullableC<LocalDateC>,
  relatedContent: RoadShowPostRelatedContentC,
  esgRelated: t.BooleanC
}>;
export type RoadShowPostBody = {
  id: number,
  title: string,
  hidden: boolean,
  startAvailableDate: O.Option<LocalDate>,
  endAvailableDate: O.Option<LocalDate>,
  relatedContent: RoadShowPostRelatedContent,
  esgRelated: boolean
};
export const roadShowPostBodyC: RoadShowPostBodyC = t.type({
  id: t.number,
  title: t.string,
  hidden: t.boolean,
  startAvailableDate: optionFromNullable(LocalDateC),
  endAvailableDate: optionFromNullable(LocalDateC),
  relatedContent: roadShowPostRelatedContentC,
  esgRelated: t.boolean
}) satisfies t.Type<RoadShowPostBody, unknown>;


export type RoadShowProgressPostC = t.TypeC<{
  issuerId: t.NumberC,
  roadShowId: t.NumberC,
  sessionId: t.StringC,
  furthestSeek: t.NumberC,
  slidesViewed: t.NumberC
}>;
export type RoadShowProgressPost = {
  issuerId: number,
  roadShowId: number,
  sessionId: string,
  furthestSeek: number,
  slidesViewed: number
};
export const roadShowProgressPostC: RoadShowProgressPostC = t.type({
  issuerId: t.number,
  roadShowId: t.number,
  sessionId: t.string,
  furthestSeek: t.number,
  slidesViewed: t.number
}) satisfies t.Type<RoadShowProgressPost, unknown>;


export type RoadShowSlidePostC = t.TypeC<{
  startTime: t.NumberC,
  participants: OptionFromNullableC<t.StringC>,
  mediaId: OptionFromNullableC<t.NumberC>
}>;
export type RoadShowSlidePost = {
  startTime: number,
  participants: O.Option<string>,
  mediaId: O.Option<number>
};
export const roadShowSlidePostC: RoadShowSlidePostC = t.type({
  startTime: t.number,
  participants: optionFromNullable(t.string),
  mediaId: optionFromNullable(t.number)
}) satisfies t.Type<RoadShowSlidePost, unknown>;


export type RoadShowSlideMediaPostC = t.TypeC<{
  slide: RoadShowSlidePostC,
  media: imported5_ImageUploadResponseC
}>;
export type RoadShowSlideMediaPost = {
  slide: RoadShowSlidePost,
  media: imported5_ImageUploadResponse
};
export const roadShowSlideMediaPostC: RoadShowSlideMediaPostC = t.type({
  slide: roadShowSlidePostC,
  media: imported5_imageUploadResponseC
}) satisfies t.Type<RoadShowSlideMediaPost, unknown>;


export type RoadShowViewC = t.TypeC<{
  roadShowId: t.NumberC,
  userId: OptionFromNullableC<t.NumberC>,
  sessionId: OptionFromNullableC<t.StringC>,
  furthestSeek: t.NumberC,
  slidesViewed: t.NumberC
}>;
export type RoadShowView = {
  roadShowId: number,
  userId: O.Option<number>,
  sessionId: O.Option<string>,
  furthestSeek: number,
  slidesViewed: number
};
export const roadShowViewC: RoadShowViewC = t.type({
  roadShowId: t.number,
  userId: optionFromNullable(t.number),
  sessionId: optionFromNullable(t.string),
  furthestSeek: t.number,
  slidesViewed: t.number
}) satisfies t.Type<RoadShowView, unknown>;


