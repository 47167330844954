import * as t from "io-ts";
import { InvestorFocusCU as imported11_InvestorFocusCU, InvestorFocusU as imported5_InvestorFocusU, InvestorFocusCU as imported10_InvestorFocusCU } from "../domaintables/investorFocuses";
import * as O from "fp-ts/lib/Option";
import { InvestorTypeCU as imported9_InvestorTypeCU, InvestorTypeU as imported4_InvestorTypeU, InvestorTypeCU as imported8_InvestorTypeCU } from "../domaintables/investorTypes";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SectorCU as imported37_SectorCU, SectorU as imported18_SectorU, SectorCU as imported36_SectorCU } from "../domaintables/sectors";

export type InstitutionalInvestorInfoC = t.TypeC<{
  investorTypeId: OptionFromNullableC<imported9_InvestorTypeCU>,
  focuses: t.ReadonlyArrayC<imported11_InvestorFocusCU>,
  sectors: t.ReadonlyArrayC<imported37_SectorCU>
}>;
export type InstitutionalInvestorInfo = {
  investorTypeId: O.Option<imported4_InvestorTypeU>,
  focuses: ReadonlyArray<imported5_InvestorFocusU>,
  sectors: ReadonlyArray<imported18_SectorU>
};
export const institutionalInvestorInfoC: InstitutionalInvestorInfoC = t.type({
  investorTypeId: optionFromNullable(imported8_InvestorTypeCU),
  focuses: t.readonlyArray(imported10_InvestorFocusCU),
  sectors: t.readonlyArray(imported36_SectorCU)
}) satisfies t.Type<InstitutionalInvestorInfo, unknown>;


export type InstitutionalInvestorInfoPostC = t.TypeC<{
  investorTypeId: OptionFromNullableC<t.NumberC>,
  focusIds: t.ReadonlyArrayC<t.NumberC>,
  noFocus: t.BooleanC,
  sectorIds: t.ReadonlyArrayC<t.NumberC>,
  noSector: t.BooleanC
}>;
export type InstitutionalInvestorInfoPost = {
  investorTypeId: O.Option<number>,
  focusIds: ReadonlyArray<number>,
  noFocus: boolean,
  sectorIds: ReadonlyArray<number>,
  noSector: boolean
};
export const institutionalInvestorInfoPostC: InstitutionalInvestorInfoPostC = t.type({
  investorTypeId: optionFromNullable(t.number),
  focusIds: t.readonlyArray(t.number),
  noFocus: t.boolean,
  sectorIds: t.readonlyArray(t.number),
  noSector: t.boolean
}) satisfies t.Type<InstitutionalInvestorInfoPost, unknown>;


