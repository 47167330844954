import * as t from "io-ts";
import { TheseC, theseC } from "../../codecs/these";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MediaUploadResponseC as imported23_MediaUploadResponseC, MediaUploadResponse as imported23_MediaUploadResponse, mediaUploadResponseC as imported23_mediaUploadResponseC } from "./media";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { PublicationCU as imported3_PublicationCU, PublicationU as imported1_PublicationU, PublicationCU as imported2_PublicationCU } from "../domaintables/publications";
import * as Th from "fp-ts/lib/These";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type RecentInsightUrlC = t.TypeC<{
  url: t.StringC
}>;
export type RecentInsightUrl = {
  url: string
};
export const recentInsightUrlC: RecentInsightUrlC = t.type({
  url: t.string
}) satisfies t.Type<RecentInsightUrl, unknown>;


export type RecentInsightContentC = t.TypeC<{
  content: MarkdownC
}>;
export type RecentInsightContent = {
  content: Markdown
};
export const recentInsightContentC: RecentInsightContentC = t.type({
  content: markdownC
}) satisfies t.Type<RecentInsightContent, unknown>;


export type RecentInsightC = t.TypeC<{
  publication: imported3_PublicationCU,
  title: t.StringC,
  description: OptionFromNullableC<MarkdownC>,
  urlAndContent: TheseC<RecentInsightUrlC, RecentInsightContentC>,
  date: LocalDateC,
  image: OptionFromNullableC<imported23_MediaUploadResponseC>,
  mediaId: OptionFromNullableC<t.NumberC>
}>;
export type RecentInsight = {
  publication: imported1_PublicationU,
  title: string,
  description: O.Option<Markdown>,
  urlAndContent: Th.These<RecentInsightUrl, RecentInsightContent>,
  date: LocalDate,
  image: O.Option<imported23_MediaUploadResponse>,
  mediaId: O.Option<number>
};
export const recentInsightC: RecentInsightC = t.type({
  publication: imported2_PublicationCU,
  title: t.string,
  description: optionFromNullable(markdownC),
  urlAndContent: theseC(recentInsightUrlC, recentInsightContentC),
  date: LocalDateC,
  image: optionFromNullable(imported23_mediaUploadResponseC),
  mediaId: optionFromNullable(t.number)
}) satisfies t.Type<RecentInsight, unknown>;


