import * as A from "fp-ts/lib/Array";
import type * as NEA from "fp-ts/lib/NonEmptyArray";

import type { NonEmptyRecord } from "./Record";

export const fromNativeNonEmptyArray = <A>(a: [A, ...A[]]): NEA.NonEmptyArray<A> => {
  const [head, ...tail] = a;
  return A.prepend(head)(tail);
};

export const fromNonEmptyRecord = <A, K extends keyof A = keyof A>(r: NonEmptyRecord<A, K>): NEA.NonEmptyArray<A[K]> => {
  const [head, ...tail] = Object.values(r);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return A.prepend(head)(tail);
};
