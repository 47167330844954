import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const social = {
  _tag: `Social`,
  facebookUrl: `https://www.facebook.com/MuniBondLink`,
  linkedInUrl: `https://www.linkedin.com/company/bondlink/`,
  twitterUrl: `https://twitter.com/bondlink`
} as const;

export type SocialC = t.TypeC<{
  _tag: t.LiteralC<`Social`>,
  facebookUrl: t.LiteralC<`https://www.facebook.com/MuniBondLink`>,
  linkedInUrl: t.LiteralC<`https://www.linkedin.com/company/bondlink/`>,
  twitterUrl: t.LiteralC<`https://twitter.com/bondlink`>
}>;
export type Social = {
  _tag: `Social`,
  facebookUrl: `https://www.facebook.com/MuniBondLink`,
  linkedInUrl: `https://www.linkedin.com/company/bondlink/`,
  twitterUrl: `https://twitter.com/bondlink`
};
export const socialC: SocialC = t.type({
  _tag: t.literal(`Social`),
  facebookUrl: t.literal(`https://www.facebook.com/MuniBondLink`),
  linkedInUrl: t.literal(`https://www.linkedin.com/company/bondlink/`),
  twitterUrl: t.literal(`https://twitter.com/bondlink`)
}) satisfies t.Type<Social, unknown>;


export type BLS3ConfigC = t.TypeC<{
  cdn: t.StringC,
  issuerBucket: t.StringC,
  issuerBulkUploadBucket: t.StringC,
  issuerPrivateBucket: t.StringC,
  issuerBidSubmissionsBucket: t.StringC
}>;
export type BLS3Config = {
  cdn: string,
  issuerBucket: string,
  issuerBulkUploadBucket: string,
  issuerPrivateBucket: string,
  issuerBidSubmissionsBucket: string
};
export const bLS3ConfigC: BLS3ConfigC = t.type({
  cdn: t.string,
  issuerBucket: t.string,
  issuerBulkUploadBucket: t.string,
  issuerPrivateBucket: t.string,
  issuerBidSubmissionsBucket: t.string
}) satisfies t.Type<BLS3Config, unknown>;


export type BLSecReqsConfigC = t.TypeC<{
  sessExpDur: t.NumberC
}>;
export type BLSecReqsConfig = {
  sessExpDur: number
};
export const bLSecReqsConfigC: BLSecReqsConfigC = t.type({
  sessExpDur: t.number
}) satisfies t.Type<BLSecReqsConfig, unknown>;


export type BLPlasmicCMSConfigC = t.TypeC<{
  id: t.StringC,
  publicToken: t.StringC
}>;
export type BLPlasmicCMSConfig = {
  id: string,
  publicToken: string
};
export const bLPlasmicCMSConfigC: BLPlasmicCMSConfigC = t.type({
  id: t.string,
  publicToken: t.string
}) satisfies t.Type<BLPlasmicCMSConfig, unknown>;


export const DEV = {
  _tag: `DEV`
} as const;

export type DEVTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DEV`>
}>;
export const DEVTaggedC: DEVTaggedC = t.type({
  _tag: t.literal(`DEV`)
});
export type DEVTagged = t.TypeOf<DEVTaggedC>;
export type DEV = DEVTagged & typeof DEV;
export type DEVC = t.Type<DEV, DEVTagged>;
export const DEVC: DEVC = pipe(DEVTaggedC, c => new t.Type<DEV, DEVTagged>(
  `DEV`,
  (u: unknown): u is DEV => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DEV> => pipe(c.decode(u), E.map(x => ({ ...x, ...DEV }))),
  (x: DEV): DEVTagged => ({ ...x, _tag: `DEV`}),
)) satisfies t.Type<DEV, unknown>;


export const STAGING = {
  _tag: `STAGING`
} as const;

export type STAGINGTaggedC = t.TypeC<{
  _tag: t.LiteralC<`STAGING`>
}>;
export const STAGINGTaggedC: STAGINGTaggedC = t.type({
  _tag: t.literal(`STAGING`)
});
export type STAGINGTagged = t.TypeOf<STAGINGTaggedC>;
export type STAGING = STAGINGTagged & typeof STAGING;
export type STAGINGC = t.Type<STAGING, STAGINGTagged>;
export const STAGINGC: STAGINGC = pipe(STAGINGTaggedC, c => new t.Type<STAGING, STAGINGTagged>(
  `STAGING`,
  (u: unknown): u is STAGING => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, STAGING> => pipe(c.decode(u), E.map(x => ({ ...x, ...STAGING }))),
  (x: STAGING): STAGINGTagged => ({ ...x, _tag: `STAGING`}),
)) satisfies t.Type<STAGING, unknown>;


export const PRODUCTION = {
  _tag: `PRODUCTION`
} as const;

export type PRODUCTIONTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PRODUCTION`>
}>;
export const PRODUCTIONTaggedC: PRODUCTIONTaggedC = t.type({
  _tag: t.literal(`PRODUCTION`)
});
export type PRODUCTIONTagged = t.TypeOf<PRODUCTIONTaggedC>;
export type PRODUCTION = PRODUCTIONTagged & typeof PRODUCTION;
export type PRODUCTIONC = t.Type<PRODUCTION, PRODUCTIONTagged>;
export const PRODUCTIONC: PRODUCTIONC = pipe(PRODUCTIONTaggedC, c => new t.Type<PRODUCTION, PRODUCTIONTagged>(
  `PRODUCTION`,
  (u: unknown): u is PRODUCTION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PRODUCTION> => pipe(c.decode(u), E.map(x => ({ ...x, ...PRODUCTION }))),
  (x: PRODUCTION): PRODUCTIONTagged => ({ ...x, _tag: `PRODUCTION`}),
)) satisfies t.Type<PRODUCTION, unknown>;


export const allBLEnvC = [DEVC, STAGINGC, PRODUCTIONC] as const;
export const allBLEnvNames = [`DEV`, `STAGING`, `PRODUCTION`] as const;
export type BLEnvName = (typeof allBLEnvNames)[number];

export type BLEnvCU = t.UnionC<[DEVC, STAGINGC, PRODUCTIONC]>;
export type BLEnvU = DEV | STAGING | PRODUCTION;
export const BLEnvCU: BLEnvCU = t.union([DEVC, STAGINGC, PRODUCTIONC]) satisfies t.Type<BLEnvU, unknown>;

export const bLEnvOrd: Ord.Ord<BLEnvU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBLEnv = [DEV, STAGING, PRODUCTION] as const;
export type BLEnvMap<A> = { [K in BLEnvName]: A };


export type BLConfigC = t.TypeC<{
  csrf: t.StringC,
  baseUrl: t.StringC,
  currentBaseUrl: t.StringC,
  commit: t.StringC,
  environment: BLEnvCU,
  s3: BLS3ConfigC,
  recaptchaKey: t.StringC,
  recaptchaLoaded: t.BooleanC,
  emmaCDDetails: t.StringC,
  customerSuccessEmail: t.StringC,
  bondlinkPrimaryEmail: t.StringC,
  bondlinkPrimaryTermsUrl: t.StringC,
  secReqs: OptionFromNullableC<BLSecReqsConfigC>,
  plasmicCMS: BLPlasmicCMSConfigC
}>;
export type BLConfig = {
  csrf: string,
  baseUrl: string,
  currentBaseUrl: string,
  commit: string,
  environment: BLEnvU,
  s3: BLS3Config,
  recaptchaKey: string,
  recaptchaLoaded: boolean,
  emmaCDDetails: string,
  customerSuccessEmail: string,
  bondlinkPrimaryEmail: string,
  bondlinkPrimaryTermsUrl: string,
  secReqs: O.Option<BLSecReqsConfig>,
  plasmicCMS: BLPlasmicCMSConfig
};
export const bLConfigC: BLConfigC = t.type({
  csrf: t.string,
  baseUrl: t.string,
  currentBaseUrl: t.string,
  commit: t.string,
  environment: BLEnvCU,
  s3: bLS3ConfigC,
  recaptchaKey: t.string,
  recaptchaLoaded: t.boolean,
  emmaCDDetails: t.string,
  customerSuccessEmail: t.string,
  bondlinkPrimaryEmail: t.string,
  bondlinkPrimaryTermsUrl: t.string,
  secReqs: optionFromNullable(bLSecReqsConfigC),
  plasmicCMS: bLPlasmicCMSConfigC
}) satisfies t.Type<BLConfig, unknown>;


