import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { SortTypeCU as imported3_SortTypeCU, SortTypeU as imported1_SortTypeU, SortTypeCU as imported2_SortTypeCU } from "../domaintables/sortTypes";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

export type SortItemsC = t.TypeC<{
  sortType: imported3_SortTypeCU,
  sortIds: ReadonlyNonEmptyArrayType<t.NumberC>
}>;
export type SortItems = {
  sortType: imported1_SortTypeU,
  sortIds: RNEA.ReadonlyNonEmptyArray<number>
};
export const sortItemsC: SortItemsC = t.type({
  sortType: imported2_SortTypeCU,
  sortIds: readonlyNonEmptyArrayC(t.number)
}) satisfies t.Type<SortItems, unknown>;


