import { WithIdC as imported217_WithIdC, withIdC as imported217_withIdC, withIdC as imported218_withIdC, WithId as imported217_WithId, WithIdC as imported218_WithIdC, WithId as imported218_WithId } from "./threadThrough";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { dateQualifierC as imported16_dateQualifierC, DateQualifierC as imported18_DateQualifierC, DateQualifierC as imported16_DateQualifierC, DateQualifierC as imported14_DateQualifierC, DateQualifierC as imported15_DateQualifierC, DateQualifier as imported16_DateQualifier, DateQualifier as imported18_DateQualifier, DateQualifierC as imported17_DateQualifierC, dateQualifierC as imported15_dateQualifierC, dateQualifierC as imported14_dateQualifierC, dateQualifierC as imported18_dateQualifierC, DateQualifier as imported17_DateQualifier, DateQualifier as imported14_DateQualifier, dateQualifierC as imported19_dateQualifierC, DateQualifierC as imported19_DateQualifierC, DateQualifier as imported19_DateQualifier, DateQualifier as imported15_DateQualifier, dateQualifierC as imported17_dateQualifierC } from "./dateQualifier";

export type Cusip6C = t.TypeC<{
  name: t.StringC,
  cusip6: t.StringC
}>;
export type Cusip6 = {
  name: string,
  cusip6: string
};
export const cusip6C: Cusip6C = t.type({
  name: t.string,
  cusip6: t.string
}) satisfies t.Type<Cusip6, unknown>;


export type Cusip6PostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  cusip6: t.StringC
}>;
export type Cusip6PostBody = {
  id: O.Option<number>,
  name: string,
  cusip6: string
};
export const cusip6PostBodyC: Cusip6PostBodyC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  cusip6: t.string
}) satisfies t.Type<Cusip6PostBody, unknown>;


export type Cusip9C = t.TypeC<{
  bondName: t.StringC,
  cusip: t.StringC,
  hidden: t.BooleanC,
  issueDate: OptionFromNullableC<imported14_DateQualifierC>,
  datedDate: OptionFromNullableC<imported15_DateQualifierC>,
  maturityDate: OptionFromNullableC<imported16_DateQualifierC>
}>;
export type Cusip9 = {
  bondName: string,
  cusip: string,
  hidden: boolean,
  issueDate: O.Option<imported14_DateQualifier>,
  datedDate: O.Option<imported15_DateQualifier>,
  maturityDate: O.Option<imported16_DateQualifier>
};
export const cusip9C: Cusip9C = t.type({
  bondName: t.string,
  cusip: t.string,
  hidden: t.boolean,
  issueDate: optionFromNullable(imported14_dateQualifierC),
  datedDate: optionFromNullable(imported15_dateQualifierC),
  maturityDate: optionFromNullable(imported16_dateQualifierC)
}) satisfies t.Type<Cusip9, unknown>;


export type Cusip9PostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  bondName: t.StringC,
  cusip: t.StringC,
  hidden: t.BooleanC,
  issueDate: OptionFromNullableC<imported17_DateQualifierC>,
  datedDate: OptionFromNullableC<imported18_DateQualifierC>,
  maturityDate: OptionFromNullableC<imported19_DateQualifierC>
}>;
export type Cusip9PostBody = {
  id: O.Option<number>,
  bondName: string,
  cusip: string,
  hidden: boolean,
  issueDate: O.Option<imported17_DateQualifier>,
  datedDate: O.Option<imported18_DateQualifier>,
  maturityDate: O.Option<imported19_DateQualifier>
};
export const cusip9PostBodyC: Cusip9PostBodyC = t.type({
  id: optionFromNullable(t.number),
  bondName: t.string,
  cusip: t.string,
  hidden: t.boolean,
  issueDate: optionFromNullable(imported17_dateQualifierC),
  datedDate: optionFromNullable(imported18_dateQualifierC),
  maturityDate: optionFromNullable(imported19_dateQualifierC)
}) satisfies t.Type<Cusip9PostBody, unknown>;


export type HasCusip9C = t.TypeC<{
  hasCusip9: t.BooleanC,
  cusip9: Cusip9C
}>;
export type HasCusip9 = {
  hasCusip9: boolean,
  cusip9: Cusip9
};
export const hasCusip9C: HasCusip9C = t.type({
  hasCusip9: t.boolean,
  cusip9: cusip9C
}) satisfies t.Type<HasCusip9, unknown>;


export type EmmaLinksPageDataC = t.TypeC<{
  cusip9s: t.ReadonlyArrayC<imported217_WithIdC<Cusip9C>>,
  cusip6s: t.ReadonlyArrayC<imported218_WithIdC<Cusip6C>>,
  searchedCusip9: OptionFromNullableC<t.StringC>
}>;
export type EmmaLinksPageData = {
  cusip9s: ReadonlyArray<imported217_WithId<Cusip9>>,
  cusip6s: ReadonlyArray<imported218_WithId<Cusip6>>,
  searchedCusip9: O.Option<string>
};
export const emmaLinksPageDataC: EmmaLinksPageDataC = t.type({
  cusip9s: t.readonlyArray(imported217_withIdC(cusip9C)),
  cusip6s: t.readonlyArray(imported218_withIdC(cusip6C)),
  searchedCusip9: optionFromNullable(t.string)
}) satisfies t.Type<EmmaLinksPageData, unknown>;


