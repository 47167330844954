import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const password = {
  _tag: `password`,
  field: `password`
} as const;

export type PasswordTaggedC = t.TypeC<{
  _tag: t.LiteralC<`password`>
}>;
export const passwordTaggedC: PasswordTaggedC = t.type({
  _tag: t.literal(`password`)
});
export type PasswordTagged = t.TypeOf<PasswordTaggedC>;
export type Password = PasswordTagged & typeof password;
export type PasswordC = t.Type<Password, PasswordTagged>;
export const passwordC: PasswordC = pipe(passwordTaggedC, c => new t.Type<Password, PasswordTagged>(
  `password`,
  (u: unknown): u is Password => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Password> => pipe(c.decode(u), E.map(x => ({ ...x, ...password }))),
  (x: Password): PasswordTagged => ({ ...x, _tag: `password`}),
)) satisfies t.Type<Password, unknown>;


export const passwordConfirm = {
  _tag: `passwordConfirm`,
  field: `passwordConfirm`
} as const;

export type PasswordConfirmTaggedC = t.TypeC<{
  _tag: t.LiteralC<`passwordConfirm`>
}>;
export const passwordConfirmTaggedC: PasswordConfirmTaggedC = t.type({
  _tag: t.literal(`passwordConfirm`)
});
export type PasswordConfirmTagged = t.TypeOf<PasswordConfirmTaggedC>;
export type PasswordConfirm = PasswordConfirmTagged & typeof passwordConfirm;
export type PasswordConfirmC = t.Type<PasswordConfirm, PasswordConfirmTagged>;
export const passwordConfirmC: PasswordConfirmC = pipe(passwordConfirmTaggedC, c => new t.Type<PasswordConfirm, PasswordConfirmTagged>(
  `passwordConfirm`,
  (u: unknown): u is PasswordConfirm => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PasswordConfirm> => pipe(c.decode(u), E.map(x => ({ ...x, ...passwordConfirm }))),
  (x: PasswordConfirm): PasswordConfirmTagged => ({ ...x, _tag: `passwordConfirm`}),
)) satisfies t.Type<PasswordConfirm, unknown>;


export const newPassword = {
  _tag: `newPassword`,
  field: `newPassword`
} as const;

export type NewPasswordTaggedC = t.TypeC<{
  _tag: t.LiteralC<`newPassword`>
}>;
export const newPasswordTaggedC: NewPasswordTaggedC = t.type({
  _tag: t.literal(`newPassword`)
});
export type NewPasswordTagged = t.TypeOf<NewPasswordTaggedC>;
export type NewPassword = NewPasswordTagged & typeof newPassword;
export type NewPasswordC = t.Type<NewPassword, NewPasswordTagged>;
export const newPasswordC: NewPasswordC = pipe(newPasswordTaggedC, c => new t.Type<NewPassword, NewPasswordTagged>(
  `newPassword`,
  (u: unknown): u is NewPassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewPassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...newPassword }))),
  (x: NewPassword): NewPasswordTagged => ({ ...x, _tag: `newPassword`}),
)) satisfies t.Type<NewPassword, unknown>;


export const newPasswordConfirm = {
  _tag: `newPasswordConfirm`,
  field: `newPasswordConfirm`
} as const;

export type NewPasswordConfirmTaggedC = t.TypeC<{
  _tag: t.LiteralC<`newPasswordConfirm`>
}>;
export const newPasswordConfirmTaggedC: NewPasswordConfirmTaggedC = t.type({
  _tag: t.literal(`newPasswordConfirm`)
});
export type NewPasswordConfirmTagged = t.TypeOf<NewPasswordConfirmTaggedC>;
export type NewPasswordConfirm = NewPasswordConfirmTagged & typeof newPasswordConfirm;
export type NewPasswordConfirmC = t.Type<NewPasswordConfirm, NewPasswordConfirmTagged>;
export const newPasswordConfirmC: NewPasswordConfirmC = pipe(newPasswordConfirmTaggedC, c => new t.Type<NewPasswordConfirm, NewPasswordConfirmTagged>(
  `newPasswordConfirm`,
  (u: unknown): u is NewPasswordConfirm => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewPasswordConfirm> => pipe(c.decode(u), E.map(x => ({ ...x, ...newPasswordConfirm }))),
  (x: NewPasswordConfirm): NewPasswordConfirmTagged => ({ ...x, _tag: `newPasswordConfirm`}),
)) satisfies t.Type<NewPasswordConfirm, unknown>;


export const salt = {
  _tag: `salt`,
  field: `salt`
} as const;

export type SaltTaggedC = t.TypeC<{
  _tag: t.LiteralC<`salt`>
}>;
export const saltTaggedC: SaltTaggedC = t.type({
  _tag: t.literal(`salt`)
});
export type SaltTagged = t.TypeOf<SaltTaggedC>;
export type Salt = SaltTagged & typeof salt;
export type SaltC = t.Type<Salt, SaltTagged>;
export const saltC: SaltC = pipe(saltTaggedC, c => new t.Type<Salt, SaltTagged>(
  `salt`,
  (u: unknown): u is Salt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Salt> => pipe(c.decode(u), E.map(x => ({ ...x, ...salt }))),
  (x: Salt): SaltTagged => ({ ...x, _tag: `salt`}),
)) satisfies t.Type<Salt, unknown>;


export const publishPassword = {
  _tag: `publishPassword`,
  field: `publishPassword`
} as const;

export type PublishPasswordTaggedC = t.TypeC<{
  _tag: t.LiteralC<`publishPassword`>
}>;
export const publishPasswordTaggedC: PublishPasswordTaggedC = t.type({
  _tag: t.literal(`publishPassword`)
});
export type PublishPasswordTagged = t.TypeOf<PublishPasswordTaggedC>;
export type PublishPassword = PublishPasswordTagged & typeof publishPassword;
export type PublishPasswordC = t.Type<PublishPassword, PublishPasswordTagged>;
export const publishPasswordC: PublishPasswordC = pipe(publishPasswordTaggedC, c => new t.Type<PublishPassword, PublishPasswordTagged>(
  `publishPassword`,
  (u: unknown): u is PublishPassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublishPassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...publishPassword }))),
  (x: PublishPassword): PublishPasswordTagged => ({ ...x, _tag: `publishPassword`}),
)) satisfies t.Type<PublishPassword, unknown>;


export const publishSalt = {
  _tag: `publishSalt`,
  field: `publishSalt`
} as const;

export type PublishSaltTaggedC = t.TypeC<{
  _tag: t.LiteralC<`publishSalt`>
}>;
export const publishSaltTaggedC: PublishSaltTaggedC = t.type({
  _tag: t.literal(`publishSalt`)
});
export type PublishSaltTagged = t.TypeOf<PublishSaltTaggedC>;
export type PublishSalt = PublishSaltTagged & typeof publishSalt;
export type PublishSaltC = t.Type<PublishSalt, PublishSaltTagged>;
export const publishSaltC: PublishSaltC = pipe(publishSaltTaggedC, c => new t.Type<PublishSalt, PublishSaltTagged>(
  `publishSalt`,
  (u: unknown): u is PublishSalt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublishSalt> => pipe(c.decode(u), E.map(x => ({ ...x, ...publishSalt }))),
  (x: PublishSalt): PublishSaltTagged => ({ ...x, _tag: `publishSalt`}),
)) satisfies t.Type<PublishSalt, unknown>;


export const confirmPassword = {
  _tag: `confirmPassword`,
  field: `confirmPassword`
} as const;

export type ConfirmPasswordTaggedC = t.TypeC<{
  _tag: t.LiteralC<`confirmPassword`>
}>;
export const confirmPasswordTaggedC: ConfirmPasswordTaggedC = t.type({
  _tag: t.literal(`confirmPassword`)
});
export type ConfirmPasswordTagged = t.TypeOf<ConfirmPasswordTaggedC>;
export type ConfirmPassword = ConfirmPasswordTagged & typeof confirmPassword;
export type ConfirmPasswordC = t.Type<ConfirmPassword, ConfirmPasswordTagged>;
export const confirmPasswordC: ConfirmPasswordC = pipe(confirmPasswordTaggedC, c => new t.Type<ConfirmPassword, ConfirmPasswordTagged>(
  `confirmPassword`,
  (u: unknown): u is ConfirmPassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ConfirmPassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...confirmPassword }))),
  (x: ConfirmPassword): ConfirmPasswordTagged => ({ ...x, _tag: `confirmPassword`}),
)) satisfies t.Type<ConfirmPassword, unknown>;


export const paymentToken = {
  _tag: `paymentToken`,
  field: `paymentToken`
} as const;

export type PaymentTokenTaggedC = t.TypeC<{
  _tag: t.LiteralC<`paymentToken`>
}>;
export const paymentTokenTaggedC: PaymentTokenTaggedC = t.type({
  _tag: t.literal(`paymentToken`)
});
export type PaymentTokenTagged = t.TypeOf<PaymentTokenTaggedC>;
export type PaymentToken = PaymentTokenTagged & typeof paymentToken;
export type PaymentTokenC = t.Type<PaymentToken, PaymentTokenTagged>;
export const paymentTokenC: PaymentTokenC = pipe(paymentTokenTaggedC, c => new t.Type<PaymentToken, PaymentTokenTagged>(
  `paymentToken`,
  (u: unknown): u is PaymentToken => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PaymentToken> => pipe(c.decode(u), E.map(x => ({ ...x, ...paymentToken }))),
  (x: PaymentToken): PaymentTokenTagged => ({ ...x, _tag: `paymentToken`}),
)) satisfies t.Type<PaymentToken, unknown>;


export const emails_body = {
  _tag: `Emails_body`,
  field: `Emails.body`
} as const;

export type Emails_bodyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Emails_body`>
}>;
export const emails_bodyTaggedC: Emails_bodyTaggedC = t.type({
  _tag: t.literal(`Emails_body`)
});
export type Emails_bodyTagged = t.TypeOf<Emails_bodyTaggedC>;
export type Emails_body = Emails_bodyTagged & typeof emails_body;
export type Emails_bodyC = t.Type<Emails_body, Emails_bodyTagged>;
export const emails_bodyC: Emails_bodyC = pipe(emails_bodyTaggedC, c => new t.Type<Emails_body, Emails_bodyTagged>(
  `Emails_body`,
  (u: unknown): u is Emails_body => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Emails_body> => pipe(c.decode(u), E.map(x => ({ ...x, ...emails_body }))),
  (x: Emails_body): Emails_bodyTagged => ({ ...x, _tag: `Emails_body`}),
)) satisfies t.Type<Emails_body, unknown>;


export const sitePassword = {
  _tag: `sitePassword`,
  field: `sitePassword`
} as const;

export type SitePasswordTaggedC = t.TypeC<{
  _tag: t.LiteralC<`sitePassword`>
}>;
export const sitePasswordTaggedC: SitePasswordTaggedC = t.type({
  _tag: t.literal(`sitePassword`)
});
export type SitePasswordTagged = t.TypeOf<SitePasswordTaggedC>;
export type SitePassword = SitePasswordTagged & typeof sitePassword;
export type SitePasswordC = t.Type<SitePassword, SitePasswordTagged>;
export const sitePasswordC: SitePasswordC = pipe(sitePasswordTaggedC, c => new t.Type<SitePassword, SitePasswordTagged>(
  `sitePassword`,
  (u: unknown): u is SitePassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SitePassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...sitePassword }))),
  (x: SitePassword): SitePasswordTagged => ({ ...x, _tag: `sitePassword`}),
)) satisfies t.Type<SitePassword, unknown>;


export const authorization = {
  _tag: `Authorization`,
  field: `Authorization`
} as const;

export type AuthorizationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Authorization`>
}>;
export const authorizationTaggedC: AuthorizationTaggedC = t.type({
  _tag: t.literal(`Authorization`)
});
export type AuthorizationTagged = t.TypeOf<AuthorizationTaggedC>;
export type Authorization = AuthorizationTagged & typeof authorization;
export type AuthorizationC = t.Type<Authorization, AuthorizationTagged>;
export const authorizationC: AuthorizationC = pipe(authorizationTaggedC, c => new t.Type<Authorization, AuthorizationTagged>(
  `Authorization`,
  (u: unknown): u is Authorization => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Authorization> => pipe(c.decode(u), E.map(x => ({ ...x, ...authorization }))),
  (x: Authorization): AuthorizationTagged => ({ ...x, _tag: `Authorization`}),
)) satisfies t.Type<Authorization, unknown>;


export const apiToken = {
  _tag: `ApiToken`,
  field: `Api-Token`
} as const;

export type ApiTokenTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ApiToken`>
}>;
export const apiTokenTaggedC: ApiTokenTaggedC = t.type({
  _tag: t.literal(`ApiToken`)
});
export type ApiTokenTagged = t.TypeOf<ApiTokenTaggedC>;
export type ApiToken = ApiTokenTagged & typeof apiToken;
export type ApiTokenC = t.Type<ApiToken, ApiTokenTagged>;
export const apiTokenC: ApiTokenC = pipe(apiTokenTaggedC, c => new t.Type<ApiToken, ApiTokenTagged>(
  `ApiToken`,
  (u: unknown): u is ApiToken => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ApiToken> => pipe(c.decode(u), E.map(x => ({ ...x, ...apiToken }))),
  (x: ApiToken): ApiTokenTagged => ({ ...x, _tag: `ApiToken`}),
)) satisfies t.Type<ApiToken, unknown>;


export const client_secret = {
  _tag: `client_secret`,
  field: `client_secret`
} as const;

export type Client_secretTaggedC = t.TypeC<{
  _tag: t.LiteralC<`client_secret`>
}>;
export const client_secretTaggedC: Client_secretTaggedC = t.type({
  _tag: t.literal(`client_secret`)
});
export type Client_secretTagged = t.TypeOf<Client_secretTaggedC>;
export type Client_secret = Client_secretTagged & typeof client_secret;
export type Client_secretC = t.Type<Client_secret, Client_secretTagged>;
export const client_secretC: Client_secretC = pipe(client_secretTaggedC, c => new t.Type<Client_secret, Client_secretTagged>(
  `client_secret`,
  (u: unknown): u is Client_secret => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Client_secret> => pipe(c.decode(u), E.map(x => ({ ...x, ...client_secret }))),
  (x: Client_secret): Client_secretTagged => ({ ...x, _tag: `client_secret`}),
)) satisfies t.Type<Client_secret, unknown>;


export const access_token = {
  _tag: `access_token`,
  field: `access_token`
} as const;

export type Access_tokenTaggedC = t.TypeC<{
  _tag: t.LiteralC<`access_token`>
}>;
export const access_tokenTaggedC: Access_tokenTaggedC = t.type({
  _tag: t.literal(`access_token`)
});
export type Access_tokenTagged = t.TypeOf<Access_tokenTaggedC>;
export type Access_token = Access_tokenTagged & typeof access_token;
export type Access_tokenC = t.Type<Access_token, Access_tokenTagged>;
export const access_tokenC: Access_tokenC = pipe(access_tokenTaggedC, c => new t.Type<Access_token, Access_tokenTagged>(
  `access_token`,
  (u: unknown): u is Access_token => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Access_token> => pipe(c.decode(u), E.map(x => ({ ...x, ...access_token }))),
  (x: Access_token): Access_tokenTagged => ({ ...x, _tag: `access_token`}),
)) satisfies t.Type<Access_token, unknown>;


export const hapikey = {
  _tag: `hapikey`,
  field: `hapikey`
} as const;

export type HapikeyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`hapikey`>
}>;
export const hapikeyTaggedC: HapikeyTaggedC = t.type({
  _tag: t.literal(`hapikey`)
});
export type HapikeyTagged = t.TypeOf<HapikeyTaggedC>;
export type Hapikey = HapikeyTagged & typeof hapikey;
export type HapikeyC = t.Type<Hapikey, HapikeyTagged>;
export const hapikeyC: HapikeyC = pipe(hapikeyTaggedC, c => new t.Type<Hapikey, HapikeyTagged>(
  `hapikey`,
  (u: unknown): u is Hapikey => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Hapikey> => pipe(c.decode(u), E.map(x => ({ ...x, ...hapikey }))),
  (x: Hapikey): HapikeyTagged => ({ ...x, _tag: `hapikey`}),
)) satisfies t.Type<Hapikey, unknown>;


export const allSensitiveFieldC = [passwordC, passwordConfirmC, newPasswordC, newPasswordConfirmC, saltC, publishPasswordC, publishSaltC, confirmPasswordC, paymentTokenC, emails_bodyC, sitePasswordC, authorizationC, apiTokenC, client_secretC, access_tokenC, hapikeyC] as const;
export const allSensitiveFieldNames = [`password`, `passwordConfirm`, `newPassword`, `newPasswordConfirm`, `salt`, `publishPassword`, `publishSalt`, `confirmPassword`, `paymentToken`, `Emails_body`, `sitePassword`, `Authorization`, `ApiToken`, `client_secret`, `access_token`, `hapikey`] as const;
export type SensitiveFieldName = (typeof allSensitiveFieldNames)[number];

export type SensitiveFieldCU = t.UnionC<[PasswordC, PasswordConfirmC, NewPasswordC, NewPasswordConfirmC, SaltC, PublishPasswordC, PublishSaltC, ConfirmPasswordC, PaymentTokenC, Emails_bodyC, SitePasswordC, AuthorizationC, ApiTokenC, Client_secretC, Access_tokenC, HapikeyC]>;
export type SensitiveFieldU = Password | PasswordConfirm | NewPassword | NewPasswordConfirm | Salt | PublishPassword | PublishSalt | ConfirmPassword | PaymentToken | Emails_body | SitePassword | Authorization | ApiToken | Client_secret | Access_token | Hapikey;
export const SensitiveFieldCU: SensitiveFieldCU = t.union([passwordC, passwordConfirmC, newPasswordC, newPasswordConfirmC, saltC, publishPasswordC, publishSaltC, confirmPasswordC, paymentTokenC, emails_bodyC, sitePasswordC, authorizationC, apiTokenC, client_secretC, access_tokenC, hapikeyC]) satisfies t.Type<SensitiveFieldU, unknown>;

export const sensitiveFieldOrd: Ord.Ord<SensitiveFieldU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSensitiveField = [password, passwordConfirm, newPassword, newPasswordConfirm, salt, publishPassword, publishSalt, confirmPassword, paymentToken, emails_body, sitePassword, authorization, apiToken, client_secret, access_token, hapikey] as const;
export type SensitiveFieldMap<A> = { [K in SensitiveFieldName]: A };


