import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { TypeOfServicingCU as imported3_TypeOfServicingCU, TypeOfServicingU as imported1_TypeOfServicingU, TypeOfServicingCU as imported2_TypeOfServicingCU } from "../domaintables/typesOfServicing";
import { LocalDateC, LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { Ongoing as imported4_Ongoing, ongoingC as imported3_ongoingC, OngoingC as imported3_OngoingC, OngoingC as imported4_OngoingC, Ongoing as imported3_Ongoing, ongoingC as imported2_ongoingC } from "../domaintables/dateQualifiers";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BiddingStateCU as imported3_BiddingStateCU, BiddingStateU as imported1_BiddingStateU, BiddingStateCU as imported2_BiddingStateCU } from "../domaintables/biddingStates";
import { StateInfoCU as imported11_StateInfoCU, StateInfoU as imported5_StateInfoU, StateInfoCU as imported10_StateInfoCU } from "../domaintables/states";
import { SectorCU as imported23_SectorCU, SectorU as imported11_SectorU, SectorCU as imported22_SectorCU } from "../domaintables/sectors";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { EitherC, either } from "io-ts-types/lib/either";
import { TimeZoneCU as imported4_TimeZoneCU, TimeZoneCU as imported6_TimeZoneCU, TimeZoneCU as imported7_TimeZoneCU, TimeZoneU as imported3_TimeZoneU, TimeZoneU as imported2_TimeZoneU, TimeZoneCU as imported5_TimeZoneCU } from "../domaintables/timeZones";

export type TimeC = t.TypeC<{
  hour: t.NumberC,
  minute: t.NumberC
}>;
export type Time = {
  hour: number,
  minute: number
};
export const timeC: TimeC = t.type({
  hour: t.number,
  minute: t.number
}) satisfies t.Type<Time, unknown>;


export type TimeWithZoneC<A1 extends t.Mixed> = t.TypeC<{
  time: TimeC,
  timezone: A1
}>;
export type TimeWithZone<A1> = {
  time: Time,
  timezone: A1
};
export const timeWithZoneC = <A1 extends t.Mixed>(A1: A1): TimeWithZoneC<A1> => t.type({
  time: timeC,
  timezone: A1
}) satisfies t.Type<TimeWithZone<t.TypeOf<A1>>, unknown>;


export type DateWithOptionalTimeC<A1 extends t.Mixed> = t.TypeC<{
  date: LocalDateC,
  time: OptionFromNullableC<TimeWithZoneC<A1>>
}>;
export type DateWithOptionalTime<A1> = {
  date: LocalDate,
  time: O.Option<TimeWithZone<A1>>
};
export const dateWithOptionalTimeC = <A1 extends t.Mixed>(A1: A1): DateWithOptionalTimeC<A1> => t.type({
  date: LocalDateC,
  time: optionFromNullable(timeWithZoneC(A1))
}) satisfies t.Type<DateWithOptionalTime<t.TypeOf<A1>>, unknown>;


export type RfpC = t.TypeC<{
  order: t.NumberC,
  name: t.StringC,
  hidden: t.BooleanC,
  bondProgramId: OptionFromNullableC<t.NumberC>,
  sector: imported23_SectorCU,
  state: imported11_StateInfoCU,
  loanAmount: OptionFromNullableC<t.NumberC>,
  typeOfServicing: OptionFromNullableC<imported3_TypeOfServicingCU>,
  projectDescription: OptionFromNullableC<MarkdownC>,
  submissionRequirements: OptionFromNullableC<MarkdownC>,
  allowQuestions: t.BooleanC,
  contactEmail: OptionFromNullableC<t.StringC>,
  dateListed: OptionFromNullableC<LocalDateC>,
  deadlineForQuestions: OptionFromNullableC<DateWithOptionalTimeC<imported6_TimeZoneCU>>,
  biddingState: imported3_BiddingStateCU,
  bidsDue: OptionFromNullableC<EitherC<DateWithOptionalTimeC<imported7_TimeZoneCU>, imported3_OngoingC>>,
  bidsDueDateTime: OptionFromNullableC<EitherC<LocalDateTimeFromIsoStringC, imported4_OngoingC>>,
  acceptBidsOnSite: t.BooleanC,
  bidSubmissionTemplateId: OptionFromNullableC<t.NumberC>,
  bidAwarded: t.BooleanC,
  awardDate: OptionFromNullableC<LocalDateC>,
  awardDateTentative: t.BooleanC,
  contractStartDate: OptionFromNullableC<LocalDateC>,
  contractStartDateTentative: t.BooleanC,
  showWinningBid: t.BooleanC,
  winningBidCompany: OptionFromNullableC<t.StringC>,
  winningBidRate: OptionFromNullableC<t.NumberC>,
  winningBidFees: OptionFromNullableC<t.NumberC>,
  winningBidReceived: OptionFromNullableC<LocalDateC>,
  winningBidAdditionalInfo: OptionFromNullableC<MarkdownC>
}>;
export type Rfp = {
  order: number,
  name: string,
  hidden: boolean,
  bondProgramId: O.Option<number>,
  sector: imported11_SectorU,
  state: imported5_StateInfoU,
  loanAmount: O.Option<number>,
  typeOfServicing: O.Option<imported1_TypeOfServicingU>,
  projectDescription: O.Option<Markdown>,
  submissionRequirements: O.Option<Markdown>,
  allowQuestions: boolean,
  contactEmail: O.Option<string>,
  dateListed: O.Option<LocalDate>,
  deadlineForQuestions: O.Option<DateWithOptionalTime<imported2_TimeZoneU>>,
  biddingState: imported1_BiddingStateU,
  bidsDue: O.Option<E.Either<DateWithOptionalTime<imported3_TimeZoneU>, imported3_Ongoing>>,
  bidsDueDateTime: O.Option<E.Either<LocalDateTime, imported4_Ongoing>>,
  acceptBidsOnSite: boolean,
  bidSubmissionTemplateId: O.Option<number>,
  bidAwarded: boolean,
  awardDate: O.Option<LocalDate>,
  awardDateTentative: boolean,
  contractStartDate: O.Option<LocalDate>,
  contractStartDateTentative: boolean,
  showWinningBid: boolean,
  winningBidCompany: O.Option<string>,
  winningBidRate: O.Option<number>,
  winningBidFees: O.Option<number>,
  winningBidReceived: O.Option<LocalDate>,
  winningBidAdditionalInfo: O.Option<Markdown>
};
export const rfpC: RfpC = t.type({
  order: t.number,
  name: t.string,
  hidden: t.boolean,
  bondProgramId: optionFromNullable(t.number),
  sector: imported22_SectorCU,
  state: imported10_StateInfoCU,
  loanAmount: optionFromNullable(t.number),
  typeOfServicing: optionFromNullable(imported2_TypeOfServicingCU),
  projectDescription: optionFromNullable(markdownC),
  submissionRequirements: optionFromNullable(markdownC),
  allowQuestions: t.boolean,
  contactEmail: optionFromNullable(t.string),
  dateListed: optionFromNullable(LocalDateC),
  deadlineForQuestions: optionFromNullable(dateWithOptionalTimeC(imported4_TimeZoneCU)),
  biddingState: imported2_BiddingStateCU,
  bidsDue: optionFromNullable(either(dateWithOptionalTimeC(imported5_TimeZoneCU), imported2_ongoingC)),
  bidsDueDateTime: optionFromNullable(either(LocalDateTimeFromIsoStringC, imported3_ongoingC)),
  acceptBidsOnSite: t.boolean,
  bidSubmissionTemplateId: optionFromNullable(t.number),
  bidAwarded: t.boolean,
  awardDate: optionFromNullable(LocalDateC),
  awardDateTentative: t.boolean,
  contractStartDate: optionFromNullable(LocalDateC),
  contractStartDateTentative: t.boolean,
  showWinningBid: t.boolean,
  winningBidCompany: optionFromNullable(t.string),
  winningBidRate: optionFromNullable(t.number),
  winningBidFees: optionFromNullable(t.number),
  winningBidReceived: optionFromNullable(LocalDateC),
  winningBidAdditionalInfo: optionFromNullable(markdownC)
}) satisfies t.Type<Rfp, unknown>;


