import type { CloseXButtonProps } from "@scripts/react/components/Button";
import type { AlertsProps } from "@scripts/react/components/layout/Alerts";
import { AlertsBase } from "@scripts/react/components/layout/Alerts";
import { klass } from "@scripts/react/util/classnames";

const SitesAlertCloseXButton = (
  props: CloseXButtonProps,
) =>
  <button ref={props.ref} type="button" {...klass("close btn-close")} aria-label="Close" onClick={props.onClick}>
    <span aria-hidden="true">&times;</span>
  </button>;

export const SitesAlerts = (props: AlertsProps) =>
  <AlertsBase {...props} closeButton={SitesAlertCloseXButton} />;
