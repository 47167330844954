import { AlertTypeCU as imported3_AlertTypeCU, AlertTypeU as imported1_AlertTypeU, AlertTypeCU as imported2_AlertTypeCU } from "../domaintables/alertTypes";
import * as t from "io-ts";

export type FlashC = t.TypeC<{
  type: imported3_AlertTypeCU,
  safe: t.BooleanC,
  msg: t.StringC
}>;
export type Flash = {
  type: imported1_AlertTypeU,
  safe: boolean,
  msg: string
};
export const flashC: FlashC = t.type({
  type: imported2_AlertTypeCU,
  safe: t.boolean,
  msg: t.string
}) satisfies t.Type<Flash, unknown>;


