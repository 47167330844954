import { withModInfoC as imported192_withModInfoC, WithIdC as imported262_WithIdC, withIdC as imported262_withIdC, WithModInfoC as imported192_WithModInfoC, WithId as imported262_WithId, WithModInfo as imported192_WithModInfo } from "./threadThrough";
import * as t from "io-ts";
import { MediaUploadResponse as imported26_MediaUploadResponse, MediaUploadResponseC as imported26_MediaUploadResponseC, MediaC as imported74_MediaC, Media as imported74_Media, mediaUploadResponseC as imported26_mediaUploadResponseC, mediaC as imported70_mediaC } from "./media";
import * as O from "fp-ts/lib/Option";
import { HrefLocationCU as imported6_HrefLocationCU, HrefLocationCU as imported7_HrefLocationCU, HrefLocationU as imported2_HrefLocationU, HrefLocationU as imported3_HrefLocationU, HrefLocationCU as imported5_HrefLocationCU, HrefLocationCU as imported4_HrefLocationCU } from "../domaintables/hrefLocations";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BucketCU as imported19_BucketCU, BucketU as imported9_BucketU, BucketCU as imported18_BucketCU } from "../domaintables/pages";

export type HrefC = t.TypeC<{
  href: OptionFromNullableC<t.StringC>,
  text: t.StringC,
  calloutText: OptionFromNullableC<t.StringC>,
  sortOrder: t.NumberC,
  location: imported5_HrefLocationCU,
  pageHeader: OptionFromNullableC<imported19_BucketCU>,
  media: OptionFromNullableC<imported262_WithIdC<imported192_WithModInfoC<imported74_MediaC>>>,
  url: t.StringC
}>;
export type Href = {
  href: O.Option<string>,
  text: string,
  calloutText: O.Option<string>,
  sortOrder: number,
  location: imported2_HrefLocationU,
  pageHeader: O.Option<imported9_BucketU>,
  media: O.Option<imported262_WithId<imported192_WithModInfo<imported74_Media>>>,
  url: string
};
export const hrefC: HrefC = t.type({
  href: optionFromNullable(t.string),
  text: t.string,
  calloutText: optionFromNullable(t.string),
  sortOrder: t.number,
  location: imported4_HrefLocationCU,
  pageHeader: optionFromNullable(imported18_BucketCU),
  media: optionFromNullable(imported262_withIdC(imported192_withModInfoC(imported70_mediaC))),
  url: t.string
}) satisfies t.Type<Href, unknown>;


export type HrefPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  href: OptionFromNullableC<t.StringC>,
  location: imported7_HrefLocationCU,
  text: t.StringC,
  pageHeaderId: OptionFromNullableC<t.NumberC>,
  media: OptionFromNullableC<imported26_MediaUploadResponseC>,
  mediaId: OptionFromNullableC<t.NumberC>,
  sortOrder: t.NumberC
}>;
export type HrefPostBody = {
  id: O.Option<number>,
  href: O.Option<string>,
  location: imported3_HrefLocationU,
  text: string,
  pageHeaderId: O.Option<number>,
  media: O.Option<imported26_MediaUploadResponse>,
  mediaId: O.Option<number>,
  sortOrder: number
};
export const hrefPostBodyC: HrefPostBodyC = t.type({
  id: optionFromNullable(t.number),
  href: optionFromNullable(t.string),
  location: imported6_HrefLocationCU,
  text: t.string,
  pageHeaderId: optionFromNullable(t.number),
  media: optionFromNullable(imported26_mediaUploadResponseC),
  mediaId: optionFromNullable(t.number),
  sortOrder: t.number
}) satisfies t.Type<HrefPostBody, unknown>;


