import { ReadonlySetFromArrayC, readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { UserContentSubscriptionTypeCU as imported11_UserContentSubscriptionTypeCU, UserContentSubscriptionTypeU as imported5_UserContentSubscriptionTypeU, UserContentSubscriptionTypeCU as imported10_UserContentSubscriptionTypeCU, userContentSubscriptionTypeOrd as imported1_userContentSubscriptionTypeOrd } from "../domaintables/userContentSubscriptionTypes";
import { IssuerWithAddressC as imported8_IssuerWithAddressC, IssuerWithAddress as imported8_IssuerWithAddress, issuerWithAddressC as imported8_issuerWithAddressC } from "./issuer";
import * as t from "io-ts";

export type NotificationSubscriptionsStatusC = t.TypeC<{
  notificationsEnabled: t.BooleanC,
  subscribedIssuers: t.ReadonlyArrayC<imported8_IssuerWithAddressC>,
  subscribedContentTypes: ReadonlySetFromArrayC<imported11_UserContentSubscriptionTypeCU>
}>;
export type NotificationSubscriptionsStatus = {
  notificationsEnabled: boolean,
  subscribedIssuers: ReadonlyArray<imported8_IssuerWithAddress>,
  subscribedContentTypes: ReadonlySet<imported5_UserContentSubscriptionTypeU>
};
export const notificationSubscriptionsStatusC: NotificationSubscriptionsStatusC = t.type({
  notificationsEnabled: t.boolean,
  subscribedIssuers: t.readonlyArray(imported8_issuerWithAddressC),
  subscribedContentTypes: readonlySetFromArray(imported10_UserContentSubscriptionTypeCU, imported1_userContentSubscriptionTypeOrd)
}) satisfies t.Type<NotificationSubscriptionsStatus, unknown>;


export type UserIssuerSubscriptionsPostC = t.TypeC<{
  issuerIds: t.ReadonlyArrayC<t.NumberC>,
  enabled: t.BooleanC
}>;
export type UserIssuerSubscriptionsPost = {
  issuerIds: ReadonlyArray<number>,
  enabled: boolean
};
export const userIssuerSubscriptionsPostC: UserIssuerSubscriptionsPostC = t.type({
  issuerIds: t.readonlyArray(t.number),
  enabled: t.boolean
}) satisfies t.Type<UserIssuerSubscriptionsPost, unknown>;


