import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { WithStatusCU as imported481_WithStatusCU, WithStatusU as imported240_WithStatusU, WithStatusCU as imported480_WithStatusCU } from "./threadThrough";
import { IssuerC as imported48_IssuerC, Issuer as imported48_Issuer, issuerC as imported47_issuerC } from "./issuer";

export type RelatedIssuerC = t.TypeC<{
  relatedIssuerId: OptionFromNullableC<t.NumberC>,
  outsideLink: OptionFromNullableC<t.StringC>,
  notes: OptionFromNullableC<MarkdownC>,
  issuerOrder: t.NumberC,
  name: t.StringC
}>;
export type RelatedIssuer = {
  relatedIssuerId: O.Option<number>,
  outsideLink: O.Option<string>,
  notes: O.Option<Markdown>,
  issuerOrder: number,
  name: string
};
export const relatedIssuerC: RelatedIssuerC = t.type({
  relatedIssuerId: optionFromNullable(t.number),
  outsideLink: optionFromNullable(t.string),
  notes: optionFromNullable(markdownC),
  issuerOrder: t.number,
  name: t.string
}) satisfies t.Type<RelatedIssuer, unknown>;


export type RelatedIssuerPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  relatedIssuerId: OptionFromNullableC<t.NumberC>,
  outsideLink: OptionFromNullableC<t.StringC>,
  notes: OptionFromNullableC<MarkdownC>,
  name: t.StringC
}>;
export type RelatedIssuerPostBody = {
  id: O.Option<number>,
  relatedIssuerId: O.Option<number>,
  outsideLink: O.Option<string>,
  notes: O.Option<Markdown>,
  name: string
};
export const relatedIssuerPostBodyC: RelatedIssuerPostBodyC = t.type({
  id: optionFromNullable(t.number),
  relatedIssuerId: optionFromNullable(t.number),
  outsideLink: optionFromNullable(t.string),
  notes: optionFromNullable(markdownC),
  name: t.string
}) satisfies t.Type<RelatedIssuerPostBody, unknown>;


export type RelatedIssuerWithIssuerC = t.TypeC<{
  related: RelatedIssuerC,
  issuer: OptionFromNullableC<imported48_IssuerC>
}>;
export type RelatedIssuerWithIssuer = {
  related: RelatedIssuer,
  issuer: O.Option<imported48_Issuer>
};
export const relatedIssuerWithIssuerC: RelatedIssuerWithIssuerC = t.type({
  related: relatedIssuerC,
  issuer: optionFromNullable(imported47_issuerC)
}) satisfies t.Type<RelatedIssuerWithIssuer, unknown>;


export type IssuersTypeAheadC = t.TypeC<{
  id: t.NumberC,
  name: t.StringC,
  slug: t.StringC,
  customDomain: OptionFromNullableC<t.StringC>
}>;
export type IssuersTypeAhead = {
  id: number,
  name: string,
  slug: string,
  customDomain: O.Option<string>
};
export const issuersTypeAheadC: IssuersTypeAheadC = t.type({
  id: t.number,
  name: t.string,
  slug: t.string,
  customDomain: optionFromNullable(t.string)
}) satisfies t.Type<IssuersTypeAhead, unknown>;


export type RelatedIssuersC = t.TypeC<{
  relatedIssuers: t.ReadonlyArrayC<imported481_WithStatusCU<RelatedIssuerWithIssuerC>>,
  issuersTypeAhead: t.ReadonlyArrayC<IssuersTypeAheadC>
}>;
export type RelatedIssuers = {
  relatedIssuers: ReadonlyArray<imported240_WithStatusU<RelatedIssuerWithIssuer>>,
  issuersTypeAhead: ReadonlyArray<IssuersTypeAhead>
};
export const relatedIssuersC: RelatedIssuersC = t.type({
  relatedIssuers: t.readonlyArray(imported480_WithStatusCU(relatedIssuerWithIssuerC)),
  issuersTypeAhead: t.readonlyArray(issuersTypeAheadC)
}) satisfies t.Type<RelatedIssuers, unknown>;


