import * as t from "io-ts";
import { MfaInitiatedC as imported3_MfaInitiatedC, MfaInitiated as imported3_MfaInitiated, mfaInitiatedC as imported1_mfaInitiatedC } from "../domaintables/loginStatus";
import * as O from "fp-ts/lib/Option";
import { RelativeUrlC, RelativeUrl, relativeUrlC } from "../../codecs/relativeUrl";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { UserC as imported28_UserC, User as imported27_User, userC as imported28_userC, UserC as imported27_UserC, userC as imported27_userC, User as imported28_User } from "./user";

export type UserSettingsPostC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  phoneExtension: OptionFromNullableC<t.StringC>,
  company: t.StringC,
  title: OptionFromNullableC<t.StringC>,
  personaId: t.NumberC,
  investorTypeId: OptionFromNullableC<t.NumberC>,
  subscribedToHubspot: t.BooleanC
}>;
export type UserSettingsPost = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: O.Option<string>,
  phoneExtension: O.Option<string>,
  company: string,
  title: O.Option<string>,
  personaId: number,
  investorTypeId: O.Option<number>,
  subscribedToHubspot: boolean
};
export const userSettingsPostC: UserSettingsPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number),
  subscribedToHubspot: t.boolean
}) satisfies t.Type<UserSettingsPost, unknown>;


export type UserSettingsResponseC = t.TypeC<{
  user: imported27_UserC,
  emailChanged: t.BooleanC
}>;
export type UserSettingsResponse = {
  user: imported27_User,
  emailChanged: boolean
};
export const userSettingsResponseC: UserSettingsResponseC = t.type({
  user: imported27_userC,
  emailChanged: t.boolean
}) satisfies t.Type<UserSettingsResponse, unknown>;


export type MfaSettingsPostC = t.TypeC<{
  phoneNumber: t.StringC
}>;
export type MfaSettingsPost = {
  phoneNumber: string
};
export const mfaSettingsPostC: MfaSettingsPostC = t.type({
  phoneNumber: t.string
}) satisfies t.Type<MfaSettingsPost, unknown>;


export type MfaSettingsResponseC = t.TypeC<{
  user: imported28_UserC,
  verification: imported3_MfaInitiatedC
}>;
export type MfaSettingsResponse = {
  user: imported28_User,
  verification: imported3_MfaInitiated
};
export const mfaSettingsResponseC: MfaSettingsResponseC = t.type({
  user: imported28_userC,
  verification: imported1_mfaInitiatedC
}) satisfies t.Type<MfaSettingsResponse, unknown>;


export type VerifyMfaPostC = t.TypeC<{
  verificationId: t.StringC,
  totp: t.StringC
}>;
export type VerifyMfaPost = {
  verificationId: string,
  totp: string
};
export const verifyMfaPostC: VerifyMfaPostC = t.type({
  verificationId: t.string,
  totp: t.string
}) satisfies t.Type<VerifyMfaPost, unknown>;


export type UpdatePasswordPostC = t.TypeC<{
  password: t.StringC,
  newPassword: t.StringC,
  newPasswordConfirm: t.StringC
}>;
export type UpdatePasswordPost = {
  password: string,
  newPassword: string,
  newPasswordConfirm: string
};
export const updatePasswordPostC: UpdatePasswordPostC = t.type({
  password: t.string,
  newPassword: t.string,
  newPasswordConfirm: t.string
}) satisfies t.Type<UpdatePasswordPost, unknown>;


export type VerifyTotpPostC = t.TypeC<{
  verificationId: t.StringC,
  totp: t.StringC,
  userId: t.NumberC,
  redirect: OptionFromNullableC<RelativeUrlC>,
  remember: t.BooleanC
}>;
export type VerifyTotpPost = {
  verificationId: string,
  totp: string,
  userId: number,
  redirect: O.Option<RelativeUrl>,
  remember: boolean
};
export const verifyTotpPostC: VerifyTotpPostC = t.type({
  verificationId: t.string,
  totp: t.string,
  userId: t.number,
  redirect: optionFromNullable(relativeUrlC),
  remember: t.boolean
}) satisfies t.Type<VerifyTotpPost, unknown>;


