import * as t from "io-ts";

import { allC } from "@scripts/codecs/filter";

const userStatus = ["active", "passive", allC.value] as const;

export const userStatusC = t.union([t.literal(userStatus[0]), t.literal(userStatus[1]), t.literal(userStatus[2])]);
type UserStatusC = typeof userStatusC;
export type UserStatus = t.TypeOf<UserStatusC>;

export const defaultUserStatus = userStatus[0];
