import * as t from "io-ts";
import { MediaCategoryCU as imported17_MediaCategoryCU, MediaCategoryU as imported8_MediaCategoryU, MediaCategoryCU as imported16_MediaCategoryCU } from "../domaintables/mediaCategories";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export type PdfC = t.TypeC<{
  link: t.StringC,
  title: t.StringC,
  documentDate: OptionFromNullableC<LocalDateC>,
  category: OptionFromNullableC<imported17_MediaCategoryCU>
}>;
export type Pdf = {
  link: string,
  title: string,
  documentDate: O.Option<LocalDate>,
  category: O.Option<imported8_MediaCategoryU>
};
export const pdfC: PdfC = t.type({
  link: t.string,
  title: t.string,
  documentDate: optionFromNullable(LocalDateC),
  category: optionFromNullable(imported16_MediaCategoryCU)
}) satisfies t.Type<Pdf, unknown>;


export type QuickFactC = t.TypeC<{
  label: t.StringC,
  fact: t.StringC,
  sourceUrl: OptionFromNullableC<t.StringC>
}>;
export type QuickFact = {
  label: string,
  fact: string,
  sourceUrl: O.Option<string>
};
export const quickFactC: QuickFactC = t.type({
  label: t.string,
  fact: t.string,
  sourceUrl: optionFromNullable(t.string)
}) satisfies t.Type<QuickFact, unknown>;


export type ProjectC = t.TypeC<{
  name: t.StringC,
  description: t.StringC,
  quickFacts: t.ReadonlyArrayC<QuickFactC>
}>;
export type Project = {
  name: string,
  description: string,
  quickFacts: ReadonlyArray<QuickFact>
};
export const projectC: ProjectC = t.type({
  name: t.string,
  description: t.string,
  quickFacts: t.readonlyArray(quickFactC)
}) satisfies t.Type<Project, unknown>;


