import { WithStatusCU as imported827_WithStatusCU, WithStatusU as imported413_WithStatusU, WithStatusCU as imported826_WithStatusCU } from "./threadThrough";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { ClientTextTypeCU as imported3_ClientTextTypeCU, ClientTextTypeU as imported1_ClientTextTypeU, ClientTextTypeCU as imported2_ClientTextTypeCU } from "../domaintables/clientTextTypes";
import * as t from "io-ts";

export type ClientTextItemC = t.TypeC<{
  textType: imported3_ClientTextTypeCU,
  data: MarkdownC
}>;
export type ClientTextItem = {
  textType: imported1_ClientTextTypeU,
  data: Markdown
};
export const clientTextItemC: ClientTextItemC = t.type({
  textType: imported2_ClientTextTypeCU,
  data: markdownC
}) satisfies t.Type<ClientTextItem, unknown>;


export type ClientTextItemOC = OptionFromNullableC<imported827_WithStatusCU<ClientTextItemC>>;
export type ClientTextItemO = O.Option<imported413_WithStatusU<ClientTextItem>>;
export const clientTextItemOC: ClientTextItemOC = optionFromNullable(imported826_WithStatusCU(clientTextItemC)) satisfies t.Type<ClientTextItemO, unknown>;


