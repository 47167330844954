import { LocalDateC } from "../../codecs/localDate";
import * as t from "io-ts";
import { LocalDate } from "@js-joda/core";

export type PointC = t.TypeC<{
  earlierYield: t.NumberC,
  recentYield: t.NumberC
}>;
export type Point = {
  earlierYield: number,
  recentYield: number
};
export const pointC: PointC = t.type({
  earlierYield: t.number,
  recentYield: t.number
}) satisfies t.Type<Point, unknown>;


export type IceCurveC = t.TypeC<{
  earlierDate: LocalDateC,
  recentDate: LocalDateC,
  data: t.ReadonlyArrayC<t.TupleC<[t.StringC, PointC]>>
}>;
export type IceCurve = {
  earlierDate: LocalDate,
  recentDate: LocalDate,
  data: ReadonlyArray<[string, Point]>
};
export const iceCurveC: IceCurveC = t.type({
  earlierDate: LocalDateC,
  recentDate: LocalDateC,
  data: t.readonlyArray(t.tuple([t.string, pointC]))
}) satisfies t.Type<IceCurve, unknown>;


