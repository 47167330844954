import type * as Th from "fp-ts/lib/These";
import * as t from "io-ts";

export type TheseC<L extends t.Mixed, R extends t.Mixed> = t.Type<
  Th.These<t.TypeOf<L>, t.TypeOf<R>>,
  Th.These<t.OutputOf<L>, t.OutputOf<R>>
>;

export function theseC<LI, LO, RI, RO>(lCodec: t.Type<LI, LO>, rCodec: t.Type<RI, RO>): TheseC<t.Type<LI, LO>, t.Type<RI, RO>> {
  const c = t.union([
    t.strict({ _tag: t.literal("Left"), left: lCodec }),
    t.strict({ _tag: t.literal("Right"), right: rCodec }),
    t.strict({ _tag: t.literal("Both"), left: lCodec, right: rCodec }),
  ]);
  return new t.Type<Th.These<LI, RI>, Th.These<LO, RO>>(`These<${lCodec.name}, ${rCodec.name}>`, c.is, c.validate, c.encode);
}
