import * as t from "io-ts";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export type InvoiceC = t.TypeC<{
  invoiceDate: LocalDateC,
  mediaId: t.NumberC,
  stripeId: OptionFromNullableC<t.StringC>,
  paid: t.BooleanC,
  amountDue: t.NumberC,
  netSuite: t.BooleanC
}>;
export type Invoice = {
  invoiceDate: LocalDate,
  mediaId: number,
  stripeId: O.Option<string>,
  paid: boolean,
  amountDue: number,
  netSuite: boolean
};
export const invoiceC: InvoiceC = t.type({
  invoiceDate: LocalDateC,
  mediaId: t.number,
  stripeId: optionFromNullable(t.string),
  paid: t.boolean,
  amountDue: t.number,
  netSuite: t.boolean
}) satisfies t.Type<Invoice, unknown>;


export type InvoiceNotificationEmailC = t.TypeC<{
  email: t.StringC
}>;
export type InvoiceNotificationEmail = {
  email: string
};
export const invoiceNotificationEmailC: InvoiceNotificationEmailC = t.type({
  email: t.string
}) satisfies t.Type<InvoiceNotificationEmail, unknown>;


