import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { ResponseCodeU as imported3_ResponseCodeU, ResponseCodeCU as imported5_ResponseCodeCU, ResponseCodeCU as imported7_ResponseCodeCU, ResponseCodeU as imported2_ResponseCodeU, ResponseCodeCU as imported4_ResponseCodeCU, ResponseCodeCU as imported6_ResponseCodeCU } from "../domaintables/responseCodes";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

export type ApiErrorC = t.TypeC<{
  field: t.StringC,
  error: imported5_ResponseCodeCU,
  extra: t.StringC
}>;
export type ApiError = {
  field: string,
  error: imported2_ResponseCodeU,
  extra: string
};
export const apiErrorC: ApiErrorC = t.type({
  field: t.string,
  error: imported4_ResponseCodeCU,
  extra: t.string
}) satisfies t.Type<ApiError, unknown>;


export type ApiErrorsC = t.TypeC<{
  error: imported7_ResponseCodeCU,
  errors: ReadonlyNonEmptyArrayType<ApiErrorC>
}>;
export type ApiErrors = {
  error: imported3_ResponseCodeU,
  errors: RNEA.ReadonlyNonEmptyArray<ApiError>
};
export const apiErrorsC: ApiErrorsC = t.type({
  error: imported6_ResponseCodeCU,
  errors: readonlyNonEmptyArrayC(apiErrorC)
}) satisfies t.Type<ApiErrors, unknown>;


