import { DateQualifierU as imported3_DateQualifierU, DateQualifierCU as imported7_DateQualifierCU, DateQualifierCU as imported4_DateQualifierCU, DateQualifierCU as imported5_DateQualifierCU, DateQualifierU as imported2_DateQualifierU, DateQualifierCU as imported6_DateQualifierCU } from "../domaintables/dateQualifiers";
import { LocalDateC } from "../../codecs/localDate";
import * as t from "io-ts";
import { LocalDate } from "@js-joda/core";

export type DateQualifierC = t.TypeC<{
  qualifier: imported5_DateQualifierCU,
  date: LocalDateC
}>;
export type DateQualifier = {
  qualifier: imported2_DateQualifierU,
  date: LocalDate
};
export const dateQualifierC: DateQualifierC = t.type({
  qualifier: imported4_DateQualifierCU,
  date: LocalDateC
}) satisfies t.Type<DateQualifier, unknown>;


export type DateQualifierRangeC = t.TypeC<{
  qualifier: imported7_DateQualifierCU,
  date: LocalDateC,
  endDate: LocalDateC
}>;
export type DateQualifierRange = {
  qualifier: imported3_DateQualifierU,
  date: LocalDate,
  endDate: LocalDate
};
export const dateQualifierRangeC: DateQualifierRangeC = t.type({
  qualifier: imported6_DateQualifierCU,
  date: LocalDateC,
  endDate: LocalDateC
}) satisfies t.Type<DateQualifierRange, unknown>;


