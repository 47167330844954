import { WithStatusCU as imported519_WithStatusCU, WithStatusU as imported259_WithStatusU, WithStatusCU as imported518_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { InvestorPortalOfferingC as imported1_InvestorPortalOfferingC, InvestorPortalOffering as imported1_InvestorPortalOffering, investorPortalOfferingC as imported1_investorPortalOfferingC } from "./investorPortalOffering";
import { ClientTextItemC as imported20_ClientTextItemC, ClientTextItem as imported20_ClientTextItem, clientTextItemC as imported20_clientTextItemC } from "./clientTextItem";

export type BankCalendarDataC = t.TypeC<{
  offerings: t.ReadonlyArrayC<imported1_InvestorPortalOfferingC>,
  about: OptionFromNullableC<imported519_WithStatusCU<imported20_ClientTextItemC>>
}>;
export type BankCalendarData = {
  offerings: ReadonlyArray<imported1_InvestorPortalOffering>,
  about: O.Option<imported259_WithStatusU<imported20_ClientTextItem>>
};
export const bankCalendarDataC: BankCalendarDataC = t.type({
  offerings: t.readonlyArray(imported1_investorPortalOfferingC),
  about: optionFromNullable(imported518_WithStatusCU(imported20_clientTextItemC))
}) satisfies t.Type<BankCalendarData, unknown>;


