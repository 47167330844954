import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";

export type AnalystC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  email: OptionFromNullableC<t.StringC>,
  phoneNumber: OptionFromNullableC<t.StringC>
}>;
export type Analyst = {
  firstName: string,
  lastName: string,
  email: O.Option<string>,
  phoneNumber: O.Option<string>
};
export const analystC: AnalystC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string)
}) satisfies t.Type<Analyst, unknown>;


export type AnalystPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  firstName: t.StringC,
  lastName: t.StringC,
  email: OptionFromNullableC<t.StringC>,
  phoneNumber: OptionFromNullableC<t.StringC>
}>;
export type AnalystPost = {
  id: O.Option<number>,
  firstName: string,
  lastName: string,
  email: O.Option<string>,
  phoneNumber: O.Option<string>
};
export const analystPostC: AnalystPostC = t.type({
  id: optionFromNullable(t.number),
  firstName: t.string,
  lastName: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string)
}) satisfies t.Type<AnalystPost, unknown>;


