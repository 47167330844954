import * as E from "fp-ts/Either";
import type * as Rdr from "fp-ts/lib/Reader";
import type * as t from "io-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";

export type SafeCallError = {
  _tag: string;
  error: Error | t.Errors;
};

/**
 * Makes any function safe.
 */
export const dangerousSafeCall =
  (errConstructor: (err: Error) => SafeCallError) =>
    <A, R>(fn: (...args: A[]) => R) =>
      (...params: A[]): Rdr.Reader<BLConfigWithLog, E.Either<SafeCallError, R>> =>
        config =>
          E.tryCatch(
            (): R => fn(...params),
            (e: unknown) => {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              const msg = `Error calling ${fn.name} with ${params.toString()}: ${e}`;
              config.log.error(msg);
              return errConstructor(new Error(msg));
            }
          );
