import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types";

import { NumberFromUnknown } from "@scripts/codecs/numberFromUnknown";
import { QueryStringArray } from "@scripts/codecs/routing";
import { pipe, RNEA } from "@scripts/fp-ts";
import * as ivp from "@scripts/generated/domaintables/investorPortalPages";
import { allRole } from "@scripts/generated/domaintables/roles";
import { defaultRfpStatus } from "@scripts/syntax/rfpStatus";
import { pick } from "@scripts/util/pick";
import { prop } from "@scripts/util/prop";

import type { PageMeta } from "../base";
import { makeParams } from "../base";
import type { PortalPageMeta } from "../pageMeta";
import { makePageMeta } from "../pageMeta";
import { bondStatusParamC, rfpStatusParamC, rfpStatusParamDefault, sectorsParamC, sectorsParamDefault, statesParamC, statesParamDefault } from "../params/base";
import type { CalendarSortColumnTypes } from "../params/bondSaleCalendarParams";
import { calendarDefaultParams, calendarParamsC, calendarSortColumnTypesC } from "../params/bondSaleCalendarParams";
import { issuerFeedDaysToLoad } from "../params/date";
import { methodOfSaleParamC, methodOfSaleParamDefault, otherIssuerFiltersParamC, otherIssuerFiltersParamDefault } from "../params/other";
import { defaultTablePageNumber, defaultTableParams, qsWithTableParams } from "../params/tableParams";
import { bondSaleCalendarMeta, bondsWatchlistMeta, dashboardMeta, issuerFeedMeta, issuerWatchlistMeta, rfpWatchlistMeta, sectorsWatchlistMeta, stateWatchlistMeta } from "./dataMeta";

const invMetaToPortalMeta = <A extends ivp.InvestorPortalPageU>(invMeta: A): PortalPageMeta<A["_tag"]> => ({
  _tag: invMeta._tag,
  bankAllowed: true,
  rolesAllowed: allRole,
  featuresRequired: [],
});

// * DASHBOARD
const dashboardMatch: ivp.DashboardRoute = ivp.dashboardRoute();
export const dashboard = (): PageMeta<never> =>
  makePageMeta(dashboardMatch)(O.none)(invMetaToPortalMeta(ivp.dashboard), dashboardMeta);

// * ISSUER FEED

export const allOrSubscribedC = t.union([t.literal("all"), t.literal("subscribed")]);

export const activityTypeC = t.union([
  t.literal("bonds"),
  t.literal("calendar"),
  t.literal("new-issuers"),
  t.literal("news"),
  t.literal("documents"),
  t.literal("ratings"),
  t.literal("rfps"),
]);
export type ActivityType = t.TypeOf<typeof activityTypeC>;

export const allActivityTypes = pipe(
  activityTypeC.types,
  RNEA.fromNativeReadonlyNonEmptyArray<(typeof activityTypeC.types)[number]>,
  RNEA.map(prop("value"))
);

const issuerFeedQueryParams = t.exact(t.partial({
  duration: NumberFromUnknown,
  types: QueryStringArray(activityTypeC),
  allOrSubscribed: allOrSubscribedC,
}));

export const makeIssuerFeedParams = (q: O.Option<IssuerFeedParamsPartial>): IssuerFeedParams => makeParams<IssuerFeedParams>({
  duration: issuerFeedDaysToLoad,
  types: [],
  allOrSubscribed: "all",
})(q);
type IssuerFeedParamsPartial = typeof issuerFeedQueryParams._A;
export type IssuerFeedParams = Required<IssuerFeedParamsPartial>;

export const issuerFeedMatch: ivp.IssuerFeedRoute<IssuerFeedParamsPartial> = ivp.issuerFeedRoute(issuerFeedQueryParams);

export const issuerFeed = (q: O.Option<IssuerFeedParamsPartial>): PageMeta<IssuerFeedParams> =>
  makePageMeta(
    issuerFeedMatch,
    makeIssuerFeedParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.issuerFeed),
    issuerFeedMeta
  );

// * BOND SALE CALENDAR

/** @knipignore - used in corp */
export const calendarViewTypesC = t.union([
  t.literal("allOfferings"),
  t.literal("competitive"),
  t.literal("negotiated"),
  t.literal("rfps"),
]);
type CalendarViewTypesC = typeof calendarViewTypesC;
export type CalendarViewTypes = t.TypeOf<CalendarViewTypesC>;

const rfpSortColumnTypesC = t.union([
  t.literal("bidsDue-asc"),
  t.literal("bidsDue-desc"),
  t.literal("issuerName-asc"),
  t.literal("issuerName-desc"),
  t.literal("rfpName-asc"),
  t.literal("rfpName-desc"),
  t.literal("loanAmount-asc"),
  t.literal("loanAmount-desc"),
]);

type RfpSortColumnTypesC = typeof rfpSortColumnTypesC;
export type RfpSortColumnTypes = t.TypeOf<RfpSortColumnTypesC>;

const fullCalendarQueryParams = qsWithTableParams(calendarSortColumnTypesC, t.type({
  ...calendarParamsC.props,
  view: calendarViewTypesC,
  rfpSortBy: rfpSortColumnTypesC,
}));
type FullCalendarQueryParamsPartial = typeof fullCalendarQueryParams._A;
export type FullCalendarQueryParams = Required<FullCalendarQueryParamsPartial>;

/** @knipignore - used in corp */
export const fullCalendarDefaultSortBy: CalendarSortColumnTypes = "saleDate-asc";
/** @knipignore - used in corp */
export const rfpDefaultSortBy: RfpSortColumnTypes = "bidsDue-asc";
/** @knipignore - used in corp */
export const calendarDefaultView: CalendarViewTypes = "allOfferings";

export const makeFullCalendarQueryParams = (q: O.Option<FullCalendarQueryParamsPartial>): FullCalendarQueryParams => makeParams<FullCalendarQueryParams>({
  ...calendarDefaultParams,
  rfpSortBy: rfpDefaultSortBy,
  sortBy: fullCalendarDefaultSortBy,
  view: calendarDefaultView,
})(q);

export const bondSaleCalendarMatch: ivp.BondSaleCalendarRoute<FullCalendarQueryParamsPartial> = ivp.bondSaleCalendarRoute(fullCalendarQueryParams);

export const bondSaleCalendar = (q: O.Option<FullCalendarQueryParamsPartial>): PageMeta<FullCalendarQueryParams> =>
  makePageMeta(
    bondSaleCalendarMatch,
    makeFullCalendarQueryParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.bondSaleCalendar),
    bondSaleCalendarMeta
  );

const issuerWatchlistSortColumnTypesC = t.union([
  t.literal("issuerName-asc"),
  t.literal("issuerName-desc"),
  t.literal("sector-asc"),
  t.literal("sector-desc"),
  t.literal("state-asc"),
  t.literal("state-desc"),
  t.literal("activeBonds-asc"),
  t.literal("activeBonds-desc"),
  t.literal("activeRfps-asc"),
  t.literal("activeRfps-desc"),
]);

const issuerWatchlistParamsC = t.type({
  ...sectorsParamC.props,
  ...statesParamC.props,
  ...otherIssuerFiltersParamC.props,
  unsubscribedPage: NumberFromString,
});

const issuerWatchlistQueryParams = qsWithTableParams(issuerWatchlistSortColumnTypesC, issuerWatchlistParamsC);
type IssuerWatchlistQueryParamsPartial = typeof issuerWatchlistQueryParams._A;
export type IssuerWatchlistQueryParams = Required<IssuerWatchlistQueryParamsPartial>;

export const issuerWatchlistMatch: ivp.WatchlistIssuersRoute<IssuerWatchlistQueryParamsPartial> = ivp.watchlistIssuersRoute(issuerWatchlistQueryParams);

export const makeIssuerWatchlistParams = (q: O.Option<IssuerWatchlistQueryParamsPartial>): IssuerWatchlistQueryParams => makeParams<IssuerWatchlistQueryParams>({
  ...defaultTableParams("issuerName-asc"),
  ...sectorsParamDefault,
  ...statesParamDefault,
  ...otherIssuerFiltersParamDefault,
  unsubscribedPage: defaultTablePageNumber,
})(q);

export const issuerWatchlist = (q: O.Option<IssuerWatchlistQueryParamsPartial>): PageMeta<IssuerWatchlistQueryParams> =>
  makePageMeta(
    issuerWatchlistMatch,
    makeIssuerWatchlistParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.watchlistIssuers),
    issuerWatchlistMeta
  );

export const watchlist = (): PageMeta<BondsWatchlistQueryParams> => ({
  ...bondsWatchlist(O.none),
  _tag: "watchlist",
  dataMeta: O.none,
  title: () => "Watchlist",
});

const bondsWatchlistFilterParamsC = t.type({
  ...sectorsParamC.props,
  ...statesParamC.props,
  ...methodOfSaleParamC.props,
  ...bondStatusParamC.props,
  search: t.string,
});

const bondsWatchlistParams = qsWithTableParams(calendarSortColumnTypesC, t.type({
  ...bondsWatchlistFilterParamsC.props,
  unsubscribedPage: NumberFromUnknown,
}));

type BondsWatchlistQueryParamsPartial = typeof bondsWatchlistParams._A;
export type BondsWatchlistQueryParams = Required<BondsWatchlistQueryParamsPartial>;

export const makeBondsWatchlistQueryParams = (q: O.Option<BondsWatchlistQueryParamsPartial>): BondsWatchlistQueryParams => makeParams<BondsWatchlistQueryParams>({
  ...pick("page", "search", "sortBy")(calendarDefaultParams),
  ...sectorsParamDefault,
  ...statesParamDefault,
  ...methodOfSaleParamDefault,
  bondStatus: "active",
  unsubscribedPage: calendarDefaultParams.page,
})(q);

export const bondsWatchlistMatch: ivp.WatchlistBondsRoute<BondsWatchlistQueryParamsPartial> = ivp.watchlistBondsRoute(bondsWatchlistParams);

export const bondsWatchlist = (q: O.Option<BondsWatchlistQueryParamsPartial>): PageMeta<BondsWatchlistQueryParams> =>
  makePageMeta(
    bondsWatchlistMatch,
    makeBondsWatchlistQueryParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.watchlistBonds),
    bondsWatchlistMeta
  );

// * RFP Watchlist

const rfpWatchlistSortColumnTypesC = t.union([
  t.literal("bidsDue-asc"),
  t.literal("bidsDue-desc"),
  t.literal("issuerName-asc"),
  t.literal("issuerName-desc"),
  t.literal("rfpName-asc"),
  t.literal("rfpName-desc"),
  t.literal("loanAmount-asc"),
  t.literal("loanAmount-desc"),
]);

type RfpWatchlistSortColumnTypesC = typeof rfpWatchlistSortColumnTypesC;
export type RfpWatchlistSortColumnTypes = t.TypeOf<RfpWatchlistSortColumnTypesC>;

const rfpWatchlistParamsC = qsWithTableParams(rfpWatchlistSortColumnTypesC, t.type({
  ...sectorsParamC.props,
  ...statesParamC.props,
  ...rfpStatusParamC.props,
  unsubscribedPage: NumberFromUnknown,
}));
type RfpWatchlistQueryParamsPartial = typeof rfpWatchlistParamsC._A;
export type RfpWatchlistQueryParams = Required<RfpWatchlistQueryParamsPartial>;

export const makeRfpWatchlistQueryParams = (q: O.Option<RfpWatchlistQueryParamsPartial>): RfpWatchlistQueryParams => makeParams<RfpWatchlistQueryParams>({
  ...defaultTableParams("bidsDue-asc"),
  ...sectorsParamDefault,
  ...statesParamDefault,
  rfpStatus: defaultRfpStatus,
  unsubscribedPage: defaultTablePageNumber,
})(q);

// Use this when rendering from the url so that the default state doesn't reselect when a user tries to deselect everything
export const makeRfpWatchlistRenderParams = (q: O.Option<RfpWatchlistQueryParamsPartial>): RfpWatchlistQueryParams => makeParams<RfpWatchlistQueryParams>({
  ...defaultTableParams("bidsDue-asc"),
  ...sectorsParamDefault,
  ...statesParamDefault,
  ...rfpStatusParamDefault,
  unsubscribedPage: defaultTablePageNumber,
})(q);

export const rfpWatchlistMatch: ivp.WatchlistRfpsRoute<RfpWatchlistQueryParamsPartial> = ivp.watchlistRfpsRoute(rfpWatchlistParamsC);

export const rfpWatchlist = (q: O.Option<RfpWatchlistQueryParamsPartial>): PageMeta<RfpWatchlistQueryParams> =>
  makePageMeta(
    rfpWatchlistMatch,
    makeRfpWatchlistQueryParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.watchlistRfps),
    rfpWatchlistMeta
  );


// * SECTORS WATCHLIST

const sectorsStatesWatchlistSortColumnTypesC = t.union([
  t.literal("name-asc"),
  t.literal("name-desc"),
  t.literal("activeBonds-asc"),
  t.literal("activeBonds-desc"),
  t.literal("activeRfps-asc"),
  t.literal("activeRfps-desc"),
  t.literal("issuers-asc"),
  t.literal("issuers-desc"),
]);
type SectorsStatesWatchlistSortColumnTypesC = typeof sectorsStatesWatchlistSortColumnTypesC;
export type SectorsStatesWatchlistSortColumnTypes = t.TypeOf<SectorsStatesWatchlistSortColumnTypesC>;

const sectorsStatesWatchlistParams = qsWithTableParams(sectorsStatesWatchlistSortColumnTypesC, t.type({}));

type SectorsStatesWatchlistQueryParamsPartial = typeof sectorsStatesWatchlistParams._A;
export type SectorsStatesWatchlistQueryParams = Required<SectorsStatesWatchlistQueryParamsPartial>;

export const makeSectorsStatesWatchlistQueryParams = (q: O.Option<SectorsStatesWatchlistQueryParamsPartial>): SectorsStatesWatchlistQueryParams => makeParams<SectorsStatesWatchlistQueryParams>({
  ...defaultTableParams("name-asc"),
})(q);

export const sectorsWatchlistMatch: ivp.WatchlistSectorsRoute<SectorsStatesWatchlistQueryParamsPartial> = ivp.watchlistSectorsRoute(sectorsStatesWatchlistParams);

export const sectorsWatchlist = (q: O.Option<SectorsStatesWatchlistQueryParamsPartial>): PageMeta<SectorsStatesWatchlistQueryParams> =>
  makePageMeta(
    sectorsWatchlistMatch,
    makeSectorsStatesWatchlistQueryParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.watchlistSectors),
    sectorsWatchlistMeta
  );

export const stateWatchlistMatch: ivp.WatchlistStatesRoute<SectorsStatesWatchlistQueryParamsPartial> = ivp.watchlistStatesRoute(sectorsStatesWatchlistParams);

export const stateWatchlist = (q: O.Option<SectorsStatesWatchlistQueryParamsPartial>): PageMeta<SectorsStatesWatchlistQueryParams> =>
  makePageMeta(
    stateWatchlistMatch,
    makeSectorsStatesWatchlistQueryParams(q),
  )(
    O.none
  )(
    invMetaToPortalMeta(ivp.watchlistStates),
    stateWatchlistMeta
  );
