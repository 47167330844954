import { SortColumnCU as imported7_SortColumnCU, SortColumnU as imported3_SortColumnU, SortColumnCU as imported6_SortColumnCU } from "../domaintables/sortColumns";
import { SortDirCU as imported3_SortDirCU, SortDirU as imported1_SortDirU, SortDirCU as imported2_SortDirCU } from "../domaintables/sortDir";
import * as t from "io-ts";
import { SortableU as imported5_SortableU, SortableCU as imported11_SortableCU, SortableCU as imported10_SortableCU, SortableU as imported4_SortableU, SortableCU as imported8_SortableCU, SortableCU as imported9_SortableCU } from "../domaintables/pages";

export type PageSortC = t.TypeC<{
  column: imported7_SortColumnCU,
  direction: imported3_SortDirCU
}>;
export type PageSort = {
  column: imported3_SortColumnU,
  direction: imported1_SortDirU
};
export const pageSortC: PageSortC = t.type({
  column: imported6_SortColumnCU,
  direction: imported2_SortDirCU
}) satisfies t.Type<PageSort, unknown>;


export type PageSortsC = t.ReadonlyArrayC<t.TupleC<[imported9_SortableCU, PageSortC]>>;
export type PageSorts = ReadonlyArray<[imported4_SortableU, PageSort]>;
export const pageSortsC: PageSortsC = t.readonlyArray(t.tuple([imported8_SortableCU, pageSortC])) satisfies t.Type<PageSorts, unknown>;


export type PostSortC = t.TypeC<{
  page: imported11_SortableCU,
  sort: PageSortC
}>;
export type PostSort = {
  page: imported5_SortableU,
  sort: PageSort
};
export const postSortC: PostSortC = t.type({
  page: imported10_SortableCU,
  sort: pageSortC
}) satisfies t.Type<PostSort, unknown>;


