import { WithIdC as imported197_WithIdC, WithId as imported197_WithId, withIdC as imported197_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { DocumentWithCategoryC as imported13_DocumentWithCategoryC, DocumentWithCategory as imported13_DocumentWithCategory, documentWithCategoryC as imported13_documentWithCategoryC } from "./document";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { ActivitiesC as imported2_ActivitiesC, Activities as imported2_Activities, activitiesC as imported2_activitiesC } from "./dealPortalActivity";
import { LocalDateTime } from "@js-joda/core";
import { ActorC as imported6_ActorC, Actor as imported6_Actor, actorC as imported6_actorC } from "./actor";

export type InvestorActivityRowC = t.TypeC<{
  actor: imported6_ActorC,
  activities: imported2_ActivitiesC,
  lastActive: LocalDateTimeFromIsoStringC
}>;
export type InvestorActivityRow = {
  actor: imported6_Actor,
  activities: imported2_Activities,
  lastActive: LocalDateTime
};
export const investorActivityRowC: InvestorActivityRowC = t.type({
  actor: imported6_actorC,
  activities: imported2_activitiesC,
  lastActive: LocalDateTimeFromIsoStringC
}) satisfies t.Type<InvestorActivityRow, unknown>;


export type InvestorActivityTableC = t.TypeC<{
  investorActivityRows: t.ReadonlyArrayC<InvestorActivityRowC>,
  documents: t.ReadonlyArrayC<imported197_WithIdC<imported13_DocumentWithCategoryC>>
}>;
export type InvestorActivityTable = {
  investorActivityRows: ReadonlyArray<InvestorActivityRow>,
  documents: ReadonlyArray<imported197_WithId<imported13_DocumentWithCategory>>
};
export const investorActivityTableC: InvestorActivityTableC = t.type({
  investorActivityRows: t.readonlyArray(investorActivityRowC),
  documents: t.readonlyArray(imported197_withIdC(imported13_documentWithCategoryC))
}) satisfies t.Type<InvestorActivityTable, unknown>;


