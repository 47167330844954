/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { getMonoid } from "fp-ts/lib/Record";
import * as Sg from "fp-ts/lib/Semigroup";

import { eq } from "./eq";
import { prop } from "./prop";
import { wrapArr } from "./wrapArr";

const fToS = ({}).hasOwnProperty.toString;

export const isObj = (a: any): a is object => pipe(O.fromNullable(a),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  O.filter(flow(_ => _.toString(), eq("[object Object]"))),
  O.fold(
    () => false,
    (x: any) => pipe(O.fromNullable(Object.getPrototypeOf(x)), O.chainNullableK(prop("constructor")), O.fold(
      () => true,
      (ctor: any) => typeof ctor === "function" && fToS.call(ctor) === fToS.call(Object)))));

export const mergeWith = (sg: Sg.Semigroup<any>) => <A extends Record<string, any>>(a: A) => <B extends Record<string, any>>(b: B): A & B =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  <A & B>(<unknown>getMonoid(sg).concat(a, b));

const defaultSg: Sg.Semigroup<any> = Sg.last();

const deepSg: Sg.Semigroup<any> = {
  concat: (x: any, y: any) => {
    if (Array.isArray(x) && Array.isArray(y)) {
      return x.concat(y);
    } else if (isObj(x) && isObj(y)) {
      return mergeWith(deepSg)(x)(y);
    } else {
      return defaultSg.concat(x, y);
    }
  },
};

export const deepArrSg: Sg.Semigroup<any> = {
  concat: (x: any, y: any) => {
    if (Array.isArray(x) || Array.isArray(y)) {
      return wrapArr(x).concat(wrapArr(y));
    } else if (isObj(x) && isObj(y)) {
      return mergeWith(deepArrSg)(x)(y);
    } else {
      return [x, y];
    }
  },
};

export const merge = mergeWith(defaultSg);
export const mergeDeep = mergeWith(deepSg);
