import * as t from "io-ts";
import { LocalDate } from "@js-joda/core";
import { CustomReportTypeCU as imported3_CustomReportTypeCU, CustomReportTypeU as imported1_CustomReportTypeU, CustomReportTypeCU as imported2_CustomReportTypeCU } from "../domaintables/customReportTypes";
import { MediaC as imported82_MediaC, Media as imported82_Media, mediaC as imported78_mediaC } from "./media";
import { LocalDateC } from "../../codecs/localDate";

export type CustomReportC = t.TypeC<{
  date: LocalDateC,
  name: t.StringC,
  document: imported82_MediaC,
  reportType: imported3_CustomReportTypeCU
}>;
export type CustomReport = {
  date: LocalDate,
  name: string,
  document: imported82_Media,
  reportType: imported1_CustomReportTypeU
};
export const customReportC: CustomReportC = t.type({
  date: LocalDateC,
  name: t.string,
  document: imported78_mediaC,
  reportType: imported2_CustomReportTypeCU
}) satisfies t.Type<CustomReport, unknown>;


