// This file was generated by postcss-transpiler. Do not edit by hand.

export const modal = {
  ".modal-background": {
    css: ".modal-background",
    html: "modal-background",
    attrs: {
      ".hiding": {
        css: ".hiding",
        html: "hiding"
      },
      ".show": {
        css: ".show",
        html: "show"
      }
    }
  },
  ".modal": {
    css: ".modal",
    html: "modal",
    "button": {
      css: "button",
      html: "button",
      attrs: {
        ".btn-close": {
          css: ".btn-close",
          html: "btn-close"
        }
      }
    },
    ".inline-edit-buttons": {
      css: ".inline-edit-buttons",
      html: "inline-edit-buttons"
    },
    ".disclaimer-edit-buttons": {
      css: ".disclaimer-edit-buttons",
      html: "disclaimer-edit-buttons"
    },
    ".modal-content": {
      css: ".modal-content",
      html: "modal-content"
    },
    ".modal-dialog": {
      css: ".modal-dialog",
      html: "modal-dialog"
    },
    ".modal-body": {
      css: ".modal-body",
      html: "modal-body",
      "h3": {
        css: "h3",
        html: "h3"
      },
      "p": {
        css: "p",
        html: "p"
      },
      ".md-to-html": {
        css: ".md-to-html",
        html: "md-to-html",
        "p": {
          css: "p",
          html: "p"
        }
      },
      "form": {
        css: "form",
        html: "form",
        ".modal-note": {
          css: ".modal-note",
          html: "modal-note"
        }
      }
    },
    ".modal-header": {
      css: ".modal-header",
      html: "modal-header",
      ".modal-title": {
        css: ".modal-title",
        html: "modal-title",
        ".modal-title-text": {
          css: ".modal-title-text",
          html: "modal-title-text"
        }
      },
      ".icon-button": {
        css: ".icon-button",
        html: "icon-button",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    },
    ".confirmation-error": {
      css: ".confirmation-error",
      html: "confirmation-error",
      ".alert": {
        css: ".alert",
        html: "alert"
      }
    },
    ".modal-footer": {
      css: ".modal-footer",
      html: "modal-footer"
    }
  },
  ".content-modal": {
    css: ".content-modal",
    html: "content-modal",
    ".modal-content": {
      css: ".modal-content",
      html: "modal-content"
    },
    ".modal-footer": {
      css: ".modal-footer",
      html: "modal-footer",
      attrs: {
        ".bt-0": {
          css: ".bt-0",
          html: "bt-0"
        }
      }
    }
  },
  ".filters-open": {
    css: ".filters-open",
    html: "filters-open",
    ".modal": {
      css: ".modal",
      html: "modal"
    }
  },
  ".modal-open": {
    css: ".modal-open",
    html: "modal-open",
    ".modal": {
      css: ".modal",
      html: "modal"
    }
  },
  ".modal-dialog": {
    css: ".modal-dialog",
    html: "modal-dialog"
  },
  ".modal-md": {
    css: ".modal-md",
    html: "modal-md",
    ".modal-dialog": {
      css: ".modal-dialog",
      html: "modal-dialog"
    }
  },
  ".modal-viewport": {
    css: ".modal-viewport",
    html: "modal-viewport"
  },
  ".modal-content": {
    css: ".modal-content",
    html: "modal-content",
    ".modal-body": {
      css: ".modal-body",
      html: "modal-body"
    },
    "form": {
      css: "form",
      html: "form"
    }
  },
  "div": {
    css: "div",
    html: "div",
    "iframe": {
      css: "iframe",
      html: "iframe",
      attrs: {
        ".email-preview": {
          css: ".email-preview",
          html: "email-preview"
        }
      }
    }
  },
  ".modal-title": {
    css: ".modal-title",
    html: "modal-title",
    "h2": {
      css: "h2",
      html: "h2"
    }
  },
  ".modal-body": {
    css: ".modal-body",
    html: "modal-body",
    ":last-child": {
      css: ":last-child",
      html: "last-child"
    },
    ".draft-status-cta": {
      css: ".draft-status-cta",
      html: "draft-status-cta",
      ".btn-callout": {
        css: ".btn-callout",
        html: "btn-callout"
      }
    }
  },
  ".modal-footer": {
    css: ".modal-footer",
    html: "modal-footer",
    ".btn": {
      css: ".btn",
      html: "btn"
    }
  },
  ".modal-moved-back": {
    css: ".modal-moved-back",
    html: "modal-moved-back"
  },
  ".modal-sm": {
    css: ".modal-sm",
    html: "modal-sm",
    attrs: {
      ".modal": {
        css: ".modal",
        html: "modal"
      }
    },
    ".modal-dialog": {
      css: ".modal-dialog",
      html: "modal-dialog",
      ".modal-body": {
        css: ".modal-body",
        html: "modal-body"
      }
    },
    ".modal-header": {
      css: ".modal-header",
      html: "modal-header",
      ".modal-title": {
        css: ".modal-title",
        html: "modal-title",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    }
  },
  ".modal-lg": {
    css: ".modal-lg",
    html: "modal-lg",
    attrs: {
      ".modal": {
        css: ".modal",
        html: "modal"
      }
    },
    ".modal-dialog": {
      css: ".modal-dialog",
      html: "modal-dialog",
      ".modal-body": {
        css: ".modal-body",
        html: "modal-body"
      }
    },
    ".modal-header": {
      css: ".modal-header",
      html: "modal-header",
      ".modal-title": {
        css: ".modal-title",
        html: "modal-title",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    }
  },
  ".disclaimer-modal": {
    css: ".disclaimer-modal",
    html: "disclaimer-modal",
    ".modal-content": {
      css: ".modal-content",
      html: "modal-content",
      ".modal-body": {
        css: ".modal-body",
        html: "modal-body",
        ".disclaimer-content": {
          css: ".disclaimer-content",
          html: "disclaimer-content",
          ".md-to-html": {
            css: ".md-to-html",
            html: "md-to-html"
          }
        }
      },
      ".modal-footer": {
        css: ".modal-footer",
        html: "modal-footer",
        "button": {
          css: "button",
          html: "button"
        }
      }
    }
  }
};
