import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const sub = {
  _tag: `Sub`,
  name: `Sub`
} as const;

export type SubTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Sub`>
}>;
export const subTaggedC: SubTaggedC = t.type({
  _tag: t.literal(`Sub`)
});
export type SubTagged = t.TypeOf<SubTaggedC>;
export type Sub = SubTagged & typeof sub;
export type SubC = t.Type<Sub, SubTagged>;
export const subC: SubC = pipe(subTaggedC, c => new t.Type<Sub, SubTagged>(
  `Sub`,
  (u: unknown): u is Sub => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Sub> => pipe(c.decode(u), E.map(x => ({ ...x, ...sub }))),
  (x: Sub): SubTagged => ({ ...x, _tag: `Sub`}),
)) satisfies t.Type<Sub, unknown>;


export const unsub = {
  _tag: `Unsub`,
  name: `Unsub`
} as const;

export type UnsubTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Unsub`>
}>;
export const unsubTaggedC: UnsubTaggedC = t.type({
  _tag: t.literal(`Unsub`)
});
export type UnsubTagged = t.TypeOf<UnsubTaggedC>;
export type Unsub = UnsubTagged & typeof unsub;
export type UnsubC = t.Type<Unsub, UnsubTagged>;
export const unsubC: UnsubC = pipe(unsubTaggedC, c => new t.Type<Unsub, UnsubTagged>(
  `Unsub`,
  (u: unknown): u is Unsub => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Unsub> => pipe(c.decode(u), E.map(x => ({ ...x, ...unsub }))),
  (x: Unsub): UnsubTagged => ({ ...x, _tag: `Unsub`}),
)) satisfies t.Type<Unsub, unknown>;


export const allSubscriptionChangeC = [subC, unsubC] as const;
export const allSubscriptionChangeNames = [`Sub`, `Unsub`] as const;
export type SubscriptionChangeName = (typeof allSubscriptionChangeNames)[number];

export type SubscriptionChangeCU = t.UnionC<[SubC, UnsubC]>;
export type SubscriptionChangeU = Sub | Unsub;
export const SubscriptionChangeCU: SubscriptionChangeCU = t.union([subC, unsubC]) satisfies t.Type<SubscriptionChangeU, unknown>;

export const subscriptionChangeOrd: Ord.Ord<SubscriptionChangeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSubscriptionChange = [sub, unsub] as const;
export type SubscriptionChangeMap<A> = { [K in SubscriptionChangeName]: A };


