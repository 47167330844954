import { EitherC, either } from "io-ts-types/lib/either";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";

export type BrowserRedirectC = t.TypeC<{
  status: EitherC<t.NumberC, t.NumberC>,
  redirect: t.StringC
}>;
export type BrowserRedirect = {
  status: E.Either<number, number>,
  redirect: string
};
export const browserRedirectC: BrowserRedirectC = t.type({
  status: either(t.number, t.number),
  redirect: t.string
}) satisfies t.Type<BrowserRedirect, unknown>;


