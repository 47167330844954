import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const noContent = {
  _tag: `NoContent`,
  name: `NoContent`
} as const;

export type NoContentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NoContent`>
}>;
export const noContentTaggedC: NoContentTaggedC = t.type({
  _tag: t.literal(`NoContent`)
});
export type NoContentTagged = t.TypeOf<NoContentTaggedC>;
export type NoContent = NoContentTagged & typeof noContent;
export type NoContentC = t.Type<NoContent, NoContentTagged>;
export const noContentC: NoContentC = pipe(noContentTaggedC, c => new t.Type<NoContent, NoContentTagged>(
  `NoContent`,
  (u: unknown): u is NoContent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NoContent> => pipe(c.decode(u), E.map(x => ({ ...x, ...noContent }))),
  (x: NoContent): NoContentTagged => ({ ...x, _tag: `NoContent`}),
)) satisfies t.Type<NoContent, unknown>;


export const someContent = {
  _tag: `SomeContent`,
  name: `SomeContent`
} as const;

export type SomeContentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SomeContent`>
}>;
export const someContentTaggedC: SomeContentTaggedC = t.type({
  _tag: t.literal(`SomeContent`)
});
export type SomeContentTagged = t.TypeOf<SomeContentTaggedC>;
export type SomeContent = SomeContentTagged & typeof someContent;
export type SomeContentC = t.Type<SomeContent, SomeContentTagged>;
export const someContentC: SomeContentC = pipe(someContentTaggedC, c => new t.Type<SomeContent, SomeContentTagged>(
  `SomeContent`,
  (u: unknown): u is SomeContent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SomeContent> => pipe(c.decode(u), E.map(x => ({ ...x, ...someContent }))),
  (x: SomeContent): SomeContentTagged => ({ ...x, _tag: `SomeContent`}),
)) satisfies t.Type<SomeContent, unknown>;


export const strongContent = {
  _tag: `StrongContent`,
  name: `StrongContent`
} as const;

export type StrongContentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StrongContent`>
}>;
export const strongContentTaggedC: StrongContentTaggedC = t.type({
  _tag: t.literal(`StrongContent`)
});
export type StrongContentTagged = t.TypeOf<StrongContentTaggedC>;
export type StrongContent = StrongContentTagged & typeof strongContent;
export type StrongContentC = t.Type<StrongContent, StrongContentTagged>;
export const strongContentC: StrongContentC = pipe(strongContentTaggedC, c => new t.Type<StrongContent, StrongContentTagged>(
  `StrongContent`,
  (u: unknown): u is StrongContent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StrongContent> => pipe(c.decode(u), E.map(x => ({ ...x, ...strongContent }))),
  (x: StrongContent): StrongContentTagged => ({ ...x, _tag: `StrongContent`}),
)) satisfies t.Type<StrongContent, unknown>;


export const allContentLevelC = [noContentC, someContentC, strongContentC] as const;
export const allContentLevelNames = [`NoContent`, `SomeContent`, `StrongContent`] as const;
export type ContentLevelName = (typeof allContentLevelNames)[number];

export type ContentLevelCU = t.UnionC<[NoContentC, SomeContentC, StrongContentC]>;
export type ContentLevelU = NoContent | SomeContent | StrongContent;
export const ContentLevelCU: ContentLevelCU = t.union([noContentC, someContentC, strongContentC]) satisfies t.Type<ContentLevelU, unknown>;

export const contentLevelOrd: Ord.Ord<ContentLevelU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allContentLevel = [noContent, someContent, strongContent] as const;
export type ContentLevelMap<A> = { [K in ContentLevelName]: A };


export const about = {
  _tag: `About`,
  name: `About`
} as const;

export type AboutTaggedC = t.TypeC<{
  _tag: t.LiteralC<`About`>
}>;
export const aboutTaggedC: AboutTaggedC = t.type({
  _tag: t.literal(`About`)
});
export type AboutTagged = t.TypeOf<AboutTaggedC>;
export type About = AboutTagged & typeof about;
export type AboutC = t.Type<About, AboutTagged>;
export const aboutC: AboutC = pipe(aboutTaggedC, c => new t.Type<About, AboutTagged>(
  `About`,
  (u: unknown): u is About => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, About> => pipe(c.decode(u), E.map(x => ({ ...x, ...about }))),
  (x: About): AboutTagged => ({ ...x, _tag: `About`}),
)) satisfies t.Type<About, unknown>;


export const ratings = {
  _tag: `Ratings`,
  name: `Ratings`
} as const;

export type RatingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Ratings`>
}>;
export const ratingsTaggedC: RatingsTaggedC = t.type({
  _tag: t.literal(`Ratings`)
});
export type RatingsTagged = t.TypeOf<RatingsTaggedC>;
export type Ratings = RatingsTagged & typeof ratings;
export type RatingsC = t.Type<Ratings, RatingsTagged>;
export const ratingsC: RatingsC = pipe(ratingsTaggedC, c => new t.Type<Ratings, RatingsTagged>(
  `Ratings`,
  (u: unknown): u is Ratings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ratings> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratings }))),
  (x: Ratings): RatingsTagged => ({ ...x, _tag: `Ratings`}),
)) satisfies t.Type<Ratings, unknown>;


export const cusipLinks = {
  _tag: `CusipLinks`,
  name: `CusipLinks`
} as const;

export type CusipLinksTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CusipLinks`>
}>;
export const cusipLinksTaggedC: CusipLinksTaggedC = t.type({
  _tag: t.literal(`CusipLinks`)
});
export type CusipLinksTagged = t.TypeOf<CusipLinksTaggedC>;
export type CusipLinks = CusipLinksTagged & typeof cusipLinks;
export type CusipLinksC = t.Type<CusipLinks, CusipLinksTagged>;
export const cusipLinksC: CusipLinksC = pipe(cusipLinksTaggedC, c => new t.Type<CusipLinks, CusipLinksTagged>(
  `CusipLinks`,
  (u: unknown): u is CusipLinks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CusipLinks> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusipLinks }))),
  (x: CusipLinks): CusipLinksTagged => ({ ...x, _tag: `CusipLinks`}),
)) satisfies t.Type<CusipLinks, unknown>;


export const documentUploads = {
  _tag: `DocumentUploads`,
  name: `DocumentUploads`
} as const;

export type DocumentUploadsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DocumentUploads`>
}>;
export const documentUploadsTaggedC: DocumentUploadsTaggedC = t.type({
  _tag: t.literal(`DocumentUploads`)
});
export type DocumentUploadsTagged = t.TypeOf<DocumentUploadsTaggedC>;
export type DocumentUploads = DocumentUploadsTagged & typeof documentUploads;
export type DocumentUploadsC = t.Type<DocumentUploads, DocumentUploadsTagged>;
export const documentUploadsC: DocumentUploadsC = pipe(documentUploadsTaggedC, c => new t.Type<DocumentUploads, DocumentUploadsTagged>(
  `DocumentUploads`,
  (u: unknown): u is DocumentUploads => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentUploads> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentUploads }))),
  (x: DocumentUploads): DocumentUploadsTagged => ({ ...x, _tag: `DocumentUploads`}),
)) satisfies t.Type<DocumentUploads, unknown>;


export const issuerBios = {
  _tag: `IssuerBios`,
  name: `IssuerBios`
} as const;

export type IssuerBiosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerBios`>
}>;
export const issuerBiosTaggedC: IssuerBiosTaggedC = t.type({
  _tag: t.literal(`IssuerBios`)
});
export type IssuerBiosTagged = t.TypeOf<IssuerBiosTaggedC>;
export type IssuerBios = IssuerBiosTagged & typeof issuerBios;
export type IssuerBiosC = t.Type<IssuerBios, IssuerBiosTagged>;
export const issuerBiosC: IssuerBiosC = pipe(issuerBiosTaggedC, c => new t.Type<IssuerBios, IssuerBiosTagged>(
  `IssuerBios`,
  (u: unknown): u is IssuerBios => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerBios> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerBios }))),
  (x: IssuerBios): IssuerBiosTagged => ({ ...x, _tag: `IssuerBios`}),
)) satisfies t.Type<IssuerBios, unknown>;


export const newsUpdates = {
  _tag: `NewsUpdates`,
  name: `NewsUpdates`
} as const;

export type NewsUpdatesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NewsUpdates`>
}>;
export const newsUpdatesTaggedC: NewsUpdatesTaggedC = t.type({
  _tag: t.literal(`NewsUpdates`)
});
export type NewsUpdatesTagged = t.TypeOf<NewsUpdatesTaggedC>;
export type NewsUpdates = NewsUpdatesTagged & typeof newsUpdates;
export type NewsUpdatesC = t.Type<NewsUpdates, NewsUpdatesTagged>;
export const newsUpdatesC: NewsUpdatesC = pipe(newsUpdatesTaggedC, c => new t.Type<NewsUpdates, NewsUpdatesTagged>(
  `NewsUpdates`,
  (u: unknown): u is NewsUpdates => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsUpdates> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsUpdates }))),
  (x: NewsUpdates): NewsUpdatesTagged => ({ ...x, _tag: `NewsUpdates`}),
)) satisfies t.Type<NewsUpdates, unknown>;


export const projects = {
  _tag: `Projects`,
  name: `Projects`
} as const;

export type ProjectsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Projects`>
}>;
export const projectsTaggedC: ProjectsTaggedC = t.type({
  _tag: t.literal(`Projects`)
});
export type ProjectsTagged = t.TypeOf<ProjectsTaggedC>;
export type Projects = ProjectsTagged & typeof projects;
export type ProjectsC = t.Type<Projects, ProjectsTagged>;
export const projectsC: ProjectsC = pipe(projectsTaggedC, c => new t.Type<Projects, ProjectsTagged>(
  `Projects`,
  (u: unknown): u is Projects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Projects> => pipe(c.decode(u), E.map(x => ({ ...x, ...projects }))),
  (x: Projects): ProjectsTagged => ({ ...x, _tag: `Projects`}),
)) satisfies t.Type<Projects, unknown>;


export const quickFacts = {
  _tag: `QuickFacts`,
  name: `QuickFacts`
} as const;

export type QuickFactsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`QuickFacts`>
}>;
export const quickFactsTaggedC: QuickFactsTaggedC = t.type({
  _tag: t.literal(`QuickFacts`)
});
export type QuickFactsTagged = t.TypeOf<QuickFactsTaggedC>;
export type QuickFacts = QuickFactsTagged & typeof quickFacts;
export type QuickFactsC = t.Type<QuickFacts, QuickFactsTagged>;
export const quickFactsC: QuickFactsC = pipe(quickFactsTaggedC, c => new t.Type<QuickFacts, QuickFactsTagged>(
  `QuickFacts`,
  (u: unknown): u is QuickFacts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QuickFacts> => pipe(c.decode(u), E.map(x => ({ ...x, ...quickFacts }))),
  (x: QuickFacts): QuickFactsTagged => ({ ...x, _tag: `QuickFacts`}),
)) satisfies t.Type<QuickFacts, unknown>;


export const upcomingEvents = {
  _tag: `UpcomingEvents`,
  name: `UpcomingEvents`
} as const;

export type UpcomingEventsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UpcomingEvents`>
}>;
export const upcomingEventsTaggedC: UpcomingEventsTaggedC = t.type({
  _tag: t.literal(`UpcomingEvents`)
});
export type UpcomingEventsTagged = t.TypeOf<UpcomingEventsTaggedC>;
export type UpcomingEvents = UpcomingEventsTagged & typeof upcomingEvents;
export type UpcomingEventsC = t.Type<UpcomingEvents, UpcomingEventsTagged>;
export const upcomingEventsC: UpcomingEventsC = pipe(upcomingEventsTaggedC, c => new t.Type<UpcomingEvents, UpcomingEventsTagged>(
  `UpcomingEvents`,
  (u: unknown): u is UpcomingEvents => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UpcomingEvents> => pipe(c.decode(u), E.map(x => ({ ...x, ...upcomingEvents }))),
  (x: UpcomingEvents): UpcomingEventsTagged => ({ ...x, _tag: `UpcomingEvents`}),
)) satisfies t.Type<UpcomingEvents, unknown>;


export const allProfileSectionC = [aboutC, ratingsC, cusipLinksC, documentUploadsC, issuerBiosC, newsUpdatesC, projectsC, quickFactsC, upcomingEventsC] as const;
export const allProfileSectionNames = [`About`, `Ratings`, `CusipLinks`, `DocumentUploads`, `IssuerBios`, `NewsUpdates`, `Projects`, `QuickFacts`, `UpcomingEvents`] as const;
export type ProfileSectionName = (typeof allProfileSectionNames)[number];

export type ProfileSectionCU = t.UnionC<[AboutC, RatingsC, CusipLinksC, DocumentUploadsC, IssuerBiosC, NewsUpdatesC, ProjectsC, QuickFactsC, UpcomingEventsC]>;
export type ProfileSectionU = About | Ratings | CusipLinks | DocumentUploads | IssuerBios | NewsUpdates | Projects | QuickFacts | UpcomingEvents;
export const ProfileSectionCU: ProfileSectionCU = t.union([aboutC, ratingsC, cusipLinksC, documentUploadsC, issuerBiosC, newsUpdatesC, projectsC, quickFactsC, upcomingEventsC]) satisfies t.Type<ProfileSectionU, unknown>;

export const profileSectionOrd: Ord.Ord<ProfileSectionU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allProfileSection = [about, ratings, cusipLinks, documentUploads, issuerBios, newsUpdates, projects, quickFacts, upcomingEvents] as const;
export type ProfileSectionMap<A> = { [K in ProfileSectionName]: A };


export type RankedMetricC = t.TypeC<{
  section: ProfileSectionCU,
  level: ContentLevelCU
}>;
export type RankedMetric = {
  section: ProfileSectionU,
  level: ContentLevelU
};
export const rankedMetricC: RankedMetricC = t.type({
  section: ProfileSectionCU,
  level: ContentLevelCU
}) satisfies t.Type<RankedMetric, unknown>;


export type ProfileMetricsC = t.TypeC<{
  noContent: t.ReadonlyArrayC<RankedMetricC>,
  someContent: t.ReadonlyArrayC<RankedMetricC>,
  strongContent: t.ReadonlyArrayC<RankedMetricC>,
  disabledSections: t.ReadonlyArrayC<ProfileSectionCU>
}>;
export type ProfileMetrics = {
  noContent: ReadonlyArray<RankedMetric>,
  someContent: ReadonlyArray<RankedMetric>,
  strongContent: ReadonlyArray<RankedMetric>,
  disabledSections: ReadonlyArray<ProfileSectionU>
};
export const profileMetricsC: ProfileMetricsC = t.type({
  noContent: t.readonlyArray(rankedMetricC),
  someContent: t.readonlyArray(rankedMetricC),
  strongContent: t.readonlyArray(rankedMetricC),
  disabledSections: t.readonlyArray(ProfileSectionCU)
}) satisfies t.Type<ProfileMetrics, unknown>;


