import * as t from "io-ts";

export type EmptyObjectC = t.TypeC<{

}>;
export type EmptyObject = {

};
export const emptyObjectC: EmptyObjectC = t.type({

}) satisfies t.Type<EmptyObject, unknown>;


