import * as t from "io-ts";

export type UserContentSubscriptionsC = t.TypeC<{
  userContentSubscriptions: t.ReadonlyArrayC<t.NumberC>
}>;
export type UserContentSubscriptions = {
  userContentSubscriptions: ReadonlyArray<number>
};
export const userContentSubscriptionsC: UserContentSubscriptionsC = t.type({
  userContentSubscriptions: t.readonlyArray(t.number)
}) satisfies t.Type<UserContentSubscriptions, unknown>;


