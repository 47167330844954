import * as t from "io-ts";
import { DocumentTypeC as imported10_DocumentTypeC, DocumentType as imported10_DocumentType, documentTypeC as imported10_documentTypeC } from "./document";
import { EitherC, either } from "io-ts-types/lib/either";
import { MediaCategoryCU as imported19_MediaCategoryCU, MediaCategoryU as imported9_MediaCategoryU, MediaCategoryCU as imported18_MediaCategoryCU } from "../domaintables/mediaCategories";
import * as E from "fp-ts/lib/Either";

export type CategoryWithCountC = t.TypeC<{
  category: EitherC<imported10_DocumentTypeC, imported19_MediaCategoryCU>,
  count: t.NumberC
}>;
export type CategoryWithCount = {
  category: E.Either<imported10_DocumentType, imported9_MediaCategoryU>,
  count: number
};
export const categoryWithCountC: CategoryWithCountC = t.type({
  category: either(imported10_documentTypeC, imported18_MediaCategoryCU),
  count: t.number
}) satisfies t.Type<CategoryWithCount, unknown>;


