import * as t from "io-ts";
import { DocumentWithCategory as imported16_DocumentWithCategory, documentTypeC as imported8_documentTypeC, documentWithCategoryC as imported16_documentWithCategoryC, DocumentType as imported8_DocumentType, DocumentTypeC as imported8_DocumentTypeC, DocumentWithCategoryC as imported16_DocumentWithCategoryC } from "./document";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { UserC as imported18_UserC, User as imported18_User, userC as imported18_userC } from "./user";

export type DocumentActivityC = t.TypeC<{
  date: LocalDateC,
  user: OptionFromNullableC<imported18_UserC>,
  totalDownloads: t.NumberC,
  totalViews: t.NumberC
}>;
export type DocumentActivity = {
  date: LocalDate,
  user: O.Option<imported18_User>,
  totalDownloads: number,
  totalViews: number
};
export const documentActivityC: DocumentActivityC = t.type({
  date: LocalDateC,
  user: optionFromNullable(imported18_userC),
  totalDownloads: t.number,
  totalViews: t.number
}) satisfies t.Type<DocumentActivity, unknown>;


export type TotalDocumentActivityC = t.TypeC<{
  allDownloads: t.NumberC,
  allViews: t.NumberC,
  documentActivity: t.ReadonlyArrayC<DocumentActivityC>
}>;
export type TotalDocumentActivity = {
  allDownloads: number,
  allViews: number,
  documentActivity: ReadonlyArray<DocumentActivity>
};
export const totalDocumentActivityC: TotalDocumentActivityC = t.type({
  allDownloads: t.number,
  allViews: t.number,
  documentActivity: t.readonlyArray(documentActivityC)
}) satisfies t.Type<TotalDocumentActivity, unknown>;


export type DocumentPairC = t.TypeC<{
  mediaId: t.NumberC,
  doc: imported16_DocumentWithCategoryC,
  total: TotalDocumentActivityC
}>;
export type DocumentPair = {
  mediaId: number,
  doc: imported16_DocumentWithCategory,
  total: TotalDocumentActivity
};
export const documentPairC: DocumentPairC = t.type({
  mediaId: t.number,
  doc: imported16_documentWithCategoryC,
  total: totalDocumentActivityC
}) satisfies t.Type<DocumentPair, unknown>;


export type DocumentStatisticsC = t.TypeC<{
  docTypes: t.ReadonlyArrayC<imported8_DocumentTypeC>,
  docs: t.ReadonlyArrayC<DocumentPairC>
}>;
export type DocumentStatistics = {
  docTypes: ReadonlyArray<imported8_DocumentType>,
  docs: ReadonlyArray<DocumentPair>
};
export const documentStatisticsC: DocumentStatisticsC = t.type({
  docTypes: t.readonlyArray(imported8_documentTypeC),
  docs: t.readonlyArray(documentPairC)
}) satisfies t.Type<DocumentStatistics, unknown>;


