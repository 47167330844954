import { IssuerC as imported46_IssuerC, Issuer as imported46_Issuer, issuerC as imported45_issuerC } from "./issuer";
import * as t from "io-ts";
import { AddressC as imported7_AddressC, Address as imported7_Address, addressC as imported6_addressC } from "./address";
import { BondOfferingC as imported21_BondOfferingC, BondOfferingC as imported22_BondOfferingC, BondOffering as imported21_BondOffering, bondOfferingC as imported21_bondOfferingC, bondOfferingC as imported22_bondOfferingC, BondOffering as imported22_BondOffering } from "./bondOfferingBase";
import { RfpC as imported56_RfpC, Rfp as imported56_Rfp, rfpC as imported53_rfpC, RfpC as imported55_RfpC, rfpC as imported54_rfpC, Rfp as imported55_Rfp } from "./rfpBase";

export type IssuerWithDealsC = t.TypeC<{
  issuer: imported46_IssuerC,
  address: imported7_AddressC,
  activeOfferings: t.ReadonlyArrayC<imported21_BondOfferingC>,
  archivedOfferings: t.ReadonlyArrayC<imported22_BondOfferingC>,
  activeRfps: t.ReadonlyArrayC<imported55_RfpC>,
  archivedRfps: t.ReadonlyArrayC<imported56_RfpC>
}>;
export type IssuerWithDeals = {
  issuer: imported46_Issuer,
  address: imported7_Address,
  activeOfferings: ReadonlyArray<imported21_BondOffering>,
  archivedOfferings: ReadonlyArray<imported22_BondOffering>,
  activeRfps: ReadonlyArray<imported55_Rfp>,
  archivedRfps: ReadonlyArray<imported56_Rfp>
};
export const issuerWithDealsC: IssuerWithDealsC = t.type({
  issuer: imported45_issuerC,
  address: imported6_addressC,
  activeOfferings: t.readonlyArray(imported21_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported22_bondOfferingC),
  activeRfps: t.readonlyArray(imported53_rfpC),
  archivedRfps: t.readonlyArray(imported54_rfpC)
}) satisfies t.Type<IssuerWithDeals, unknown>;


