import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const callouts = {
  _tag: `Callouts`,
  name: `callouts`
} as const;

export type CalloutsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Callouts`>
}>;
export const calloutsTaggedC: CalloutsTaggedC = t.type({
  _tag: t.literal(`Callouts`)
});
export type CalloutsTagged = t.TypeOf<CalloutsTaggedC>;
export type Callouts = CalloutsTagged & typeof callouts;
export type CalloutsC = t.Type<Callouts, CalloutsTagged>;
export const calloutsC: CalloutsC = pipe(calloutsTaggedC, c => new t.Type<Callouts, CalloutsTagged>(
  `Callouts`,
  (u: unknown): u is Callouts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Callouts> => pipe(c.decode(u), E.map(x => ({ ...x, ...callouts }))),
  (x: Callouts): CalloutsTagged => ({ ...x, _tag: `Callouts`}),
)) satisfies t.Type<Callouts, unknown>;


export const faqs = {
  _tag: `Faqs`,
  name: `faqs`
} as const;

export type FaqsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Faqs`>
}>;
export const faqsTaggedC: FaqsTaggedC = t.type({
  _tag: t.literal(`Faqs`)
});
export type FaqsTagged = t.TypeOf<FaqsTaggedC>;
export type Faqs = FaqsTagged & typeof faqs;
export type FaqsC = t.Type<Faqs, FaqsTagged>;
export const faqsC: FaqsC = pipe(faqsTaggedC, c => new t.Type<Faqs, FaqsTagged>(
  `Faqs`,
  (u: unknown): u is Faqs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faqs> => pipe(c.decode(u), E.map(x => ({ ...x, ...faqs }))),
  (x: Faqs): FaqsTagged => ({ ...x, _tag: `Faqs`}),
)) satisfies t.Type<Faqs, unknown>;


export const featuredImages = {
  _tag: `FeaturedImages`,
  name: `featuredImages`
} as const;

export type FeaturedImagesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FeaturedImages`>
}>;
export const featuredImagesTaggedC: FeaturedImagesTaggedC = t.type({
  _tag: t.literal(`FeaturedImages`)
});
export type FeaturedImagesTagged = t.TypeOf<FeaturedImagesTaggedC>;
export type FeaturedImages = FeaturedImagesTagged & typeof featuredImages;
export type FeaturedImagesC = t.Type<FeaturedImages, FeaturedImagesTagged>;
export const featuredImagesC: FeaturedImagesC = pipe(featuredImagesTaggedC, c => new t.Type<FeaturedImages, FeaturedImagesTagged>(
  `FeaturedImages`,
  (u: unknown): u is FeaturedImages => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FeaturedImages> => pipe(c.decode(u), E.map(x => ({ ...x, ...featuredImages }))),
  (x: FeaturedImages): FeaturedImagesTagged => ({ ...x, _tag: `FeaturedImages`}),
)) satisfies t.Type<FeaturedImages, unknown>;


export const esgImages = {
  _tag: `EsgImages`,
  name: `esgImages`
} as const;

export type EsgImagesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgImages`>
}>;
export const esgImagesTaggedC: EsgImagesTaggedC = t.type({
  _tag: t.literal(`EsgImages`)
});
export type EsgImagesTagged = t.TypeOf<EsgImagesTaggedC>;
export type EsgImages = EsgImagesTagged & typeof esgImages;
export type EsgImagesC = t.Type<EsgImages, EsgImagesTagged>;
export const esgImagesC: EsgImagesC = pipe(esgImagesTaggedC, c => new t.Type<EsgImages, EsgImagesTagged>(
  `EsgImages`,
  (u: unknown): u is EsgImages => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgImages> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgImages }))),
  (x: EsgImages): EsgImagesTagged => ({ ...x, _tag: `EsgImages`}),
)) satisfies t.Type<EsgImages, unknown>;


export const projects = {
  _tag: `Projects`,
  name: `projects`
} as const;

export type ProjectsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Projects`>
}>;
export const projectsTaggedC: ProjectsTaggedC = t.type({
  _tag: t.literal(`Projects`)
});
export type ProjectsTagged = t.TypeOf<ProjectsTaggedC>;
export type Projects = ProjectsTagged & typeof projects;
export type ProjectsC = t.Type<Projects, ProjectsTagged>;
export const projectsC: ProjectsC = pipe(projectsTaggedC, c => new t.Type<Projects, ProjectsTagged>(
  `Projects`,
  (u: unknown): u is Projects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Projects> => pipe(c.decode(u), E.map(x => ({ ...x, ...projects }))),
  (x: Projects): ProjectsTagged => ({ ...x, _tag: `Projects`}),
)) satisfies t.Type<Projects, unknown>;


export const officers = {
  _tag: `Officers`,
  name: `officers`
} as const;

export type OfficersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Officers`>
}>;
export const officersTaggedC: OfficersTaggedC = t.type({
  _tag: t.literal(`Officers`)
});
export type OfficersTagged = t.TypeOf<OfficersTaggedC>;
export type Officers = OfficersTagged & typeof officers;
export type OfficersC = t.Type<Officers, OfficersTagged>;
export const officersC: OfficersC = pipe(officersTaggedC, c => new t.Type<Officers, OfficersTagged>(
  `Officers`,
  (u: unknown): u is Officers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Officers> => pipe(c.decode(u), E.map(x => ({ ...x, ...officers }))),
  (x: Officers): OfficersTagged => ({ ...x, _tag: `Officers`}),
)) satisfies t.Type<Officers, unknown>;


export const offerings = {
  _tag: `Offerings`,
  name: `offerings`
} as const;

export type OfferingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Offerings`>
}>;
export const offeringsTaggedC: OfferingsTaggedC = t.type({
  _tag: t.literal(`Offerings`)
});
export type OfferingsTagged = t.TypeOf<OfferingsTaggedC>;
export type Offerings = OfferingsTagged & typeof offerings;
export type OfferingsC = t.Type<Offerings, OfferingsTagged>;
export const offeringsC: OfferingsC = pipe(offeringsTaggedC, c => new t.Type<Offerings, OfferingsTagged>(
  `Offerings`,
  (u: unknown): u is Offerings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offerings> => pipe(c.decode(u), E.map(x => ({ ...x, ...offerings }))),
  (x: Offerings): OfferingsTagged => ({ ...x, _tag: `Offerings`}),
)) satisfies t.Type<Offerings, unknown>;


export const relatedIssuers = {
  _tag: `RelatedIssuers`,
  name: `relatedIssuers`
} as const;

export type RelatedIssuersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RelatedIssuers`>
}>;
export const relatedIssuersTaggedC: RelatedIssuersTaggedC = t.type({
  _tag: t.literal(`RelatedIssuers`)
});
export type RelatedIssuersTagged = t.TypeOf<RelatedIssuersTaggedC>;
export type RelatedIssuers = RelatedIssuersTagged & typeof relatedIssuers;
export type RelatedIssuersC = t.Type<RelatedIssuers, RelatedIssuersTagged>;
export const relatedIssuersC: RelatedIssuersC = pipe(relatedIssuersTaggedC, c => new t.Type<RelatedIssuers, RelatedIssuersTagged>(
  `RelatedIssuers`,
  (u: unknown): u is RelatedIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuers }))),
  (x: RelatedIssuers): RelatedIssuersTagged => ({ ...x, _tag: `RelatedIssuers`}),
)) satisfies t.Type<RelatedIssuers, unknown>;


export const ratingAgencies = {
  _tag: `RatingAgencies`,
  name: `ratingAgencies`
} as const;

export type RatingAgenciesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RatingAgencies`>
}>;
export const ratingAgenciesTaggedC: RatingAgenciesTaggedC = t.type({
  _tag: t.literal(`RatingAgencies`)
});
export type RatingAgenciesTagged = t.TypeOf<RatingAgenciesTaggedC>;
export type RatingAgencies = RatingAgenciesTagged & typeof ratingAgencies;
export type RatingAgenciesC = t.Type<RatingAgencies, RatingAgenciesTagged>;
export const ratingAgenciesC: RatingAgenciesC = pipe(ratingAgenciesTaggedC, c => new t.Type<RatingAgencies, RatingAgenciesTagged>(
  `RatingAgencies`,
  (u: unknown): u is RatingAgencies => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingAgencies> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingAgencies }))),
  (x: RatingAgencies): RatingAgenciesTagged => ({ ...x, _tag: `RatingAgencies`}),
)) satisfies t.Type<RatingAgencies, unknown>;


export const hrefs = {
  _tag: `Hrefs`,
  name: `hrefs`
} as const;

export type HrefsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Hrefs`>
}>;
export const hrefsTaggedC: HrefsTaggedC = t.type({
  _tag: t.literal(`Hrefs`)
});
export type HrefsTagged = t.TypeOf<HrefsTaggedC>;
export type Hrefs = HrefsTagged & typeof hrefs;
export type HrefsC = t.Type<Hrefs, HrefsTagged>;
export const hrefsC: HrefsC = pipe(hrefsTaggedC, c => new t.Type<Hrefs, HrefsTagged>(
  `Hrefs`,
  (u: unknown): u is Hrefs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Hrefs> => pipe(c.decode(u), E.map(x => ({ ...x, ...hrefs }))),
  (x: Hrefs): HrefsTagged => ({ ...x, _tag: `Hrefs`}),
)) satisfies t.Type<Hrefs, unknown>;


export const participantTypes = {
  _tag: `ParticipantTypes`,
  name: `participantTypes`
} as const;

export type ParticipantTypesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ParticipantTypes`>
}>;
export const participantTypesTaggedC: ParticipantTypesTaggedC = t.type({
  _tag: t.literal(`ParticipantTypes`)
});
export type ParticipantTypesTagged = t.TypeOf<ParticipantTypesTaggedC>;
export type ParticipantTypes = ParticipantTypesTagged & typeof participantTypes;
export type ParticipantTypesC = t.Type<ParticipantTypes, ParticipantTypesTagged>;
export const participantTypesC: ParticipantTypesC = pipe(participantTypesTaggedC, c => new t.Type<ParticipantTypes, ParticipantTypesTagged>(
  `ParticipantTypes`,
  (u: unknown): u is ParticipantTypes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ParticipantTypes> => pipe(c.decode(u), E.map(x => ({ ...x, ...participantTypes }))),
  (x: ParticipantTypes): ParticipantTypesTagged => ({ ...x, _tag: `ParticipantTypes`}),
)) satisfies t.Type<ParticipantTypes, unknown>;


export const roadShows = {
  _tag: `RoadShows`,
  name: `roadShows`
} as const;

export type RoadShowsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RoadShows`>
}>;
export const roadShowsTaggedC: RoadShowsTaggedC = t.type({
  _tag: t.literal(`RoadShows`)
});
export type RoadShowsTagged = t.TypeOf<RoadShowsTaggedC>;
export type RoadShows = RoadShowsTagged & typeof roadShows;
export type RoadShowsC = t.Type<RoadShows, RoadShowsTagged>;
export const roadShowsC: RoadShowsC = pipe(roadShowsTaggedC, c => new t.Type<RoadShows, RoadShowsTagged>(
  `RoadShows`,
  (u: unknown): u is RoadShows => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShows> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShows }))),
  (x: RoadShows): RoadShowsTagged => ({ ...x, _tag: `RoadShows`}),
)) satisfies t.Type<RoadShows, unknown>;


export const offeringParticipants = {
  _tag: `OfferingParticipants`,
  name: `offeringParticipants`
} as const;

export type OfferingParticipantsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingParticipants`>
}>;
export const offeringParticipantsTaggedC: OfferingParticipantsTaggedC = t.type({
  _tag: t.literal(`OfferingParticipants`)
});
export type OfferingParticipantsTagged = t.TypeOf<OfferingParticipantsTaggedC>;
export type OfferingParticipants = OfferingParticipantsTagged & typeof offeringParticipants;
export type OfferingParticipantsC = t.Type<OfferingParticipants, OfferingParticipantsTagged>;
export const offeringParticipantsC: OfferingParticipantsC = pipe(offeringParticipantsTaggedC, c => new t.Type<OfferingParticipants, OfferingParticipantsTagged>(
  `OfferingParticipants`,
  (u: unknown): u is OfferingParticipants => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingParticipants> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringParticipants }))),
  (x: OfferingParticipants): OfferingParticipantsTagged => ({ ...x, _tag: `OfferingParticipants`}),
)) satisfies t.Type<OfferingParticipants, unknown>;


export const featuredItems = {
  _tag: `FeaturedItems`,
  name: `featuredItems`
} as const;

export type FeaturedItemsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FeaturedItems`>
}>;
export const featuredItemsTaggedC: FeaturedItemsTaggedC = t.type({
  _tag: t.literal(`FeaturedItems`)
});
export type FeaturedItemsTagged = t.TypeOf<FeaturedItemsTaggedC>;
export type FeaturedItems = FeaturedItemsTagged & typeof featuredItems;
export type FeaturedItemsC = t.Type<FeaturedItems, FeaturedItemsTagged>;
export const featuredItemsC: FeaturedItemsC = pipe(featuredItemsTaggedC, c => new t.Type<FeaturedItems, FeaturedItemsTagged>(
  `FeaturedItems`,
  (u: unknown): u is FeaturedItems => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FeaturedItems> => pipe(c.decode(u), E.map(x => ({ ...x, ...featuredItems }))),
  (x: FeaturedItems): FeaturedItemsTagged => ({ ...x, _tag: `FeaturedItems`}),
)) satisfies t.Type<FeaturedItems, unknown>;


export const issuerLinkSections = {
  _tag: `IssuerLinkSections`,
  name: `issuerLinkSections`
} as const;

export type IssuerLinkSectionsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerLinkSections`>
}>;
export const issuerLinkSectionsTaggedC: IssuerLinkSectionsTaggedC = t.type({
  _tag: t.literal(`IssuerLinkSections`)
});
export type IssuerLinkSectionsTagged = t.TypeOf<IssuerLinkSectionsTaggedC>;
export type IssuerLinkSections = IssuerLinkSectionsTagged & typeof issuerLinkSections;
export type IssuerLinkSectionsC = t.Type<IssuerLinkSections, IssuerLinkSectionsTagged>;
export const issuerLinkSectionsC: IssuerLinkSectionsC = pipe(issuerLinkSectionsTaggedC, c => new t.Type<IssuerLinkSections, IssuerLinkSectionsTagged>(
  `IssuerLinkSections`,
  (u: unknown): u is IssuerLinkSections => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerLinkSections> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerLinkSections }))),
  (x: IssuerLinkSections): IssuerLinkSectionsTagged => ({ ...x, _tag: `IssuerLinkSections`}),
)) satisfies t.Type<IssuerLinkSections, unknown>;


export const issuerFaqSections = {
  _tag: `IssuerFaqSections`,
  name: `issuerFaqSections`
} as const;

export type IssuerFaqSectionsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerFaqSections`>
}>;
export const issuerFaqSectionsTaggedC: IssuerFaqSectionsTaggedC = t.type({
  _tag: t.literal(`IssuerFaqSections`)
});
export type IssuerFaqSectionsTagged = t.TypeOf<IssuerFaqSectionsTaggedC>;
export type IssuerFaqSections = IssuerFaqSectionsTagged & typeof issuerFaqSections;
export type IssuerFaqSectionsC = t.Type<IssuerFaqSections, IssuerFaqSectionsTagged>;
export const issuerFaqSectionsC: IssuerFaqSectionsC = pipe(issuerFaqSectionsTaggedC, c => new t.Type<IssuerFaqSections, IssuerFaqSectionsTagged>(
  `IssuerFaqSections`,
  (u: unknown): u is IssuerFaqSections => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerFaqSections> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerFaqSections }))),
  (x: IssuerFaqSections): IssuerFaqSectionsTagged => ({ ...x, _tag: `IssuerFaqSections`}),
)) satisfies t.Type<IssuerFaqSections, unknown>;


export const links = {
  _tag: `Links`,
  name: `links`
} as const;

export type LinksTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Links`>
}>;
export const linksTaggedC: LinksTaggedC = t.type({
  _tag: t.literal(`Links`)
});
export type LinksTagged = t.TypeOf<LinksTaggedC>;
export type Links = LinksTagged & typeof links;
export type LinksC = t.Type<Links, LinksTagged>;
export const linksC: LinksC = pipe(linksTaggedC, c => new t.Type<Links, LinksTagged>(
  `Links`,
  (u: unknown): u is Links => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Links> => pipe(c.decode(u), E.map(x => ({ ...x, ...links }))),
  (x: Links): LinksTagged => ({ ...x, _tag: `Links`}),
)) satisfies t.Type<Links, unknown>;


export const rfps = {
  _tag: `Rfps`,
  name: `rfps`
} as const;

export type RfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Rfps`>
}>;
export const rfpsTaggedC: RfpsTaggedC = t.type({
  _tag: t.literal(`Rfps`)
});
export type RfpsTagged = t.TypeOf<RfpsTaggedC>;
export type Rfps = RfpsTagged & typeof rfps;
export type RfpsC = t.Type<Rfps, RfpsTagged>;
export const rfpsC: RfpsC = pipe(rfpsTaggedC, c => new t.Type<Rfps, RfpsTagged>(
  `Rfps`,
  (u: unknown): u is Rfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfps }))),
  (x: Rfps): RfpsTagged => ({ ...x, _tag: `Rfps`}),
)) satisfies t.Type<Rfps, unknown>;


export const bondProgram = {
  _tag: `BondProgram`,
  name: `bondProgram`
} as const;

export type BondProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondProgram`>
}>;
export const bondProgramTaggedC: BondProgramTaggedC = t.type({
  _tag: t.literal(`BondProgram`)
});
export type BondProgramTagged = t.TypeOf<BondProgramTaggedC>;
export type BondProgram = BondProgramTagged & typeof bondProgram;
export type BondProgramC = t.Type<BondProgram, BondProgramTagged>;
export const bondProgramC: BondProgramC = pipe(bondProgramTaggedC, c => new t.Type<BondProgram, BondProgramTagged>(
  `BondProgram`,
  (u: unknown): u is BondProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgram }))),
  (x: BondProgram): BondProgramTagged => ({ ...x, _tag: `BondProgram`}),
)) satisfies t.Type<BondProgram, unknown>;


export const allSortTypeC = [calloutsC, faqsC, featuredImagesC, esgImagesC, projectsC, officersC, offeringsC, relatedIssuersC, ratingAgenciesC, hrefsC, participantTypesC, roadShowsC, offeringParticipantsC, featuredItemsC, issuerLinkSectionsC, issuerFaqSectionsC, linksC, rfpsC, bondProgramC] as const;
export const allSortTypeNames = [`Callouts`, `Faqs`, `FeaturedImages`, `EsgImages`, `Projects`, `Officers`, `Offerings`, `RelatedIssuers`, `RatingAgencies`, `Hrefs`, `ParticipantTypes`, `RoadShows`, `OfferingParticipants`, `FeaturedItems`, `IssuerLinkSections`, `IssuerFaqSections`, `Links`, `Rfps`, `BondProgram`] as const;
export type SortTypeName = (typeof allSortTypeNames)[number];

export type SortTypeCU = t.UnionC<[CalloutsC, FaqsC, FeaturedImagesC, EsgImagesC, ProjectsC, OfficersC, OfferingsC, RelatedIssuersC, RatingAgenciesC, HrefsC, ParticipantTypesC, RoadShowsC, OfferingParticipantsC, FeaturedItemsC, IssuerLinkSectionsC, IssuerFaqSectionsC, LinksC, RfpsC, BondProgramC]>;
export type SortTypeU = Callouts | Faqs | FeaturedImages | EsgImages | Projects | Officers | Offerings | RelatedIssuers | RatingAgencies | Hrefs | ParticipantTypes | RoadShows | OfferingParticipants | FeaturedItems | IssuerLinkSections | IssuerFaqSections | Links | Rfps | BondProgram;
export const SortTypeCU: SortTypeCU = t.union([calloutsC, faqsC, featuredImagesC, esgImagesC, projectsC, officersC, offeringsC, relatedIssuersC, ratingAgenciesC, hrefsC, participantTypesC, roadShowsC, offeringParticipantsC, featuredItemsC, issuerLinkSectionsC, issuerFaqSectionsC, linksC, rfpsC, bondProgramC]) satisfies t.Type<SortTypeU, unknown>;

export const sortTypeOrd: Ord.Ord<SortTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortType = [callouts, faqs, featuredImages, esgImages, projects, officers, offerings, relatedIssuers, ratingAgencies, hrefs, participantTypes, roadShows, offeringParticipants, featuredItems, issuerLinkSections, issuerFaqSections, links, rfps, bondProgram] as const;
export type SortTypeMap<A> = { [K in SortTypeName]: A };


