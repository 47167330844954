import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const airports = {
  _tag: `Airports`,
  id: 1,
  name: `Airports`
} as const;

export type AirportsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Airports`>
}>;
export const airportsTaggedC: AirportsTaggedC = t.type({
  _tag: t.literal(`Airports`)
});
export type AirportsTagged = t.TypeOf<AirportsTaggedC>;
export type Airports = AirportsTagged & typeof airports;
export type AirportsC = t.Type<Airports, AirportsTagged>;
export const airportsC: AirportsC = pipe(airportsTaggedC, c => new t.Type<Airports, AirportsTagged>(
  `Airports`,
  (u: unknown): u is Airports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Airports> => pipe(c.decode(u), E.map(x => ({ ...x, ...airports }))),
  (x: Airports): AirportsTagged => ({ ...x, _tag: `Airports`}),
)) satisfies t.Type<Airports, unknown>;


export const charterSchools = {
  _tag: `CharterSchools`,
  id: 2,
  name: `Charter Schools`
} as const;

export type CharterSchoolsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CharterSchools`>
}>;
export const charterSchoolsTaggedC: CharterSchoolsTaggedC = t.type({
  _tag: t.literal(`CharterSchools`)
});
export type CharterSchoolsTagged = t.TypeOf<CharterSchoolsTaggedC>;
export type CharterSchools = CharterSchoolsTagged & typeof charterSchools;
export type CharterSchoolsC = t.Type<CharterSchools, CharterSchoolsTagged>;
export const charterSchoolsC: CharterSchoolsC = pipe(charterSchoolsTaggedC, c => new t.Type<CharterSchools, CharterSchoolsTagged>(
  `CharterSchools`,
  (u: unknown): u is CharterSchools => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CharterSchools> => pipe(c.decode(u), E.map(x => ({ ...x, ...charterSchools }))),
  (x: CharterSchools): CharterSchoolsTagged => ({ ...x, _tag: `CharterSchools`}),
)) satisfies t.Type<CharterSchools, unknown>;


export const combinedUtility = {
  _tag: `CombinedUtility`,
  id: 3,
  name: `Combined Utility`
} as const;

export type CombinedUtilityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CombinedUtility`>
}>;
export const combinedUtilityTaggedC: CombinedUtilityTaggedC = t.type({
  _tag: t.literal(`CombinedUtility`)
});
export type CombinedUtilityTagged = t.TypeOf<CombinedUtilityTaggedC>;
export type CombinedUtility = CombinedUtilityTagged & typeof combinedUtility;
export type CombinedUtilityC = t.Type<CombinedUtility, CombinedUtilityTagged>;
export const combinedUtilityC: CombinedUtilityC = pipe(combinedUtilityTaggedC, c => new t.Type<CombinedUtility, CombinedUtilityTagged>(
  `CombinedUtility`,
  (u: unknown): u is CombinedUtility => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CombinedUtility> => pipe(c.decode(u), E.map(x => ({ ...x, ...combinedUtility }))),
  (x: CombinedUtility): CombinedUtilityTagged => ({ ...x, _tag: `CombinedUtility`}),
)) satisfies t.Type<CombinedUtility, unknown>;


export const countyCityTown = {
  _tag: `CountyCityTown`,
  id: 4,
  name: `County / City / Town`
} as const;

export type CountyCityTownTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CountyCityTown`>
}>;
export const countyCityTownTaggedC: CountyCityTownTaggedC = t.type({
  _tag: t.literal(`CountyCityTown`)
});
export type CountyCityTownTagged = t.TypeOf<CountyCityTownTaggedC>;
export type CountyCityTown = CountyCityTownTagged & typeof countyCityTown;
export type CountyCityTownC = t.Type<CountyCityTown, CountyCityTownTagged>;
export const countyCityTownC: CountyCityTownC = pipe(countyCityTownTaggedC, c => new t.Type<CountyCityTown, CountyCityTownTagged>(
  `CountyCityTown`,
  (u: unknown): u is CountyCityTown => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CountyCityTown> => pipe(c.decode(u), E.map(x => ({ ...x, ...countyCityTown }))),
  (x: CountyCityTown): CountyCityTownTagged => ({ ...x, _tag: `CountyCityTown`}),
)) satisfies t.Type<CountyCityTown, unknown>;


export const healthcare = {
  _tag: `Healthcare`,
  id: 5,
  name: `Healthcare`
} as const;

export type HealthcareTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Healthcare`>
}>;
export const healthcareTaggedC: HealthcareTaggedC = t.type({
  _tag: t.literal(`Healthcare`)
});
export type HealthcareTagged = t.TypeOf<HealthcareTaggedC>;
export type Healthcare = HealthcareTagged & typeof healthcare;
export type HealthcareC = t.Type<Healthcare, HealthcareTagged>;
export const healthcareC: HealthcareC = pipe(healthcareTaggedC, c => new t.Type<Healthcare, HealthcareTagged>(
  `Healthcare`,
  (u: unknown): u is Healthcare => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Healthcare> => pipe(c.decode(u), E.map(x => ({ ...x, ...healthcare }))),
  (x: Healthcare): HealthcareTagged => ({ ...x, _tag: `Healthcare`}),
)) satisfies t.Type<Healthcare, unknown>;


export const higherEducation = {
  _tag: `HigherEducation`,
  id: 6,
  name: `Higher Education`
} as const;

export type HigherEducationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HigherEducation`>
}>;
export const higherEducationTaggedC: HigherEducationTaggedC = t.type({
  _tag: t.literal(`HigherEducation`)
});
export type HigherEducationTagged = t.TypeOf<HigherEducationTaggedC>;
export type HigherEducation = HigherEducationTagged & typeof higherEducation;
export type HigherEducationC = t.Type<HigherEducation, HigherEducationTagged>;
export const higherEducationC: HigherEducationC = pipe(higherEducationTaggedC, c => new t.Type<HigherEducation, HigherEducationTagged>(
  `HigherEducation`,
  (u: unknown): u is HigherEducation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HigherEducation> => pipe(c.decode(u), E.map(x => ({ ...x, ...higherEducation }))),
  (x: HigherEducation): HigherEducationTagged => ({ ...x, _tag: `HigherEducation`}),
)) satisfies t.Type<HigherEducation, unknown>;


export const housing = {
  _tag: `Housing`,
  id: 7,
  name: `Housing`
} as const;

export type HousingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Housing`>
}>;
export const housingTaggedC: HousingTaggedC = t.type({
  _tag: t.literal(`Housing`)
});
export type HousingTagged = t.TypeOf<HousingTaggedC>;
export type Housing = HousingTagged & typeof housing;
export type HousingC = t.Type<Housing, HousingTagged>;
export const housingC: HousingC = pipe(housingTaggedC, c => new t.Type<Housing, HousingTagged>(
  `Housing`,
  (u: unknown): u is Housing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Housing> => pipe(c.decode(u), E.map(x => ({ ...x, ...housing }))),
  (x: Housing): HousingTagged => ({ ...x, _tag: `Housing`}),
)) satisfies t.Type<Housing, unknown>;


export const nonProfit = {
  _tag: `NonProfit`,
  id: 8,
  name: `Non-Profit`
} as const;

export type NonProfitTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NonProfit`>
}>;
export const nonProfitTaggedC: NonProfitTaggedC = t.type({
  _tag: t.literal(`NonProfit`)
});
export type NonProfitTagged = t.TypeOf<NonProfitTaggedC>;
export type NonProfit = NonProfitTagged & typeof nonProfit;
export type NonProfitC = t.Type<NonProfit, NonProfitTagged>;
export const nonProfitC: NonProfitC = pipe(nonProfitTaggedC, c => new t.Type<NonProfit, NonProfitTagged>(
  `NonProfit`,
  (u: unknown): u is NonProfit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NonProfit> => pipe(c.decode(u), E.map(x => ({ ...x, ...nonProfit }))),
  (x: NonProfit): NonProfitTagged => ({ ...x, _tag: `NonProfit`}),
)) satisfies t.Type<NonProfit, unknown>;


export const poolBondBankConduit = {
  _tag: `PoolBondBankConduit`,
  id: 9,
  name: `Pool / Bond Bank / Conduit`
} as const;

export type PoolBondBankConduitTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PoolBondBankConduit`>
}>;
export const poolBondBankConduitTaggedC: PoolBondBankConduitTaggedC = t.type({
  _tag: t.literal(`PoolBondBankConduit`)
});
export type PoolBondBankConduitTagged = t.TypeOf<PoolBondBankConduitTaggedC>;
export type PoolBondBankConduit = PoolBondBankConduitTagged & typeof poolBondBankConduit;
export type PoolBondBankConduitC = t.Type<PoolBondBankConduit, PoolBondBankConduitTagged>;
export const poolBondBankConduitC: PoolBondBankConduitC = pipe(poolBondBankConduitTaggedC, c => new t.Type<PoolBondBankConduit, PoolBondBankConduitTagged>(
  `PoolBondBankConduit`,
  (u: unknown): u is PoolBondBankConduit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PoolBondBankConduit> => pipe(c.decode(u), E.map(x => ({ ...x, ...poolBondBankConduit }))),
  (x: PoolBondBankConduit): PoolBondBankConduitTagged => ({ ...x, _tag: `PoolBondBankConduit`}),
)) satisfies t.Type<PoolBondBankConduit, unknown>;


export const ports = {
  _tag: `Ports`,
  id: 10,
  name: `Ports`
} as const;

export type PortsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Ports`>
}>;
export const portsTaggedC: PortsTaggedC = t.type({
  _tag: t.literal(`Ports`)
});
export type PortsTagged = t.TypeOf<PortsTaggedC>;
export type Ports = PortsTagged & typeof ports;
export type PortsC = t.Type<Ports, PortsTagged>;
export const portsC: PortsC = pipe(portsTaggedC, c => new t.Type<Ports, PortsTagged>(
  `Ports`,
  (u: unknown): u is Ports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ports> => pipe(c.decode(u), E.map(x => ({ ...x, ...ports }))),
  (x: Ports): PortsTagged => ({ ...x, _tag: `Ports`}),
)) satisfies t.Type<Ports, unknown>;


export const powerGas = {
  _tag: `PowerGas`,
  id: 11,
  name: `Power / Gas`
} as const;

export type PowerGasTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PowerGas`>
}>;
export const powerGasTaggedC: PowerGasTaggedC = t.type({
  _tag: t.literal(`PowerGas`)
});
export type PowerGasTagged = t.TypeOf<PowerGasTaggedC>;
export type PowerGas = PowerGasTagged & typeof powerGas;
export type PowerGasC = t.Type<PowerGas, PowerGasTagged>;
export const powerGasC: PowerGasC = pipe(powerGasTaggedC, c => new t.Type<PowerGas, PowerGasTagged>(
  `PowerGas`,
  (u: unknown): u is PowerGas => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PowerGas> => pipe(c.decode(u), E.map(x => ({ ...x, ...powerGas }))),
  (x: PowerGas): PowerGasTagged => ({ ...x, _tag: `PowerGas`}),
)) satisfies t.Type<PowerGas, unknown>;


export const projectFinanceLandSecured = {
  _tag: `ProjectFinanceLandSecured`,
  id: 12,
  name: `Project Finance / Land Secured`
} as const;

export type ProjectFinanceLandSecuredTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ProjectFinanceLandSecured`>
}>;
export const projectFinanceLandSecuredTaggedC: ProjectFinanceLandSecuredTaggedC = t.type({
  _tag: t.literal(`ProjectFinanceLandSecured`)
});
export type ProjectFinanceLandSecuredTagged = t.TypeOf<ProjectFinanceLandSecuredTaggedC>;
export type ProjectFinanceLandSecured = ProjectFinanceLandSecuredTagged & typeof projectFinanceLandSecured;
export type ProjectFinanceLandSecuredC = t.Type<ProjectFinanceLandSecured, ProjectFinanceLandSecuredTagged>;
export const projectFinanceLandSecuredC: ProjectFinanceLandSecuredC = pipe(projectFinanceLandSecuredTaggedC, c => new t.Type<ProjectFinanceLandSecured, ProjectFinanceLandSecuredTagged>(
  `ProjectFinanceLandSecured`,
  (u: unknown): u is ProjectFinanceLandSecured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProjectFinanceLandSecured> => pipe(c.decode(u), E.map(x => ({ ...x, ...projectFinanceLandSecured }))),
  (x: ProjectFinanceLandSecured): ProjectFinanceLandSecuredTagged => ({ ...x, _tag: `ProjectFinanceLandSecured`}),
)) satisfies t.Type<ProjectFinanceLandSecured, unknown>;


export const schoolDistricts = {
  _tag: `SchoolDistricts`,
  id: 13,
  name: `School Districts`
} as const;

export type SchoolDistrictsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SchoolDistricts`>
}>;
export const schoolDistrictsTaggedC: SchoolDistrictsTaggedC = t.type({
  _tag: t.literal(`SchoolDistricts`)
});
export type SchoolDistrictsTagged = t.TypeOf<SchoolDistrictsTaggedC>;
export type SchoolDistricts = SchoolDistrictsTagged & typeof schoolDistricts;
export type SchoolDistrictsC = t.Type<SchoolDistricts, SchoolDistrictsTagged>;
export const schoolDistrictsC: SchoolDistrictsC = pipe(schoolDistrictsTaggedC, c => new t.Type<SchoolDistricts, SchoolDistrictsTagged>(
  `SchoolDistricts`,
  (u: unknown): u is SchoolDistricts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SchoolDistricts> => pipe(c.decode(u), E.map(x => ({ ...x, ...schoolDistricts }))),
  (x: SchoolDistricts): SchoolDistrictsTagged => ({ ...x, _tag: `SchoolDistricts`}),
)) satisfies t.Type<SchoolDistricts, unknown>;


export const stateProvince = {
  _tag: `StateProvince`,
  id: 14,
  name: `State / Province`
} as const;

export type StateProvinceTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StateProvince`>
}>;
export const stateProvinceTaggedC: StateProvinceTaggedC = t.type({
  _tag: t.literal(`StateProvince`)
});
export type StateProvinceTagged = t.TypeOf<StateProvinceTaggedC>;
export type StateProvince = StateProvinceTagged & typeof stateProvince;
export type StateProvinceC = t.Type<StateProvince, StateProvinceTagged>;
export const stateProvinceC: StateProvinceC = pipe(stateProvinceTaggedC, c => new t.Type<StateProvince, StateProvinceTagged>(
  `StateProvince`,
  (u: unknown): u is StateProvince => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateProvince> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateProvince }))),
  (x: StateProvince): StateProvinceTagged => ({ ...x, _tag: `StateProvince`}),
)) satisfies t.Type<StateProvince, unknown>;


export const studentLoans = {
  _tag: `StudentLoans`,
  id: 15,
  name: `Student Loans`
} as const;

export type StudentLoansTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StudentLoans`>
}>;
export const studentLoansTaggedC: StudentLoansTaggedC = t.type({
  _tag: t.literal(`StudentLoans`)
});
export type StudentLoansTagged = t.TypeOf<StudentLoansTaggedC>;
export type StudentLoans = StudentLoansTagged & typeof studentLoans;
export type StudentLoansC = t.Type<StudentLoans, StudentLoansTagged>;
export const studentLoansC: StudentLoansC = pipe(studentLoansTaggedC, c => new t.Type<StudentLoans, StudentLoansTagged>(
  `StudentLoans`,
  (u: unknown): u is StudentLoans => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StudentLoans> => pipe(c.decode(u), E.map(x => ({ ...x, ...studentLoans }))),
  (x: StudentLoans): StudentLoansTagged => ({ ...x, _tag: `StudentLoans`}),
)) satisfies t.Type<StudentLoans, unknown>;


export const transit = {
  _tag: `Transit`,
  id: 16,
  name: `Transit`
} as const;

export type TransitTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Transit`>
}>;
export const transitTaggedC: TransitTaggedC = t.type({
  _tag: t.literal(`Transit`)
});
export type TransitTagged = t.TypeOf<TransitTaggedC>;
export type Transit = TransitTagged & typeof transit;
export type TransitC = t.Type<Transit, TransitTagged>;
export const transitC: TransitC = pipe(transitTaggedC, c => new t.Type<Transit, TransitTagged>(
  `Transit`,
  (u: unknown): u is Transit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Transit> => pipe(c.decode(u), E.map(x => ({ ...x, ...transit }))),
  (x: Transit): TransitTagged => ({ ...x, _tag: `Transit`}),
)) satisfies t.Type<Transit, unknown>;


export const transportationTollRoad = {
  _tag: `TransportationTollRoad`,
  id: 17,
  name: `Transportation / Toll Road`
} as const;

export type TransportationTollRoadTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TransportationTollRoad`>
}>;
export const transportationTollRoadTaggedC: TransportationTollRoadTaggedC = t.type({
  _tag: t.literal(`TransportationTollRoad`)
});
export type TransportationTollRoadTagged = t.TypeOf<TransportationTollRoadTaggedC>;
export type TransportationTollRoad = TransportationTollRoadTagged & typeof transportationTollRoad;
export type TransportationTollRoadC = t.Type<TransportationTollRoad, TransportationTollRoadTagged>;
export const transportationTollRoadC: TransportationTollRoadC = pipe(transportationTollRoadTaggedC, c => new t.Type<TransportationTollRoad, TransportationTollRoadTagged>(
  `TransportationTollRoad`,
  (u: unknown): u is TransportationTollRoad => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TransportationTollRoad> => pipe(c.decode(u), E.map(x => ({ ...x, ...transportationTollRoad }))),
  (x: TransportationTollRoad): TransportationTollRoadTagged => ({ ...x, _tag: `TransportationTollRoad`}),
)) satisfies t.Type<TransportationTollRoad, unknown>;


export const waterSewer = {
  _tag: `WaterSewer`,
  id: 18,
  name: `Water / Sewer`
} as const;

export type WaterSewerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`WaterSewer`>
}>;
export const waterSewerTaggedC: WaterSewerTaggedC = t.type({
  _tag: t.literal(`WaterSewer`)
});
export type WaterSewerTagged = t.TypeOf<WaterSewerTaggedC>;
export type WaterSewer = WaterSewerTagged & typeof waterSewer;
export type WaterSewerC = t.Type<WaterSewer, WaterSewerTagged>;
export const waterSewerC: WaterSewerC = pipe(waterSewerTaggedC, c => new t.Type<WaterSewer, WaterSewerTagged>(
  `WaterSewer`,
  (u: unknown): u is WaterSewer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WaterSewer> => pipe(c.decode(u), E.map(x => ({ ...x, ...waterSewer }))),
  (x: WaterSewer): WaterSewerTagged => ({ ...x, _tag: `WaterSewer`}),
)) satisfies t.Type<WaterSewer, unknown>;


export const specialDistrict = {
  _tag: `SpecialDistrict`,
  id: 19,
  name: `Special District`
} as const;

export type SpecialDistrictTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SpecialDistrict`>
}>;
export const specialDistrictTaggedC: SpecialDistrictTaggedC = t.type({
  _tag: t.literal(`SpecialDistrict`)
});
export type SpecialDistrictTagged = t.TypeOf<SpecialDistrictTaggedC>;
export type SpecialDistrict = SpecialDistrictTagged & typeof specialDistrict;
export type SpecialDistrictC = t.Type<SpecialDistrict, SpecialDistrictTagged>;
export const specialDistrictC: SpecialDistrictC = pipe(specialDistrictTaggedC, c => new t.Type<SpecialDistrict, SpecialDistrictTagged>(
  `SpecialDistrict`,
  (u: unknown): u is SpecialDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SpecialDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...specialDistrict }))),
  (x: SpecialDistrict): SpecialDistrictTagged => ({ ...x, _tag: `SpecialDistrict`}),
)) satisfies t.Type<SpecialDistrict, unknown>;


export const countrySovereignCreditIssuer = {
  _tag: `CountrySovereignCreditIssuer`,
  id: 20,
  name: `Country / Sovereign Credit Issuer`
} as const;

export type CountrySovereignCreditIssuerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CountrySovereignCreditIssuer`>
}>;
export const countrySovereignCreditIssuerTaggedC: CountrySovereignCreditIssuerTaggedC = t.type({
  _tag: t.literal(`CountrySovereignCreditIssuer`)
});
export type CountrySovereignCreditIssuerTagged = t.TypeOf<CountrySovereignCreditIssuerTaggedC>;
export type CountrySovereignCreditIssuer = CountrySovereignCreditIssuerTagged & typeof countrySovereignCreditIssuer;
export type CountrySovereignCreditIssuerC = t.Type<CountrySovereignCreditIssuer, CountrySovereignCreditIssuerTagged>;
export const countrySovereignCreditIssuerC: CountrySovereignCreditIssuerC = pipe(countrySovereignCreditIssuerTaggedC, c => new t.Type<CountrySovereignCreditIssuer, CountrySovereignCreditIssuerTagged>(
  `CountrySovereignCreditIssuer`,
  (u: unknown): u is CountrySovereignCreditIssuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CountrySovereignCreditIssuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...countrySovereignCreditIssuer }))),
  (x: CountrySovereignCreditIssuer): CountrySovereignCreditIssuerTagged => ({ ...x, _tag: `CountrySovereignCreditIssuer`}),
)) satisfies t.Type<CountrySovereignCreditIssuer, unknown>;


export const insurance = {
  _tag: `Insurance`,
  id: 21,
  name: `Insurance`
} as const;

export type InsuranceTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Insurance`>
}>;
export const insuranceTaggedC: InsuranceTaggedC = t.type({
  _tag: t.literal(`Insurance`)
});
export type InsuranceTagged = t.TypeOf<InsuranceTaggedC>;
export type Insurance = InsuranceTagged & typeof insurance;
export type InsuranceC = t.Type<Insurance, InsuranceTagged>;
export const insuranceC: InsuranceC = pipe(insuranceTaggedC, c => new t.Type<Insurance, InsuranceTagged>(
  `Insurance`,
  (u: unknown): u is Insurance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Insurance> => pipe(c.decode(u), E.map(x => ({ ...x, ...insurance }))),
  (x: Insurance): InsuranceTagged => ({ ...x, _tag: `Insurance`}),
)) satisfies t.Type<Insurance, unknown>;


export const allSectorC = [airportsC, charterSchoolsC, combinedUtilityC, countyCityTownC, healthcareC, higherEducationC, housingC, nonProfitC, poolBondBankConduitC, portsC, powerGasC, projectFinanceLandSecuredC, schoolDistrictsC, stateProvinceC, studentLoansC, transitC, transportationTollRoadC, waterSewerC, specialDistrictC, countrySovereignCreditIssuerC, insuranceC] as const;
export const allSectorNames = [`Airports`, `CharterSchools`, `CombinedUtility`, `CountyCityTown`, `Healthcare`, `HigherEducation`, `Housing`, `NonProfit`, `PoolBondBankConduit`, `Ports`, `PowerGas`, `ProjectFinanceLandSecured`, `SchoolDistricts`, `StateProvince`, `StudentLoans`, `Transit`, `TransportationTollRoad`, `WaterSewer`, `SpecialDistrict`, `CountrySovereignCreditIssuer`, `Insurance`] as const;
export type SectorName = (typeof allSectorNames)[number];

export type SectorCU = t.UnionC<[AirportsC, CharterSchoolsC, CombinedUtilityC, CountyCityTownC, HealthcareC, HigherEducationC, HousingC, NonProfitC, PoolBondBankConduitC, PortsC, PowerGasC, ProjectFinanceLandSecuredC, SchoolDistrictsC, StateProvinceC, StudentLoansC, TransitC, TransportationTollRoadC, WaterSewerC, SpecialDistrictC, CountrySovereignCreditIssuerC, InsuranceC]>;
export type SectorU = Airports | CharterSchools | CombinedUtility | CountyCityTown | Healthcare | HigherEducation | Housing | NonProfit | PoolBondBankConduit | Ports | PowerGas | ProjectFinanceLandSecured | SchoolDistricts | StateProvince | StudentLoans | Transit | TransportationTollRoad | WaterSewer | SpecialDistrict | CountrySovereignCreditIssuer | Insurance;
export const SectorCU: SectorCU = t.union([airportsC, charterSchoolsC, combinedUtilityC, countyCityTownC, healthcareC, higherEducationC, housingC, nonProfitC, poolBondBankConduitC, portsC, powerGasC, projectFinanceLandSecuredC, schoolDistrictsC, stateProvinceC, studentLoansC, transitC, transportationTollRoadC, waterSewerC, specialDistrictC, countrySovereignCreditIssuerC, insuranceC]) satisfies t.Type<SectorU, unknown>;

export const sectorOrd: Ord.Ord<SectorU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSector = [airports, charterSchools, combinedUtility, countyCityTown, healthcare, higherEducation, housing, nonProfit, poolBondBankConduit, ports, powerGas, projectFinanceLandSecured, schoolDistricts, stateProvince, studentLoans, transit, transportationTollRoad, waterSewer, specialDistrict, countrySovereignCreditIssuer, insurance] as const;
export type SectorMap<A> = { [K in SectorName]: A };


