import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const taxExempt = {
  _tag: `TaxExempt`,
  id: 1,
  name: `Tax-Exempt`
} as const;

export type TaxExemptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TaxExempt`>
}>;
export const taxExemptTaggedC: TaxExemptTaggedC = t.type({
  _tag: t.literal(`TaxExempt`)
});
export type TaxExemptTagged = t.TypeOf<TaxExemptTaggedC>;
export type TaxExempt = TaxExemptTagged & typeof taxExempt;
export type TaxExemptC = t.Type<TaxExempt, TaxExemptTagged>;
export const taxExemptC: TaxExemptC = pipe(taxExemptTaggedC, c => new t.Type<TaxExempt, TaxExemptTagged>(
  `TaxExempt`,
  (u: unknown): u is TaxExempt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxExempt> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxExempt }))),
  (x: TaxExempt): TaxExemptTagged => ({ ...x, _tag: `TaxExempt`}),
)) satisfies t.Type<TaxExempt, unknown>;


export const taxable = {
  _tag: `Taxable`,
  id: 2,
  name: `Taxable`
} as const;

export type TaxableTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Taxable`>
}>;
export const taxableTaggedC: TaxableTaggedC = t.type({
  _tag: t.literal(`Taxable`)
});
export type TaxableTagged = t.TypeOf<TaxableTaggedC>;
export type Taxable = TaxableTagged & typeof taxable;
export type TaxableC = t.Type<Taxable, TaxableTagged>;
export const taxableC: TaxableC = pipe(taxableTaggedC, c => new t.Type<Taxable, TaxableTagged>(
  `Taxable`,
  (u: unknown): u is Taxable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Taxable> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxable }))),
  (x: Taxable): TaxableTagged => ({ ...x, _tag: `Taxable`}),
)) satisfies t.Type<Taxable, unknown>;


export const allTaxStatusC = [taxExemptC, taxableC] as const;
export const allTaxStatusNames = [`TaxExempt`, `Taxable`] as const;
export type TaxStatusName = (typeof allTaxStatusNames)[number];

export type TaxStatusCU = t.UnionC<[TaxExemptC, TaxableC]>;
export type TaxStatusU = TaxExempt | Taxable;
export const TaxStatusCU: TaxStatusCU = t.union([taxExemptC, taxableC]) satisfies t.Type<TaxStatusU, unknown>;

export const taxStatusOrd: Ord.Ord<TaxStatusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTaxStatus = [taxExempt, taxable] as const;
export type TaxStatusMap<A> = { [K in TaxStatusName]: A };


