import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { IssuerRoleCU as imported3_IssuerRoleCU, IssuerRoleU as imported1_IssuerRoleU, IssuerRoleCU as imported2_IssuerRoleCU } from "../domaintables/roles";

export type IssuerPortalUserC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  lastActive: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  role: imported3_IssuerRoleCU
}>;
export type IssuerPortalUser = {
  firstName: string,
  lastName: string,
  email: string,
  lastActive: O.Option<LocalDateTime>,
  role: imported1_IssuerRoleU
};
export const issuerPortalUserC: IssuerPortalUserC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  lastActive: optionFromNullable(LocalDateTimeFromIsoStringC),
  role: imported2_IssuerRoleCU
}) satisfies t.Type<IssuerPortalUser, unknown>;


export type IssuerPortalUserCreateC = t.TypeC<{
  email: t.StringC,
  firstName: t.StringC,
  lastName: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  phoneExtension: OptionFromNullableC<t.StringC>,
  company: t.StringC,
  title: OptionFromNullableC<t.StringC>,
  roleId: t.NumberC,
  personaId: t.NumberC,
  investorTypeId: OptionFromNullableC<t.NumberC>
}>;
export type IssuerPortalUserCreate = {
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: O.Option<string>,
  phoneExtension: O.Option<string>,
  company: string,
  title: O.Option<string>,
  roleId: number,
  personaId: number,
  investorTypeId: O.Option<number>
};
export const issuerPortalUserCreateC: IssuerPortalUserCreateC = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  roleId: t.number,
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number)
}) satisfies t.Type<IssuerPortalUserCreate, unknown>;


export type IssuerExistingUserUpdateC = t.TypeC<{
  userId: t.NumberC,
  roleId: t.NumberC
}>;
export type IssuerExistingUserUpdate = {
  userId: number,
  roleId: number
};
export const issuerExistingUserUpdateC: IssuerExistingUserUpdateC = t.type({
  userId: t.number,
  roleId: t.number
}) satisfies t.Type<IssuerExistingUserUpdate, unknown>;


