import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { RelativeUrlC, RelativeUrl, relativeUrlC } from "../../codecs/relativeUrl";
import * as O from "fp-ts/lib/Option";

export type SignupPostC = t.TypeC<{
  redirect: OptionFromNullableC<RelativeUrlC>,
  issuerId: OptionFromNullableC<t.NumberC>,
  personaId: t.NumberC,
  investorTypeId: OptionFromNullableC<t.NumberC>,
  termsAccepted: t.BooleanC,
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  password: t.StringC,
  passwordConfirm: t.StringC,
  company: t.StringC
}>;
export type SignupPost = {
  redirect: O.Option<RelativeUrl>,
  issuerId: O.Option<number>,
  personaId: number,
  investorTypeId: O.Option<number>,
  termsAccepted: boolean,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirm: string,
  company: string
};
export const signupPostC: SignupPostC = t.type({
  redirect: optionFromNullable(relativeUrlC),
  issuerId: optionFromNullable(t.number),
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number),
  termsAccepted: t.boolean,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  password: t.string,
  passwordConfirm: t.string,
  company: t.string
}) satisfies t.Type<SignupPost, unknown>;


