import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";

export type IsAttachedC<A1 extends t.Mixed> = t.TypeC<{
  data: A1,
  attached: t.BooleanC
}>;
export type IsAttached<A1> = {
  data: A1,
  attached: boolean
};
export const isAttachedC = <A1 extends t.Mixed>(A1: A1): IsAttachedC<A1> => t.type({
  data: A1,
  attached: t.boolean
}) satisfies t.Type<IsAttached<t.TypeOf<A1>>, unknown>;


export type DataAndIdC<A1 extends t.Mixed> = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  data: A1
}>;
export type DataAndId<A1> = {
  id: O.Option<number>,
  data: A1
};
export const dataAndIdC = <A1 extends t.Mixed>(A1: A1): DataAndIdC<A1> => t.type({
  id: optionFromNullable(t.number),
  data: A1
}) satisfies t.Type<DataAndId<t.TypeOf<A1>>, unknown>;


export type DataAndMetaC<A1 extends t.Mixed, A2 extends t.Mixed> = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  data: A1,
  meta: A2
}>;
export type DataAndMeta<A1, A2> = {
  id: O.Option<number>,
  data: A1,
  meta: A2
};
export const dataAndMetaC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2): DataAndMetaC<A1, A2> => t.type({
  id: optionFromNullable(t.number),
  data: A1,
  meta: A2
}) satisfies t.Type<DataAndMeta<t.TypeOf<A1>, t.TypeOf<A2>>, unknown>;


export type PostAttachedC<A1 extends t.Mixed, A2 extends t.Mixed> = t.TypeC<{
  attached: t.ReadonlyArrayC<DataAndMetaC<A1, A2>>
}>;
export type PostAttached<A1, A2> = {
  attached: ReadonlyArray<DataAndMeta<A1, A2>>
};
export const postAttachedC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2): PostAttachedC<A1, A2> => t.type({
  attached: t.readonlyArray(dataAndMetaC(A1, A2))
}) satisfies t.Type<PostAttached<t.TypeOf<A1>, t.TypeOf<A2>>, unknown>;


export type PostRelatedC<A1 extends t.Mixed> = t.TypeC<{
  related: t.ReadonlyArrayC<A1>
}>;
export type PostRelated<A1> = {
  related: ReadonlyArray<A1>
};
export const postRelatedC = <A1 extends t.Mixed>(A1: A1): PostRelatedC<A1> => t.type({
  related: t.readonlyArray(A1)
}) satisfies t.Type<PostRelated<t.TypeOf<A1>>, unknown>;


export type PostRelatedAndAttachedC<A1 extends t.Mixed, A2 extends t.Mixed, A3 extends t.Mixed> = t.TypeC<{
  related: t.ReadonlyArrayC<A1>,
  attached: t.ReadonlyArrayC<DataAndMetaC<A2, A3>>
}>;
export type PostRelatedAndAttached<A1, A2, A3> = {
  related: ReadonlyArray<A1>,
  attached: ReadonlyArray<DataAndMeta<A2, A3>>
};
export const postRelatedAndAttachedC = <A1 extends t.Mixed, A2 extends t.Mixed, A3 extends t.Mixed>(A1: A1, A2: A2, A3: A3): PostRelatedAndAttachedC<A1, A2, A3> => t.type({
  related: t.readonlyArray(A1),
  attached: t.readonlyArray(dataAndMetaC(A2, A3))
}) satisfies t.Type<PostRelatedAndAttached<t.TypeOf<A1>, t.TypeOf<A2>, t.TypeOf<A3>>, unknown>;


export type RelatedAnalystLinkC = t.TypeC<{
  analystId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedAnalystLink = {
  analystId: number,
  sortOrder: number
};
export const relatedAnalystLinkC: RelatedAnalystLinkC = t.type({
  analystId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedAnalystLink, unknown>;


export type RelatedCusip9LinkC = t.TypeC<{
  cusip9Id: t.NumberC
}>;
export type RelatedCusip9Link = {
  cusip9Id: number
};
export const relatedCusip9LinkC: RelatedCusip9LinkC = t.type({
  cusip9Id: t.number
}) satisfies t.Type<RelatedCusip9Link, unknown>;


export type RelatedDocumentLinkC = t.TypeC<{
  documentId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedDocumentLink = {
  documentId: number,
  sortOrder: number
};
export const relatedDocumentLinkC: RelatedDocumentLinkC = t.type({
  documentId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedDocumentLink, unknown>;


export type RelatedEventLinkC = t.TypeC<{
  eventId: t.NumberC
}>;
export type RelatedEventLink = {
  eventId: number
};
export const relatedEventLinkC: RelatedEventLinkC = t.type({
  eventId: t.number
}) satisfies t.Type<RelatedEventLink, unknown>;


export type RelatedExternalLinkLinkC = t.TypeC<{
  externalLinkId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedExternalLinkLink = {
  externalLinkId: number,
  sortOrder: number
};
export const relatedExternalLinkLinkC: RelatedExternalLinkLinkC = t.type({
  externalLinkId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedExternalLinkLink, unknown>;


export type RelatedFaqLinkC = t.TypeC<{
  faqId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedFaqLink = {
  faqId: number,
  sortOrder: number
};
export const relatedFaqLinkC: RelatedFaqLinkC = t.type({
  faqId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedFaqLink, unknown>;


export type RelatedNewsLinkC = t.TypeC<{
  newsId: t.NumberC
}>;
export type RelatedNewsLink = {
  newsId: number
};
export const relatedNewsLinkC: RelatedNewsLinkC = t.type({
  newsId: t.number
}) satisfies t.Type<RelatedNewsLink, unknown>;


export type RelatedOfferingLinkC = t.TypeC<{
  offeringId: t.NumberC
}>;
export type RelatedOfferingLink = {
  offeringId: number
};
export const relatedOfferingLinkC: RelatedOfferingLinkC = t.type({
  offeringId: t.number
}) satisfies t.Type<RelatedOfferingLink, unknown>;


export type RelatedOfferingParticipantLinkC = t.TypeC<{
  offeringParticipantId: t.NumberC,
  participantTypeId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedOfferingParticipantLink = {
  offeringParticipantId: number,
  participantTypeId: number,
  sortOrder: number
};
export const relatedOfferingParticipantLinkC: RelatedOfferingParticipantLinkC = t.type({
  offeringParticipantId: t.number,
  participantTypeId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedOfferingParticipantLink, unknown>;


export type RelatedPhotoLinkC = t.TypeC<{
  photoId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedPhotoLink = {
  photoId: number,
  sortOrder: number
};
export const relatedPhotoLinkC: RelatedPhotoLinkC = t.type({
  photoId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedPhotoLink, unknown>;


export type RelatedPrivateDocumentLinkC = t.TypeC<{
  documentId: t.NumberC
}>;
export type RelatedPrivateDocumentLink = {
  documentId: number
};
export const relatedPrivateDocumentLinkC: RelatedPrivateDocumentLinkC = t.type({
  documentId: t.number
}) satisfies t.Type<RelatedPrivateDocumentLink, unknown>;


export type RelatedContentNoteLinkC = t.TypeC<{
  contentNoteId: t.NumberC
}>;
export type RelatedContentNoteLink = {
  contentNoteId: number
};
export const relatedContentNoteLinkC: RelatedContentNoteLinkC = t.type({
  contentNoteId: t.number
}) satisfies t.Type<RelatedContentNoteLink, unknown>;


export type RelatedProgramLinkC = t.TypeC<{
  programId: t.NumberC
}>;
export type RelatedProgramLink = {
  programId: number
};
export const relatedProgramLinkC: RelatedProgramLinkC = t.type({
  programId: t.number
}) satisfies t.Type<RelatedProgramLink, unknown>;


export type RelatedProjectLinkC = t.TypeC<{
  projectId: t.NumberC
}>;
export type RelatedProjectLink = {
  projectId: number
};
export const relatedProjectLinkC: RelatedProjectLinkC = t.type({
  projectId: t.number
}) satisfies t.Type<RelatedProjectLink, unknown>;


export type RelatedQuickFactLinkC = t.TypeC<{
  quickFactId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type RelatedQuickFactLink = {
  quickFactId: number,
  sortOrder: number
};
export const relatedQuickFactLinkC: RelatedQuickFactLinkC = t.type({
  quickFactId: t.number,
  sortOrder: t.number
}) satisfies t.Type<RelatedQuickFactLink, unknown>;


export type RelatedRatingLinkC = t.TypeC<{
  ratingId: t.NumberC
}>;
export type RelatedRatingLink = {
  ratingId: number
};
export const relatedRatingLinkC: RelatedRatingLinkC = t.type({
  ratingId: t.number
}) satisfies t.Type<RelatedRatingLink, unknown>;


export type RelatedRfpLinkC = t.TypeC<{
  rfpId: t.NumberC
}>;
export type RelatedRfpLink = {
  rfpId: number
};
export const relatedRfpLinkC: RelatedRfpLinkC = t.type({
  rfpId: t.number
}) satisfies t.Type<RelatedRfpLink, unknown>;


export type RelatedRoadShowLinkC = t.TypeC<{
  roadShowId: t.NumberC
}>;
export type RelatedRoadShowLink = {
  roadShowId: number
};
export const relatedRoadShowLinkC: RelatedRoadShowLinkC = t.type({
  roadShowId: t.number
}) satisfies t.Type<RelatedRoadShowLink, unknown>;


