import { TaggedContent as imported201_TaggedContent, TaggedContentC as imported201_TaggedContentC, taggedContentC as imported202_taggedContentC, TaggedContent as imported202_TaggedContent, taggedContentC as imported201_taggedContentC, TaggedContentC as imported202_TaggedContentC } from "./taggedContent";
import { BondOfferingWithRelatedContent as imported16_BondOfferingWithRelatedContent, bondOfferingWithRelatedContentC as imported17_bondOfferingWithRelatedContentC, BondOfferingWithRelatedContentC as imported16_BondOfferingWithRelatedContentC, BondOfferingWithRelatedContent as imported17_BondOfferingWithRelatedContent, bondOfferingWithRelatedContentC as imported16_bondOfferingWithRelatedContentC, BondOfferingWithRelatedContentC as imported17_BondOfferingWithRelatedContentC } from "./bondOffering";
import { WithStatusCU as imported781_WithStatusCU, WithStatusU as imported391_WithStatusU, WithStatusCU as imported782_WithStatusCU, WithStatusCU as imported783_WithStatusCU, WithStatusCU as imported780_WithStatusCU, WithStatusU as imported390_WithStatusU } from "./threadThrough";
import * as t from "io-ts";

export type PortalOfferingsC = t.TypeC<{
  active: t.ReadonlyArrayC<imported782_WithStatusCU<imported201_TaggedContentC<imported16_BondOfferingWithRelatedContentC>>>,
  archived: t.ReadonlyArrayC<imported783_WithStatusCU<imported202_TaggedContentC<imported17_BondOfferingWithRelatedContentC>>>
}>;
export type PortalOfferings = {
  active: ReadonlyArray<imported390_WithStatusU<imported201_TaggedContent<imported16_BondOfferingWithRelatedContent>>>,
  archived: ReadonlyArray<imported391_WithStatusU<imported202_TaggedContent<imported17_BondOfferingWithRelatedContent>>>
};
export const portalOfferingsC: PortalOfferingsC = t.type({
  active: t.readonlyArray(imported780_WithStatusCU(imported201_taggedContentC(imported16_bondOfferingWithRelatedContentC))),
  archived: t.readonlyArray(imported781_WithStatusCU(imported202_taggedContentC(imported17_bondOfferingWithRelatedContentC)))
}) satisfies t.Type<PortalOfferings, unknown>;


