import { query, lit, int, end, Match } from "fp-ts-routing/lib";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { LocalDateC } from "../../codecs/localDate";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { bankerAdmin as imported66_bankerAdmin, bankerAdmin as imported70_bankerAdmin, bankerUser as imported84_bankerUser, bankerUser as imported50_bankerUser, bankerAdmin as imported68_bankerAdmin, bankerAdmin as imported90_bankerAdmin, bankerAdmin as imported74_bankerAdmin, bankerUser as imported78_bankerUser, bankerUser as imported48_bankerUser, bankerUser as imported74_bankerUser, bankerAdmin as imported92_bankerAdmin, bankerAdmin as imported50_bankerAdmin, bankerAdmin as imported94_bankerAdmin, bankerUser as imported88_bankerUser, bankerAdmin as imported58_bankerAdmin, bankerAdmin as imported56_bankerAdmin, bankerUser as imported52_bankerUser, bankerUser as imported86_bankerUser, bankerUser as imported72_bankerUser, bankerUser as imported68_bankerUser, bankerUser as imported60_bankerUser, bankerAdmin as imported62_bankerAdmin, bankerUser as imported54_bankerUser, bankerUser as imported64_bankerUser, bankerAdmin as imported82_bankerAdmin, bankerAdmin as imported86_bankerAdmin, bankerUser as imported66_bankerUser, bankerUser as imported80_bankerUser, bankerUser as imported58_bankerUser, bankerAdmin as imported64_bankerAdmin, bankerAdmin as imported78_bankerAdmin, bankerAdmin as imported84_bankerAdmin, bankerUser as imported62_bankerUser, bankerAdmin as imported52_bankerAdmin, bankerUser as imported70_bankerUser, bankerAdmin as imported80_bankerAdmin, bankerAdmin as imported54_bankerAdmin, bankerAdmin as imported76_bankerAdmin, bankerUser as imported90_bankerUser, bankerAdmin as imported88_bankerAdmin, bankerAdmin as imported72_bankerAdmin, bankerUser as imported76_bankerUser, bankerUser as imported82_bankerUser, bankerAdmin as imported60_bankerAdmin, bankerUser as imported56_bankerUser } from "./roles";


export const dashboard = {
  _tag: `Dashboard`,
  rolesAllowed: [imported48_bankerUser, imported50_bankerAdmin]
} as const;

export type DashboardTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Dashboard`>
}>;
export const dashboardTaggedC: DashboardTaggedC = t.type({
  _tag: t.literal(`Dashboard`)
});
export type DashboardTagged = t.TypeOf<DashboardTaggedC>;
export type Dashboard = DashboardTagged & typeof dashboard;
export type DashboardC = t.Type<Dashboard, DashboardTagged>;
export const dashboardC: DashboardC = pipe(dashboardTaggedC, c => new t.Type<Dashboard, DashboardTagged>(
  `Dashboard`,
  (u: unknown): u is Dashboard => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Dashboard> => pipe(c.decode(u), E.map(x => ({ ...x, ...dashboard }))),
  (x: Dashboard): DashboardTagged => ({ ...x, _tag: `Dashboard`}),
)) satisfies t.Type<Dashboard, unknown>;


export const investorActivity = {
  _tag: `InvestorActivity`,
  rolesAllowed: [imported50_bankerUser, imported52_bankerAdmin]
} as const;

export type InvestorActivityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorActivity`>
}>;
export const investorActivityTaggedC: InvestorActivityTaggedC = t.type({
  _tag: t.literal(`InvestorActivity`)
});
export type InvestorActivityTagged = t.TypeOf<InvestorActivityTaggedC>;
export type InvestorActivity = InvestorActivityTagged & typeof investorActivity;
export type InvestorActivityC = t.Type<InvestorActivity, InvestorActivityTagged>;
export const investorActivityC: InvestorActivityC = pipe(investorActivityTaggedC, c => new t.Type<InvestorActivity, InvestorActivityTagged>(
  `InvestorActivity`,
  (u: unknown): u is InvestorActivity => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorActivity> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorActivity }))),
  (x: InvestorActivity): InvestorActivityTagged => ({ ...x, _tag: `InvestorActivity`}),
)) satisfies t.Type<InvestorActivity, unknown>;


export const issuers = {
  _tag: `Issuers`,
  rolesAllowed: [imported52_bankerUser, imported54_bankerAdmin]
} as const;

export type IssuersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Issuers`>
}>;
export const issuersTaggedC: IssuersTaggedC = t.type({
  _tag: t.literal(`Issuers`)
});
export type IssuersTagged = t.TypeOf<IssuersTaggedC>;
export type Issuers = IssuersTagged & typeof issuers;
export type IssuersC = t.Type<Issuers, IssuersTagged>;
export const issuersC: IssuersC = pipe(issuersTaggedC, c => new t.Type<Issuers, IssuersTagged>(
  `Issuers`,
  (u: unknown): u is Issuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Issuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuers }))),
  (x: Issuers): IssuersTagged => ({ ...x, _tag: `Issuers`}),
)) satisfies t.Type<Issuers, unknown>;


export const portalUsers = {
  _tag: `PortalUsers`,
  rolesAllowed: [imported56_bankerAdmin]
} as const;

export type PortalUsersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PortalUsers`>
}>;
export const portalUsersTaggedC: PortalUsersTaggedC = t.type({
  _tag: t.literal(`PortalUsers`)
});
export type PortalUsersTagged = t.TypeOf<PortalUsersTaggedC>;
export type PortalUsers = PortalUsersTagged & typeof portalUsers;
export type PortalUsersC = t.Type<PortalUsers, PortalUsersTagged>;
export const portalUsersC: PortalUsersC = pipe(portalUsersTaggedC, c => new t.Type<PortalUsers, PortalUsersTagged>(
  `PortalUsers`,
  (u: unknown): u is PortalUsers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PortalUsers> => pipe(c.decode(u), E.map(x => ({ ...x, ...portalUsers }))),
  (x: PortalUsers): PortalUsersTagged => ({ ...x, _tag: `PortalUsers`}),
)) satisfies t.Type<PortalUsers, unknown>;


export const bondOfferings = {
  _tag: `BondOfferings`,
  rolesAllowed: [imported54_bankerUser, imported58_bankerAdmin]
} as const;

export type BondOfferingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferings`>
}>;
export const bondOfferingsTaggedC: BondOfferingsTaggedC = t.type({
  _tag: t.literal(`BondOfferings`)
});
export type BondOfferingsTagged = t.TypeOf<BondOfferingsTaggedC>;
export type BondOfferings = BondOfferingsTagged & typeof bondOfferings;
export type BondOfferingsC = t.Type<BondOfferings, BondOfferingsTagged>;
export const bondOfferingsC: BondOfferingsC = pipe(bondOfferingsTaggedC, c => new t.Type<BondOfferings, BondOfferingsTagged>(
  `BondOfferings`,
  (u: unknown): u is BondOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferings }))),
  (x: BondOfferings): BondOfferingsTagged => ({ ...x, _tag: `BondOfferings`}),
)) satisfies t.Type<BondOfferings, unknown>;


export const analyticsOverall = {
  _tag: `AnalyticsOverall`,
  rolesAllowed: [imported56_bankerUser, imported60_bankerAdmin]
} as const;

export type AnalyticsOverallTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AnalyticsOverall`>
}>;
export const analyticsOverallTaggedC: AnalyticsOverallTaggedC = t.type({
  _tag: t.literal(`AnalyticsOverall`)
});
export type AnalyticsOverallTagged = t.TypeOf<AnalyticsOverallTaggedC>;
export type AnalyticsOverall = AnalyticsOverallTagged & typeof analyticsOverall;
export type AnalyticsOverallC = t.Type<AnalyticsOverall, AnalyticsOverallTagged>;
export const analyticsOverallC: AnalyticsOverallC = pipe(analyticsOverallTaggedC, c => new t.Type<AnalyticsOverall, AnalyticsOverallTagged>(
  `AnalyticsOverall`,
  (u: unknown): u is AnalyticsOverall => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsOverall> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsOverall }))),
  (x: AnalyticsOverall): AnalyticsOverallTagged => ({ ...x, _tag: `AnalyticsOverall`}),
)) satisfies t.Type<AnalyticsOverall, unknown>;


export const analyticsBonds = {
  _tag: `AnalyticsBonds`,
  rolesAllowed: [imported58_bankerUser, imported62_bankerAdmin]
} as const;

export type AnalyticsBondsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AnalyticsBonds`>
}>;
export const analyticsBondsTaggedC: AnalyticsBondsTaggedC = t.type({
  _tag: t.literal(`AnalyticsBonds`)
});
export type AnalyticsBondsTagged = t.TypeOf<AnalyticsBondsTaggedC>;
export type AnalyticsBonds = AnalyticsBondsTagged & typeof analyticsBonds;
export type AnalyticsBondsC = t.Type<AnalyticsBonds, AnalyticsBondsTagged>;
export const analyticsBondsC: AnalyticsBondsC = pipe(analyticsBondsTaggedC, c => new t.Type<AnalyticsBonds, AnalyticsBondsTagged>(
  `AnalyticsBonds`,
  (u: unknown): u is AnalyticsBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsBonds }))),
  (x: AnalyticsBonds): AnalyticsBondsTagged => ({ ...x, _tag: `AnalyticsBonds`}),
)) satisfies t.Type<AnalyticsBonds, unknown>;


export const analyticsEmails = {
  _tag: `AnalyticsEmails`,
  rolesAllowed: [imported60_bankerUser, imported64_bankerAdmin]
} as const;

export type AnalyticsEmailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AnalyticsEmails`>
}>;
export const analyticsEmailsTaggedC: AnalyticsEmailsTaggedC = t.type({
  _tag: t.literal(`AnalyticsEmails`)
});
export type AnalyticsEmailsTagged = t.TypeOf<AnalyticsEmailsTaggedC>;
export type AnalyticsEmails = AnalyticsEmailsTagged & typeof analyticsEmails;
export type AnalyticsEmailsC = t.Type<AnalyticsEmails, AnalyticsEmailsTagged>;
export const analyticsEmailsC: AnalyticsEmailsC = pipe(analyticsEmailsTaggedC, c => new t.Type<AnalyticsEmails, AnalyticsEmailsTagged>(
  `AnalyticsEmails`,
  (u: unknown): u is AnalyticsEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsEmails }))),
  (x: AnalyticsEmails): AnalyticsEmailsTagged => ({ ...x, _tag: `AnalyticsEmails`}),
)) satisfies t.Type<AnalyticsEmails, unknown>;


export const rfps = {
  _tag: `Rfps`,
  rolesAllowed: [imported62_bankerUser, imported66_bankerAdmin]
} as const;

export type RfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Rfps`>
}>;
export const rfpsTaggedC: RfpsTaggedC = t.type({
  _tag: t.literal(`Rfps`)
});
export type RfpsTagged = t.TypeOf<RfpsTaggedC>;
export type Rfps = RfpsTagged & typeof rfps;
export type RfpsC = t.Type<Rfps, RfpsTagged>;
export const rfpsC: RfpsC = pipe(rfpsTaggedC, c => new t.Type<Rfps, RfpsTagged>(
  `Rfps`,
  (u: unknown): u is Rfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfps }))),
  (x: Rfps): RfpsTagged => ({ ...x, _tag: `Rfps`}),
)) satisfies t.Type<Rfps, unknown>;


export const investorProfile = {
  _tag: `InvestorProfile`,
  rolesAllowed: [imported64_bankerUser, imported68_bankerAdmin]
} as const;

export type InvestorProfileTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorProfile`>
}>;
export const investorProfileTaggedC: InvestorProfileTaggedC = t.type({
  _tag: t.literal(`InvestorProfile`)
});
export type InvestorProfileTagged = t.TypeOf<InvestorProfileTaggedC>;
export type InvestorProfile = InvestorProfileTagged & typeof investorProfile;
export type InvestorProfileC = t.Type<InvestorProfile, InvestorProfileTagged>;
export const investorProfileC: InvestorProfileC = pipe(investorProfileTaggedC, c => new t.Type<InvestorProfile, InvestorProfileTagged>(
  `InvestorProfile`,
  (u: unknown): u is InvestorProfile => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorProfile> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorProfile }))),
  (x: InvestorProfile): InvestorProfileTagged => ({ ...x, _tag: `InvestorProfile`}),
)) satisfies t.Type<InvestorProfile, unknown>;


export const bondOfferingsDealFileSummary = {
  _tag: `BondOfferingsDealFileSummary`,
  rolesAllowed: [imported66_bankerUser, imported70_bankerAdmin]
} as const;

export type BondOfferingsDealFileSummaryTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDealFileSummary`>
}>;
export const bondOfferingsDealFileSummaryTaggedC: BondOfferingsDealFileSummaryTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileSummary`)
});
export type BondOfferingsDealFileSummaryTagged = t.TypeOf<BondOfferingsDealFileSummaryTaggedC>;
export type BondOfferingsDealFileSummary = BondOfferingsDealFileSummaryTagged & typeof bondOfferingsDealFileSummary;
export type BondOfferingsDealFileSummaryC = t.Type<BondOfferingsDealFileSummary, BondOfferingsDealFileSummaryTagged>;
export const bondOfferingsDealFileSummaryC: BondOfferingsDealFileSummaryC = pipe(bondOfferingsDealFileSummaryTaggedC, c => new t.Type<BondOfferingsDealFileSummary, BondOfferingsDealFileSummaryTagged>(
  `BondOfferingsDealFileSummary`,
  (u: unknown): u is BondOfferingsDealFileSummary => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileSummary> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileSummary }))),
  (x: BondOfferingsDealFileSummary): BondOfferingsDealFileSummaryTagged => ({ ...x, _tag: `BondOfferingsDealFileSummary`}),
)) satisfies t.Type<BondOfferingsDealFileSummary, unknown>;


export const bondOfferingsDealFileDetails = {
  _tag: `BondOfferingsDealFileDetails`,
  rolesAllowed: [imported68_bankerUser, imported72_bankerAdmin]
} as const;

export type BondOfferingsDealFileDetailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDealFileDetails`>
}>;
export const bondOfferingsDealFileDetailsTaggedC: BondOfferingsDealFileDetailsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileDetails`)
});
export type BondOfferingsDealFileDetailsTagged = t.TypeOf<BondOfferingsDealFileDetailsTaggedC>;
export type BondOfferingsDealFileDetails = BondOfferingsDealFileDetailsTagged & typeof bondOfferingsDealFileDetails;
export type BondOfferingsDealFileDetailsC = t.Type<BondOfferingsDealFileDetails, BondOfferingsDealFileDetailsTagged>;
export const bondOfferingsDealFileDetailsC: BondOfferingsDealFileDetailsC = pipe(bondOfferingsDealFileDetailsTaggedC, c => new t.Type<BondOfferingsDealFileDetails, BondOfferingsDealFileDetailsTagged>(
  `BondOfferingsDealFileDetails`,
  (u: unknown): u is BondOfferingsDealFileDetails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileDetails> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileDetails }))),
  (x: BondOfferingsDealFileDetails): BondOfferingsDealFileDetailsTagged => ({ ...x, _tag: `BondOfferingsDealFileDetails`}),
)) satisfies t.Type<BondOfferingsDealFileDetails, unknown>;


export const bondOfferingsDealFileInvestors = {
  _tag: `BondOfferingsDealFileInvestors`,
  rolesAllowed: [imported70_bankerUser, imported74_bankerAdmin]
} as const;

export type BondOfferingsDealFileInvestorsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDealFileInvestors`>
}>;
export const bondOfferingsDealFileInvestorsTaggedC: BondOfferingsDealFileInvestorsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileInvestors`)
});
export type BondOfferingsDealFileInvestorsTagged = t.TypeOf<BondOfferingsDealFileInvestorsTaggedC>;
export type BondOfferingsDealFileInvestors = BondOfferingsDealFileInvestorsTagged & typeof bondOfferingsDealFileInvestors;
export type BondOfferingsDealFileInvestorsC = t.Type<BondOfferingsDealFileInvestors, BondOfferingsDealFileInvestorsTagged>;
export const bondOfferingsDealFileInvestorsC: BondOfferingsDealFileInvestorsC = pipe(bondOfferingsDealFileInvestorsTaggedC, c => new t.Type<BondOfferingsDealFileInvestors, BondOfferingsDealFileInvestorsTagged>(
  `BondOfferingsDealFileInvestors`,
  (u: unknown): u is BondOfferingsDealFileInvestors => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileInvestors> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileInvestors }))),
  (x: BondOfferingsDealFileInvestors): BondOfferingsDealFileInvestorsTagged => ({ ...x, _tag: `BondOfferingsDealFileInvestors`}),
)) satisfies t.Type<BondOfferingsDealFileInvestors, unknown>;


export const bondOfferingsDealFileAnalytics = {
  _tag: `BondOfferingsDealFileAnalytics`,
  rolesAllowed: [imported72_bankerUser, imported76_bankerAdmin]
} as const;

export type BondOfferingsDealFileAnalyticsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDealFileAnalytics`>
}>;
export const bondOfferingsDealFileAnalyticsTaggedC: BondOfferingsDealFileAnalyticsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileAnalytics`)
});
export type BondOfferingsDealFileAnalyticsTagged = t.TypeOf<BondOfferingsDealFileAnalyticsTaggedC>;
export type BondOfferingsDealFileAnalytics = BondOfferingsDealFileAnalyticsTagged & typeof bondOfferingsDealFileAnalytics;
export type BondOfferingsDealFileAnalyticsC = t.Type<BondOfferingsDealFileAnalytics, BondOfferingsDealFileAnalyticsTagged>;
export const bondOfferingsDealFileAnalyticsC: BondOfferingsDealFileAnalyticsC = pipe(bondOfferingsDealFileAnalyticsTaggedC, c => new t.Type<BondOfferingsDealFileAnalytics, BondOfferingsDealFileAnalyticsTagged>(
  `BondOfferingsDealFileAnalytics`,
  (u: unknown): u is BondOfferingsDealFileAnalytics => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileAnalytics> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileAnalytics }))),
  (x: BondOfferingsDealFileAnalytics): BondOfferingsDealFileAnalyticsTagged => ({ ...x, _tag: `BondOfferingsDealFileAnalytics`}),
)) satisfies t.Type<BondOfferingsDealFileAnalytics, unknown>;


export const bondOfferingsDealFileEmails = {
  _tag: `BondOfferingsDealFileEmails`,
  rolesAllowed: [imported74_bankerUser, imported78_bankerAdmin]
} as const;

export type BondOfferingsDealFileEmailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDealFileEmails`>
}>;
export const bondOfferingsDealFileEmailsTaggedC: BondOfferingsDealFileEmailsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileEmails`)
});
export type BondOfferingsDealFileEmailsTagged = t.TypeOf<BondOfferingsDealFileEmailsTaggedC>;
export type BondOfferingsDealFileEmails = BondOfferingsDealFileEmailsTagged & typeof bondOfferingsDealFileEmails;
export type BondOfferingsDealFileEmailsC = t.Type<BondOfferingsDealFileEmails, BondOfferingsDealFileEmailsTagged>;
export const bondOfferingsDealFileEmailsC: BondOfferingsDealFileEmailsC = pipe(bondOfferingsDealFileEmailsTaggedC, c => new t.Type<BondOfferingsDealFileEmails, BondOfferingsDealFileEmailsTagged>(
  `BondOfferingsDealFileEmails`,
  (u: unknown): u is BondOfferingsDealFileEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileEmails }))),
  (x: BondOfferingsDealFileEmails): BondOfferingsDealFileEmailsTagged => ({ ...x, _tag: `BondOfferingsDealFileEmails`}),
)) satisfies t.Type<BondOfferingsDealFileEmails, unknown>;


export const bondOfferingsDealFileInfoRequests = {
  _tag: `BondOfferingsDealFileInfoRequests`,
  rolesAllowed: [imported76_bankerUser, imported80_bankerAdmin]
} as const;

export type BondOfferingsDealFileInfoRequestsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDealFileInfoRequests`>
}>;
export const bondOfferingsDealFileInfoRequestsTaggedC: BondOfferingsDealFileInfoRequestsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileInfoRequests`)
});
export type BondOfferingsDealFileInfoRequestsTagged = t.TypeOf<BondOfferingsDealFileInfoRequestsTaggedC>;
export type BondOfferingsDealFileInfoRequests = BondOfferingsDealFileInfoRequestsTagged & typeof bondOfferingsDealFileInfoRequests;
export type BondOfferingsDealFileInfoRequestsC = t.Type<BondOfferingsDealFileInfoRequests, BondOfferingsDealFileInfoRequestsTagged>;
export const bondOfferingsDealFileInfoRequestsC: BondOfferingsDealFileInfoRequestsC = pipe(bondOfferingsDealFileInfoRequestsTaggedC, c => new t.Type<BondOfferingsDealFileInfoRequests, BondOfferingsDealFileInfoRequestsTagged>(
  `BondOfferingsDealFileInfoRequests`,
  (u: unknown): u is BondOfferingsDealFileInfoRequests => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileInfoRequests> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileInfoRequests }))),
  (x: BondOfferingsDealFileInfoRequests): BondOfferingsDealFileInfoRequestsTagged => ({ ...x, _tag: `BondOfferingsDealFileInfoRequests`}),
)) satisfies t.Type<BondOfferingsDealFileInfoRequests, unknown>;


export const analyticsEmailDetails = {
  _tag: `AnalyticsEmailDetails`,
  rolesAllowed: [imported78_bankerUser, imported82_bankerAdmin]
} as const;

export type AnalyticsEmailDetailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AnalyticsEmailDetails`>
}>;
export const analyticsEmailDetailsTaggedC: AnalyticsEmailDetailsTaggedC = t.type({
  _tag: t.literal(`AnalyticsEmailDetails`)
});
export type AnalyticsEmailDetailsTagged = t.TypeOf<AnalyticsEmailDetailsTaggedC>;
export type AnalyticsEmailDetails = AnalyticsEmailDetailsTagged & typeof analyticsEmailDetails;
export type AnalyticsEmailDetailsC = t.Type<AnalyticsEmailDetails, AnalyticsEmailDetailsTagged>;
export const analyticsEmailDetailsC: AnalyticsEmailDetailsC = pipe(analyticsEmailDetailsTaggedC, c => new t.Type<AnalyticsEmailDetails, AnalyticsEmailDetailsTagged>(
  `AnalyticsEmailDetails`,
  (u: unknown): u is AnalyticsEmailDetails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsEmailDetails> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsEmailDetails }))),
  (x: AnalyticsEmailDetails): AnalyticsEmailDetailsTagged => ({ ...x, _tag: `AnalyticsEmailDetails`}),
)) satisfies t.Type<AnalyticsEmailDetails, unknown>;


export const rfpsDealFileSummary = {
  _tag: `RfpsDealFileSummary`,
  rolesAllowed: [imported80_bankerUser, imported84_bankerAdmin]
} as const;

export type RfpsDealFileSummaryTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsDealFileSummary`>
}>;
export const rfpsDealFileSummaryTaggedC: RfpsDealFileSummaryTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileSummary`)
});
export type RfpsDealFileSummaryTagged = t.TypeOf<RfpsDealFileSummaryTaggedC>;
export type RfpsDealFileSummary = RfpsDealFileSummaryTagged & typeof rfpsDealFileSummary;
export type RfpsDealFileSummaryC = t.Type<RfpsDealFileSummary, RfpsDealFileSummaryTagged>;
export const rfpsDealFileSummaryC: RfpsDealFileSummaryC = pipe(rfpsDealFileSummaryTaggedC, c => new t.Type<RfpsDealFileSummary, RfpsDealFileSummaryTagged>(
  `RfpsDealFileSummary`,
  (u: unknown): u is RfpsDealFileSummary => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileSummary> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileSummary }))),
  (x: RfpsDealFileSummary): RfpsDealFileSummaryTagged => ({ ...x, _tag: `RfpsDealFileSummary`}),
)) satisfies t.Type<RfpsDealFileSummary, unknown>;


export const rfpsDealFileDetails = {
  _tag: `RfpsDealFileDetails`,
  rolesAllowed: [imported82_bankerUser, imported86_bankerAdmin]
} as const;

export type RfpsDealFileDetailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsDealFileDetails`>
}>;
export const rfpsDealFileDetailsTaggedC: RfpsDealFileDetailsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileDetails`)
});
export type RfpsDealFileDetailsTagged = t.TypeOf<RfpsDealFileDetailsTaggedC>;
export type RfpsDealFileDetails = RfpsDealFileDetailsTagged & typeof rfpsDealFileDetails;
export type RfpsDealFileDetailsC = t.Type<RfpsDealFileDetails, RfpsDealFileDetailsTagged>;
export const rfpsDealFileDetailsC: RfpsDealFileDetailsC = pipe(rfpsDealFileDetailsTaggedC, c => new t.Type<RfpsDealFileDetails, RfpsDealFileDetailsTagged>(
  `RfpsDealFileDetails`,
  (u: unknown): u is RfpsDealFileDetails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileDetails> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileDetails }))),
  (x: RfpsDealFileDetails): RfpsDealFileDetailsTagged => ({ ...x, _tag: `RfpsDealFileDetails`}),
)) satisfies t.Type<RfpsDealFileDetails, unknown>;


export const rfpsDealFileInvestors = {
  _tag: `RfpsDealFileInvestors`,
  rolesAllowed: [imported84_bankerUser, imported88_bankerAdmin]
} as const;

export type RfpsDealFileInvestorsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsDealFileInvestors`>
}>;
export const rfpsDealFileInvestorsTaggedC: RfpsDealFileInvestorsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileInvestors`)
});
export type RfpsDealFileInvestorsTagged = t.TypeOf<RfpsDealFileInvestorsTaggedC>;
export type RfpsDealFileInvestors = RfpsDealFileInvestorsTagged & typeof rfpsDealFileInvestors;
export type RfpsDealFileInvestorsC = t.Type<RfpsDealFileInvestors, RfpsDealFileInvestorsTagged>;
export const rfpsDealFileInvestorsC: RfpsDealFileInvestorsC = pipe(rfpsDealFileInvestorsTaggedC, c => new t.Type<RfpsDealFileInvestors, RfpsDealFileInvestorsTagged>(
  `RfpsDealFileInvestors`,
  (u: unknown): u is RfpsDealFileInvestors => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileInvestors> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileInvestors }))),
  (x: RfpsDealFileInvestors): RfpsDealFileInvestorsTagged => ({ ...x, _tag: `RfpsDealFileInvestors`}),
)) satisfies t.Type<RfpsDealFileInvestors, unknown>;


export const rfpsDealFileAnalytics = {
  _tag: `RfpsDealFileAnalytics`,
  rolesAllowed: [imported86_bankerUser, imported90_bankerAdmin]
} as const;

export type RfpsDealFileAnalyticsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsDealFileAnalytics`>
}>;
export const rfpsDealFileAnalyticsTaggedC: RfpsDealFileAnalyticsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileAnalytics`)
});
export type RfpsDealFileAnalyticsTagged = t.TypeOf<RfpsDealFileAnalyticsTaggedC>;
export type RfpsDealFileAnalytics = RfpsDealFileAnalyticsTagged & typeof rfpsDealFileAnalytics;
export type RfpsDealFileAnalyticsC = t.Type<RfpsDealFileAnalytics, RfpsDealFileAnalyticsTagged>;
export const rfpsDealFileAnalyticsC: RfpsDealFileAnalyticsC = pipe(rfpsDealFileAnalyticsTaggedC, c => new t.Type<RfpsDealFileAnalytics, RfpsDealFileAnalyticsTagged>(
  `RfpsDealFileAnalytics`,
  (u: unknown): u is RfpsDealFileAnalytics => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileAnalytics> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileAnalytics }))),
  (x: RfpsDealFileAnalytics): RfpsDealFileAnalyticsTagged => ({ ...x, _tag: `RfpsDealFileAnalytics`}),
)) satisfies t.Type<RfpsDealFileAnalytics, unknown>;


export const rfpsDealFileEmails = {
  _tag: `RfpsDealFileEmails`,
  rolesAllowed: [imported88_bankerUser, imported92_bankerAdmin]
} as const;

export type RfpsDealFileEmailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsDealFileEmails`>
}>;
export const rfpsDealFileEmailsTaggedC: RfpsDealFileEmailsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileEmails`)
});
export type RfpsDealFileEmailsTagged = t.TypeOf<RfpsDealFileEmailsTaggedC>;
export type RfpsDealFileEmails = RfpsDealFileEmailsTagged & typeof rfpsDealFileEmails;
export type RfpsDealFileEmailsC = t.Type<RfpsDealFileEmails, RfpsDealFileEmailsTagged>;
export const rfpsDealFileEmailsC: RfpsDealFileEmailsC = pipe(rfpsDealFileEmailsTaggedC, c => new t.Type<RfpsDealFileEmails, RfpsDealFileEmailsTagged>(
  `RfpsDealFileEmails`,
  (u: unknown): u is RfpsDealFileEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileEmails }))),
  (x: RfpsDealFileEmails): RfpsDealFileEmailsTagged => ({ ...x, _tag: `RfpsDealFileEmails`}),
)) satisfies t.Type<RfpsDealFileEmails, unknown>;


export const rfpsDealFileInfoRequests = {
  _tag: `RfpsDealFileInfoRequests`,
  rolesAllowed: [imported90_bankerUser, imported94_bankerAdmin]
} as const;

export type RfpsDealFileInfoRequestsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsDealFileInfoRequests`>
}>;
export const rfpsDealFileInfoRequestsTaggedC: RfpsDealFileInfoRequestsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileInfoRequests`)
});
export type RfpsDealFileInfoRequestsTagged = t.TypeOf<RfpsDealFileInfoRequestsTaggedC>;
export type RfpsDealFileInfoRequests = RfpsDealFileInfoRequestsTagged & typeof rfpsDealFileInfoRequests;
export type RfpsDealFileInfoRequestsC = t.Type<RfpsDealFileInfoRequests, RfpsDealFileInfoRequestsTagged>;
export const rfpsDealFileInfoRequestsC: RfpsDealFileInfoRequestsC = pipe(rfpsDealFileInfoRequestsTaggedC, c => new t.Type<RfpsDealFileInfoRequests, RfpsDealFileInfoRequestsTagged>(
  `RfpsDealFileInfoRequests`,
  (u: unknown): u is RfpsDealFileInfoRequests => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileInfoRequests> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileInfoRequests }))),
  (x: RfpsDealFileInfoRequests): RfpsDealFileInfoRequestsTagged => ({ ...x, _tag: `RfpsDealFileInfoRequests`}),
)) satisfies t.Type<RfpsDealFileInfoRequests, unknown>;


export const allDealPortalPageC = [dashboardC, investorActivityC, issuersC, portalUsersC, bondOfferingsC, analyticsOverallC, analyticsBondsC, analyticsEmailsC, rfpsC, investorProfileC, bondOfferingsDealFileSummaryC, bondOfferingsDealFileDetailsC, bondOfferingsDealFileInvestorsC, bondOfferingsDealFileAnalyticsC, bondOfferingsDealFileEmailsC, bondOfferingsDealFileInfoRequestsC, analyticsEmailDetailsC, rfpsDealFileSummaryC, rfpsDealFileDetailsC, rfpsDealFileInvestorsC, rfpsDealFileAnalyticsC, rfpsDealFileEmailsC, rfpsDealFileInfoRequestsC] as const;
export const allDealPortalPageNames = [`Dashboard`, `InvestorActivity`, `Issuers`, `PortalUsers`, `BondOfferings`, `AnalyticsOverall`, `AnalyticsBonds`, `AnalyticsEmails`, `Rfps`, `InvestorProfile`, `BondOfferingsDealFileSummary`, `BondOfferingsDealFileDetails`, `BondOfferingsDealFileInvestors`, `BondOfferingsDealFileAnalytics`, `BondOfferingsDealFileEmails`, `BondOfferingsDealFileInfoRequests`, `AnalyticsEmailDetails`, `RfpsDealFileSummary`, `RfpsDealFileDetails`, `RfpsDealFileInvestors`, `RfpsDealFileAnalytics`, `RfpsDealFileEmails`, `RfpsDealFileInfoRequests`] as const;
export type DealPortalPageName = (typeof allDealPortalPageNames)[number];

export type DealPortalPageCU = t.UnionC<[DashboardC, InvestorActivityC, IssuersC, PortalUsersC, BondOfferingsC, AnalyticsOverallC, AnalyticsBondsC, AnalyticsEmailsC, RfpsC, InvestorProfileC, BondOfferingsDealFileSummaryC, BondOfferingsDealFileDetailsC, BondOfferingsDealFileInvestorsC, BondOfferingsDealFileAnalyticsC, BondOfferingsDealFileEmailsC, BondOfferingsDealFileInfoRequestsC, AnalyticsEmailDetailsC, RfpsDealFileSummaryC, RfpsDealFileDetailsC, RfpsDealFileInvestorsC, RfpsDealFileAnalyticsC, RfpsDealFileEmailsC, RfpsDealFileInfoRequestsC]>;
export type DealPortalPageU = Dashboard | InvestorActivity | Issuers | PortalUsers | BondOfferings | AnalyticsOverall | AnalyticsBonds | AnalyticsEmails | Rfps | InvestorProfile | BondOfferingsDealFileSummary | BondOfferingsDealFileDetails | BondOfferingsDealFileInvestors | BondOfferingsDealFileAnalytics | BondOfferingsDealFileEmails | BondOfferingsDealFileInfoRequests | AnalyticsEmailDetails | RfpsDealFileSummary | RfpsDealFileDetails | RfpsDealFileInvestors | RfpsDealFileAnalytics | RfpsDealFileEmails | RfpsDealFileInfoRequests;
export const DealPortalPageCU: DealPortalPageCU = t.union([dashboardC, investorActivityC, issuersC, portalUsersC, bondOfferingsC, analyticsOverallC, analyticsBondsC, analyticsEmailsC, rfpsC, investorProfileC, bondOfferingsDealFileSummaryC, bondOfferingsDealFileDetailsC, bondOfferingsDealFileInvestorsC, bondOfferingsDealFileAnalyticsC, bondOfferingsDealFileEmailsC, bondOfferingsDealFileInfoRequestsC, analyticsEmailDetailsC, rfpsDealFileSummaryC, rfpsDealFileDetailsC, rfpsDealFileInvestorsC, rfpsDealFileAnalyticsC, rfpsDealFileEmailsC, rfpsDealFileInfoRequestsC]) satisfies t.Type<DealPortalPageU, unknown>;

export const dealPortalPageOrd: Ord.Ord<DealPortalPageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDealPortalPage = [dashboard, investorActivity, issuers, portalUsers, bondOfferings, analyticsOverall, analyticsBonds, analyticsEmails, rfps, investorProfile, bondOfferingsDealFileSummary, bondOfferingsDealFileDetails, bondOfferingsDealFileInvestors, bondOfferingsDealFileAnalytics, bondOfferingsDealFileEmails, bondOfferingsDealFileInfoRequests, analyticsEmailDetails, rfpsDealFileSummary, rfpsDealFileDetails, rfpsDealFileInvestors, rfpsDealFileAnalytics, rfpsDealFileEmails, rfpsDealFileInfoRequests] as const;
export type DealPortalPageMap<A> = { [K in DealPortalPageName]: A };





const analyticsBondsPath = lit("deal-portal").then(lit("analytics")).then(lit("bonds"));
const analyticsBondsPathParts = ["analytics", "bonds"] as const;
export type AnalyticsBondsPathParts = typeof analyticsBondsPathParts;

const analyticsBondsQuery = t.strict({});
export type AnalyticsBondsParams = Describe<typeof analyticsBondsPath._A & typeof analyticsBondsQuery._A>;

export type AnalyticsBondsRoute<A = {}> = { match: Match<AnalyticsBondsParams & A>; pathParts: AnalyticsBondsPathParts };

export function analyticsBondsRoute(): AnalyticsBondsRoute;
export function analyticsBondsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsBondsParams>): AnalyticsBondsRoute<t.TypeOf<A>>;
export function analyticsBondsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsBondsParams>) {
  return {
    match: (q ? analyticsBondsPath.then(query(t.intersection([analyticsBondsQuery, q]))) : analyticsBondsPath.then(query(analyticsBondsQuery))).then(end),
    pathParts: analyticsBondsPathParts,
  };
}

const analyticsEmailsPath = lit("deal-portal").then(lit("analytics")).then(lit("emails"));
const analyticsEmailsPathParts = ["analytics", "emails"] as const;
export type AnalyticsEmailsPathParts = typeof analyticsEmailsPathParts;

const analyticsEmailsQuery = t.strict({});
export type AnalyticsEmailsParams = Describe<typeof analyticsEmailsPath._A & typeof analyticsEmailsQuery._A>;

export type AnalyticsEmailsRoute<A = {}> = { match: Match<AnalyticsEmailsParams & A>; pathParts: AnalyticsEmailsPathParts };

export function analyticsEmailsRoute(): AnalyticsEmailsRoute;
export function analyticsEmailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsEmailsParams>): AnalyticsEmailsRoute<t.TypeOf<A>>;
export function analyticsEmailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsEmailsParams>) {
  return {
    match: (q ? analyticsEmailsPath.then(query(t.intersection([analyticsEmailsQuery, q]))) : analyticsEmailsPath.then(query(analyticsEmailsQuery))).then(end),
    pathParts: analyticsEmailsPathParts,
  };
}

const analyticsEmailDetailsPath = lit("deal-portal").then(lit("analytics")).then(lit("emails")).then(lit("details")).then(int("campaignId"));
const analyticsEmailDetailsPathParts = ["analytics", "emails", "details", "campaignId"] as const;
export type AnalyticsEmailDetailsPathParts = typeof analyticsEmailDetailsPathParts;

const analyticsEmailDetailsQuery = t.strict({});
export type AnalyticsEmailDetailsParams = Describe<typeof analyticsEmailDetailsPath._A & typeof analyticsEmailDetailsQuery._A>;

export type AnalyticsEmailDetailsRoute<A = {}> = { match: Match<AnalyticsEmailDetailsParams & A>; pathParts: AnalyticsEmailDetailsPathParts };

export function analyticsEmailDetailsRoute(): AnalyticsEmailDetailsRoute;
export function analyticsEmailDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsEmailDetailsParams>): AnalyticsEmailDetailsRoute<t.TypeOf<A>>;
export function analyticsEmailDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsEmailDetailsParams>) {
  return {
    match: (q ? analyticsEmailDetailsPath.then(query(t.intersection([analyticsEmailDetailsQuery, q]))) : analyticsEmailDetailsPath.then(query(analyticsEmailDetailsQuery))).then(end),
    pathParts: analyticsEmailDetailsPathParts,
  };
}

const investorActivityPath = lit("deal-portal").then(lit("analytics")).then(lit("investor-activity"));
const investorActivityPathParts = ["analytics", "investor-activity"] as const;
export type InvestorActivityPathParts = typeof investorActivityPathParts;

const investorActivityQuery = t.strict({});
export type InvestorActivityParams = Describe<typeof investorActivityPath._A & typeof investorActivityQuery._A>;

export type InvestorActivityRoute<A = {}> = { match: Match<InvestorActivityParams & A>; pathParts: InvestorActivityPathParts };

export function investorActivityRoute(): InvestorActivityRoute;
export function investorActivityRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorActivityParams>): InvestorActivityRoute<t.TypeOf<A>>;
export function investorActivityRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorActivityParams>) {
  return {
    match: (q ? investorActivityPath.then(query(t.intersection([investorActivityQuery, q]))) : investorActivityPath.then(query(investorActivityQuery))).then(end),
    pathParts: investorActivityPathParts,
  };
}

const investorProfilePath = lit("deal-portal").then(lit("analytics")).then(lit("investor-activity")).then(lit("details"));
const investorProfilePathParts = ["analytics", "investor-activity", "details"] as const;
export type InvestorProfilePathParts = typeof investorProfilePathParts;

const investorProfileQuery = t.exact(t.type({
  "userId": NumberFromString,
  "startDate": LocalDateC,
  "endDate": LocalDateC
}));
export type InvestorProfileParams = Describe<typeof investorProfilePath._A & typeof investorProfileQuery._A>;

export type InvestorProfileRoute<A = {}> = { match: Match<InvestorProfileParams & A>; pathParts: InvestorProfilePathParts };

export function investorProfileRoute(): InvestorProfileRoute;
export function investorProfileRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorProfileParams>): InvestorProfileRoute<t.TypeOf<A>>;
export function investorProfileRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorProfileParams>) {
  return {
    match: (q ? investorProfilePath.then(query(t.intersection([investorProfileQuery, q]))) : investorProfilePath.then(query(investorProfileQuery))).then(end),
    pathParts: investorProfilePathParts,
  };
}

const analyticsOverallPath = lit("deal-portal").then(lit("analytics")).then(lit("overall"));
const analyticsOverallPathParts = ["analytics", "overall"] as const;
export type AnalyticsOverallPathParts = typeof analyticsOverallPathParts;

const analyticsOverallQuery = t.strict({});
export type AnalyticsOverallParams = Describe<typeof analyticsOverallPath._A & typeof analyticsOverallQuery._A>;

export type AnalyticsOverallRoute<A = {}> = { match: Match<AnalyticsOverallParams & A>; pathParts: AnalyticsOverallPathParts };

export function analyticsOverallRoute(): AnalyticsOverallRoute;
export function analyticsOverallRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsOverallParams>): AnalyticsOverallRoute<t.TypeOf<A>>;
export function analyticsOverallRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsOverallParams>) {
  return {
    match: (q ? analyticsOverallPath.then(query(t.intersection([analyticsOverallQuery, q]))) : analyticsOverallPath.then(query(analyticsOverallQuery))).then(end),
    pathParts: analyticsOverallPathParts,
  };
}

const bondOfferingsPath = lit("deal-portal").then(lit("bond-offerings"));
const bondOfferingsPathParts = ["bond-offerings"] as const;
export type BondOfferingsPathParts = typeof bondOfferingsPathParts;

const bondOfferingsQuery = t.strict({});
export type BondOfferingsParams = Describe<typeof bondOfferingsPath._A & typeof bondOfferingsQuery._A>;

export type BondOfferingsRoute<A = {}> = { match: Match<BondOfferingsParams & A>; pathParts: BondOfferingsPathParts };

export function bondOfferingsRoute(): BondOfferingsRoute;
export function bondOfferingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsParams>): BondOfferingsRoute<t.TypeOf<A>>;
export function bondOfferingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsParams>) {
  return {
    match: (q ? bondOfferingsPath.then(query(t.intersection([bondOfferingsQuery, q]))) : bondOfferingsPath.then(query(bondOfferingsQuery))).then(end),
    pathParts: bondOfferingsPathParts,
  };
}

const bondOfferingsDealFileAnalyticsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("analytics"));
const bondOfferingsDealFileAnalyticsPathParts = ["bond-offerings", "deal-file", "offeringId", "analytics"] as const;
export type BondOfferingsDealFileAnalyticsPathParts = typeof bondOfferingsDealFileAnalyticsPathParts;

const bondOfferingsDealFileAnalyticsQuery = t.strict({});
export type BondOfferingsDealFileAnalyticsParams = Describe<typeof bondOfferingsDealFileAnalyticsPath._A & typeof bondOfferingsDealFileAnalyticsQuery._A>;

export type BondOfferingsDealFileAnalyticsRoute<A = {}> = { match: Match<BondOfferingsDealFileAnalyticsParams & A>; pathParts: BondOfferingsDealFileAnalyticsPathParts };

export function bondOfferingsDealFileAnalyticsRoute(): BondOfferingsDealFileAnalyticsRoute;
export function bondOfferingsDealFileAnalyticsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileAnalyticsParams>): BondOfferingsDealFileAnalyticsRoute<t.TypeOf<A>>;
export function bondOfferingsDealFileAnalyticsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileAnalyticsParams>) {
  return {
    match: (q ? bondOfferingsDealFileAnalyticsPath.then(query(t.intersection([bondOfferingsDealFileAnalyticsQuery, q]))) : bondOfferingsDealFileAnalyticsPath.then(query(bondOfferingsDealFileAnalyticsQuery))).then(end),
    pathParts: bondOfferingsDealFileAnalyticsPathParts,
  };
}

const bondOfferingsDealFileDetailsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("details"));
const bondOfferingsDealFileDetailsPathParts = ["bond-offerings", "deal-file", "offeringId", "details"] as const;
export type BondOfferingsDealFileDetailsPathParts = typeof bondOfferingsDealFileDetailsPathParts;

const bondOfferingsDealFileDetailsQuery = t.strict({});
export type BondOfferingsDealFileDetailsParams = Describe<typeof bondOfferingsDealFileDetailsPath._A & typeof bondOfferingsDealFileDetailsQuery._A>;

export type BondOfferingsDealFileDetailsRoute<A = {}> = { match: Match<BondOfferingsDealFileDetailsParams & A>; pathParts: BondOfferingsDealFileDetailsPathParts };

export function bondOfferingsDealFileDetailsRoute(): BondOfferingsDealFileDetailsRoute;
export function bondOfferingsDealFileDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileDetailsParams>): BondOfferingsDealFileDetailsRoute<t.TypeOf<A>>;
export function bondOfferingsDealFileDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileDetailsParams>) {
  return {
    match: (q ? bondOfferingsDealFileDetailsPath.then(query(t.intersection([bondOfferingsDealFileDetailsQuery, q]))) : bondOfferingsDealFileDetailsPath.then(query(bondOfferingsDealFileDetailsQuery))).then(end),
    pathParts: bondOfferingsDealFileDetailsPathParts,
  };
}

const bondOfferingsDealFileEmailsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("emails"));
const bondOfferingsDealFileEmailsPathParts = ["bond-offerings", "deal-file", "offeringId", "emails"] as const;
export type BondOfferingsDealFileEmailsPathParts = typeof bondOfferingsDealFileEmailsPathParts;

const bondOfferingsDealFileEmailsQuery = t.strict({});
export type BondOfferingsDealFileEmailsParams = Describe<typeof bondOfferingsDealFileEmailsPath._A & typeof bondOfferingsDealFileEmailsQuery._A>;

export type BondOfferingsDealFileEmailsRoute<A = {}> = { match: Match<BondOfferingsDealFileEmailsParams & A>; pathParts: BondOfferingsDealFileEmailsPathParts };

export function bondOfferingsDealFileEmailsRoute(): BondOfferingsDealFileEmailsRoute;
export function bondOfferingsDealFileEmailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileEmailsParams>): BondOfferingsDealFileEmailsRoute<t.TypeOf<A>>;
export function bondOfferingsDealFileEmailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileEmailsParams>) {
  return {
    match: (q ? bondOfferingsDealFileEmailsPath.then(query(t.intersection([bondOfferingsDealFileEmailsQuery, q]))) : bondOfferingsDealFileEmailsPath.then(query(bondOfferingsDealFileEmailsQuery))).then(end),
    pathParts: bondOfferingsDealFileEmailsPathParts,
  };
}

const bondOfferingsDealFileInfoRequestsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("info-requests"));
const bondOfferingsDealFileInfoRequestsPathParts = [
  "bond-offerings", "deal-file", "offeringId", "info-requests"
] as const;
export type BondOfferingsDealFileInfoRequestsPathParts = typeof bondOfferingsDealFileInfoRequestsPathParts;

const bondOfferingsDealFileInfoRequestsQuery = t.strict({});
export type BondOfferingsDealFileInfoRequestsParams = Describe<typeof bondOfferingsDealFileInfoRequestsPath._A & typeof bondOfferingsDealFileInfoRequestsQuery._A>;

export type BondOfferingsDealFileInfoRequestsRoute<A = {}> = { match: Match<BondOfferingsDealFileInfoRequestsParams & A>; pathParts: BondOfferingsDealFileInfoRequestsPathParts };

export function bondOfferingsDealFileInfoRequestsRoute(): BondOfferingsDealFileInfoRequestsRoute;
export function bondOfferingsDealFileInfoRequestsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileInfoRequestsParams>): BondOfferingsDealFileInfoRequestsRoute<t.TypeOf<A>>;
export function bondOfferingsDealFileInfoRequestsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileInfoRequestsParams>) {
  return {
    match: (q ? bondOfferingsDealFileInfoRequestsPath.then(query(t.intersection([bondOfferingsDealFileInfoRequestsQuery, q]))) : bondOfferingsDealFileInfoRequestsPath.then(query(bondOfferingsDealFileInfoRequestsQuery))).then(end),
    pathParts: bondOfferingsDealFileInfoRequestsPathParts,
  };
}

const bondOfferingsDealFileInvestorsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("investors"));
const bondOfferingsDealFileInvestorsPathParts = ["bond-offerings", "deal-file", "offeringId", "investors"] as const;
export type BondOfferingsDealFileInvestorsPathParts = typeof bondOfferingsDealFileInvestorsPathParts;

const bondOfferingsDealFileInvestorsQuery = t.strict({});
export type BondOfferingsDealFileInvestorsParams = Describe<typeof bondOfferingsDealFileInvestorsPath._A & typeof bondOfferingsDealFileInvestorsQuery._A>;

export type BondOfferingsDealFileInvestorsRoute<A = {}> = { match: Match<BondOfferingsDealFileInvestorsParams & A>; pathParts: BondOfferingsDealFileInvestorsPathParts };

export function bondOfferingsDealFileInvestorsRoute(): BondOfferingsDealFileInvestorsRoute;
export function bondOfferingsDealFileInvestorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileInvestorsParams>): BondOfferingsDealFileInvestorsRoute<t.TypeOf<A>>;
export function bondOfferingsDealFileInvestorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileInvestorsParams>) {
  return {
    match: (q ? bondOfferingsDealFileInvestorsPath.then(query(t.intersection([bondOfferingsDealFileInvestorsQuery, q]))) : bondOfferingsDealFileInvestorsPath.then(query(bondOfferingsDealFileInvestorsQuery))).then(end),
    pathParts: bondOfferingsDealFileInvestorsPathParts,
  };
}

const bondOfferingsDealFileSummaryPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("summary"));
const bondOfferingsDealFileSummaryPathParts = ["bond-offerings", "deal-file", "offeringId", "summary"] as const;
export type BondOfferingsDealFileSummaryPathParts = typeof bondOfferingsDealFileSummaryPathParts;

const bondOfferingsDealFileSummaryQuery = t.strict({});
export type BondOfferingsDealFileSummaryParams = Describe<typeof bondOfferingsDealFileSummaryPath._A & typeof bondOfferingsDealFileSummaryQuery._A>;

export type BondOfferingsDealFileSummaryRoute<A = {}> = { match: Match<BondOfferingsDealFileSummaryParams & A>; pathParts: BondOfferingsDealFileSummaryPathParts };

export function bondOfferingsDealFileSummaryRoute(): BondOfferingsDealFileSummaryRoute;
export function bondOfferingsDealFileSummaryRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileSummaryParams>): BondOfferingsDealFileSummaryRoute<t.TypeOf<A>>;
export function bondOfferingsDealFileSummaryRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileSummaryParams>) {
  return {
    match: (q ? bondOfferingsDealFileSummaryPath.then(query(t.intersection([bondOfferingsDealFileSummaryQuery, q]))) : bondOfferingsDealFileSummaryPath.then(query(bondOfferingsDealFileSummaryQuery))).then(end),
    pathParts: bondOfferingsDealFileSummaryPathParts,
  };
}

const dashboardPath = lit("deal-portal").then(lit("dashboard"));
const dashboardPathParts = ["dashboard"] as const;
export type DashboardPathParts = typeof dashboardPathParts;

const dashboardQuery = t.strict({});
export type DashboardParams = Describe<typeof dashboardPath._A & typeof dashboardQuery._A>;

export type DashboardRoute<A = {}> = { match: Match<DashboardParams & A>; pathParts: DashboardPathParts };

export function dashboardRoute(): DashboardRoute;
export function dashboardRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DashboardParams>): DashboardRoute<t.TypeOf<A>>;
export function dashboardRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DashboardParams>) {
  return {
    match: (q ? dashboardPath.then(query(t.intersection([dashboardQuery, q]))) : dashboardPath.then(query(dashboardQuery))).then(end),
    pathParts: dashboardPathParts,
  };
}

const issuersPath = lit("deal-portal").then(lit("issuers"));
const issuersPathParts = ["issuers"] as const;
export type IssuersPathParts = typeof issuersPathParts;

const issuersQuery = t.strict({});
export type IssuersParams = Describe<typeof issuersPath._A & typeof issuersQuery._A>;

export type IssuersRoute<A = {}> = { match: Match<IssuersParams & A>; pathParts: IssuersPathParts };

export function issuersRoute(): IssuersRoute;
export function issuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersParams>): IssuersRoute<t.TypeOf<A>>;
export function issuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersParams>) {
  return {
    match: (q ? issuersPath.then(query(t.intersection([issuersQuery, q]))) : issuersPath.then(query(issuersQuery))).then(end),
    pathParts: issuersPathParts,
  };
}

const portalUsersPath = lit("deal-portal").then(lit("portal-users"));
const portalUsersPathParts = ["portal-users"] as const;
export type PortalUsersPathParts = typeof portalUsersPathParts;

const portalUsersQuery = t.strict({});
export type PortalUsersParams = Describe<typeof portalUsersPath._A & typeof portalUsersQuery._A>;

export type PortalUsersRoute<A = {}> = { match: Match<PortalUsersParams & A>; pathParts: PortalUsersPathParts };

export function portalUsersRoute(): PortalUsersRoute;
export function portalUsersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, PortalUsersParams>): PortalUsersRoute<t.TypeOf<A>>;
export function portalUsersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, PortalUsersParams>) {
  return {
    match: (q ? portalUsersPath.then(query(t.intersection([portalUsersQuery, q]))) : portalUsersPath.then(query(portalUsersQuery))).then(end),
    pathParts: portalUsersPathParts,
  };
}

const rfpsPath = lit("deal-portal").then(lit("rfps"));
const rfpsPathParts = ["rfps"] as const;
export type RfpsPathParts = typeof rfpsPathParts;

const rfpsQuery = t.strict({});
export type RfpsParams = Describe<typeof rfpsPath._A & typeof rfpsQuery._A>;

export type RfpsRoute<A = {}> = { match: Match<RfpsParams & A>; pathParts: RfpsPathParts };

export function rfpsRoute(): RfpsRoute;
export function rfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsParams>): RfpsRoute<t.TypeOf<A>>;
export function rfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsParams>) {
  return {
    match: (q ? rfpsPath.then(query(t.intersection([rfpsQuery, q]))) : rfpsPath.then(query(rfpsQuery))).then(end),
    pathParts: rfpsPathParts,
  };
}

const rfpsDealFileAnalyticsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("analytics"));
const rfpsDealFileAnalyticsPathParts = ["rfps", "deal-file", "rfpId", "analytics"] as const;
export type RfpsDealFileAnalyticsPathParts = typeof rfpsDealFileAnalyticsPathParts;

const rfpsDealFileAnalyticsQuery = t.strict({});
export type RfpsDealFileAnalyticsParams = Describe<typeof rfpsDealFileAnalyticsPath._A & typeof rfpsDealFileAnalyticsQuery._A>;

export type RfpsDealFileAnalyticsRoute<A = {}> = { match: Match<RfpsDealFileAnalyticsParams & A>; pathParts: RfpsDealFileAnalyticsPathParts };

export function rfpsDealFileAnalyticsRoute(): RfpsDealFileAnalyticsRoute;
export function rfpsDealFileAnalyticsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileAnalyticsParams>): RfpsDealFileAnalyticsRoute<t.TypeOf<A>>;
export function rfpsDealFileAnalyticsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileAnalyticsParams>) {
  return {
    match: (q ? rfpsDealFileAnalyticsPath.then(query(t.intersection([rfpsDealFileAnalyticsQuery, q]))) : rfpsDealFileAnalyticsPath.then(query(rfpsDealFileAnalyticsQuery))).then(end),
    pathParts: rfpsDealFileAnalyticsPathParts,
  };
}

const rfpsDealFileDetailsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("details"));
const rfpsDealFileDetailsPathParts = ["rfps", "deal-file", "rfpId", "details"] as const;
export type RfpsDealFileDetailsPathParts = typeof rfpsDealFileDetailsPathParts;

const rfpsDealFileDetailsQuery = t.strict({});
export type RfpsDealFileDetailsParams = Describe<typeof rfpsDealFileDetailsPath._A & typeof rfpsDealFileDetailsQuery._A>;

export type RfpsDealFileDetailsRoute<A = {}> = { match: Match<RfpsDealFileDetailsParams & A>; pathParts: RfpsDealFileDetailsPathParts };

export function rfpsDealFileDetailsRoute(): RfpsDealFileDetailsRoute;
export function rfpsDealFileDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileDetailsParams>): RfpsDealFileDetailsRoute<t.TypeOf<A>>;
export function rfpsDealFileDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileDetailsParams>) {
  return {
    match: (q ? rfpsDealFileDetailsPath.then(query(t.intersection([rfpsDealFileDetailsQuery, q]))) : rfpsDealFileDetailsPath.then(query(rfpsDealFileDetailsQuery))).then(end),
    pathParts: rfpsDealFileDetailsPathParts,
  };
}

const rfpsDealFileEmailsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("emails"));
const rfpsDealFileEmailsPathParts = ["rfps", "deal-file", "rfpId", "emails"] as const;
export type RfpsDealFileEmailsPathParts = typeof rfpsDealFileEmailsPathParts;

const rfpsDealFileEmailsQuery = t.strict({});
export type RfpsDealFileEmailsParams = Describe<typeof rfpsDealFileEmailsPath._A & typeof rfpsDealFileEmailsQuery._A>;

export type RfpsDealFileEmailsRoute<A = {}> = { match: Match<RfpsDealFileEmailsParams & A>; pathParts: RfpsDealFileEmailsPathParts };

export function rfpsDealFileEmailsRoute(): RfpsDealFileEmailsRoute;
export function rfpsDealFileEmailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileEmailsParams>): RfpsDealFileEmailsRoute<t.TypeOf<A>>;
export function rfpsDealFileEmailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileEmailsParams>) {
  return {
    match: (q ? rfpsDealFileEmailsPath.then(query(t.intersection([rfpsDealFileEmailsQuery, q]))) : rfpsDealFileEmailsPath.then(query(rfpsDealFileEmailsQuery))).then(end),
    pathParts: rfpsDealFileEmailsPathParts,
  };
}

const rfpsDealFileInfoRequestsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("info-requests"));
const rfpsDealFileInfoRequestsPathParts = ["rfps", "deal-file", "rfpId", "info-requests"] as const;
export type RfpsDealFileInfoRequestsPathParts = typeof rfpsDealFileInfoRequestsPathParts;

const rfpsDealFileInfoRequestsQuery = t.strict({});
export type RfpsDealFileInfoRequestsParams = Describe<typeof rfpsDealFileInfoRequestsPath._A & typeof rfpsDealFileInfoRequestsQuery._A>;

export type RfpsDealFileInfoRequestsRoute<A = {}> = { match: Match<RfpsDealFileInfoRequestsParams & A>; pathParts: RfpsDealFileInfoRequestsPathParts };

export function rfpsDealFileInfoRequestsRoute(): RfpsDealFileInfoRequestsRoute;
export function rfpsDealFileInfoRequestsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileInfoRequestsParams>): RfpsDealFileInfoRequestsRoute<t.TypeOf<A>>;
export function rfpsDealFileInfoRequestsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileInfoRequestsParams>) {
  return {
    match: (q ? rfpsDealFileInfoRequestsPath.then(query(t.intersection([rfpsDealFileInfoRequestsQuery, q]))) : rfpsDealFileInfoRequestsPath.then(query(rfpsDealFileInfoRequestsQuery))).then(end),
    pathParts: rfpsDealFileInfoRequestsPathParts,
  };
}

const rfpsDealFileInvestorsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("investors"));
const rfpsDealFileInvestorsPathParts = ["rfps", "deal-file", "rfpId", "investors"] as const;
export type RfpsDealFileInvestorsPathParts = typeof rfpsDealFileInvestorsPathParts;

const rfpsDealFileInvestorsQuery = t.strict({});
export type RfpsDealFileInvestorsParams = Describe<typeof rfpsDealFileInvestorsPath._A & typeof rfpsDealFileInvestorsQuery._A>;

export type RfpsDealFileInvestorsRoute<A = {}> = { match: Match<RfpsDealFileInvestorsParams & A>; pathParts: RfpsDealFileInvestorsPathParts };

export function rfpsDealFileInvestorsRoute(): RfpsDealFileInvestorsRoute;
export function rfpsDealFileInvestorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileInvestorsParams>): RfpsDealFileInvestorsRoute<t.TypeOf<A>>;
export function rfpsDealFileInvestorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileInvestorsParams>) {
  return {
    match: (q ? rfpsDealFileInvestorsPath.then(query(t.intersection([rfpsDealFileInvestorsQuery, q]))) : rfpsDealFileInvestorsPath.then(query(rfpsDealFileInvestorsQuery))).then(end),
    pathParts: rfpsDealFileInvestorsPathParts,
  };
}

const rfpsDealFileSummaryPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("summary"));
const rfpsDealFileSummaryPathParts = ["rfps", "deal-file", "rfpId", "summary"] as const;
export type RfpsDealFileSummaryPathParts = typeof rfpsDealFileSummaryPathParts;

const rfpsDealFileSummaryQuery = t.strict({});
export type RfpsDealFileSummaryParams = Describe<typeof rfpsDealFileSummaryPath._A & typeof rfpsDealFileSummaryQuery._A>;

export type RfpsDealFileSummaryRoute<A = {}> = { match: Match<RfpsDealFileSummaryParams & A>; pathParts: RfpsDealFileSummaryPathParts };

export function rfpsDealFileSummaryRoute(): RfpsDealFileSummaryRoute;
export function rfpsDealFileSummaryRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileSummaryParams>): RfpsDealFileSummaryRoute<t.TypeOf<A>>;
export function rfpsDealFileSummaryRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileSummaryParams>) {
  return {
    match: (q ? rfpsDealFileSummaryPath.then(query(t.intersection([rfpsDealFileSummaryQuery, q]))) : rfpsDealFileSummaryPath.then(query(rfpsDealFileSummaryQuery))).then(end),
    pathParts: rfpsDealFileSummaryPathParts,
  };
}

export const allRoutes = [analyticsBondsRoute, analyticsEmailsRoute, analyticsEmailDetailsRoute, investorActivityRoute, investorProfileRoute, analyticsOverallRoute, bondOfferingsRoute, bondOfferingsDealFileAnalyticsRoute, bondOfferingsDealFileDetailsRoute, bondOfferingsDealFileEmailsRoute, bondOfferingsDealFileInfoRequestsRoute, bondOfferingsDealFileInvestorsRoute, bondOfferingsDealFileSummaryRoute, dashboardRoute, issuersRoute, portalUsersRoute, rfpsRoute, rfpsDealFileAnalyticsRoute, rfpsDealFileDetailsRoute, rfpsDealFileEmailsRoute, rfpsDealFileInfoRequestsRoute, rfpsDealFileInvestorsRoute, rfpsDealFileSummaryRoute] as const;