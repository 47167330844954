import { IssuerWithAddressC as imported7_IssuerWithAddressC, issuerWithAddressC as imported6_issuerWithAddressC, IssuerWithAddress as imported7_IssuerWithAddress, issuerWithAddressC as imported7_issuerWithAddressC, IssuerWithAddress as imported6_IssuerWithAddress, IssuerWithAddressC as imported6_IssuerWithAddressC } from "./issuer";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { subscribedC as imported31_subscribedC, Subscribed as imported31_Subscribed, Subscribed as imported30_Subscribed, SubscribedC as imported31_SubscribedC, SubscribedC as imported30_SubscribedC, subscribedC as imported30_subscribedC } from "./subscribed";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

export type IssuerSearchPostC = t.TypeC<{
  term: t.StringC,
  searchId: t.StringC,
  page: t.NumberC,
  origin: t.StringC,
  searchByCusip: t.BooleanC
}>;
export type IssuerSearchPost = {
  term: string,
  searchId: string,
  page: number,
  origin: string,
  searchByCusip: boolean
};
export const issuerSearchPostC: IssuerSearchPostC = t.type({
  term: t.string,
  searchId: t.string,
  page: t.number,
  origin: t.string,
  searchByCusip: t.boolean
}) satisfies t.Type<IssuerSearchPost, unknown>;


export type IssuerSearchRequestPostC = t.TypeC<{
  issuer: t.StringC,
  captcha: t.StringC
}>;
export type IssuerSearchRequestPost = {
  issuer: string,
  captcha: string
};
export const issuerSearchRequestPostC: IssuerSearchRequestPostC = t.type({
  issuer: t.string,
  captcha: t.string
}) satisfies t.Type<IssuerSearchRequestPost, unknown>;


export type IssuerSearchResultC = t.TypeC<{
  link: t.StringC,
  main: t.StringC,
  secondary: t.StringC,
  issuer: imported30_SubscribedC<imported6_IssuerWithAddressC>
}>;
export type IssuerSearchResult = {
  link: string,
  main: string,
  secondary: string,
  issuer: imported30_Subscribed<imported6_IssuerWithAddress>
};
export const issuerSearchResultC: IssuerSearchResultC = t.type({
  link: t.string,
  main: t.string,
  secondary: t.string,
  issuer: imported30_subscribedC(imported6_issuerWithAddressC)
}) satisfies t.Type<IssuerSearchResult, unknown>;


export type IssuerSearchResultsC = t.TypeC<{
  page: t.NumberC,
  pageSize: t.NumberC,
  results: t.ReadonlyArrayC<IssuerSearchResultC>
}>;
export type IssuerSearchResults = {
  page: number,
  pageSize: number,
  results: ReadonlyArray<IssuerSearchResult>
};
export const issuerSearchResultsC: IssuerSearchResultsC = t.type({
  page: t.number,
  pageSize: t.number,
  results: t.readonlyArray(issuerSearchResultC)
}) satisfies t.Type<IssuerSearchResults, unknown>;


export type IssuersBySectorPostC = t.TypeC<{
  sectors: ReadonlyNonEmptyArrayType<t.NumberC>
}>;
export type IssuersBySectorPost = {
  sectors: RNEA.ReadonlyNonEmptyArray<number>
};
export const issuersBySectorPostC: IssuersBySectorPostC = t.type({
  sectors: readonlyNonEmptyArrayC(t.number)
}) satisfies t.Type<IssuersBySectorPost, unknown>;


export type IssuersCusip6SearchPostC = t.TypeC<{
  cusips: ReadonlyNonEmptyArrayType<t.StringC>
}>;
export type IssuersCusip6SearchPost = {
  cusips: RNEA.ReadonlyNonEmptyArray<string>
};
export const issuersCusip6SearchPostC: IssuersCusip6SearchPostC = t.type({
  cusips: readonlyNonEmptyArrayC(t.string)
}) satisfies t.Type<IssuersCusip6SearchPost, unknown>;


export type IssuersCusip6SearchResultsC = t.TypeC<{
  valid: t.RecordC<t.StringC, t.ReadonlyArrayC<imported31_SubscribedC<imported7_IssuerWithAddressC>>>,
  invalid: t.ReadonlyArrayC<t.StringC>
}>;
export type IssuersCusip6SearchResults = {
  valid: Record<string, ReadonlyArray<imported31_Subscribed<imported7_IssuerWithAddress>>>,
  invalid: ReadonlyArray<string>
};
export const issuersCusip6SearchResultsC: IssuersCusip6SearchResultsC = t.type({
  valid: t.record(t.string, t.readonlyArray(imported31_subscribedC(imported7_issuerWithAddressC))),
  invalid: t.readonlyArray(t.string)
}) satisfies t.Type<IssuersCusip6SearchResults, unknown>;


