import * as t from "io-ts";
import { LocalDateOrd } from "../../syntax/date/jodaSyntax";
import * as E from "fp-ts/lib/Either";
import { LocalDateC } from "../../codecs/localDate";
import { LocalDate } from "@js-joda/core";
import { EitherC, either } from "io-ts-types/lib/either";
import { AnalyticsIntervalCU as imported3_AnalyticsIntervalCU, AnalyticsIntervalU as imported1_AnalyticsIntervalU, AnalyticsIntervalCU as imported2_AnalyticsIntervalCU } from "../domaintables/analyticsInterval";
import { WithStatusCU as imported626_WithStatusCU, WithStatusCU as imported628_WithStatusCU, WithModInfo as imported186_WithModInfo, WithStatusCU as imported627_WithStatusCU, withModInfoC as imported186_withModInfoC, withModInfoC as imported185_withModInfoC, WithStatusCU as imported629_WithStatusCU, WithModInfoC as imported186_WithModInfoC, WithStatusU as imported314_WithStatusU, WithModInfo as imported185_WithModInfo, WithModInfoC as imported185_WithModInfoC, WithStatusU as imported313_WithStatusU } from "./threadThrough";
import { WithIssuerC as imported8_WithIssuerC, WithIssuer as imported9_WithIssuer, WithIssuer as imported8_WithIssuer, WithIssuerC as imported9_WithIssuerC, withIssuerC as imported9_withIssuerC, withIssuerC as imported8_withIssuerC } from "./issuer";
import { BondOfferingC as imported25_BondOfferingC, BondOffering as imported25_BondOffering, bondOfferingC as imported25_bondOfferingC } from "./bondOfferingBase";
import { RfpC as imported68_RfpC, Rfp as imported68_Rfp, rfpC as imported65_rfpC } from "./rfpBase";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type ActivityByTypeC = t.TypeC<{
  pageViews: t.NumberC,
  documentDownloads: t.NumberC,
  roadshowViews: t.NumberC,
  infoRequests: t.NumberC,
  emailOpens: t.NumberC,
  emailClicks: t.NumberC
}>;
export type ActivityByType = {
  pageViews: number,
  documentDownloads: number,
  roadshowViews: number,
  infoRequests: number,
  emailOpens: number,
  emailClicks: number
};
export const activityByTypeC: ActivityByTypeC = t.type({
  pageViews: t.number,
  documentDownloads: t.number,
  roadshowViews: t.number,
  infoRequests: t.number,
  emailOpens: t.number,
  emailClicks: t.number
}) satisfies t.Type<ActivityByType, unknown>;


export type ActivityByActorC = t.TypeC<{
  byUser: ReadonlyMapFromEntriesC<t.NumberC, ActivityByTypeC>,
  byEmailContact: ReadonlyMapFromEntriesC<t.NumberC, ActivityByTypeC>
}>;
export type ActivityByActor = {
  byUser: ReadonlyMap<number, ActivityByType>,
  byEmailContact: ReadonlyMap<number, ActivityByType>
};
export const activityByActorC: ActivityByActorC = t.type({
  byUser: readonlyMapFromEntries(t.number, numberOrd, activityByTypeC),
  byEmailContact: readonlyMapFromEntries(t.number, numberOrd, activityByTypeC)
}) satisfies t.Type<ActivityByActor, unknown>;


export type ActivityByDateC = t.TypeC<{
  byDate: ReadonlyMapFromEntriesC<LocalDateC, ActivityByActorC>
}>;
export type ActivityByDate = {
  byDate: ReadonlyMap<LocalDate, ActivityByActor>
};
export const activityByDateC: ActivityByDateC = t.type({
  byDate: readonlyMapFromEntries(LocalDateC, LocalDateOrd, activityByActorC)
}) satisfies t.Type<ActivityByDate, unknown>;


export type ActivityFC<A1 extends t.Mixed, A2 extends t.Mixed> = t.TypeC<{
  interval: imported3_AnalyticsIntervalCU,
  byBond: ReadonlyMapFromEntriesC<t.NumberC, ActivityByDateC>,
  byRfp: ReadonlyMapFromEntriesC<t.NumberC, ActivityByDateC>,
  emailActivity: ActivityByDateC,
  bonds: t.ReadonlyArrayC<imported8_WithIssuerC<imported628_WithStatusCU<imported185_WithModInfoC<imported25_BondOfferingC>>>>,
  rfps: t.ReadonlyArrayC<imported9_WithIssuerC<imported629_WithStatusCU<imported186_WithModInfoC<imported68_RfpC>>>>,
  actors: t.ReadonlyArrayC<EitherC<A1, A2>>
}>;
export type ActivityF<A1, A2> = {
  interval: imported1_AnalyticsIntervalU,
  byBond: ReadonlyMap<number, ActivityByDate>,
  byRfp: ReadonlyMap<number, ActivityByDate>,
  emailActivity: ActivityByDate,
  bonds: ReadonlyArray<imported8_WithIssuer<imported313_WithStatusU<imported185_WithModInfo<imported25_BondOffering>>>>,
  rfps: ReadonlyArray<imported9_WithIssuer<imported314_WithStatusU<imported186_WithModInfo<imported68_Rfp>>>>,
  actors: ReadonlyArray<E.Either<A1, A2>>
};
export const activityFC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2): ActivityFC<A1, A2> => t.type({
  interval: imported2_AnalyticsIntervalCU,
  byBond: readonlyMapFromEntries(t.number, numberOrd, activityByDateC),
  byRfp: readonlyMapFromEntries(t.number, numberOrd, activityByDateC),
  emailActivity: activityByDateC,
  bonds: t.readonlyArray(imported8_withIssuerC(imported626_WithStatusCU(imported185_withModInfoC(imported25_bondOfferingC)))),
  rfps: t.readonlyArray(imported9_withIssuerC(imported627_WithStatusCU(imported186_withModInfoC(imported65_rfpC)))),
  actors: t.readonlyArray(either(A1, A2))
}) satisfies t.Type<ActivityF<t.TypeOf<A1>, t.TypeOf<A2>>, unknown>;


