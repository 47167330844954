import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { LocalDateTime } from "@js-joda/core";

export type BuildInfoC = t.TypeC<{
  buildId: t.StringC,
  builtAtMillis: t.NumberC,
  name: t.StringC,
  commit: t.StringC,
  branch: t.StringC,
  version: t.StringC,
  builtAtString: LocalDateTimeFromIsoStringC
}>;
export type BuildInfo = {
  buildId: string,
  builtAtMillis: number,
  name: string,
  commit: string,
  branch: string,
  version: string,
  builtAtString: LocalDateTime
};
export const buildInfoC: BuildInfoC = t.type({
  buildId: t.string,
  builtAtMillis: t.number,
  name: t.string,
  commit: t.string,
  branch: t.string,
  version: t.string,
  builtAtString: LocalDateTimeFromIsoStringC
}) satisfies t.Type<BuildInfo, unknown>;


