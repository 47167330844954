import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bondSaleDocuments = {
  _tag: `BondSaleDocuments`,
  description: `Bond Sale Documents (POS, OS, NOS)`,
  id: 6
} as const;

export type BondSaleDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondSaleDocuments`>
}>;
export const bondSaleDocumentsTaggedC: BondSaleDocumentsTaggedC = t.type({
  _tag: t.literal(`BondSaleDocuments`)
});
export type BondSaleDocumentsTagged = t.TypeOf<BondSaleDocumentsTaggedC>;
export type BondSaleDocuments = BondSaleDocumentsTagged & typeof bondSaleDocuments;
export type BondSaleDocumentsC = t.Type<BondSaleDocuments, BondSaleDocumentsTagged>;
export const bondSaleDocumentsC: BondSaleDocumentsC = pipe(bondSaleDocumentsTaggedC, c => new t.Type<BondSaleDocuments, BondSaleDocumentsTagged>(
  `BondSaleDocuments`,
  (u: unknown): u is BondSaleDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondSaleDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondSaleDocuments }))),
  (x: BondSaleDocuments): BondSaleDocumentsTagged => ({ ...x, _tag: `BondSaleDocuments`}),
)) satisfies t.Type<BondSaleDocuments, unknown>;


export const aCFRDocuments = {
  _tag: `ACFRDocuments`,
  description: `Annual Comprehensive Financial Reports`,
  id: 7
} as const;

export type ACFRDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ACFRDocuments`>
}>;
export const aCFRDocumentsTaggedC: ACFRDocumentsTaggedC = t.type({
  _tag: t.literal(`ACFRDocuments`)
});
export type ACFRDocumentsTagged = t.TypeOf<ACFRDocumentsTaggedC>;
export type ACFRDocuments = ACFRDocumentsTagged & typeof aCFRDocuments;
export type ACFRDocumentsC = t.Type<ACFRDocuments, ACFRDocumentsTagged>;
export const aCFRDocumentsC: ACFRDocumentsC = pipe(aCFRDocumentsTaggedC, c => new t.Type<ACFRDocuments, ACFRDocumentsTagged>(
  `ACFRDocuments`,
  (u: unknown): u is ACFRDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ACFRDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...aCFRDocuments }))),
  (x: ACFRDocuments): ACFRDocumentsTagged => ({ ...x, _tag: `ACFRDocuments`}),
)) satisfies t.Type<ACFRDocuments, unknown>;


export const financialStatementsDocuments = {
  _tag: `FinancialStatementsDocuments`,
  description: `Cash-Based or Unaudited Financial Statements`,
  id: 8
} as const;

export type FinancialStatementsDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinancialStatementsDocuments`>
}>;
export const financialStatementsDocumentsTaggedC: FinancialStatementsDocumentsTaggedC = t.type({
  _tag: t.literal(`FinancialStatementsDocuments`)
});
export type FinancialStatementsDocumentsTagged = t.TypeOf<FinancialStatementsDocumentsTaggedC>;
export type FinancialStatementsDocuments = FinancialStatementsDocumentsTagged & typeof financialStatementsDocuments;
export type FinancialStatementsDocumentsC = t.Type<FinancialStatementsDocuments, FinancialStatementsDocumentsTagged>;
export const financialStatementsDocumentsC: FinancialStatementsDocumentsC = pipe(financialStatementsDocumentsTaggedC, c => new t.Type<FinancialStatementsDocuments, FinancialStatementsDocumentsTagged>(
  `FinancialStatementsDocuments`,
  (u: unknown): u is FinancialStatementsDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialStatementsDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialStatementsDocuments }))),
  (x: FinancialStatementsDocuments): FinancialStatementsDocumentsTagged => ({ ...x, _tag: `FinancialStatementsDocuments`}),
)) satisfies t.Type<FinancialStatementsDocuments, unknown>;


export const budgetDocuments = {
  _tag: `BudgetDocuments`,
  description: `Budget Documents and Reports`,
  id: 9
} as const;

export type BudgetDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BudgetDocuments`>
}>;
export const budgetDocumentsTaggedC: BudgetDocumentsTaggedC = t.type({
  _tag: t.literal(`BudgetDocuments`)
});
export type BudgetDocumentsTagged = t.TypeOf<BudgetDocumentsTaggedC>;
export type BudgetDocuments = BudgetDocumentsTagged & typeof budgetDocuments;
export type BudgetDocumentsC = t.Type<BudgetDocuments, BudgetDocumentsTagged>;
export const budgetDocumentsC: BudgetDocumentsC = pipe(budgetDocumentsTaggedC, c => new t.Type<BudgetDocuments, BudgetDocumentsTagged>(
  `BudgetDocuments`,
  (u: unknown): u is BudgetDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BudgetDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...budgetDocuments }))),
  (x: BudgetDocuments): BudgetDocumentsTagged => ({ ...x, _tag: `BudgetDocuments`}),
)) satisfies t.Type<BudgetDocuments, unknown>;


export const otherDocuments = {
  _tag: `OtherDocuments`,
  description: `All Other Documents`,
  id: 10
} as const;

export type OtherDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OtherDocuments`>
}>;
export const otherDocumentsTaggedC: OtherDocumentsTaggedC = t.type({
  _tag: t.literal(`OtherDocuments`)
});
export type OtherDocumentsTagged = t.TypeOf<OtherDocumentsTaggedC>;
export type OtherDocuments = OtherDocumentsTagged & typeof otherDocuments;
export type OtherDocumentsC = t.Type<OtherDocuments, OtherDocumentsTagged>;
export const otherDocumentsC: OtherDocumentsC = pipe(otherDocumentsTaggedC, c => new t.Type<OtherDocuments, OtherDocumentsTagged>(
  `OtherDocuments`,
  (u: unknown): u is OtherDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OtherDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...otherDocuments }))),
  (x: OtherDocuments): OtherDocumentsTagged => ({ ...x, _tag: `OtherDocuments`}),
)) satisfies t.Type<OtherDocuments, unknown>;


export const rfpDocuments = {
  _tag: `RfpDocuments`,
  description: `RFP Documents`,
  id: 11
} as const;

export type RfpDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpDocuments`>
}>;
export const rfpDocumentsTaggedC: RfpDocumentsTaggedC = t.type({
  _tag: t.literal(`RfpDocuments`)
});
export type RfpDocumentsTagged = t.TypeOf<RfpDocumentsTaggedC>;
export type RfpDocuments = RfpDocumentsTagged & typeof rfpDocuments;
export type RfpDocumentsC = t.Type<RfpDocuments, RfpDocumentsTagged>;
export const rfpDocumentsC: RfpDocumentsC = pipe(rfpDocumentsTaggedC, c => new t.Type<RfpDocuments, RfpDocumentsTagged>(
  `RfpDocuments`,
  (u: unknown): u is RfpDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpDocuments }))),
  (x: RfpDocuments): RfpDocumentsTagged => ({ ...x, _tag: `RfpDocuments`}),
)) satisfies t.Type<RfpDocuments, unknown>;


export const bondOfferings = {
  _tag: `BondOfferings`,
  description: `Bond Offerings`,
  id: 1
} as const;

export type BondOfferingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferings`>
}>;
export const bondOfferingsTaggedC: BondOfferingsTaggedC = t.type({
  _tag: t.literal(`BondOfferings`)
});
export type BondOfferingsTagged = t.TypeOf<BondOfferingsTaggedC>;
export type BondOfferings = BondOfferingsTagged & typeof bondOfferings;
export type BondOfferingsC = t.Type<BondOfferings, BondOfferingsTagged>;
export const bondOfferingsC: BondOfferingsC = pipe(bondOfferingsTaggedC, c => new t.Type<BondOfferings, BondOfferingsTagged>(
  `BondOfferings`,
  (u: unknown): u is BondOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferings }))),
  (x: BondOfferings): BondOfferingsTagged => ({ ...x, _tag: `BondOfferings`}),
)) satisfies t.Type<BondOfferings, unknown>;


export const events = {
  _tag: `Events`,
  description: `Events`,
  id: 2
} as const;

export type EventsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Events`>
}>;
export const eventsTaggedC: EventsTaggedC = t.type({
  _tag: t.literal(`Events`)
});
export type EventsTagged = t.TypeOf<EventsTaggedC>;
export type Events = EventsTagged & typeof events;
export type EventsC = t.Type<Events, EventsTagged>;
export const eventsC: EventsC = pipe(eventsTaggedC, c => new t.Type<Events, EventsTagged>(
  `Events`,
  (u: unknown): u is Events => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Events> => pipe(c.decode(u), E.map(x => ({ ...x, ...events }))),
  (x: Events): EventsTagged => ({ ...x, _tag: `Events`}),
)) satisfies t.Type<Events, unknown>;


export const newsAndPressReleases = {
  _tag: `NewsAndPressReleases`,
  description: `News & Press Releases`,
  id: 3
} as const;

export type NewsAndPressReleasesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NewsAndPressReleases`>
}>;
export const newsAndPressReleasesTaggedC: NewsAndPressReleasesTaggedC = t.type({
  _tag: t.literal(`NewsAndPressReleases`)
});
export type NewsAndPressReleasesTagged = t.TypeOf<NewsAndPressReleasesTaggedC>;
export type NewsAndPressReleases = NewsAndPressReleasesTagged & typeof newsAndPressReleases;
export type NewsAndPressReleasesC = t.Type<NewsAndPressReleases, NewsAndPressReleasesTagged>;
export const newsAndPressReleasesC: NewsAndPressReleasesC = pipe(newsAndPressReleasesTaggedC, c => new t.Type<NewsAndPressReleases, NewsAndPressReleasesTagged>(
  `NewsAndPressReleases`,
  (u: unknown): u is NewsAndPressReleases => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsAndPressReleases> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsAndPressReleases }))),
  (x: NewsAndPressReleases): NewsAndPressReleasesTagged => ({ ...x, _tag: `NewsAndPressReleases`}),
)) satisfies t.Type<NewsAndPressReleases, unknown>;


export const ratings = {
  _tag: `Ratings`,
  description: `Ratings`,
  id: 4
} as const;

export type RatingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Ratings`>
}>;
export const ratingsTaggedC: RatingsTaggedC = t.type({
  _tag: t.literal(`Ratings`)
});
export type RatingsTagged = t.TypeOf<RatingsTaggedC>;
export type Ratings = RatingsTagged & typeof ratings;
export type RatingsC = t.Type<Ratings, RatingsTagged>;
export const ratingsC: RatingsC = pipe(ratingsTaggedC, c => new t.Type<Ratings, RatingsTagged>(
  `Ratings`,
  (u: unknown): u is Ratings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ratings> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratings }))),
  (x: Ratings): RatingsTagged => ({ ...x, _tag: `Ratings`}),
)) satisfies t.Type<Ratings, unknown>;


export const rfps = {
  _tag: `Rfps`,
  description: `RFPs`,
  id: 5
} as const;

export type RfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Rfps`>
}>;
export const rfpsTaggedC: RfpsTaggedC = t.type({
  _tag: t.literal(`Rfps`)
});
export type RfpsTagged = t.TypeOf<RfpsTaggedC>;
export type Rfps = RfpsTagged & typeof rfps;
export type RfpsC = t.Type<Rfps, RfpsTagged>;
export const rfpsC: RfpsC = pipe(rfpsTaggedC, c => new t.Type<Rfps, RfpsTagged>(
  `Rfps`,
  (u: unknown): u is Rfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfps }))),
  (x: Rfps): RfpsTagged => ({ ...x, _tag: `Rfps`}),
)) satisfies t.Type<Rfps, unknown>;


export const allUserContentSubscriptionTypeC = [bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC, bondOfferingsC, eventsC, newsAndPressReleasesC, ratingsC, rfpsC] as const;
export const allUserContentSubscriptionTypeNames = [`BondSaleDocuments`, `ACFRDocuments`, `FinancialStatementsDocuments`, `BudgetDocuments`, `OtherDocuments`, `RfpDocuments`, `BondOfferings`, `Events`, `NewsAndPressReleases`, `Ratings`, `Rfps`] as const;
export type UserContentSubscriptionTypeName = (typeof allUserContentSubscriptionTypeNames)[number];

export type UserContentSubscriptionTypeCU = t.UnionC<[BondSaleDocumentsC, ACFRDocumentsC, FinancialStatementsDocumentsC, BudgetDocumentsC, OtherDocumentsC, RfpDocumentsC, BondOfferingsC, EventsC, NewsAndPressReleasesC, RatingsC, RfpsC]>;
export type UserContentSubscriptionTypeU = BondSaleDocuments | ACFRDocuments | FinancialStatementsDocuments | BudgetDocuments | OtherDocuments | RfpDocuments | BondOfferings | Events | NewsAndPressReleases | Ratings | Rfps;
export const UserContentSubscriptionTypeCU: UserContentSubscriptionTypeCU = t.union([bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC, bondOfferingsC, eventsC, newsAndPressReleasesC, ratingsC, rfpsC]) satisfies t.Type<UserContentSubscriptionTypeU, unknown>;

export const userContentSubscriptionTypeOrd: Ord.Ord<UserContentSubscriptionTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allUserContentSubscriptionType = [bondSaleDocuments, aCFRDocuments, financialStatementsDocuments, budgetDocuments, otherDocuments, rfpDocuments, bondOfferings, events, newsAndPressReleases, ratings, rfps] as const;
export type UserContentSubscriptionTypeMap<A> = { [K in UserContentSubscriptionTypeName]: A };


export const allUserDocumentSubscriptionTypeC = [bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC] as const;
export const allUserDocumentSubscriptionTypeNames = [`BondSaleDocuments`, `ACFRDocuments`, `FinancialStatementsDocuments`, `BudgetDocuments`, `OtherDocuments`, `RfpDocuments`] as const;
export type UserDocumentSubscriptionTypeName = (typeof allUserDocumentSubscriptionTypeNames)[number];

export type UserDocumentSubscriptionTypeCU = t.UnionC<[BondSaleDocumentsC, ACFRDocumentsC, FinancialStatementsDocumentsC, BudgetDocumentsC, OtherDocumentsC, RfpDocumentsC]>;
export type UserDocumentSubscriptionTypeU = BondSaleDocuments | ACFRDocuments | FinancialStatementsDocuments | BudgetDocuments | OtherDocuments | RfpDocuments;
export const UserDocumentSubscriptionTypeCU: UserDocumentSubscriptionTypeCU = t.union([bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC]) satisfies t.Type<UserDocumentSubscriptionTypeU, unknown>;

export const userDocumentSubscriptionTypeOrd: Ord.Ord<UserDocumentSubscriptionTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allUserDocumentSubscriptionType = [bondSaleDocuments, aCFRDocuments, financialStatementsDocuments, budgetDocuments, otherDocuments, rfpDocuments] as const;
export type UserDocumentSubscriptionTypeMap<A> = { [K in UserDocumentSubscriptionTypeName]: A };


