import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { CalloutLocationCU as imported6_CalloutLocationCU, CalloutLocationCU as imported4_CalloutLocationCU, CalloutLocationCU as imported5_CalloutLocationCU, CalloutLocationCU as imported7_CalloutLocationCU, CalloutLocationU as imported2_CalloutLocationU, CalloutLocationU as imported3_CalloutLocationU } from "../domaintables/calloutLocations";
import * as O from "fp-ts/lib/Option";

export type QuickFactC = t.TypeC<{
  fieldHeader: t.StringC,
  fieldValue: t.StringC,
  fieldUrl: OptionFromNullableC<t.StringC>,
  linkText: OptionFromNullableC<t.StringC>,
  fieldOrder: t.NumberC,
  location: imported5_CalloutLocationCU
}>;
export type QuickFact = {
  fieldHeader: string,
  fieldValue: string,
  fieldUrl: O.Option<string>,
  linkText: O.Option<string>,
  fieldOrder: number,
  location: imported2_CalloutLocationU
};
export const quickFactC: QuickFactC = t.type({
  fieldHeader: t.string,
  fieldValue: t.string,
  fieldUrl: optionFromNullable(t.string),
  linkText: optionFromNullable(t.string),
  fieldOrder: t.number,
  location: imported4_CalloutLocationCU
}) satisfies t.Type<QuickFact, unknown>;


export type QuickFactPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  fieldHeader: t.StringC,
  fieldValue: t.StringC,
  fieldUrl: OptionFromNullableC<t.StringC>,
  linkText: OptionFromNullableC<t.StringC>,
  fieldOrder: t.NumberC,
  location: imported7_CalloutLocationCU
}>;
export type QuickFactPostBody = {
  id: O.Option<number>,
  fieldHeader: string,
  fieldValue: string,
  fieldUrl: O.Option<string>,
  linkText: O.Option<string>,
  fieldOrder: number,
  location: imported3_CalloutLocationU
};
export const quickFactPostBodyC: QuickFactPostBodyC = t.type({
  id: optionFromNullable(t.number),
  fieldHeader: t.string,
  fieldValue: t.string,
  fieldUrl: optionFromNullable(t.string),
  linkText: optionFromNullable(t.string),
  fieldOrder: t.number,
  location: imported6_CalloutLocationCU
}) satisfies t.Type<QuickFactPostBody, unknown>;


