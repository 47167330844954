import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { IntroSentenceEditableCU as imported9_IntroSentenceEditableCU, IntroSentenceEditableU as imported4_IntroSentenceEditableU, IntroSentenceEditableCU as imported8_IntroSentenceEditableCU } from "../domaintables/pages";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type PostIntroSentenceC = t.TypeC<{
  page: imported9_IntroSentenceEditableCU,
  introSentence: OptionFromNullableC<MarkdownC>
}>;
export type PostIntroSentence = {
  page: imported4_IntroSentenceEditableU,
  introSentence: O.Option<Markdown>
};
export const postIntroSentenceC: PostIntroSentenceC = t.type({
  page: imported8_IntroSentenceEditableCU,
  introSentence: optionFromNullable(markdownC)
}) satisfies t.Type<PostIntroSentence, unknown>;


