import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bondLinkFisc = {
  _tag: `BondLinkFisc`,
  id: 1,
  name: `BondLink The Fisc`
} as const;

export type BondLinkFiscTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkFisc`>
}>;
export const bondLinkFiscTaggedC: BondLinkFiscTaggedC = t.type({
  _tag: t.literal(`BondLinkFisc`)
});
export type BondLinkFiscTagged = t.TypeOf<BondLinkFiscTaggedC>;
export type BondLinkFisc = BondLinkFiscTagged & typeof bondLinkFisc;
export type BondLinkFiscC = t.Type<BondLinkFisc, BondLinkFiscTagged>;
export const bondLinkFiscC: BondLinkFiscC = pipe(bondLinkFiscTaggedC, c => new t.Type<BondLinkFisc, BondLinkFiscTagged>(
  `BondLinkFisc`,
  (u: unknown): u is BondLinkFisc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkFisc> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkFisc }))),
  (x: BondLinkFisc): BondLinkFiscTagged => ({ ...x, _tag: `BondLinkFisc`}),
)) satisfies t.Type<BondLinkFisc, unknown>;


export const bondLinkMuniPod = {
  _tag: `BondLinkMuniPod`,
  id: 2,
  name: `BondLink Muniland Podcast`
} as const;

export type BondLinkMuniPodTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkMuniPod`>
}>;
export const bondLinkMuniPodTaggedC: BondLinkMuniPodTaggedC = t.type({
  _tag: t.literal(`BondLinkMuniPod`)
});
export type BondLinkMuniPodTagged = t.TypeOf<BondLinkMuniPodTaggedC>;
export type BondLinkMuniPod = BondLinkMuniPodTagged & typeof bondLinkMuniPod;
export type BondLinkMuniPodC = t.Type<BondLinkMuniPod, BondLinkMuniPodTagged>;
export const bondLinkMuniPodC: BondLinkMuniPodC = pipe(bondLinkMuniPodTaggedC, c => new t.Type<BondLinkMuniPod, BondLinkMuniPodTagged>(
  `BondLinkMuniPod`,
  (u: unknown): u is BondLinkMuniPod => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkMuniPod> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkMuniPod }))),
  (x: BondLinkMuniPod): BondLinkMuniPodTagged => ({ ...x, _tag: `BondLinkMuniPod`}),
)) satisfies t.Type<BondLinkMuniPod, unknown>;


export const theBondBuyer = {
  _tag: `TheBondBuyer`,
  id: 3,
  name: `The Bond Buyer`
} as const;

export type TheBondBuyerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TheBondBuyer`>
}>;
export const theBondBuyerTaggedC: TheBondBuyerTaggedC = t.type({
  _tag: t.literal(`TheBondBuyer`)
});
export type TheBondBuyerTagged = t.TypeOf<TheBondBuyerTaggedC>;
export type TheBondBuyer = TheBondBuyerTagged & typeof theBondBuyer;
export type TheBondBuyerC = t.Type<TheBondBuyer, TheBondBuyerTagged>;
export const theBondBuyerC: TheBondBuyerC = pipe(theBondBuyerTaggedC, c => new t.Type<TheBondBuyer, TheBondBuyerTagged>(
  `TheBondBuyer`,
  (u: unknown): u is TheBondBuyer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheBondBuyer> => pipe(c.decode(u), E.map(x => ({ ...x, ...theBondBuyer }))),
  (x: TheBondBuyer): TheBondBuyerTagged => ({ ...x, _tag: `TheBondBuyer`}),
)) satisfies t.Type<TheBondBuyer, unknown>;


export const municipalMarketAnalytics = {
  _tag: `MunicipalMarketAnalytics`,
  id: 4,
  name: `Municipal Market Analytics`
} as const;

export type MunicipalMarketAnalyticsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MunicipalMarketAnalytics`>
}>;
export const municipalMarketAnalyticsTaggedC: MunicipalMarketAnalyticsTaggedC = t.type({
  _tag: t.literal(`MunicipalMarketAnalytics`)
});
export type MunicipalMarketAnalyticsTagged = t.TypeOf<MunicipalMarketAnalyticsTaggedC>;
export type MunicipalMarketAnalytics = MunicipalMarketAnalyticsTagged & typeof municipalMarketAnalytics;
export type MunicipalMarketAnalyticsC = t.Type<MunicipalMarketAnalytics, MunicipalMarketAnalyticsTagged>;
export const municipalMarketAnalyticsC: MunicipalMarketAnalyticsC = pipe(municipalMarketAnalyticsTaggedC, c => new t.Type<MunicipalMarketAnalytics, MunicipalMarketAnalyticsTagged>(
  `MunicipalMarketAnalytics`,
  (u: unknown): u is MunicipalMarketAnalytics => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalMarketAnalytics> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalMarketAnalytics }))),
  (x: MunicipalMarketAnalytics): MunicipalMarketAnalyticsTagged => ({ ...x, _tag: `MunicipalMarketAnalytics`}),
)) satisfies t.Type<MunicipalMarketAnalytics, unknown>;


export const marketReports = {
  _tag: `MarketReports`,
  id: 5,
  name: `Market Reports`
} as const;

export type MarketReportsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketReports`>
}>;
export const marketReportsTaggedC: MarketReportsTaggedC = t.type({
  _tag: t.literal(`MarketReports`)
});
export type MarketReportsTagged = t.TypeOf<MarketReportsTaggedC>;
export type MarketReports = MarketReportsTagged & typeof marketReports;
export type MarketReportsC = t.Type<MarketReports, MarketReportsTagged>;
export const marketReportsC: MarketReportsC = pipe(marketReportsTaggedC, c => new t.Type<MarketReports, MarketReportsTagged>(
  `MarketReports`,
  (u: unknown): u is MarketReports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketReports> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketReports }))),
  (x: MarketReports): MarketReportsTagged => ({ ...x, _tag: `MarketReports`}),
)) satisfies t.Type<MarketReports, unknown>;


export const bondLinkResearch = {
  _tag: `BondLinkResearch`,
  id: 6,
  name: `BondLink Research and Analysis`
} as const;

export type BondLinkResearchTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkResearch`>
}>;
export const bondLinkResearchTaggedC: BondLinkResearchTaggedC = t.type({
  _tag: t.literal(`BondLinkResearch`)
});
export type BondLinkResearchTagged = t.TypeOf<BondLinkResearchTaggedC>;
export type BondLinkResearch = BondLinkResearchTagged & typeof bondLinkResearch;
export type BondLinkResearchC = t.Type<BondLinkResearch, BondLinkResearchTagged>;
export const bondLinkResearchC: BondLinkResearchC = pipe(bondLinkResearchTaggedC, c => new t.Type<BondLinkResearch, BondLinkResearchTagged>(
  `BondLinkResearch`,
  (u: unknown): u is BondLinkResearch => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkResearch> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkResearch }))),
  (x: BondLinkResearch): BondLinkResearchTagged => ({ ...x, _tag: `BondLinkResearch`}),
)) satisfies t.Type<BondLinkResearch, unknown>;


export const bloomberg = {
  _tag: `Bloomberg`,
  id: 7,
  name: `Bloomberg`
} as const;

export type BloombergTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Bloomberg`>
}>;
export const bloombergTaggedC: BloombergTaggedC = t.type({
  _tag: t.literal(`Bloomberg`)
});
export type BloombergTagged = t.TypeOf<BloombergTaggedC>;
export type Bloomberg = BloombergTagged & typeof bloomberg;
export type BloombergC = t.Type<Bloomberg, BloombergTagged>;
export const bloombergC: BloombergC = pipe(bloombergTaggedC, c => new t.Type<Bloomberg, BloombergTagged>(
  `Bloomberg`,
  (u: unknown): u is Bloomberg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Bloomberg> => pipe(c.decode(u), E.map(x => ({ ...x, ...bloomberg }))),
  (x: Bloomberg): BloombergTagged => ({ ...x, _tag: `Bloomberg`}),
)) satisfies t.Type<Bloomberg, unknown>;


export const allPublicationC = [bondLinkFiscC, bondLinkMuniPodC, theBondBuyerC, municipalMarketAnalyticsC, marketReportsC, bondLinkResearchC, bloombergC] as const;
export const allPublicationNames = [`BondLinkFisc`, `BondLinkMuniPod`, `TheBondBuyer`, `MunicipalMarketAnalytics`, `MarketReports`, `BondLinkResearch`, `Bloomberg`] as const;
export type PublicationName = (typeof allPublicationNames)[number];

export type PublicationCU = t.UnionC<[BondLinkFiscC, BondLinkMuniPodC, TheBondBuyerC, MunicipalMarketAnalyticsC, MarketReportsC, BondLinkResearchC, BloombergC]>;
export type PublicationU = BondLinkFisc | BondLinkMuniPod | TheBondBuyer | MunicipalMarketAnalytics | MarketReports | BondLinkResearch | Bloomberg;
export const PublicationCU: PublicationCU = t.union([bondLinkFiscC, bondLinkMuniPodC, theBondBuyerC, municipalMarketAnalyticsC, marketReportsC, bondLinkResearchC, bloombergC]) satisfies t.Type<PublicationU, unknown>;

export const publicationOrd: Ord.Ord<PublicationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPublication = [bondLinkFisc, bondLinkMuniPod, theBondBuyer, municipalMarketAnalytics, marketReports, bondLinkResearch, bloomberg] as const;
export type PublicationMap<A> = { [K in PublicationName]: A };


