import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fidelity = {
  _tag: `Fidelity`
} as const;

export type FidelityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Fidelity`>
}>;
export const fidelityTaggedC: FidelityTaggedC = t.type({
  _tag: t.literal(`Fidelity`)
});
export type FidelityTagged = t.TypeOf<FidelityTaggedC>;
export type Fidelity = FidelityTagged & typeof fidelity;
export type FidelityC = t.Type<Fidelity, FidelityTagged>;
export const fidelityC: FidelityC = pipe(fidelityTaggedC, c => new t.Type<Fidelity, FidelityTagged>(
  `Fidelity`,
  (u: unknown): u is Fidelity => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Fidelity> => pipe(c.decode(u), E.map(x => ({ ...x, ...fidelity }))),
  (x: Fidelity): FidelityTagged => ({ ...x, _tag: `Fidelity`}),
)) satisfies t.Type<Fidelity, unknown>;


export const ipreo = {
  _tag: `Ipreo`
} as const;

export type IpreoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Ipreo`>
}>;
export const ipreoTaggedC: IpreoTaggedC = t.type({
  _tag: t.literal(`Ipreo`)
});
export type IpreoTagged = t.TypeOf<IpreoTaggedC>;
export type Ipreo = IpreoTagged & typeof ipreo;
export type IpreoC = t.Type<Ipreo, IpreoTagged>;
export const ipreoC: IpreoC = pipe(ipreoTaggedC, c => new t.Type<Ipreo, IpreoTagged>(
  `Ipreo`,
  (u: unknown): u is Ipreo => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ipreo> => pipe(c.decode(u), E.map(x => ({ ...x, ...ipreo }))),
  (x: Ipreo): IpreoTagged => ({ ...x, _tag: `Ipreo`}),
)) satisfies t.Type<Ipreo, unknown>;


export const ICE = {
  _tag: `ICE`
} as const;

export type ICETaggedC = t.TypeC<{
  _tag: t.LiteralC<`ICE`>
}>;
export const ICETaggedC: ICETaggedC = t.type({
  _tag: t.literal(`ICE`)
});
export type ICETagged = t.TypeOf<ICETaggedC>;
export type ICE = ICETagged & typeof ICE;
export type ICEC = t.Type<ICE, ICETagged>;
export const ICEC: ICEC = pipe(ICETaggedC, c => new t.Type<ICE, ICETagged>(
  `ICE`,
  (u: unknown): u is ICE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ICE> => pipe(c.decode(u), E.map(x => ({ ...x, ...ICE }))),
  (x: ICE): ICETagged => ({ ...x, _tag: `ICE`}),
)) satisfies t.Type<ICE, unknown>;


export const bondNav = {
  _tag: `BondNav`
} as const;

export type BondNavTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondNav`>
}>;
export const bondNavTaggedC: BondNavTaggedC = t.type({
  _tag: t.literal(`BondNav`)
});
export type BondNavTagged = t.TypeOf<BondNavTaggedC>;
export type BondNav = BondNavTagged & typeof bondNav;
export type BondNavC = t.Type<BondNav, BondNavTagged>;
export const bondNavC: BondNavC = pipe(bondNavTaggedC, c => new t.Type<BondNav, BondNavTagged>(
  `BondNav`,
  (u: unknown): u is BondNav => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondNav> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondNav }))),
  (x: BondNav): BondNavTagged => ({ ...x, _tag: `BondNav`}),
)) satisfies t.Type<BondNav, unknown>;


export const allPartnerC = [fidelityC, ipreoC, ICEC, bondNavC] as const;
export const allPartnerNames = [`Fidelity`, `Ipreo`, `ICE`, `BondNav`] as const;
export type PartnerName = (typeof allPartnerNames)[number];

export type PartnerCU = t.UnionC<[FidelityC, IpreoC, ICEC, BondNavC]>;
export type PartnerU = Fidelity | Ipreo | ICE | BondNav;
export const PartnerCU: PartnerCU = t.union([fidelityC, ipreoC, ICEC, bondNavC]) satisfies t.Type<PartnerU, unknown>;

export const partnerOrd: Ord.Ord<PartnerU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPartner = [fidelity, ipreo, ICE, bondNav] as const;
export type PartnerMap<A> = { [K in PartnerName]: A };


