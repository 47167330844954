import { WithModInfoC as imported250_WithModInfoC, WithId as imported310_WithId, withModInfoC as imported250_withModInfoC, WithId as imported311_WithId, WithIdC as imported311_WithIdC, withModInfoC as imported251_withModInfoC, withIdC as imported310_withIdC, WithModInfo as imported250_WithModInfo, withIdC as imported311_withIdC, WithModInfoC as imported251_WithModInfoC, WithModInfo as imported251_WithModInfo, WithIdC as imported310_WithIdC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { RfpC as imported81_RfpC, Rfp as imported81_Rfp, rfpC as imported77_rfpC } from "./rfpBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BondOfferingDetailsC as imported47_BondOfferingDetailsC, BondOfferingDetails as imported47_BondOfferingDetails, bondOfferingDetailsC as imported47_bondOfferingDetailsC } from "./bondOffering";
import { ActorC as imported9_ActorC, Actor as imported9_Actor, actorC as imported9_actorC } from "./actor";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type ActivitiesC = t.TypeC<{
  visitedSite: t.NumberC,
  viewedOffering: ReadonlyMapFromEntriesC<t.NumberC, t.NumberC>,
  viewedRfp: ReadonlyMapFromEntriesC<t.NumberC, t.NumberC>,
  viewedRoadshow: t.NumberC,
  downloadedDocument: t.NumberC,
  viewedDocument: t.NumberC,
  submittedContactForm: t.NumberC,
  subscribedToNotifications: t.NumberC,
  subscribedToEvents: t.NumberC,
  subscribedToBonds: t.NumberC,
  subscribedToRfps: t.NumberC,
  subscribedToDocs: t.NumberC,
  subscribedToNews: t.NumberC,
  subscribedToRatings: t.NumberC,
  receivedEmail: t.NumberC,
  openedEmail: t.NumberC,
  clickedEmail: t.NumberC,
  clickedLink: t.NumberC,
  didAnythingOtherThanEmailAction: t.NumberC
}>;
export type Activities = {
  visitedSite: number,
  viewedOffering: ReadonlyMap<number, number>,
  viewedRfp: ReadonlyMap<number, number>,
  viewedRoadshow: number,
  downloadedDocument: number,
  viewedDocument: number,
  submittedContactForm: number,
  subscribedToNotifications: number,
  subscribedToEvents: number,
  subscribedToBonds: number,
  subscribedToRfps: number,
  subscribedToDocs: number,
  subscribedToNews: number,
  subscribedToRatings: number,
  receivedEmail: number,
  openedEmail: number,
  clickedEmail: number,
  clickedLink: number,
  didAnythingOtherThanEmailAction: number
};
export const activitiesC: ActivitiesC = t.type({
  visitedSite: t.number,
  viewedOffering: readonlyMapFromEntries(t.number, numberOrd, t.number),
  viewedRfp: readonlyMapFromEntries(t.number, numberOrd, t.number),
  viewedRoadshow: t.number,
  downloadedDocument: t.number,
  viewedDocument: t.number,
  submittedContactForm: t.number,
  subscribedToNotifications: t.number,
  subscribedToEvents: t.number,
  subscribedToBonds: t.number,
  subscribedToRfps: t.number,
  subscribedToDocs: t.number,
  subscribedToNews: t.number,
  subscribedToRatings: t.number,
  receivedEmail: t.number,
  openedEmail: t.number,
  clickedEmail: t.number,
  clickedLink: t.number,
  didAnythingOtherThanEmailAction: t.number
}) satisfies t.Type<Activities, unknown>;


export type InvestorActivityRowC = t.TypeC<{
  actor: imported9_ActorC,
  activities: ActivitiesC,
  lastActive: LocalDateTimeFromIsoStringC,
  lastContact: OptionFromNullableC<LocalDateTimeFromIsoStringC>
}>;
export type InvestorActivityRow = {
  actor: imported9_Actor,
  activities: Activities,
  lastActive: LocalDateTime,
  lastContact: O.Option<LocalDateTime>
};
export const investorActivityRowC: InvestorActivityRowC = t.type({
  actor: imported9_actorC,
  activities: activitiesC,
  lastActive: LocalDateTimeFromIsoStringC,
  lastContact: optionFromNullable(LocalDateTimeFromIsoStringC)
}) satisfies t.Type<InvestorActivityRow, unknown>;


export type InvestorActivityTableC = t.TypeC<{
  investorActivityRows: t.ReadonlyArrayC<InvestorActivityRowC>,
  offerings: ReadonlyMapFromEntriesC<t.NumberC, imported310_WithIdC<imported250_WithModInfoC<imported47_BondOfferingDetailsC>>>,
  rfps: ReadonlyMapFromEntriesC<t.NumberC, imported311_WithIdC<imported251_WithModInfoC<imported81_RfpC>>>
}>;
export type InvestorActivityTable = {
  investorActivityRows: ReadonlyArray<InvestorActivityRow>,
  offerings: ReadonlyMap<number, imported310_WithId<imported250_WithModInfo<imported47_BondOfferingDetails>>>,
  rfps: ReadonlyMap<number, imported311_WithId<imported251_WithModInfo<imported81_Rfp>>>
};
export const investorActivityTableC: InvestorActivityTableC = t.type({
  investorActivityRows: t.readonlyArray(investorActivityRowC),
  offerings: readonlyMapFromEntries(t.number, numberOrd, imported310_withIdC(imported250_withModInfoC(imported47_bondOfferingDetailsC))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, imported311_withIdC(imported251_withModInfoC(imported77_rfpC)))
}) satisfies t.Type<InvestorActivityTable, unknown>;


