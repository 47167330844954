import type { ReactNode } from "react";
import { createPortal } from "react-dom";

import { isServerSide } from "@scripts/util/ssr";

import { Empty } from "./Empty";

type PortalBaseProps = {
  children: ReactNode;
  container: Element | DocumentFragment;
  id: string;
};

export const PortalBase = (props: PortalBaseProps) => isServerSide()
  ? <Empty />
  : createPortal(props.children, props.container, props.id);

export const Portal = (props: Omit<PortalBaseProps, "container">) =>
  <PortalBase {...props} container={document.body} />;
