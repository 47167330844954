import * as t from "io-ts";

export type SubscribedC<A1 extends t.Mixed> = t.TypeC<{
  data: A1,
  subscribed: t.BooleanC
}>;
export type Subscribed<A1> = {
  data: A1,
  subscribed: boolean
};
export const subscribedC = <A1 extends t.Mixed>(A1: A1): SubscribedC<A1> => t.type({
  data: A1,
  subscribed: t.boolean
}) satisfies t.Type<Subscribed<t.TypeOf<A1>>, unknown>;


