import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { EitherC, either } from "io-ts-types/lib/either";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { ActorIdCU as imported5_ActorIdCU, ActorIdU as imported2_ActorIdU, ActorIdCU as imported4_ActorIdCU } from "./actor";
import { WithStatusCU as imported437_WithStatusCU, WithStatusU as imported218_WithStatusU, WithStatusCU as imported434_WithStatusCU, WithStatusCU as imported435_WithStatusCU, WithStatusU as imported217_WithStatusU, WithStatusCU as imported436_WithStatusCU } from "./threadThrough";
import { IssuerC as imported43_IssuerC, Issuer as imported43_Issuer, issuerC as imported42_issuerC } from "./issuer";
import { BondOfferingC as imported20_BondOfferingC, BondOffering as imported20_BondOffering, bondOfferingC as imported20_bondOfferingC } from "./bondOfferingBase";
import { RfpC as imported54_RfpC, Rfp as imported54_Rfp, rfpC as imported52_rfpC } from "./rfpBase";

export type ValidateEmailC = t.TypeC<{
  email: t.StringC
}>;
export type ValidateEmail = {
  email: string
};
export const validateEmailC: ValidateEmailC = t.type({
  email: t.string
}) satisfies t.Type<ValidateEmail, unknown>;


export type ActorPostEmailRecipientC = t.TypeC<{
  _tag: t.LiteralC<`ActorPostEmailRecipient`>,
  id: imported5_ActorIdCU
}>;
export type ActorPostEmailRecipient = {
  _tag: `ActorPostEmailRecipient`,
  id: imported2_ActorIdU
};
export const actorPostEmailRecipientC: ActorPostEmailRecipientC = t.type({
  _tag: t.literal(`ActorPostEmailRecipient`),
  id: imported4_ActorIdCU
}) satisfies t.Type<ActorPostEmailRecipient, unknown>;


export type CustomPostEmailRecipientC = t.TypeC<{
  _tag: t.LiteralC<`CustomPostEmailRecipient`>,
  email: t.StringC
}>;
export type CustomPostEmailRecipient = {
  _tag: `CustomPostEmailRecipient`,
  email: string
};
export const customPostEmailRecipientC: CustomPostEmailRecipientC = t.type({
  _tag: t.literal(`CustomPostEmailRecipient`),
  email: t.string
}) satisfies t.Type<CustomPostEmailRecipient, unknown>;


export const allPostEmailRecipientC = [actorPostEmailRecipientC, customPostEmailRecipientC] as const;
export const allPostEmailRecipientNames = [`ActorPostEmailRecipient`, `CustomPostEmailRecipient`] as const;
export type PostEmailRecipientName = (typeof allPostEmailRecipientNames)[number];

export type PostEmailRecipientCU = t.UnionC<[ActorPostEmailRecipientC, CustomPostEmailRecipientC]>;
export type PostEmailRecipientU = ActorPostEmailRecipient | CustomPostEmailRecipient;
export const PostEmailRecipientCU: PostEmailRecipientCU = t.union([actorPostEmailRecipientC, customPostEmailRecipientC]) satisfies t.Type<PostEmailRecipientU, unknown>;

export type PostEmailRecipientMap<A> = { [K in PostEmailRecipientName]: A };


export type TemplateEmailC = t.TypeC<{
  announcement: t.StringC,
  featuredParticipant: OptionFromNullableC<t.StringC>,
  issuer: imported43_IssuerC,
  deal: EitherC<imported436_WithStatusCU<imported54_RfpC>, imported437_WithStatusCU<imported20_BondOfferingC>>
}>;
export type TemplateEmail = {
  announcement: string,
  featuredParticipant: O.Option<string>,
  issuer: imported43_Issuer,
  deal: E.Either<imported217_WithStatusU<imported54_Rfp>, imported218_WithStatusU<imported20_BondOffering>>
};
export const templateEmailC: TemplateEmailC = t.type({
  announcement: t.string,
  featuredParticipant: optionFromNullable(t.string),
  issuer: imported42_issuerC,
  deal: either(imported434_WithStatusCU(imported52_rfpC), imported435_WithStatusCU(imported20_bondOfferingC))
}) satisfies t.Type<TemplateEmail, unknown>;


