import { SortColumnCU as imported5_SortColumnCU, SortColumnU as imported2_SortColumnU, SortColumnCU as imported4_SortColumnCU } from "../domaintables/sortColumns";
import * as t from "io-ts";
import { PageSortC as imported4_PageSortC, PageSort as imported4_PageSort, pageSortC as imported4_pageSortC } from "./pageSorts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BucketCU as imported11_BucketCU, BucketU as imported5_BucketU, BucketCU as imported10_BucketCU } from "../domaintables/pages";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type PageConfigC<A1 extends t.Mixed> = t.TypeC<{
  page: A1,
  title: OptionFromNullableC<t.StringC>,
  introSentence: OptionFromNullableC<MarkdownC>,
  introSentencePlainText: OptionFromNullableC<t.StringC>,
  sort: OptionFromNullableC<imported4_PageSortC>,
  possibleSortColumns: t.ReadonlyArrayC<imported5_SortColumnCU>,
  pageHeader: OptionFromNullableC<imported11_BucketCU>
}>;
export type PageConfig<A1> = {
  page: A1,
  title: O.Option<string>,
  introSentence: O.Option<Markdown>,
  introSentencePlainText: O.Option<string>,
  sort: O.Option<imported4_PageSort>,
  possibleSortColumns: ReadonlyArray<imported2_SortColumnU>,
  pageHeader: O.Option<imported5_BucketU>
};
export const pageConfigC = <A1 extends t.Mixed>(A1: A1): PageConfigC<A1> => t.type({
  page: A1,
  title: optionFromNullable(t.string),
  introSentence: optionFromNullable(markdownC),
  introSentencePlainText: optionFromNullable(t.string),
  sort: optionFromNullable(imported4_pageSortC),
  possibleSortColumns: t.readonlyArray(imported4_SortColumnCU),
  pageHeader: optionFromNullable(imported10_BucketCU)
}) satisfies t.Type<PageConfig<t.TypeOf<A1>>, unknown>;


