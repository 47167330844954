import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const custodialReceipt = {
  _tag: `CustodialReceipt`,
  id: 1,
  name: `Custodial receipt`
} as const;

export type CustodialReceiptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustodialReceipt`>
}>;
export const custodialReceiptTaggedC: CustodialReceiptTaggedC = t.type({
  _tag: t.literal(`CustodialReceipt`)
});
export type CustodialReceiptTagged = t.TypeOf<CustodialReceiptTaggedC>;
export type CustodialReceipt = CustodialReceiptTagged & typeof custodialReceipt;
export type CustodialReceiptC = t.Type<CustodialReceipt, CustodialReceiptTagged>;
export const custodialReceiptC: CustodialReceiptC = pipe(custodialReceiptTaggedC, c => new t.Type<CustodialReceipt, CustodialReceiptTagged>(
  `CustodialReceipt`,
  (u: unknown): u is CustodialReceipt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustodialReceipt> => pipe(c.decode(u), E.map(x => ({ ...x, ...custodialReceipt }))),
  (x: CustodialReceipt): CustodialReceiptTagged => ({ ...x, _tag: `CustodialReceipt`}),
)) satisfies t.Type<CustodialReceipt, unknown>;


export const periodicAuctionSlashInverseFloater = {
  _tag: `PeriodicAuctionSlashInverseFloater`,
  id: 2,
  name: `Periodic auction/inverse floater`
} as const;

export type PeriodicAuctionSlashInverseFloaterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PeriodicAuctionSlashInverseFloater`>
}>;
export const periodicAuctionSlashInverseFloaterTaggedC: PeriodicAuctionSlashInverseFloaterTaggedC = t.type({
  _tag: t.literal(`PeriodicAuctionSlashInverseFloater`)
});
export type PeriodicAuctionSlashInverseFloaterTagged = t.TypeOf<PeriodicAuctionSlashInverseFloaterTaggedC>;
export type PeriodicAuctionSlashInverseFloater = PeriodicAuctionSlashInverseFloaterTagged & typeof periodicAuctionSlashInverseFloater;
export type PeriodicAuctionSlashInverseFloaterC = t.Type<PeriodicAuctionSlashInverseFloater, PeriodicAuctionSlashInverseFloaterTagged>;
export const periodicAuctionSlashInverseFloaterC: PeriodicAuctionSlashInverseFloaterC = pipe(periodicAuctionSlashInverseFloaterTaggedC, c => new t.Type<PeriodicAuctionSlashInverseFloater, PeriodicAuctionSlashInverseFloaterTagged>(
  `PeriodicAuctionSlashInverseFloater`,
  (u: unknown): u is PeriodicAuctionSlashInverseFloater => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PeriodicAuctionSlashInverseFloater> => pipe(c.decode(u), E.map(x => ({ ...x, ...periodicAuctionSlashInverseFloater }))),
  (x: PeriodicAuctionSlashInverseFloater): PeriodicAuctionSlashInverseFloaterTagged => ({ ...x, _tag: `PeriodicAuctionSlashInverseFloater`}),
)) satisfies t.Type<PeriodicAuctionSlashInverseFloater, unknown>;


export const premMunicipalBondReceipt = {
  _tag: `PremMunicipalBondReceipt`,
  id: 3,
  name: `Prem municipal bond receipt`
} as const;

export type PremMunicipalBondReceiptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PremMunicipalBondReceipt`>
}>;
export const premMunicipalBondReceiptTaggedC: PremMunicipalBondReceiptTaggedC = t.type({
  _tag: t.literal(`PremMunicipalBondReceipt`)
});
export type PremMunicipalBondReceiptTagged = t.TypeOf<PremMunicipalBondReceiptTaggedC>;
export type PremMunicipalBondReceipt = PremMunicipalBondReceiptTagged & typeof premMunicipalBondReceipt;
export type PremMunicipalBondReceiptC = t.Type<PremMunicipalBondReceipt, PremMunicipalBondReceiptTagged>;
export const premMunicipalBondReceiptC: PremMunicipalBondReceiptC = pipe(premMunicipalBondReceiptTaggedC, c => new t.Type<PremMunicipalBondReceipt, PremMunicipalBondReceiptTagged>(
  `PremMunicipalBondReceipt`,
  (u: unknown): u is PremMunicipalBondReceipt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PremMunicipalBondReceipt> => pipe(c.decode(u), E.map(x => ({ ...x, ...premMunicipalBondReceipt }))),
  (x: PremMunicipalBondReceipt): PremMunicipalBondReceiptTagged => ({ ...x, _tag: `PremMunicipalBondReceipt`}),
)) satisfies t.Type<PremMunicipalBondReceipt, unknown>;


export const preRefunded = {
  _tag: `PreRefunded`,
  id: 4,
  name: `Pre-refunded`
} as const;

export type PreRefundedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PreRefunded`>
}>;
export const preRefundedTaggedC: PreRefundedTaggedC = t.type({
  _tag: t.literal(`PreRefunded`)
});
export type PreRefundedTagged = t.TypeOf<PreRefundedTaggedC>;
export type PreRefunded = PreRefundedTagged & typeof preRefunded;
export type PreRefundedC = t.Type<PreRefunded, PreRefundedTagged>;
export const preRefundedC: PreRefundedC = pipe(preRefundedTaggedC, c => new t.Type<PreRefunded, PreRefundedTagged>(
  `PreRefunded`,
  (u: unknown): u is PreRefunded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefunded> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefunded }))),
  (x: PreRefunded): PreRefundedTagged => ({ ...x, _tag: `PreRefunded`}),
)) satisfies t.Type<PreRefunded, unknown>;


export const thirdPartyPutSlashTenderOptionBond = {
  _tag: `ThirdPartyPutSlashTenderOptionBond`,
  id: 5,
  name: `Third party put/tender option bond`
} as const;

export type ThirdPartyPutSlashTenderOptionBondTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ThirdPartyPutSlashTenderOptionBond`>
}>;
export const thirdPartyPutSlashTenderOptionBondTaggedC: ThirdPartyPutSlashTenderOptionBondTaggedC = t.type({
  _tag: t.literal(`ThirdPartyPutSlashTenderOptionBond`)
});
export type ThirdPartyPutSlashTenderOptionBondTagged = t.TypeOf<ThirdPartyPutSlashTenderOptionBondTaggedC>;
export type ThirdPartyPutSlashTenderOptionBond = ThirdPartyPutSlashTenderOptionBondTagged & typeof thirdPartyPutSlashTenderOptionBond;
export type ThirdPartyPutSlashTenderOptionBondC = t.Type<ThirdPartyPutSlashTenderOptionBond, ThirdPartyPutSlashTenderOptionBondTagged>;
export const thirdPartyPutSlashTenderOptionBondC: ThirdPartyPutSlashTenderOptionBondC = pipe(thirdPartyPutSlashTenderOptionBondTaggedC, c => new t.Type<ThirdPartyPutSlashTenderOptionBond, ThirdPartyPutSlashTenderOptionBondTagged>(
  `ThirdPartyPutSlashTenderOptionBond`,
  (u: unknown): u is ThirdPartyPutSlashTenderOptionBond => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ThirdPartyPutSlashTenderOptionBond> => pipe(c.decode(u), E.map(x => ({ ...x, ...thirdPartyPutSlashTenderOptionBond }))),
  (x: ThirdPartyPutSlashTenderOptionBond): ThirdPartyPutSlashTenderOptionBondTagged => ({ ...x, _tag: `ThirdPartyPutSlashTenderOptionBond`}),
)) satisfies t.Type<ThirdPartyPutSlashTenderOptionBond, unknown>;


export const remarketing = {
  _tag: `Remarketing`,
  id: 6,
  name: `Remarketing`
} as const;

export type RemarketingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Remarketing`>
}>;
export const remarketingTaggedC: RemarketingTaggedC = t.type({
  _tag: t.literal(`Remarketing`)
});
export type RemarketingTagged = t.TypeOf<RemarketingTaggedC>;
export type Remarketing = RemarketingTagged & typeof remarketing;
export type RemarketingC = t.Type<Remarketing, RemarketingTagged>;
export const remarketingC: RemarketingC = pipe(remarketingTaggedC, c => new t.Type<Remarketing, RemarketingTagged>(
  `Remarketing`,
  (u: unknown): u is Remarketing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Remarketing> => pipe(c.decode(u), E.map(x => ({ ...x, ...remarketing }))),
  (x: Remarketing): RemarketingTagged => ({ ...x, _tag: `Remarketing`}),
)) satisfies t.Type<Remarketing, unknown>;


export const secondarilyInsured = {
  _tag: `SecondarilyInsured`,
  id: 7,
  name: `Secondarily insured`
} as const;

export type SecondarilyInsuredTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SecondarilyInsured`>
}>;
export const secondarilyInsuredTaggedC: SecondarilyInsuredTaggedC = t.type({
  _tag: t.literal(`SecondarilyInsured`)
});
export type SecondarilyInsuredTagged = t.TypeOf<SecondarilyInsuredTaggedC>;
export type SecondarilyInsured = SecondarilyInsuredTagged & typeof secondarilyInsured;
export type SecondarilyInsuredC = t.Type<SecondarilyInsured, SecondarilyInsuredTagged>;
export const secondarilyInsuredC: SecondarilyInsuredC = pipe(secondarilyInsuredTaggedC, c => new t.Type<SecondarilyInsured, SecondarilyInsuredTagged>(
  `SecondarilyInsured`,
  (u: unknown): u is SecondarilyInsured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SecondarilyInsured> => pipe(c.decode(u), E.map(x => ({ ...x, ...secondarilyInsured }))),
  (x: SecondarilyInsured): SecondarilyInsuredTagged => ({ ...x, _tag: `SecondarilyInsured`}),
)) satisfies t.Type<SecondarilyInsured, unknown>;


export const unitPutAndSecondarilyInsured = {
  _tag: `UnitPutAndSecondarilyInsured`,
  id: 8,
  name: `Unit put & secondarily insured`
} as const;

export type UnitPutAndSecondarilyInsuredTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UnitPutAndSecondarilyInsured`>
}>;
export const unitPutAndSecondarilyInsuredTaggedC: UnitPutAndSecondarilyInsuredTaggedC = t.type({
  _tag: t.literal(`UnitPutAndSecondarilyInsured`)
});
export type UnitPutAndSecondarilyInsuredTagged = t.TypeOf<UnitPutAndSecondarilyInsuredTaggedC>;
export type UnitPutAndSecondarilyInsured = UnitPutAndSecondarilyInsuredTagged & typeof unitPutAndSecondarilyInsured;
export type UnitPutAndSecondarilyInsuredC = t.Type<UnitPutAndSecondarilyInsured, UnitPutAndSecondarilyInsuredTagged>;
export const unitPutAndSecondarilyInsuredC: UnitPutAndSecondarilyInsuredC = pipe(unitPutAndSecondarilyInsuredTaggedC, c => new t.Type<UnitPutAndSecondarilyInsured, UnitPutAndSecondarilyInsuredTagged>(
  `UnitPutAndSecondarilyInsured`,
  (u: unknown): u is UnitPutAndSecondarilyInsured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnitPutAndSecondarilyInsured> => pipe(c.decode(u), E.map(x => ({ ...x, ...unitPutAndSecondarilyInsured }))),
  (x: UnitPutAndSecondarilyInsured): UnitPutAndSecondarilyInsuredTagged => ({ ...x, _tag: `UnitPutAndSecondarilyInsured`}),
)) satisfies t.Type<UnitPutAndSecondarilyInsured, unknown>;


export const thirdPartyPutUnit = {
  _tag: `ThirdPartyPutUnit`,
  id: 9,
  name: `Third party put unit`
} as const;

export type ThirdPartyPutUnitTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ThirdPartyPutUnit`>
}>;
export const thirdPartyPutUnitTaggedC: ThirdPartyPutUnitTaggedC = t.type({
  _tag: t.literal(`ThirdPartyPutUnit`)
});
export type ThirdPartyPutUnitTagged = t.TypeOf<ThirdPartyPutUnitTaggedC>;
export type ThirdPartyPutUnit = ThirdPartyPutUnitTagged & typeof thirdPartyPutUnit;
export type ThirdPartyPutUnitC = t.Type<ThirdPartyPutUnit, ThirdPartyPutUnitTagged>;
export const thirdPartyPutUnitC: ThirdPartyPutUnitC = pipe(thirdPartyPutUnitTaggedC, c => new t.Type<ThirdPartyPutUnit, ThirdPartyPutUnitTagged>(
  `ThirdPartyPutUnit`,
  (u: unknown): u is ThirdPartyPutUnit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ThirdPartyPutUnit> => pipe(c.decode(u), E.map(x => ({ ...x, ...thirdPartyPutUnit }))),
  (x: ThirdPartyPutUnit): ThirdPartyPutUnitTagged => ({ ...x, _tag: `ThirdPartyPutUnit`}),
)) satisfies t.Type<ThirdPartyPutUnit, unknown>;


export const unrefundedBalance = {
  _tag: `UnrefundedBalance`,
  id: 10,
  name: `Unrefunded balance`
} as const;

export type UnrefundedBalanceTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UnrefundedBalance`>
}>;
export const unrefundedBalanceTaggedC: UnrefundedBalanceTaggedC = t.type({
  _tag: t.literal(`UnrefundedBalance`)
});
export type UnrefundedBalanceTagged = t.TypeOf<UnrefundedBalanceTaggedC>;
export type UnrefundedBalance = UnrefundedBalanceTagged & typeof unrefundedBalance;
export type UnrefundedBalanceC = t.Type<UnrefundedBalance, UnrefundedBalanceTagged>;
export const unrefundedBalanceC: UnrefundedBalanceC = pipe(unrefundedBalanceTaggedC, c => new t.Type<UnrefundedBalance, UnrefundedBalanceTagged>(
  `UnrefundedBalance`,
  (u: unknown): u is UnrefundedBalance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnrefundedBalance> => pipe(c.decode(u), E.map(x => ({ ...x, ...unrefundedBalance }))),
  (x: UnrefundedBalance): UnrefundedBalanceTagged => ({ ...x, _tag: `UnrefundedBalance`}),
)) satisfies t.Type<UnrefundedBalance, unknown>;


export const allMuniEnhancementTypeC = [custodialReceiptC, periodicAuctionSlashInverseFloaterC, premMunicipalBondReceiptC, preRefundedC, thirdPartyPutSlashTenderOptionBondC, remarketingC, secondarilyInsuredC, unitPutAndSecondarilyInsuredC, thirdPartyPutUnitC, unrefundedBalanceC] as const;
export const allMuniEnhancementTypeNames = [`CustodialReceipt`, `PeriodicAuctionSlashInverseFloater`, `PremMunicipalBondReceipt`, `PreRefunded`, `ThirdPartyPutSlashTenderOptionBond`, `Remarketing`, `SecondarilyInsured`, `UnitPutAndSecondarilyInsured`, `ThirdPartyPutUnit`, `UnrefundedBalance`] as const;
export type MuniEnhancementTypeName = (typeof allMuniEnhancementTypeNames)[number];

export type MuniEnhancementTypeCU = t.UnionC<[CustodialReceiptC, PeriodicAuctionSlashInverseFloaterC, PremMunicipalBondReceiptC, PreRefundedC, ThirdPartyPutSlashTenderOptionBondC, RemarketingC, SecondarilyInsuredC, UnitPutAndSecondarilyInsuredC, ThirdPartyPutUnitC, UnrefundedBalanceC]>;
export type MuniEnhancementTypeU = CustodialReceipt | PeriodicAuctionSlashInverseFloater | PremMunicipalBondReceipt | PreRefunded | ThirdPartyPutSlashTenderOptionBond | Remarketing | SecondarilyInsured | UnitPutAndSecondarilyInsured | ThirdPartyPutUnit | UnrefundedBalance;
export const MuniEnhancementTypeCU: MuniEnhancementTypeCU = t.union([custodialReceiptC, periodicAuctionSlashInverseFloaterC, premMunicipalBondReceiptC, preRefundedC, thirdPartyPutSlashTenderOptionBondC, remarketingC, secondarilyInsuredC, unitPutAndSecondarilyInsuredC, thirdPartyPutUnitC, unrefundedBalanceC]) satisfies t.Type<MuniEnhancementTypeU, unknown>;

export const muniEnhancementTypeOrd: Ord.Ord<MuniEnhancementTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniEnhancementType = [custodialReceipt, periodicAuctionSlashInverseFloater, premMunicipalBondReceipt, preRefunded, thirdPartyPutSlashTenderOptionBond, remarketing, secondarilyInsured, unitPutAndSecondarilyInsured, thirdPartyPutUnit, unrefundedBalance] as const;
export type MuniEnhancementTypeMap<A> = { [K in MuniEnhancementTypeName]: A };


