import * as t from "io-ts";
import { DocumentActivityC as imported2_DocumentActivityC, DocumentActivity as imported2_DocumentActivity, documentActivityC as imported2_documentActivityC } from "./documentStatistics";
import { DocumentWithCategoryC as imported15_DocumentWithCategoryC, DocumentWithCategory as imported15_DocumentWithCategory, documentWithCategoryC as imported15_documentWithCategoryC } from "./document";
import { WithIdC as imported208_WithIdC, WithId as imported208_WithId, withIdC as imported208_withIdC } from "./threadThrough";

export type DocumentHistoryC = t.TypeC<{
  document: imported208_WithIdC<imported15_DocumentWithCategoryC>,
  history: t.ReadonlyArrayC<imported2_DocumentActivityC>
}>;
export type DocumentHistory = {
  document: imported208_WithId<imported15_DocumentWithCategory>,
  history: ReadonlyArray<imported2_DocumentActivity>
};
export const documentHistoryC: DocumentHistoryC = t.type({
  document: imported208_withIdC(imported15_documentWithCategoryC),
  history: t.readonlyArray(imported2_documentActivityC)
}) satisfies t.Type<DocumentHistory, unknown>;


