import * as t from "io-ts";
import { MediaCategoryCU as imported21_MediaCategoryCU, MediaCategoryU as imported10_MediaCategoryU, MediaCategoryCU as imported20_MediaCategoryCU } from "../domaintables/mediaCategories";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { DateQualifierC as imported23_DateQualifierC, DateQualifier as imported23_DateQualifier, DateQualifierC as imported24_DateQualifierC, dateQualifierC as imported24_dateQualifierC, DateQualifier as imported24_DateQualifier, dateQualifierC as imported23_dateQualifierC } from "./dateQualifier";

export type ImageUploadResponseC = t.TypeC<{
  uri: t.StringC,
  fileSize: t.NumberC,
  viewName: t.StringC,
  caption: OptionFromNullableC<t.StringC>
}>;
export type ImageUploadResponse = {
  uri: string,
  fileSize: number,
  viewName: string,
  caption: O.Option<string>
};
export const imageUploadResponseC: ImageUploadResponseC = t.type({
  uri: t.string,
  fileSize: t.number,
  viewName: t.string,
  caption: optionFromNullable(t.string)
}) satisfies t.Type<ImageUploadResponse, unknown>;


export type ImagePostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  mediaCategory: imported21_MediaCategoryCU,
  uploadResponse: ImageUploadResponseC
}>;
export type ImagePostBody = {
  id: O.Option<number>,
  mediaCategory: imported10_MediaCategoryU,
  uploadResponse: ImageUploadResponse
};
export const imagePostBodyC: ImagePostBodyC = t.type({
  id: optionFromNullable(t.number),
  mediaCategory: imported20_MediaCategoryCU,
  uploadResponse: imageUploadResponseC
}) satisfies t.Type<ImagePostBody, unknown>;


export type MediaUploadResponseC = t.TypeC<{
  uri: t.StringC,
  fileSize: t.NumberC,
  viewName: t.StringC
}>;
export type MediaUploadResponse = {
  uri: string,
  fileSize: number,
  viewName: string
};
export const mediaUploadResponseC: MediaUploadResponseC = t.type({
  uri: t.string,
  fileSize: t.number,
  viewName: t.string
}) satisfies t.Type<MediaUploadResponse, unknown>;


export type MediaC = t.TypeC<{
  caption: OptionFromNullableC<t.StringC>,
  altText: OptionFromNullableC<t.StringC>,
  mediaDate: imported23_DateQualifierC,
  mediaOrder: t.NumberC,
  documentTypeId: OptionFromNullableC<t.NumberC>,
  uploadResponse: MediaUploadResponseC,
  archived: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  mediaCategoryId: t.NumberC
}>;
export type Media = {
  caption: O.Option<string>,
  altText: O.Option<string>,
  mediaDate: imported23_DateQualifier,
  mediaOrder: number,
  documentTypeId: O.Option<number>,
  uploadResponse: MediaUploadResponse,
  archived: O.Option<LocalDateTime>,
  mediaCategoryId: number
};
export const mediaC: MediaC = t.type({
  caption: optionFromNullable(t.string),
  altText: optionFromNullable(t.string),
  mediaDate: imported23_dateQualifierC,
  mediaOrder: t.number,
  documentTypeId: optionFromNullable(t.number),
  uploadResponse: mediaUploadResponseC,
  archived: optionFromNullable(LocalDateTimeFromIsoStringC),
  mediaCategoryId: t.number
}) satisfies t.Type<Media, unknown>;


export type MediaUploadResponseWithCategoryC = t.TypeC<{
  uri: t.StringC,
  fileSize: t.NumberC,
  viewName: t.StringC,
  mediaCategoryId: OptionFromNullableC<t.NumberC>,
  mediaDate: imported24_DateQualifierC
}>;
export type MediaUploadResponseWithCategory = {
  uri: string,
  fileSize: number,
  viewName: string,
  mediaCategoryId: O.Option<number>,
  mediaDate: imported24_DateQualifier
};
export const mediaUploadResponseWithCategoryC: MediaUploadResponseWithCategoryC = t.type({
  uri: t.string,
  fileSize: t.number,
  viewName: t.string,
  mediaCategoryId: optionFromNullable(t.number),
  mediaDate: imported24_dateQualifierC
}) satisfies t.Type<MediaUploadResponseWithCategory, unknown>;


export type MediaPreviewC = t.TypeC<{
  fileSize: t.NumberC,
  preview: t.StringC,
  uri: t.StringC,
  viewName: t.StringC
}>;
export type MediaPreview = {
  fileSize: number,
  preview: string,
  uri: string,
  viewName: string
};
export const mediaPreviewC: MediaPreviewC = t.type({
  fileSize: t.number,
  preview: t.string,
  uri: t.string,
  viewName: t.string
}) satisfies t.Type<MediaPreview, unknown>;


