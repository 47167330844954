import * as t from "io-ts";

import { E, identity, pipe } from "@scripts/fp-ts";
import { leadingSlashesRegex, relativeUrlRegex } from "@scripts/generated/models/url";

export type RelativeUrl = `/${string}` & { readonly RelativeUrl: unique symbol };

export const isRelativeUrl = (s: string): s is RelativeUrl => relativeUrlRegex.test(s);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const unsafeFromRelativeUrlString = (s: string): RelativeUrl => `/${s.replace(leadingSlashesRegex, "")}` as RelativeUrl;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const appendToRelativeUrl = (u: RelativeUrl, s: string): RelativeUrl => `${u}${s}` as RelativeUrl;

export type RelativeUrlC = t.Type<RelativeUrl, string>;

export const relativeUrlC: RelativeUrlC = new t.Type(
  "RelativeUrl",
  (u: unknown): u is RelativeUrl => t.string.is(u) && isRelativeUrl(u),
  (u: unknown, context: t.Context): t.Validation<RelativeUrl> => pipe(
    t.string.validate(u, context),
    E.filterOrElse(isRelativeUrl, (s): t.Errors => [{ value: s, context, message: "Invalid relative URL" }]),
  ),
  identity,
);
