import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const project = {
  _tag: `Project`,
  id: 1,
  name: `Project`
} as const;

export type ProjectTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Project`>
}>;
export const projectTaggedC: ProjectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export type ProjectC = t.Type<Project, ProjectTagged>;
export const projectC: ProjectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
)) satisfies t.Type<Project, unknown>;


export const program = {
  _tag: `Program`,
  id: 2,
  name: `Program`
} as const;

export type ProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Program`>
}>;
export const programTaggedC: ProgramTaggedC = t.type({
  _tag: t.literal(`Program`)
});
export type ProgramTagged = t.TypeOf<ProgramTaggedC>;
export type Program = ProgramTagged & typeof program;
export type ProgramC = t.Type<Program, ProgramTagged>;
export const programC: ProgramC = pipe(programTaggedC, c => new t.Type<Program, ProgramTagged>(
  `Program`,
  (u: unknown): u is Program => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Program> => pipe(c.decode(u), E.map(x => ({ ...x, ...program }))),
  (x: Program): ProgramTagged => ({ ...x, _tag: `Program`}),
)) satisfies t.Type<Program, unknown>;


export const other = {
  _tag: `Other`,
  id: 3,
  name: `Other`
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
)) satisfies t.Type<Other, unknown>;


export const allIssuerSeriesTagCategoryC = [projectC, programC, otherC] as const;
export const allIssuerSeriesTagCategoryNames = [`Project`, `Program`, `Other`] as const;
export type IssuerSeriesTagCategoryName = (typeof allIssuerSeriesTagCategoryNames)[number];

export type IssuerSeriesTagCategoryCU = t.UnionC<[ProjectC, ProgramC, OtherC]>;
export type IssuerSeriesTagCategoryU = Project | Program | Other;
export const IssuerSeriesTagCategoryCU: IssuerSeriesTagCategoryCU = t.union([projectC, programC, otherC]) satisfies t.Type<IssuerSeriesTagCategoryU, unknown>;

export const issuerSeriesTagCategoryOrd: Ord.Ord<IssuerSeriesTagCategoryU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerSeriesTagCategory = [project, program, other] as const;
export type IssuerSeriesTagCategoryMap<A> = { [K in IssuerSeriesTagCategoryName]: A };


