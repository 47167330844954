import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SectorCU as imported31_SectorCU, SectorU as imported15_SectorU, SectorCU as imported30_SectorCU } from "../domaintables/sectors";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type BondProgramC = t.TypeC<{
  name: t.StringC,
  overview: OptionFromNullableC<MarkdownC>,
  cusip6: OptionFromNullableC<t.StringC>,
  sector: OptionFromNullableC<imported31_SectorCU>,
  sortOrder: t.NumberC
}>;
export type BondProgram = {
  name: string,
  overview: O.Option<Markdown>,
  cusip6: O.Option<string>,
  sector: O.Option<imported15_SectorU>,
  sortOrder: number
};
export const bondProgramC: BondProgramC = t.type({
  name: t.string,
  overview: optionFromNullable(markdownC),
  cusip6: optionFromNullable(t.string),
  sector: optionFromNullable(imported30_SectorCU),
  sortOrder: t.number
}) satisfies t.Type<BondProgram, unknown>;


