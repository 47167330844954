import { WithModInfo as imported187_WithModInfo, WithStatusCU as imported633_WithStatusCU, withModInfoC as imported187_withModInfoC, WithStatusCU as imported630_WithStatusCU, WithStatusU as imported316_WithStatusU, WithIdC as imported244_WithIdC, WithStatusCU as imported632_WithStatusCU, WithStatusCU as imported631_WithStatusCU, WithId as imported244_WithId, WithStatusU as imported315_WithStatusU, withIdC as imported244_withIdC, WithModInfoC as imported187_WithModInfoC } from "./threadThrough";
import * as t from "io-ts";
import { TaggedContent as imported156_TaggedContent, TaggedContent as imported155_TaggedContent, TaggedContent as imported157_TaggedContent, taggedContentC as imported157_taggedContentC, TaggedContentC as imported157_TaggedContentC, TaggedContentC as imported155_TaggedContentC, TaggedContentC as imported156_TaggedContentC, taggedContentC as imported155_taggedContentC, taggedContentC as imported156_taggedContentC } from "./taggedContent";
import { RoadShowDataC as imported26_RoadShowDataC, RoadShowData as imported26_RoadShowData, roadShowDataC as imported26_roadShowDataC } from "./roadshow";
import { BondOfferingC as imported26_BondOfferingC, BondOffering as imported26_BondOffering, bondOfferingC as imported26_bondOfferingC } from "./bondOfferingBase";
import { RfpC as imported69_RfpC, Rfp as imported69_Rfp, rfpC as imported66_rfpC } from "./rfpBase";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type RoadShowsListC = t.TypeC<{
  shows: t.ReadonlyArrayC<imported244_WithIdC<imported187_WithModInfoC<imported155_TaggedContentC<imported26_RoadShowDataC>>>>,
  offerings: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported632_WithStatusCU<imported156_TaggedContentC<imported26_BondOfferingC>>>>,
  rfps: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported633_WithStatusCU<imported157_TaggedContentC<imported69_RfpC>>>>
}>;
export type RoadShowsList = {
  shows: ReadonlyArray<imported244_WithId<imported187_WithModInfo<imported155_TaggedContent<imported26_RoadShowData>>>>,
  offerings: ReadonlyMap<number, ReadonlyArray<imported315_WithStatusU<imported156_TaggedContent<imported26_BondOffering>>>>,
  rfps: ReadonlyMap<number, ReadonlyArray<imported316_WithStatusU<imported157_TaggedContent<imported69_Rfp>>>>
};
export const roadShowsListC: RoadShowsListC = t.type({
  shows: t.readonlyArray(imported244_withIdC(imported187_withModInfoC(imported155_taggedContentC(imported26_roadShowDataC)))),
  offerings: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported630_WithStatusCU(imported156_taggedContentC(imported26_bondOfferingC)))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported631_WithStatusCU(imported157_taggedContentC(imported66_rfpC))))
}) satisfies t.Type<RoadShowsList, unknown>;


