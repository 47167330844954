import * as t from "io-ts";
import { PostTypeCU as imported3_PostTypeCU, PostTypeU as imported1_PostTypeU, PostTypeCU as imported2_PostTypeCU } from "../domaintables/postTypes";
import { LocalDateC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type IssuerNewsC = t.TypeC<{
  newsTitle: t.StringC,
  newsDate: OptionFromNullableC<LocalDateC>,
  newsBlurb: MarkdownC,
  postType: imported3_PostTypeCU,
  newsOrder: t.NumberC
}>;
export type IssuerNews = {
  newsTitle: string,
  newsDate: O.Option<LocalDate>,
  newsBlurb: Markdown,
  postType: imported1_PostTypeU,
  newsOrder: number
};
export const issuerNewsC: IssuerNewsC = t.type({
  newsTitle: t.string,
  newsDate: optionFromNullable(LocalDateC),
  newsBlurb: markdownC,
  postType: imported2_PostTypeCU,
  newsOrder: t.number
}) satisfies t.Type<IssuerNews, unknown>;


