// This file was generated by postcss-transpiler. Do not edit by hand.

export const subheaderStandard = {
  ".subheader-standard": {
    css: ".subheader-standard",
    html: "subheader-standard",
    attrs: {
      ".draft-subheader": {
        css: ".draft-subheader",
        html: "draft-subheader"
      },
      ".subheader-h3": {
        css: ".subheader-h3",
        html: "subheader-h3"
      }
    },
    ".btn": {
      css: ".btn",
      html: "btn"
    },
    "h3": {
      css: "h3",
      html: "h3"
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link"
    },
    ".bl-react-select-wrapper": {
      css: ".bl-react-select-wrapper",
      html: "bl-react-select-wrapper",
      attrs: {
        ".button-select": {
          css: ".button-select",
          html: "button-select"
        }
      }
    },
    ".icon-circle-title": {
      css: ".icon-circle-title",
      html: "icon-circle-title",
      attrs: {
        ".small": {
          css: ".small",
          html: "small"
        },
        ".warning": {
          css: ".warning",
          html: "warning"
        },
        ".danger": {
          css: ".danger",
          html: "danger"
        },
        ".success": {
          css: ".success",
          html: "success"
        },
        ".disabled": {
          css: ".disabled",
          html: "disabled"
        }
      },
      "p": {
        css: "p",
        html: "p"
      },
      "h3": {
        css: "h3",
        html: "h3"
      }
    },
    ".icon-title-link": {
      css: ".icon-title-link",
      html: "icon-title-link"
    },
    ".title-link": {
      css: ".title-link",
      html: "title-link",
      "h5": {
        css: "h5",
        html: "h5"
      }
    },
    "h2": {
      css: "h2",
      html: "h2"
    },
    ".card-header": {
      css: ".card-header",
      html: "card-header",
      ".title-container": {
        css: ".title-container",
        html: "title-container",
        "h3": {
          css: "h3",
          html: "h3"
        },
        "h4": {
          css: "h4",
          html: "h4",
          attrs: {
            ".warning": {
              css: ".warning",
              html: "warning"
            },
            ".danger": {
              css: ".danger",
              html: "danger"
            },
            ".success": {
              css: ".success",
              html: "success"
            },
            ".disabled": {
              css: ".disabled",
              html: "disabled"
            }
          }
        }
      }
    }
  },
  ".subheader-draft": {
    css: ".subheader-draft",
    html: "subheader-draft",
    attrs: {
      ".header": {
        css: ".header",
        html: "header"
      },
      ".single-text": {
        css: ".single-text",
        html: "single-text"
      }
    },
    "svg": {
      css: "svg",
      html: "svg"
    },
    ".label-container": {
      css: ".label-container",
      html: "label-container",
      "p": {
        css: "p",
        html: "p"
      }
    },
    "h3": {
      css: "h3",
      html: "h3"
    }
  },
  ".subheader-documents": {
    css: ".subheader-documents",
    html: "subheader-documents",
    ".icon-title-link": {
      css: ".icon-title-link",
      html: "icon-title-link"
    }
  },
  ".subheader-with-subtitle": {
    css: ".subheader-with-subtitle",
    html: "subheader-with-subtitle",
    ".header-content-flex": {
      css: ".header-content-flex",
      html: "header-content-flex",
      "button": {
        css: "button",
        html: "button"
      }
    },
    ".icon-title-link": {
      css: ".icon-title-link",
      html: "icon-title-link"
    }
  },
  ".subheader-deal-details": {
    css: ".subheader-deal-details",
    html: "subheader-deal-details",
    ".deal-details-button-container": {
      css: ".deal-details-button-container",
      html: "deal-details-button-container"
    }
  }
};
