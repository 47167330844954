import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const about = {
  _tag: `About`,
  id: 1,
  machineName: `about`,
  typeName: `about`
} as const;

export type AboutTaggedC = t.TypeC<{
  _tag: t.LiteralC<`About`>
}>;
export const aboutTaggedC: AboutTaggedC = t.type({
  _tag: t.literal(`About`)
});
export type AboutTagged = t.TypeOf<AboutTaggedC>;
export type About = AboutTagged & typeof about;
export type AboutC = t.Type<About, AboutTagged>;
export const aboutC: AboutC = pipe(aboutTaggedC, c => new t.Type<About, AboutTagged>(
  `About`,
  (u: unknown): u is About => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, About> => pipe(c.decode(u), E.map(x => ({ ...x, ...about }))),
  (x: About): AboutTagged => ({ ...x, _tag: `About`}),
)) satisfies t.Type<About, unknown>;


export const welcome = {
  _tag: `Welcome`,
  id: 3,
  machineName: `welcome`,
  typeName: `welcome`
} as const;

export type WelcomeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Welcome`>
}>;
export const welcomeTaggedC: WelcomeTaggedC = t.type({
  _tag: t.literal(`Welcome`)
});
export type WelcomeTagged = t.TypeOf<WelcomeTaggedC>;
export type Welcome = WelcomeTagged & typeof welcome;
export type WelcomeC = t.Type<Welcome, WelcomeTagged>;
export const welcomeC: WelcomeC = pipe(welcomeTaggedC, c => new t.Type<Welcome, WelcomeTagged>(
  `Welcome`,
  (u: unknown): u is Welcome => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Welcome> => pipe(c.decode(u), E.map(x => ({ ...x, ...welcome }))),
  (x: Welcome): WelcomeTagged => ({ ...x, _tag: `Welcome`}),
)) satisfies t.Type<Welcome, unknown>;


export const offerInfo = {
  _tag: `OfferInfo`,
  id: 6,
  machineName: `offering-info`,
  typeName: `offering_info`
} as const;

export type OfferInfoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferInfo`>
}>;
export const offerInfoTaggedC: OfferInfoTaggedC = t.type({
  _tag: t.literal(`OfferInfo`)
});
export type OfferInfoTagged = t.TypeOf<OfferInfoTaggedC>;
export type OfferInfo = OfferInfoTagged & typeof offerInfo;
export type OfferInfoC = t.Type<OfferInfo, OfferInfoTagged>;
export const offerInfoC: OfferInfoC = pipe(offerInfoTaggedC, c => new t.Type<OfferInfo, OfferInfoTagged>(
  `OfferInfo`,
  (u: unknown): u is OfferInfo => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferInfo> => pipe(c.decode(u), E.map(x => ({ ...x, ...offerInfo }))),
  (x: OfferInfo): OfferInfoTagged => ({ ...x, _tag: `OfferInfo`}),
)) satisfies t.Type<OfferInfo, unknown>;


export const contactUs = {
  _tag: `ContactUs`,
  id: 7,
  machineName: `contact-us`,
  typeName: `contact_us`
} as const;

export type ContactUsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ContactUs`>
}>;
export const contactUsTaggedC: ContactUsTaggedC = t.type({
  _tag: t.literal(`ContactUs`)
});
export type ContactUsTagged = t.TypeOf<ContactUsTaggedC>;
export type ContactUs = ContactUsTagged & typeof contactUs;
export type ContactUsC = t.Type<ContactUs, ContactUsTagged>;
export const contactUsC: ContactUsC = pipe(contactUsTaggedC, c => new t.Type<ContactUs, ContactUsTagged>(
  `ContactUs`,
  (u: unknown): u is ContactUs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ContactUs> => pipe(c.decode(u), E.map(x => ({ ...x, ...contactUs }))),
  (x: ContactUs): ContactUsTagged => ({ ...x, _tag: `ContactUs`}),
)) satisfies t.Type<ContactUs, unknown>;


export const customPage1 = {
  _tag: `CustomPage1`,
  id: 10,
  machineName: `custom-page-1`,
  typeName: `custom_page_1`
} as const;

export type CustomPage1TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage1`>
}>;
export const customPage1TaggedC: CustomPage1TaggedC = t.type({
  _tag: t.literal(`CustomPage1`)
});
export type CustomPage1Tagged = t.TypeOf<CustomPage1TaggedC>;
export type CustomPage1 = CustomPage1Tagged & typeof customPage1;
export type CustomPage1C = t.Type<CustomPage1, CustomPage1Tagged>;
export const customPage1C: CustomPage1C = pipe(customPage1TaggedC, c => new t.Type<CustomPage1, CustomPage1Tagged>(
  `CustomPage1`,
  (u: unknown): u is CustomPage1 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1 }))),
  (x: CustomPage1): CustomPage1Tagged => ({ ...x, _tag: `CustomPage1`}),
)) satisfies t.Type<CustomPage1, unknown>;


export const customPage2 = {
  _tag: `CustomPage2`,
  id: 11,
  machineName: `custom-page-2`,
  typeName: `custom_page_2`
} as const;

export type CustomPage2TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage2`>
}>;
export const customPage2TaggedC: CustomPage2TaggedC = t.type({
  _tag: t.literal(`CustomPage2`)
});
export type CustomPage2Tagged = t.TypeOf<CustomPage2TaggedC>;
export type CustomPage2 = CustomPage2Tagged & typeof customPage2;
export type CustomPage2C = t.Type<CustomPage2, CustomPage2Tagged>;
export const customPage2C: CustomPage2C = pipe(customPage2TaggedC, c => new t.Type<CustomPage2, CustomPage2Tagged>(
  `CustomPage2`,
  (u: unknown): u is CustomPage2 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2 }))),
  (x: CustomPage2): CustomPage2Tagged => ({ ...x, _tag: `CustomPage2`}),
)) satisfies t.Type<CustomPage2, unknown>;


export const customPage3 = {
  _tag: `CustomPage3`,
  id: 12,
  machineName: `custom-page-3`,
  typeName: `custom_page_3`
} as const;

export type CustomPage3TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage3`>
}>;
export const customPage3TaggedC: CustomPage3TaggedC = t.type({
  _tag: t.literal(`CustomPage3`)
});
export type CustomPage3Tagged = t.TypeOf<CustomPage3TaggedC>;
export type CustomPage3 = CustomPage3Tagged & typeof customPage3;
export type CustomPage3C = t.Type<CustomPage3, CustomPage3Tagged>;
export const customPage3C: CustomPage3C = pipe(customPage3TaggedC, c => new t.Type<CustomPage3, CustomPage3Tagged>(
  `CustomPage3`,
  (u: unknown): u is CustomPage3 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3 }))),
  (x: CustomPage3): CustomPage3Tagged => ({ ...x, _tag: `CustomPage3`}),
)) satisfies t.Type<CustomPage3, unknown>;


export const customPage4 = {
  _tag: `CustomPage4`,
  id: 13,
  machineName: `custom-page-4`,
  typeName: `custom_page_4`
} as const;

export type CustomPage4TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage4`>
}>;
export const customPage4TaggedC: CustomPage4TaggedC = t.type({
  _tag: t.literal(`CustomPage4`)
});
export type CustomPage4Tagged = t.TypeOf<CustomPage4TaggedC>;
export type CustomPage4 = CustomPage4Tagged & typeof customPage4;
export type CustomPage4C = t.Type<CustomPage4, CustomPage4Tagged>;
export const customPage4C: CustomPage4C = pipe(customPage4TaggedC, c => new t.Type<CustomPage4, CustomPage4Tagged>(
  `CustomPage4`,
  (u: unknown): u is CustomPage4 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4 }))),
  (x: CustomPage4): CustomPage4Tagged => ({ ...x, _tag: `CustomPage4`}),
)) satisfies t.Type<CustomPage4, unknown>;


export const customPage5 = {
  _tag: `CustomPage5`,
  id: 14,
  machineName: `custom-page-5`,
  typeName: `custom_page_5`
} as const;

export type CustomPage5TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage5`>
}>;
export const customPage5TaggedC: CustomPage5TaggedC = t.type({
  _tag: t.literal(`CustomPage5`)
});
export type CustomPage5Tagged = t.TypeOf<CustomPage5TaggedC>;
export type CustomPage5 = CustomPage5Tagged & typeof customPage5;
export type CustomPage5C = t.Type<CustomPage5, CustomPage5Tagged>;
export const customPage5C: CustomPage5C = pipe(customPage5TaggedC, c => new t.Type<CustomPage5, CustomPage5Tagged>(
  `CustomPage5`,
  (u: unknown): u is CustomPage5 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5 }))),
  (x: CustomPage5): CustomPage5Tagged => ({ ...x, _tag: `CustomPage5`}),
)) satisfies t.Type<CustomPage5, unknown>;


export const issuerPortalTip = {
  _tag: `IssuerPortalTip`,
  id: 15,
  machineName: `portal-tip`,
  typeName: `portal_tip`
} as const;

export type IssuerPortalTipTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerPortalTip`>
}>;
export const issuerPortalTipTaggedC: IssuerPortalTipTaggedC = t.type({
  _tag: t.literal(`IssuerPortalTip`)
});
export type IssuerPortalTipTagged = t.TypeOf<IssuerPortalTipTaggedC>;
export type IssuerPortalTip = IssuerPortalTipTagged & typeof issuerPortalTip;
export type IssuerPortalTipC = t.Type<IssuerPortalTip, IssuerPortalTipTagged>;
export const issuerPortalTipC: IssuerPortalTipC = pipe(issuerPortalTipTaggedC, c => new t.Type<IssuerPortalTip, IssuerPortalTipTagged>(
  `IssuerPortalTip`,
  (u: unknown): u is IssuerPortalTip => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerPortalTip> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerPortalTip }))),
  (x: IssuerPortalTip): IssuerPortalTipTagged => ({ ...x, _tag: `IssuerPortalTip`}),
)) satisfies t.Type<IssuerPortalTip, unknown>;


export const esgOverview = {
  _tag: `EsgOverview`,
  id: 16,
  machineName: `esg-overview`,
  typeName: `esg_overview`
} as const;

export type EsgOverviewTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgOverview`>
}>;
export const esgOverviewTaggedC: EsgOverviewTaggedC = t.type({
  _tag: t.literal(`EsgOverview`)
});
export type EsgOverviewTagged = t.TypeOf<EsgOverviewTaggedC>;
export type EsgOverview = EsgOverviewTagged & typeof esgOverview;
export type EsgOverviewC = t.Type<EsgOverview, EsgOverviewTagged>;
export const esgOverviewC: EsgOverviewC = pipe(esgOverviewTaggedC, c => new t.Type<EsgOverview, EsgOverviewTagged>(
  `EsgOverview`,
  (u: unknown): u is EsgOverview => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgOverview> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgOverview }))),
  (x: EsgOverview): EsgOverviewTagged => ({ ...x, _tag: `EsgOverview`}),
)) satisfies t.Type<EsgOverview, unknown>;


export const allClientTextTypeC = [aboutC, welcomeC, offerInfoC, contactUsC, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, issuerPortalTipC, esgOverviewC] as const;
export const allClientTextTypeNames = [`About`, `Welcome`, `OfferInfo`, `ContactUs`, `CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `IssuerPortalTip`, `EsgOverview`] as const;
export type ClientTextTypeName = (typeof allClientTextTypeNames)[number];

export type ClientTextTypeCU = t.UnionC<[AboutC, WelcomeC, OfferInfoC, ContactUsC, CustomPage1C, CustomPage2C, CustomPage3C, CustomPage4C, CustomPage5C, IssuerPortalTipC, EsgOverviewC]>;
export type ClientTextTypeU = About | Welcome | OfferInfo | ContactUs | CustomPage1 | CustomPage2 | CustomPage3 | CustomPage4 | CustomPage5 | IssuerPortalTip | EsgOverview;
export const ClientTextTypeCU: ClientTextTypeCU = t.union([aboutC, welcomeC, offerInfoC, contactUsC, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, issuerPortalTipC, esgOverviewC]) satisfies t.Type<ClientTextTypeU, unknown>;

export const clientTextTypeOrd: Ord.Ord<ClientTextTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allClientTextType = [about, welcome, offerInfo, contactUs, customPage1, customPage2, customPage3, customPage4, customPage5, issuerPortalTip, esgOverview] as const;
export type ClientTextTypeMap<A> = { [K in ClientTextTypeName]: A };


