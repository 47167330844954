import { OnboardingStepCU as imported3_OnboardingStepCU, OnboardingStepU as imported1_OnboardingStepU, OnboardingStepCU as imported2_OnboardingStepCU } from "../domaintables/onboardingSteps";
import * as t from "io-ts";

export type OnboardingStepStatusC = t.TypeC<{
  onboardingStep: imported3_OnboardingStepCU,
  completed: t.BooleanC
}>;
export type OnboardingStepStatus = {
  onboardingStep: imported1_OnboardingStepU,
  completed: boolean
};
export const onboardingStepStatusC: OnboardingStepStatusC = t.type({
  onboardingStep: imported2_OnboardingStepCU,
  completed: t.boolean
}) satisfies t.Type<OnboardingStepStatus, unknown>;


