import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const admin = {
  _tag: `Admin`,
  id: 1,
  role: `Admin`
} as const;

export type AdminTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Admin`>
}>;
export const adminTaggedC: AdminTaggedC = t.type({
  _tag: t.literal(`Admin`)
});
export type AdminTagged = t.TypeOf<AdminTaggedC>;
export type Admin = AdminTagged & typeof admin;
export type AdminC = t.Type<Admin, AdminTagged>;
export const adminC: AdminC = pipe(adminTaggedC, c => new t.Type<Admin, AdminTagged>(
  `Admin`,
  (u: unknown): u is Admin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Admin> => pipe(c.decode(u), E.map(x => ({ ...x, ...admin }))),
  (x: Admin): AdminTagged => ({ ...x, _tag: `Admin`}),
)) satisfies t.Type<Admin, unknown>;


export const salesAdmin = {
  _tag: `SalesAdmin`,
  id: 2,
  role: `SalesAdmin`
} as const;

export type SalesAdminTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SalesAdmin`>
}>;
export const salesAdminTaggedC: SalesAdminTaggedC = t.type({
  _tag: t.literal(`SalesAdmin`)
});
export type SalesAdminTagged = t.TypeOf<SalesAdminTaggedC>;
export type SalesAdmin = SalesAdminTagged & typeof salesAdmin;
export type SalesAdminC = t.Type<SalesAdmin, SalesAdminTagged>;
export const salesAdminC: SalesAdminC = pipe(salesAdminTaggedC, c => new t.Type<SalesAdmin, SalesAdminTagged>(
  `SalesAdmin`,
  (u: unknown): u is SalesAdmin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SalesAdmin> => pipe(c.decode(u), E.map(x => ({ ...x, ...salesAdmin }))),
  (x: SalesAdmin): SalesAdminTagged => ({ ...x, _tag: `SalesAdmin`}),
)) satisfies t.Type<SalesAdmin, unknown>;


export const issuerAdmin = {
  _tag: `IssuerAdmin`,
  id: 3,
  role: `IssuerAdmin`
} as const;

export type IssuerAdminTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerAdmin`>
}>;
export const issuerAdminTaggedC: IssuerAdminTaggedC = t.type({
  _tag: t.literal(`IssuerAdmin`)
});
export type IssuerAdminTagged = t.TypeOf<IssuerAdminTaggedC>;
export type IssuerAdmin = IssuerAdminTagged & typeof issuerAdmin;
export type IssuerAdminC = t.Type<IssuerAdmin, IssuerAdminTagged>;
export const issuerAdminC: IssuerAdminC = pipe(issuerAdminTaggedC, c => new t.Type<IssuerAdmin, IssuerAdminTagged>(
  `IssuerAdmin`,
  (u: unknown): u is IssuerAdmin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerAdmin> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerAdmin }))),
  (x: IssuerAdmin): IssuerAdminTagged => ({ ...x, _tag: `IssuerAdmin`}),
)) satisfies t.Type<IssuerAdmin, unknown>;


export const issuerUser = {
  _tag: `IssuerUser`,
  id: 4,
  role: `IssuerUser`
} as const;

export type IssuerUserTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerUser`>
}>;
export const issuerUserTaggedC: IssuerUserTaggedC = t.type({
  _tag: t.literal(`IssuerUser`)
});
export type IssuerUserTagged = t.TypeOf<IssuerUserTaggedC>;
export type IssuerUser = IssuerUserTagged & typeof issuerUser;
export type IssuerUserC = t.Type<IssuerUser, IssuerUserTagged>;
export const issuerUserC: IssuerUserC = pipe(issuerUserTaggedC, c => new t.Type<IssuerUser, IssuerUserTagged>(
  `IssuerUser`,
  (u: unknown): u is IssuerUser => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerUser> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerUser }))),
  (x: IssuerUser): IssuerUserTagged => ({ ...x, _tag: `IssuerUser`}),
)) satisfies t.Type<IssuerUser, unknown>;


export const issuerPreviewer = {
  _tag: `IssuerPreviewer`,
  id: 9,
  role: `IssuerPreviewer`
} as const;

export type IssuerPreviewerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerPreviewer`>
}>;
export const issuerPreviewerTaggedC: IssuerPreviewerTaggedC = t.type({
  _tag: t.literal(`IssuerPreviewer`)
});
export type IssuerPreviewerTagged = t.TypeOf<IssuerPreviewerTaggedC>;
export type IssuerPreviewer = IssuerPreviewerTagged & typeof issuerPreviewer;
export type IssuerPreviewerC = t.Type<IssuerPreviewer, IssuerPreviewerTagged>;
export const issuerPreviewerC: IssuerPreviewerC = pipe(issuerPreviewerTaggedC, c => new t.Type<IssuerPreviewer, IssuerPreviewerTagged>(
  `IssuerPreviewer`,
  (u: unknown): u is IssuerPreviewer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerPreviewer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerPreviewer }))),
  (x: IssuerPreviewer): IssuerPreviewerTagged => ({ ...x, _tag: `IssuerPreviewer`}),
)) satisfies t.Type<IssuerPreviewer, unknown>;


export const bankerAdmin = {
  _tag: `BankerAdmin`,
  id: 7,
  role: `BankerAdmin`
} as const;

export type BankerAdminTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankerAdmin`>
}>;
export const bankerAdminTaggedC: BankerAdminTaggedC = t.type({
  _tag: t.literal(`BankerAdmin`)
});
export type BankerAdminTagged = t.TypeOf<BankerAdminTaggedC>;
export type BankerAdmin = BankerAdminTagged & typeof bankerAdmin;
export type BankerAdminC = t.Type<BankerAdmin, BankerAdminTagged>;
export const bankerAdminC: BankerAdminC = pipe(bankerAdminTaggedC, c => new t.Type<BankerAdmin, BankerAdminTagged>(
  `BankerAdmin`,
  (u: unknown): u is BankerAdmin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankerAdmin> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankerAdmin }))),
  (x: BankerAdmin): BankerAdminTagged => ({ ...x, _tag: `BankerAdmin`}),
)) satisfies t.Type<BankerAdmin, unknown>;


export const bankerUser = {
  _tag: `BankerUser`,
  id: 8,
  role: `BankerUser`
} as const;

export type BankerUserTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankerUser`>
}>;
export const bankerUserTaggedC: BankerUserTaggedC = t.type({
  _tag: t.literal(`BankerUser`)
});
export type BankerUserTagged = t.TypeOf<BankerUserTaggedC>;
export type BankerUser = BankerUserTagged & typeof bankerUser;
export type BankerUserC = t.Type<BankerUser, BankerUserTagged>;
export const bankerUserC: BankerUserC = pipe(bankerUserTaggedC, c => new t.Type<BankerUser, BankerUserTagged>(
  `BankerUser`,
  (u: unknown): u is BankerUser => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankerUser> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankerUser }))),
  (x: BankerUser): BankerUserTagged => ({ ...x, _tag: `BankerUser`}),
)) satisfies t.Type<BankerUser, unknown>;


export const bankerPreviewer = {
  _tag: `BankerPreviewer`,
  id: 10,
  role: `BankerPreviewer`
} as const;

export type BankerPreviewerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankerPreviewer`>
}>;
export const bankerPreviewerTaggedC: BankerPreviewerTaggedC = t.type({
  _tag: t.literal(`BankerPreviewer`)
});
export type BankerPreviewerTagged = t.TypeOf<BankerPreviewerTaggedC>;
export type BankerPreviewer = BankerPreviewerTagged & typeof bankerPreviewer;
export type BankerPreviewerC = t.Type<BankerPreviewer, BankerPreviewerTagged>;
export const bankerPreviewerC: BankerPreviewerC = pipe(bankerPreviewerTaggedC, c => new t.Type<BankerPreviewer, BankerPreviewerTagged>(
  `BankerPreviewer`,
  (u: unknown): u is BankerPreviewer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankerPreviewer> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankerPreviewer }))),
  (x: BankerPreviewer): BankerPreviewerTagged => ({ ...x, _tag: `BankerPreviewer`}),
)) satisfies t.Type<BankerPreviewer, unknown>;


export const member = {
  _tag: `Member`,
  id: 5,
  role: `Deprecated`
} as const;

export type MemberTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Member`>
}>;
export const memberTaggedC: MemberTaggedC = t.type({
  _tag: t.literal(`Member`)
});
export type MemberTagged = t.TypeOf<MemberTaggedC>;
export type Member = MemberTagged & typeof member;
export type MemberC = t.Type<Member, MemberTagged>;
export const memberC: MemberC = pipe(memberTaggedC, c => new t.Type<Member, MemberTagged>(
  `Member`,
  (u: unknown): u is Member => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Member> => pipe(c.decode(u), E.map(x => ({ ...x, ...member }))),
  (x: Member): MemberTagged => ({ ...x, _tag: `Member`}),
)) satisfies t.Type<Member, unknown>;


export const bondlinkUser = {
  _tag: `BondlinkUser`,
  id: 6,
  role: `Deprecated`
} as const;

export type BondlinkUserTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondlinkUser`>
}>;
export const bondlinkUserTaggedC: BondlinkUserTaggedC = t.type({
  _tag: t.literal(`BondlinkUser`)
});
export type BondlinkUserTagged = t.TypeOf<BondlinkUserTaggedC>;
export type BondlinkUser = BondlinkUserTagged & typeof bondlinkUser;
export type BondlinkUserC = t.Type<BondlinkUser, BondlinkUserTagged>;
export const bondlinkUserC: BondlinkUserC = pipe(bondlinkUserTaggedC, c => new t.Type<BondlinkUser, BondlinkUserTagged>(
  `BondlinkUser`,
  (u: unknown): u is BondlinkUser => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondlinkUser> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondlinkUser }))),
  (x: BondlinkUser): BondlinkUserTagged => ({ ...x, _tag: `BondlinkUser`}),
)) satisfies t.Type<BondlinkUser, unknown>;


export const allRoleC = [adminC, salesAdminC, issuerAdminC, issuerUserC, issuerPreviewerC, bankerAdminC, bankerUserC, bankerPreviewerC, memberC, bondlinkUserC] as const;
export const allRoleNames = [`Admin`, `SalesAdmin`, `IssuerAdmin`, `IssuerUser`, `IssuerPreviewer`, `BankerAdmin`, `BankerUser`, `BankerPreviewer`, `Member`, `BondlinkUser`] as const;
export type RoleName = (typeof allRoleNames)[number];

export type RoleCU = t.UnionC<[AdminC, SalesAdminC, IssuerAdminC, IssuerUserC, IssuerPreviewerC, BankerAdminC, BankerUserC, BankerPreviewerC, MemberC, BondlinkUserC]>;
export type RoleU = Admin | SalesAdmin | IssuerAdmin | IssuerUser | IssuerPreviewer | BankerAdmin | BankerUser | BankerPreviewer | Member | BondlinkUser;
export const RoleCU: RoleCU = t.union([adminC, salesAdminC, issuerAdminC, issuerUserC, issuerPreviewerC, bankerAdminC, bankerUserC, bankerPreviewerC, memberC, bondlinkUserC]) satisfies t.Type<RoleU, unknown>;

export const roleOrd: Ord.Ord<RoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRole = [admin, salesAdmin, issuerAdmin, issuerUser, issuerPreviewer, bankerAdmin, bankerUser, bankerPreviewer, member, bondlinkUser] as const;
export type RoleMap<A> = { [K in RoleName]: A };


export const allBondLinkRoleC = [adminC, salesAdminC] as const;
export const allBondLinkRoleNames = [`Admin`, `SalesAdmin`] as const;
export type BondLinkRoleName = (typeof allBondLinkRoleNames)[number];

export type BondLinkRoleCU = t.UnionC<[AdminC, SalesAdminC]>;
export type BondLinkRoleU = Admin | SalesAdmin;
export const BondLinkRoleCU: BondLinkRoleCU = t.union([adminC, salesAdminC]) satisfies t.Type<BondLinkRoleU, unknown>;

export const bondLinkRoleOrd: Ord.Ord<BondLinkRoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkRole = [admin, salesAdmin] as const;
export type BondLinkRoleMap<A> = { [K in BondLinkRoleName]: A };


export const allIssuerRoleC = [issuerAdminC, issuerUserC, issuerPreviewerC] as const;
export const allIssuerRoleNames = [`IssuerAdmin`, `IssuerUser`, `IssuerPreviewer`] as const;
export type IssuerRoleName = (typeof allIssuerRoleNames)[number];

export type IssuerRoleCU = t.UnionC<[IssuerAdminC, IssuerUserC, IssuerPreviewerC]>;
export type IssuerRoleU = IssuerAdmin | IssuerUser | IssuerPreviewer;
export const IssuerRoleCU: IssuerRoleCU = t.union([issuerAdminC, issuerUserC, issuerPreviewerC]) satisfies t.Type<IssuerRoleU, unknown>;

export const issuerRoleOrd: Ord.Ord<IssuerRoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerRole = [issuerAdmin, issuerUser, issuerPreviewer] as const;
export type IssuerRoleMap<A> = { [K in IssuerRoleName]: A };


export const allBankerRoleC = [bankerAdminC, bankerUserC, bankerPreviewerC] as const;
export const allBankerRoleNames = [`BankerAdmin`, `BankerUser`, `BankerPreviewer`] as const;
export type BankerRoleName = (typeof allBankerRoleNames)[number];

export type BankerRoleCU = t.UnionC<[BankerAdminC, BankerUserC, BankerPreviewerC]>;
export type BankerRoleU = BankerAdmin | BankerUser | BankerPreviewer;
export const BankerRoleCU: BankerRoleCU = t.union([bankerAdminC, bankerUserC, bankerPreviewerC]) satisfies t.Type<BankerRoleU, unknown>;

export const bankerRoleOrd: Ord.Ord<BankerRoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBankerRole = [bankerAdmin, bankerUser, bankerPreviewer] as const;
export type BankerRoleMap<A> = { [K in BankerRoleName]: A };


