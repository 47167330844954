import { flow, pipe } from "fp-ts/lib/function";
import * as t from "io-ts";

import type { RNEA } from "@scripts/fp-ts";
import { E, RA } from "@scripts/fp-ts";
import { allInvestorFocus, InvestorFocusCU } from "@scripts/generated/domaintables/investorFocuses";

import { tagLookupC } from "./tagLookup";

export const noProductFocus = {
  id: -1,
  name: "No Product Focus Listed",
  _tag: "noProductFocus",
} as const;

const noProductFocusTaggedC = t.type({
  _tag: t.literal(`noProductFocus`),
});
type NoProductFocusTaggedC = typeof noProductFocusTaggedC;
type NoProductFocusTagged = t.TypeOf<NoProductFocusTaggedC>;
type NoProductFocus = NoProductFocusTagged & typeof noProductFocus;
const noProductFocusC = pipe(noProductFocusTaggedC, c => new t.Type<NoProductFocus, NoProductFocusTagged>(
  "ProductFocus noProductFocus",
  (u: unknown): u is NoProductFocus => E.isRight(c.decode(u)),
  flow(c.decode, E.map(x => ({ ...x, ...noProductFocus }))),
  (x: NoProductFocus): NoProductFocusTagged => ({ ...x, _tag: "noProductFocus" })
));


const allProductFocusCU = t.union([InvestorFocusCU, noProductFocusC]);
export type AllProductFocusU = t.TypeOf<typeof allProductFocusCU>;

export const allProductFocuses: RNEA.ReadonlyNonEmptyArray<AllProductFocusU> = RA.append<AllProductFocusU>(noProductFocus)(allInvestorFocus);

export const allProductFocusLookupC = tagLookupC(allProductFocusCU);
