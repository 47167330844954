import { UserC as imported17_UserC, User as imported17_User, userC as imported17_userC } from "./user";
import * as t from "io-ts";

export type IssuerAdminUserC = t.TypeC<{
  user: imported17_UserC,
  draftNotifications: t.BooleanC
}>;
export type IssuerAdminUser = {
  user: imported17_User,
  draftNotifications: boolean
};
export const issuerAdminUserC: IssuerAdminUserC = t.type({
  user: imported17_userC,
  draftNotifications: t.boolean
}) satisfies t.Type<IssuerAdminUser, unknown>;


