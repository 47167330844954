import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const eTMByMuniForward = {
  _tag: `ETMByMuniForward`,
  id: 1,
  name: `ETM by muni-forward`
} as const;

export type ETMByMuniForwardTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ETMByMuniForward`>
}>;
export const eTMByMuniForwardTaggedC: ETMByMuniForwardTaggedC = t.type({
  _tag: t.literal(`ETMByMuniForward`)
});
export type ETMByMuniForwardTagged = t.TypeOf<ETMByMuniForwardTaggedC>;
export type ETMByMuniForward = ETMByMuniForwardTagged & typeof eTMByMuniForward;
export type ETMByMuniForwardC = t.Type<ETMByMuniForward, ETMByMuniForwardTagged>;
export const eTMByMuniForwardC: ETMByMuniForwardC = pipe(eTMByMuniForwardTaggedC, c => new t.Type<ETMByMuniForward, ETMByMuniForwardTagged>(
  `ETMByMuniForward`,
  (u: unknown): u is ETMByMuniForward => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ETMByMuniForward> => pipe(c.decode(u), E.map(x => ({ ...x, ...eTMByMuniForward }))),
  (x: ETMByMuniForward): ETMByMuniForwardTagged => ({ ...x, _tag: `ETMByMuniForward`}),
)) satisfies t.Type<ETMByMuniForward, unknown>;


export const preRefundedByMuniForward = {
  _tag: `PreRefundedByMuniForward`,
  id: 2,
  name: `Pre-refunded by muni-forward`
} as const;

export type PreRefundedByMuniForwardTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PreRefundedByMuniForward`>
}>;
export const preRefundedByMuniForwardTaggedC: PreRefundedByMuniForwardTaggedC = t.type({
  _tag: t.literal(`PreRefundedByMuniForward`)
});
export type PreRefundedByMuniForwardTagged = t.TypeOf<PreRefundedByMuniForwardTaggedC>;
export type PreRefundedByMuniForward = PreRefundedByMuniForwardTagged & typeof preRefundedByMuniForward;
export type PreRefundedByMuniForwardC = t.Type<PreRefundedByMuniForward, PreRefundedByMuniForwardTagged>;
export const preRefundedByMuniForwardC: PreRefundedByMuniForwardC = pipe(preRefundedByMuniForwardTaggedC, c => new t.Type<PreRefundedByMuniForward, PreRefundedByMuniForwardTagged>(
  `PreRefundedByMuniForward`,
  (u: unknown): u is PreRefundedByMuniForward => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefundedByMuniForward> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefundedByMuniForward }))),
  (x: PreRefundedByMuniForward): PreRefundedByMuniForwardTagged => ({ ...x, _tag: `PreRefundedByMuniForward`}),
)) satisfies t.Type<PreRefundedByMuniForward, unknown>;


export const called = {
  _tag: `Called`,
  id: 3,
  name: `Called`
} as const;

export type CalledTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Called`>
}>;
export const calledTaggedC: CalledTaggedC = t.type({
  _tag: t.literal(`Called`)
});
export type CalledTagged = t.TypeOf<CalledTaggedC>;
export type Called = CalledTagged & typeof called;
export type CalledC = t.Type<Called, CalledTagged>;
export const calledC: CalledC = pipe(calledTaggedC, c => new t.Type<Called, CalledTagged>(
  `Called`,
  (u: unknown): u is Called => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Called> => pipe(c.decode(u), E.map(x => ({ ...x, ...called }))),
  (x: Called): CalledTagged => ({ ...x, _tag: `Called`}),
)) satisfies t.Type<Called, unknown>;


export const calledDueToDefault = {
  _tag: `CalledDueToDefault`,
  id: 4,
  name: `Called due to default`
} as const;

export type CalledDueToDefaultTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CalledDueToDefault`>
}>;
export const calledDueToDefaultTaggedC: CalledDueToDefaultTaggedC = t.type({
  _tag: t.literal(`CalledDueToDefault`)
});
export type CalledDueToDefaultTagged = t.TypeOf<CalledDueToDefaultTaggedC>;
export type CalledDueToDefault = CalledDueToDefaultTagged & typeof calledDueToDefault;
export type CalledDueToDefaultC = t.Type<CalledDueToDefault, CalledDueToDefaultTagged>;
export const calledDueToDefaultC: CalledDueToDefaultC = pipe(calledDueToDefaultTaggedC, c => new t.Type<CalledDueToDefault, CalledDueToDefaultTagged>(
  `CalledDueToDefault`,
  (u: unknown): u is CalledDueToDefault => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CalledDueToDefault> => pipe(c.decode(u), E.map(x => ({ ...x, ...calledDueToDefault }))),
  (x: CalledDueToDefault): CalledDueToDefaultTagged => ({ ...x, _tag: `CalledDueToDefault`}),
)) satisfies t.Type<CalledDueToDefault, unknown>;


export const escrowedToMaturity = {
  _tag: `EscrowedToMaturity`,
  id: 5,
  name: `Escrowed to maturity`
} as const;

export type EscrowedToMaturityTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EscrowedToMaturity`>
}>;
export const escrowedToMaturityTaggedC: EscrowedToMaturityTaggedC = t.type({
  _tag: t.literal(`EscrowedToMaturity`)
});
export type EscrowedToMaturityTagged = t.TypeOf<EscrowedToMaturityTaggedC>;
export type EscrowedToMaturity = EscrowedToMaturityTagged & typeof escrowedToMaturity;
export type EscrowedToMaturityC = t.Type<EscrowedToMaturity, EscrowedToMaturityTagged>;
export const escrowedToMaturityC: EscrowedToMaturityC = pipe(escrowedToMaturityTaggedC, c => new t.Type<EscrowedToMaturity, EscrowedToMaturityTagged>(
  `EscrowedToMaturity`,
  (u: unknown): u is EscrowedToMaturity => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedToMaturity> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedToMaturity }))),
  (x: EscrowedToMaturity): EscrowedToMaturityTagged => ({ ...x, _tag: `EscrowedToMaturity`}),
)) satisfies t.Type<EscrowedToMaturity, unknown>;


export const calledByMuniForward = {
  _tag: `CalledByMuniForward`,
  id: 6,
  name: `Called by muni-forward`
} as const;

export type CalledByMuniForwardTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CalledByMuniForward`>
}>;
export const calledByMuniForwardTaggedC: CalledByMuniForwardTaggedC = t.type({
  _tag: t.literal(`CalledByMuniForward`)
});
export type CalledByMuniForwardTagged = t.TypeOf<CalledByMuniForwardTaggedC>;
export type CalledByMuniForward = CalledByMuniForwardTagged & typeof calledByMuniForward;
export type CalledByMuniForwardC = t.Type<CalledByMuniForward, CalledByMuniForwardTagged>;
export const calledByMuniForwardC: CalledByMuniForwardC = pipe(calledByMuniForwardTaggedC, c => new t.Type<CalledByMuniForward, CalledByMuniForwardTagged>(
  `CalledByMuniForward`,
  (u: unknown): u is CalledByMuniForward => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CalledByMuniForward> => pipe(c.decode(u), E.map(x => ({ ...x, ...calledByMuniForward }))),
  (x: CalledByMuniForward): CalledByMuniForwardTagged => ({ ...x, _tag: `CalledByMuniForward`}),
)) satisfies t.Type<CalledByMuniForward, unknown>;


export const repayment = {
  _tag: `Repayment`,
  id: 7,
  name: `Repayment`
} as const;

export type RepaymentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Repayment`>
}>;
export const repaymentTaggedC: RepaymentTaggedC = t.type({
  _tag: t.literal(`Repayment`)
});
export type RepaymentTagged = t.TypeOf<RepaymentTaggedC>;
export type Repayment = RepaymentTagged & typeof repayment;
export type RepaymentC = t.Type<Repayment, RepaymentTagged>;
export const repaymentC: RepaymentC = pipe(repaymentTaggedC, c => new t.Type<Repayment, RepaymentTagged>(
  `Repayment`,
  (u: unknown): u is Repayment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Repayment> => pipe(c.decode(u), E.map(x => ({ ...x, ...repayment }))),
  (x: Repayment): RepaymentTagged => ({ ...x, _tag: `Repayment`}),
)) satisfies t.Type<Repayment, unknown>;


export const escrowedAsToInterestOnly = {
  _tag: `EscrowedAsToInterestOnly`,
  id: 8,
  name: `Escrowed as to interest only`
} as const;

export type EscrowedAsToInterestOnlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EscrowedAsToInterestOnly`>
}>;
export const escrowedAsToInterestOnlyTaggedC: EscrowedAsToInterestOnlyTaggedC = t.type({
  _tag: t.literal(`EscrowedAsToInterestOnly`)
});
export type EscrowedAsToInterestOnlyTagged = t.TypeOf<EscrowedAsToInterestOnlyTaggedC>;
export type EscrowedAsToInterestOnly = EscrowedAsToInterestOnlyTagged & typeof escrowedAsToInterestOnly;
export type EscrowedAsToInterestOnlyC = t.Type<EscrowedAsToInterestOnly, EscrowedAsToInterestOnlyTagged>;
export const escrowedAsToInterestOnlyC: EscrowedAsToInterestOnlyC = pipe(escrowedAsToInterestOnlyTaggedC, c => new t.Type<EscrowedAsToInterestOnly, EscrowedAsToInterestOnlyTagged>(
  `EscrowedAsToInterestOnly`,
  (u: unknown): u is EscrowedAsToInterestOnly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedAsToInterestOnly> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedAsToInterestOnly }))),
  (x: EscrowedAsToInterestOnly): EscrowedAsToInterestOnlyTagged => ({ ...x, _tag: `EscrowedAsToInterestOnly`}),
)) satisfies t.Type<EscrowedAsToInterestOnly, unknown>;


export const escrowedAsToPrincipalOnly = {
  _tag: `EscrowedAsToPrincipalOnly`,
  id: 9,
  name: `Escrowed as to principal only`
} as const;

export type EscrowedAsToPrincipalOnlyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EscrowedAsToPrincipalOnly`>
}>;
export const escrowedAsToPrincipalOnlyTaggedC: EscrowedAsToPrincipalOnlyTaggedC = t.type({
  _tag: t.literal(`EscrowedAsToPrincipalOnly`)
});
export type EscrowedAsToPrincipalOnlyTagged = t.TypeOf<EscrowedAsToPrincipalOnlyTaggedC>;
export type EscrowedAsToPrincipalOnly = EscrowedAsToPrincipalOnlyTagged & typeof escrowedAsToPrincipalOnly;
export type EscrowedAsToPrincipalOnlyC = t.Type<EscrowedAsToPrincipalOnly, EscrowedAsToPrincipalOnlyTagged>;
export const escrowedAsToPrincipalOnlyC: EscrowedAsToPrincipalOnlyC = pipe(escrowedAsToPrincipalOnlyTaggedC, c => new t.Type<EscrowedAsToPrincipalOnly, EscrowedAsToPrincipalOnlyTagged>(
  `EscrowedAsToPrincipalOnly`,
  (u: unknown): u is EscrowedAsToPrincipalOnly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedAsToPrincipalOnly> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedAsToPrincipalOnly }))),
  (x: EscrowedAsToPrincipalOnly): EscrowedAsToPrincipalOnlyTagged => ({ ...x, _tag: `EscrowedAsToPrincipalOnly`}),
)) satisfies t.Type<EscrowedAsToPrincipalOnly, unknown>;


export const preRefundedPendingSale = {
  _tag: `PreRefundedPendingSale`,
  id: 10,
  name: `Pre-refunded pending sale`
} as const;

export type PreRefundedPendingSaleTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PreRefundedPendingSale`>
}>;
export const preRefundedPendingSaleTaggedC: PreRefundedPendingSaleTaggedC = t.type({
  _tag: t.literal(`PreRefundedPendingSale`)
});
export type PreRefundedPendingSaleTagged = t.TypeOf<PreRefundedPendingSaleTaggedC>;
export type PreRefundedPendingSale = PreRefundedPendingSaleTagged & typeof preRefundedPendingSale;
export type PreRefundedPendingSaleC = t.Type<PreRefundedPendingSale, PreRefundedPendingSaleTagged>;
export const preRefundedPendingSaleC: PreRefundedPendingSaleC = pipe(preRefundedPendingSaleTaggedC, c => new t.Type<PreRefundedPendingSale, PreRefundedPendingSaleTagged>(
  `PreRefundedPendingSale`,
  (u: unknown): u is PreRefundedPendingSale => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefundedPendingSale> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefundedPendingSale }))),
  (x: PreRefundedPendingSale): PreRefundedPendingSaleTagged => ({ ...x, _tag: `PreRefundedPendingSale`}),
)) satisfies t.Type<PreRefundedPendingSale, unknown>;


export const eTMPendingSale = {
  _tag: `ETMPendingSale`,
  id: 11,
  name: `ETM pending sale`
} as const;

export type ETMPendingSaleTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ETMPendingSale`>
}>;
export const eTMPendingSaleTaggedC: ETMPendingSaleTaggedC = t.type({
  _tag: t.literal(`ETMPendingSale`)
});
export type ETMPendingSaleTagged = t.TypeOf<ETMPendingSaleTaggedC>;
export type ETMPendingSale = ETMPendingSaleTagged & typeof eTMPendingSale;
export type ETMPendingSaleC = t.Type<ETMPendingSale, ETMPendingSaleTagged>;
export const eTMPendingSaleC: ETMPendingSaleC = pipe(eTMPendingSaleTaggedC, c => new t.Type<ETMPendingSale, ETMPendingSaleTagged>(
  `ETMPendingSale`,
  (u: unknown): u is ETMPendingSale => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ETMPendingSale> => pipe(c.decode(u), E.map(x => ({ ...x, ...eTMPendingSale }))),
  (x: ETMPendingSale): ETMPendingSaleTagged => ({ ...x, _tag: `ETMPendingSale`}),
)) satisfies t.Type<ETMPendingSale, unknown>;


export const PARTIALCALLCODENOLONGERINUSE = {
  _tag: `PARTIALCALLCODENOLONGERINUSE`,
  id: 12,
  name: `PARTIAL CALL - CODE NO LONGER IN USE`
} as const;

export type PARTIALCALLCODENOLONGERINUSETaggedC = t.TypeC<{
  _tag: t.LiteralC<`PARTIALCALLCODENOLONGERINUSE`>
}>;
export const PARTIALCALLCODENOLONGERINUSETaggedC: PARTIALCALLCODENOLONGERINUSETaggedC = t.type({
  _tag: t.literal(`PARTIALCALLCODENOLONGERINUSE`)
});
export type PARTIALCALLCODENOLONGERINUSETagged = t.TypeOf<PARTIALCALLCODENOLONGERINUSETaggedC>;
export type PARTIALCALLCODENOLONGERINUSE = PARTIALCALLCODENOLONGERINUSETagged & typeof PARTIALCALLCODENOLONGERINUSE;
export type PARTIALCALLCODENOLONGERINUSEC = t.Type<PARTIALCALLCODENOLONGERINUSE, PARTIALCALLCODENOLONGERINUSETagged>;
export const PARTIALCALLCODENOLONGERINUSEC: PARTIALCALLCODENOLONGERINUSEC = pipe(PARTIALCALLCODENOLONGERINUSETaggedC, c => new t.Type<PARTIALCALLCODENOLONGERINUSE, PARTIALCALLCODENOLONGERINUSETagged>(
  `PARTIALCALLCODENOLONGERINUSE`,
  (u: unknown): u is PARTIALCALLCODENOLONGERINUSE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PARTIALCALLCODENOLONGERINUSE> => pipe(c.decode(u), E.map(x => ({ ...x, ...PARTIALCALLCODENOLONGERINUSE }))),
  (x: PARTIALCALLCODENOLONGERINUSE): PARTIALCALLCODENOLONGERINUSETagged => ({ ...x, _tag: `PARTIALCALLCODENOLONGERINUSE`}),
)) satisfies t.Type<PARTIALCALLCODENOLONGERINUSE, unknown>;


export const preRefunded = {
  _tag: `PreRefunded`,
  id: 13,
  name: `Pre-refunded`
} as const;

export type PreRefundedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PreRefunded`>
}>;
export const preRefundedTaggedC: PreRefundedTaggedC = t.type({
  _tag: t.literal(`PreRefunded`)
});
export type PreRefundedTagged = t.TypeOf<PreRefundedTaggedC>;
export type PreRefunded = PreRefundedTagged & typeof preRefunded;
export type PreRefundedC = t.Type<PreRefunded, PreRefundedTagged>;
export const preRefundedC: PreRefundedC = pipe(preRefundedTaggedC, c => new t.Type<PreRefunded, PreRefundedTagged>(
  `PreRefunded`,
  (u: unknown): u is PreRefunded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefunded> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefunded }))),
  (x: PreRefunded): PreRefundedTagged => ({ ...x, _tag: `PreRefunded`}),
)) satisfies t.Type<PreRefunded, unknown>;


export const remarketed = {
  _tag: `Remarketed`,
  id: 14,
  name: `Remarketed`
} as const;

export type RemarketedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Remarketed`>
}>;
export const remarketedTaggedC: RemarketedTaggedC = t.type({
  _tag: t.literal(`Remarketed`)
});
export type RemarketedTagged = t.TypeOf<RemarketedTaggedC>;
export type Remarketed = RemarketedTagged & typeof remarketed;
export type RemarketedC = t.Type<Remarketed, RemarketedTagged>;
export const remarketedC: RemarketedC = pipe(remarketedTaggedC, c => new t.Type<Remarketed, RemarketedTagged>(
  `Remarketed`,
  (u: unknown): u is Remarketed => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Remarketed> => pipe(c.decode(u), E.map(x => ({ ...x, ...remarketed }))),
  (x: Remarketed): RemarketedTagged => ({ ...x, _tag: `Remarketed`}),
)) satisfies t.Type<Remarketed, unknown>;


export const tendered = {
  _tag: `Tendered`,
  id: 15,
  name: `Tendered`
} as const;

export type TenderedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Tendered`>
}>;
export const tenderedTaggedC: TenderedTaggedC = t.type({
  _tag: t.literal(`Tendered`)
});
export type TenderedTagged = t.TypeOf<TenderedTaggedC>;
export type Tendered = TenderedTagged & typeof tendered;
export type TenderedC = t.Type<Tendered, TenderedTagged>;
export const tenderedC: TenderedC = pipe(tenderedTaggedC, c => new t.Type<Tendered, TenderedTagged>(
  `Tendered`,
  (u: unknown): u is Tendered => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Tendered> => pipe(c.decode(u), E.map(x => ({ ...x, ...tendered }))),
  (x: Tendered): TenderedTagged => ({ ...x, _tag: `Tendered`}),
)) satisfies t.Type<Tendered, unknown>;


export const escrowedToConversionDate = {
  _tag: `EscrowedToConversionDate`,
  id: 16,
  name: `Escrowed to conversion date`
} as const;

export type EscrowedToConversionDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EscrowedToConversionDate`>
}>;
export const escrowedToConversionDateTaggedC: EscrowedToConversionDateTaggedC = t.type({
  _tag: t.literal(`EscrowedToConversionDate`)
});
export type EscrowedToConversionDateTagged = t.TypeOf<EscrowedToConversionDateTaggedC>;
export type EscrowedToConversionDate = EscrowedToConversionDateTagged & typeof escrowedToConversionDate;
export type EscrowedToConversionDateC = t.Type<EscrowedToConversionDate, EscrowedToConversionDateTagged>;
export const escrowedToConversionDateC: EscrowedToConversionDateC = pipe(escrowedToConversionDateTaggedC, c => new t.Type<EscrowedToConversionDate, EscrowedToConversionDateTagged>(
  `EscrowedToConversionDate`,
  (u: unknown): u is EscrowedToConversionDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedToConversionDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedToConversionDate }))),
  (x: EscrowedToConversionDate): EscrowedToConversionDateTagged => ({ ...x, _tag: `EscrowedToConversionDate`}),
)) satisfies t.Type<EscrowedToConversionDate, unknown>;


export const crossOverRefunded = {
  _tag: `CrossOverRefunded`,
  id: 17,
  name: `Cross-over refunded`
} as const;

export type CrossOverRefundedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CrossOverRefunded`>
}>;
export const crossOverRefundedTaggedC: CrossOverRefundedTaggedC = t.type({
  _tag: t.literal(`CrossOverRefunded`)
});
export type CrossOverRefundedTagged = t.TypeOf<CrossOverRefundedTaggedC>;
export type CrossOverRefunded = CrossOverRefundedTagged & typeof crossOverRefunded;
export type CrossOverRefundedC = t.Type<CrossOverRefunded, CrossOverRefundedTagged>;
export const crossOverRefundedC: CrossOverRefundedC = pipe(crossOverRefundedTaggedC, c => new t.Type<CrossOverRefunded, CrossOverRefundedTagged>(
  `CrossOverRefunded`,
  (u: unknown): u is CrossOverRefunded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CrossOverRefunded> => pipe(c.decode(u), E.map(x => ({ ...x, ...crossOverRefunded }))),
  (x: CrossOverRefunded): CrossOverRefundedTagged => ({ ...x, _tag: `CrossOverRefunded`}),
)) satisfies t.Type<CrossOverRefunded, unknown>;


export const partiallyPreRefundedSeeNewCUSIPS = {
  _tag: `PartiallyPreRefundedSeeNewCUSIPS`,
  id: 18,
  name: `Partially pre-refunded-see new CUSIPS`
} as const;

export type PartiallyPreRefundedSeeNewCUSIPSTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PartiallyPreRefundedSeeNewCUSIPS`>
}>;
export const partiallyPreRefundedSeeNewCUSIPSTaggedC: PartiallyPreRefundedSeeNewCUSIPSTaggedC = t.type({
  _tag: t.literal(`PartiallyPreRefundedSeeNewCUSIPS`)
});
export type PartiallyPreRefundedSeeNewCUSIPSTagged = t.TypeOf<PartiallyPreRefundedSeeNewCUSIPSTaggedC>;
export type PartiallyPreRefundedSeeNewCUSIPS = PartiallyPreRefundedSeeNewCUSIPSTagged & typeof partiallyPreRefundedSeeNewCUSIPS;
export type PartiallyPreRefundedSeeNewCUSIPSC = t.Type<PartiallyPreRefundedSeeNewCUSIPS, PartiallyPreRefundedSeeNewCUSIPSTagged>;
export const partiallyPreRefundedSeeNewCUSIPSC: PartiallyPreRefundedSeeNewCUSIPSC = pipe(partiallyPreRefundedSeeNewCUSIPSTaggedC, c => new t.Type<PartiallyPreRefundedSeeNewCUSIPS, PartiallyPreRefundedSeeNewCUSIPSTagged>(
  `PartiallyPreRefundedSeeNewCUSIPS`,
  (u: unknown): u is PartiallyPreRefundedSeeNewCUSIPS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PartiallyPreRefundedSeeNewCUSIPS> => pipe(c.decode(u), E.map(x => ({ ...x, ...partiallyPreRefundedSeeNewCUSIPS }))),
  (x: PartiallyPreRefundedSeeNewCUSIPS): PartiallyPreRefundedSeeNewCUSIPSTagged => ({ ...x, _tag: `PartiallyPreRefundedSeeNewCUSIPS`}),
)) satisfies t.Type<PartiallyPreRefundedSeeNewCUSIPS, unknown>;


export const crossOverRefundingEscrowedTill = {
  _tag: `CrossOverRefundingEscrowedTill`,
  id: 19,
  name: `Cross-over refunding - escrowed till`
} as const;

export type CrossOverRefundingEscrowedTillTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CrossOverRefundingEscrowedTill`>
}>;
export const crossOverRefundingEscrowedTillTaggedC: CrossOverRefundingEscrowedTillTaggedC = t.type({
  _tag: t.literal(`CrossOverRefundingEscrowedTill`)
});
export type CrossOverRefundingEscrowedTillTagged = t.TypeOf<CrossOverRefundingEscrowedTillTaggedC>;
export type CrossOverRefundingEscrowedTill = CrossOverRefundingEscrowedTillTagged & typeof crossOverRefundingEscrowedTill;
export type CrossOverRefundingEscrowedTillC = t.Type<CrossOverRefundingEscrowedTill, CrossOverRefundingEscrowedTillTagged>;
export const crossOverRefundingEscrowedTillC: CrossOverRefundingEscrowedTillC = pipe(crossOverRefundingEscrowedTillTaggedC, c => new t.Type<CrossOverRefundingEscrowedTill, CrossOverRefundingEscrowedTillTagged>(
  `CrossOverRefundingEscrowedTill`,
  (u: unknown): u is CrossOverRefundingEscrowedTill => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CrossOverRefundingEscrowedTill> => pipe(c.decode(u), E.map(x => ({ ...x, ...crossOverRefundingEscrowedTill }))),
  (x: CrossOverRefundingEscrowedTill): CrossOverRefundingEscrowedTillTagged => ({ ...x, _tag: `CrossOverRefundingEscrowedTill`}),
)) satisfies t.Type<CrossOverRefundingEscrowedTill, unknown>;


export const allMuniRedemptionTypeC = [eTMByMuniForwardC, preRefundedByMuniForwardC, calledC, calledDueToDefaultC, escrowedToMaturityC, calledByMuniForwardC, repaymentC, escrowedAsToInterestOnlyC, escrowedAsToPrincipalOnlyC, preRefundedPendingSaleC, eTMPendingSaleC, PARTIALCALLCODENOLONGERINUSEC, preRefundedC, remarketedC, tenderedC, escrowedToConversionDateC, crossOverRefundedC, partiallyPreRefundedSeeNewCUSIPSC, crossOverRefundingEscrowedTillC] as const;
export const allMuniRedemptionTypeNames = [`ETMByMuniForward`, `PreRefundedByMuniForward`, `Called`, `CalledDueToDefault`, `EscrowedToMaturity`, `CalledByMuniForward`, `Repayment`, `EscrowedAsToInterestOnly`, `EscrowedAsToPrincipalOnly`, `PreRefundedPendingSale`, `ETMPendingSale`, `PARTIALCALLCODENOLONGERINUSE`, `PreRefunded`, `Remarketed`, `Tendered`, `EscrowedToConversionDate`, `CrossOverRefunded`, `PartiallyPreRefundedSeeNewCUSIPS`, `CrossOverRefundingEscrowedTill`] as const;
export type MuniRedemptionTypeName = (typeof allMuniRedemptionTypeNames)[number];

export type MuniRedemptionTypeCU = t.UnionC<[ETMByMuniForwardC, PreRefundedByMuniForwardC, CalledC, CalledDueToDefaultC, EscrowedToMaturityC, CalledByMuniForwardC, RepaymentC, EscrowedAsToInterestOnlyC, EscrowedAsToPrincipalOnlyC, PreRefundedPendingSaleC, ETMPendingSaleC, PARTIALCALLCODENOLONGERINUSEC, PreRefundedC, RemarketedC, TenderedC, EscrowedToConversionDateC, CrossOverRefundedC, PartiallyPreRefundedSeeNewCUSIPSC, CrossOverRefundingEscrowedTillC]>;
export type MuniRedemptionTypeU = ETMByMuniForward | PreRefundedByMuniForward | Called | CalledDueToDefault | EscrowedToMaturity | CalledByMuniForward | Repayment | EscrowedAsToInterestOnly | EscrowedAsToPrincipalOnly | PreRefundedPendingSale | ETMPendingSale | PARTIALCALLCODENOLONGERINUSE | PreRefunded | Remarketed | Tendered | EscrowedToConversionDate | CrossOverRefunded | PartiallyPreRefundedSeeNewCUSIPS | CrossOverRefundingEscrowedTill;
export const MuniRedemptionTypeCU: MuniRedemptionTypeCU = t.union([eTMByMuniForwardC, preRefundedByMuniForwardC, calledC, calledDueToDefaultC, escrowedToMaturityC, calledByMuniForwardC, repaymentC, escrowedAsToInterestOnlyC, escrowedAsToPrincipalOnlyC, preRefundedPendingSaleC, eTMPendingSaleC, PARTIALCALLCODENOLONGERINUSEC, preRefundedC, remarketedC, tenderedC, escrowedToConversionDateC, crossOverRefundedC, partiallyPreRefundedSeeNewCUSIPSC, crossOverRefundingEscrowedTillC]) satisfies t.Type<MuniRedemptionTypeU, unknown>;

export const muniRedemptionTypeOrd: Ord.Ord<MuniRedemptionTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniRedemptionType = [eTMByMuniForward, preRefundedByMuniForward, called, calledDueToDefault, escrowedToMaturity, calledByMuniForward, repayment, escrowedAsToInterestOnly, escrowedAsToPrincipalOnly, preRefundedPendingSale, eTMPendingSale, PARTIALCALLCODENOLONGERINUSE, preRefunded, remarketed, tendered, escrowedToConversionDate, crossOverRefunded, partiallyPreRefundedSeeNewCUSIPS, crossOverRefundingEscrowedTill] as const;
export type MuniRedemptionTypeMap<A> = { [K in MuniRedemptionTypeName]: A };


