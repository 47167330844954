import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const generalQuestions = {
  _tag: `GeneralQuestions`,
  text: `General Questions`
} as const;

export type GeneralQuestionsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`GeneralQuestions`>
}>;
export const generalQuestionsTaggedC: GeneralQuestionsTaggedC = t.type({
  _tag: t.literal(`GeneralQuestions`)
});
export type GeneralQuestionsTagged = t.TypeOf<GeneralQuestionsTaggedC>;
export type GeneralQuestions = GeneralQuestionsTagged & typeof generalQuestions;
export type GeneralQuestionsC = t.Type<GeneralQuestions, GeneralQuestionsTagged>;
export const generalQuestionsC: GeneralQuestionsC = pipe(generalQuestionsTaggedC, c => new t.Type<GeneralQuestions, GeneralQuestionsTagged>(
  `GeneralQuestions`,
  (u: unknown): u is GeneralQuestions => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GeneralQuestions> => pipe(c.decode(u), E.map(x => ({ ...x, ...generalQuestions }))),
  (x: GeneralQuestions): GeneralQuestionsTagged => ({ ...x, _tag: `GeneralQuestions`}),
)) satisfies t.Type<GeneralQuestions, unknown>;


export const pressInquiries = {
  _tag: `PressInquiries`,
  text: `Press Inquiries`
} as const;

export type PressInquiriesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PressInquiries`>
}>;
export const pressInquiriesTaggedC: PressInquiriesTaggedC = t.type({
  _tag: t.literal(`PressInquiries`)
});
export type PressInquiriesTagged = t.TypeOf<PressInquiriesTaggedC>;
export type PressInquiries = PressInquiriesTagged & typeof pressInquiries;
export type PressInquiriesC = t.Type<PressInquiries, PressInquiriesTagged>;
export const pressInquiriesC: PressInquiriesC = pipe(pressInquiriesTaggedC, c => new t.Type<PressInquiries, PressInquiriesTagged>(
  `PressInquiries`,
  (u: unknown): u is PressInquiries => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PressInquiries> => pipe(c.decode(u), E.map(x => ({ ...x, ...pressInquiries }))),
  (x: PressInquiries): PressInquiriesTagged => ({ ...x, _tag: `PressInquiries`}),
)) satisfies t.Type<PressInquiries, unknown>;


export const careers = {
  _tag: `Careers`,
  text: `Careers`
} as const;

export type CareersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Careers`>
}>;
export const careersTaggedC: CareersTaggedC = t.type({
  _tag: t.literal(`Careers`)
});
export type CareersTagged = t.TypeOf<CareersTaggedC>;
export type Careers = CareersTagged & typeof careers;
export type CareersC = t.Type<Careers, CareersTagged>;
export const careersC: CareersC = pipe(careersTaggedC, c => new t.Type<Careers, CareersTagged>(
  `Careers`,
  (u: unknown): u is Careers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Careers> => pipe(c.decode(u), E.map(x => ({ ...x, ...careers }))),
  (x: Careers): CareersTagged => ({ ...x, _tag: `Careers`}),
)) satisfies t.Type<Careers, unknown>;


export const productQuestions = {
  _tag: `ProductQuestions`,
  text: `Product Questions`
} as const;

export type ProductQuestionsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ProductQuestions`>
}>;
export const productQuestionsTaggedC: ProductQuestionsTaggedC = t.type({
  _tag: t.literal(`ProductQuestions`)
});
export type ProductQuestionsTagged = t.TypeOf<ProductQuestionsTaggedC>;
export type ProductQuestions = ProductQuestionsTagged & typeof productQuestions;
export type ProductQuestionsC = t.Type<ProductQuestions, ProductQuestionsTagged>;
export const productQuestionsC: ProductQuestionsC = pipe(productQuestionsTaggedC, c => new t.Type<ProductQuestions, ProductQuestionsTagged>(
  `ProductQuestions`,
  (u: unknown): u is ProductQuestions => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProductQuestions> => pipe(c.decode(u), E.map(x => ({ ...x, ...productQuestions }))),
  (x: ProductQuestions): ProductQuestionsTagged => ({ ...x, _tag: `ProductQuestions`}),
)) satisfies t.Type<ProductQuestions, unknown>;


export const sales = {
  _tag: `Sales`,
  text: `Sales`
} as const;

export type SalesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Sales`>
}>;
export const salesTaggedC: SalesTaggedC = t.type({
  _tag: t.literal(`Sales`)
});
export type SalesTagged = t.TypeOf<SalesTaggedC>;
export type Sales = SalesTagged & typeof sales;
export type SalesC = t.Type<Sales, SalesTagged>;
export const salesC: SalesC = pipe(salesTaggedC, c => new t.Type<Sales, SalesTagged>(
  `Sales`,
  (u: unknown): u is Sales => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Sales> => pipe(c.decode(u), E.map(x => ({ ...x, ...sales }))),
  (x: Sales): SalesTagged => ({ ...x, _tag: `Sales`}),
)) satisfies t.Type<Sales, unknown>;


export const helpAndSupport = {
  _tag: `HelpAndSupport`,
  text: `Help & Support`
} as const;

export type HelpAndSupportTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HelpAndSupport`>
}>;
export const helpAndSupportTaggedC: HelpAndSupportTaggedC = t.type({
  _tag: t.literal(`HelpAndSupport`)
});
export type HelpAndSupportTagged = t.TypeOf<HelpAndSupportTaggedC>;
export type HelpAndSupport = HelpAndSupportTagged & typeof helpAndSupport;
export type HelpAndSupportC = t.Type<HelpAndSupport, HelpAndSupportTagged>;
export const helpAndSupportC: HelpAndSupportC = pipe(helpAndSupportTaggedC, c => new t.Type<HelpAndSupport, HelpAndSupportTagged>(
  `HelpAndSupport`,
  (u: unknown): u is HelpAndSupport => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HelpAndSupport> => pipe(c.decode(u), E.map(x => ({ ...x, ...helpAndSupport }))),
  (x: HelpAndSupport): HelpAndSupportTagged => ({ ...x, _tag: `HelpAndSupport`}),
)) satisfies t.Type<HelpAndSupport, unknown>;


export const allCorpContactSubjectC = [generalQuestionsC, pressInquiriesC, careersC, productQuestionsC, salesC, helpAndSupportC] as const;
export const allCorpContactSubjectNames = [`GeneralQuestions`, `PressInquiries`, `Careers`, `ProductQuestions`, `Sales`, `HelpAndSupport`] as const;
export type CorpContactSubjectName = (typeof allCorpContactSubjectNames)[number];

export type CorpContactSubjectCU = t.UnionC<[GeneralQuestionsC, PressInquiriesC, CareersC, ProductQuestionsC, SalesC, HelpAndSupportC]>;
export type CorpContactSubjectU = GeneralQuestions | PressInquiries | Careers | ProductQuestions | Sales | HelpAndSupport;
export const CorpContactSubjectCU: CorpContactSubjectCU = t.union([generalQuestionsC, pressInquiriesC, careersC, productQuestionsC, salesC, helpAndSupportC]) satisfies t.Type<CorpContactSubjectU, unknown>;

export const corpContactSubjectOrd: Ord.Ord<CorpContactSubjectU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCorpContactSubject = [generalQuestions, pressInquiries, careers, productQuestions, sales, helpAndSupport] as const;
export type CorpContactSubjectMap<A> = { [K in CorpContactSubjectName]: A };


export type CorpContactPostC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  subject: CorpContactSubjectCU,
  body: t.StringC,
  captcha: t.StringC
}>;
export type CorpContactPost = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: O.Option<string>,
  subject: CorpContactSubjectU,
  body: string,
  captcha: string
};
export const corpContactPostC: CorpContactPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  subject: CorpContactSubjectCU,
  body: t.string,
  captcha: t.string
}) satisfies t.Type<CorpContactPost, unknown>;


export type CorpDemoPostC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  organization: t.StringC,
  title: OptionFromNullableC<t.StringC>,
  email: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  captcha: t.StringC
}>;
export type CorpDemoPost = {
  firstName: string,
  lastName: string,
  organization: string,
  title: O.Option<string>,
  email: string,
  phoneNumber: O.Option<string>,
  captcha: string
};
export const corpDemoPostC: CorpDemoPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  title: optionFromNullable(t.string),
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  captcha: t.string
}) satisfies t.Type<CorpDemoPost, unknown>;


export type CorpEmailSignupPostC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC
}>;
export type CorpEmailSignupPost = {
  firstName: string,
  lastName: string,
  email: string
};
export const corpEmailSignupPostC: CorpEmailSignupPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string
}) satisfies t.Type<CorpEmailSignupPost, unknown>;


