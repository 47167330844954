import * as t from "io-ts";

export type UsersIssuerC = t.TypeC<{
  clientId: t.NumberC,
  name: t.StringC,
  slug: t.StringC
}>;
export type UsersIssuer = {
  clientId: number,
  name: string,
  slug: string
};
export const usersIssuerC: UsersIssuerC = t.type({
  clientId: t.number,
  name: t.string,
  slug: t.string
}) satisfies t.Type<UsersIssuer, unknown>;


