import type { Html as HtmlType } from "@scripts/codecs/html";
import { coerceHtmlAsString } from "@scripts/syntax/html";

import { type WithHTMLAttrs } from "../util/dom";
import { UnsafeContent } from "./UnsafeContent";

type HtmlProps = Omit<WithHTMLAttrs<unknown>, "content"> & {
  content: HtmlType;
};

export const Html = (props: HtmlProps) =>
  <UnsafeContent
    {...props}
    content={coerceHtmlAsString(props.content)}
  />;
